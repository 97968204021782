import React, { memo, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import TeamGroupModel from "app/models/TeamGroupModel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import CreateNewChannelModal from "app/components/CreateNewChannelModal";
import ListModel from "app/models/ListModel";
import { useActions } from "app/utils/hooks";
import { fetchJoinTeam } from "app/actions/team-group-member-import";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectTeam } from "app/actions/team-group";

interface Props {
  channels: TeamGroupModel[];
  joinableList?: ListModel[];
}

const ChannelstList = ({ channels, joinableList }: Props) => {
  const { t } = useTranslation();
  const channelListActions = useActions({ fetchJoinTeam });
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const url = window.location.pathname;
  const groupsUrl = `/groups/${selectedGroup.id}`;
  const history = useHistory();
  const route = (path: string) => {
    history.push(path);
  };
  const dispatch = useDispatch();

  const parts = url.split("/");
  const teamId = parts[parts.indexOf("channels") + 1];

  const onChannelClick = (channel_id?: string) => {
    dispatch(selectTeam(null));
    if (channel_id) {
      route(`${groupsUrl}/channels/${channel_id}`);
    } else {
      route(groupsUrl);
    }
  };

  const CreateNewChannelHandler = () => {
    setIsModalOpen((prev) => !prev);
  };

  const [canCreateTeamGroup, setCanCreateTeamGroup] = useState(false);

  useEffect(() => {
    if (selectedGroup) {
      if (
        selectedGroup?.permissions?.includes("create_team_groups") &&
        selectedGroup?.planPermissions?.includes("create_team_groups")
      ) {
        setCanCreateTeamGroup(true);
      } else {
        setCanCreateTeamGroup(false);
      }
    }

    return () => {
      setCanCreateTeamGroup(false);
    };
  }, [selectedGroup]);

  const renderCreateChannel = () => {
    return (
      <>
        {canCreateTeamGroup && (
          <button className={styles.add_icon} onClick={CreateNewChannelHandler}>
            <i className="ico ico-add"></i>
          </button>
        )}
      </>
    );
  };

  const onJoinClick = (id: string) => {
    channelListActions.fetchJoinTeam(id).then(() => {
      route(`${selectedGroup.id}/channels/${id}`);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.channels_title_wrapper}>
        <label htmlFor="channels_title">
          {t("Channels")} (
          {channels && channels.length ? channels.length.toString() : "0"})
        </label>
        {renderCreateChannel()}
      </div>
      <div
        className={cls(styles.channels_wrapper, {
          [styles.channels_border]: channels && channels.length > 0,
        })}
      >
        <div className={styles.mobile}>{renderCreateChannel()}</div>
        <div
          className={cls(styles.channel, {
            [styles.active]: !url.includes("channels"),
          })}
          onClick={() => onChannelClick()}
        >
          <div
            className={cls(styles.groups_icon, "team-group-icon ico ico-team")}
          />
          <span className={styles.channel_info}>
            <div className={styles.channel_title}>{t("General")}</div>
            <p>{t("count_member", { count: selectedGroup.membersCount })}</p>
          </span>
        </div>
        {channels &&
          channels.map((channel: TeamGroupModel) => {
            return (
              <div
                key={channel.id}
                className={cls(styles.channel, {
                  [styles.active]:
                    url.includes("channels") && teamId === channel.id,
                })}
                onClick={() => onChannelClick(channel.id)}
              >
                <div
                  className={cls(
                    styles.groups_icon,
                    "team-group-icon ico ico-team",
                  )}
                />
                <span className={styles.channel_info}>
                  <div className={styles.channel_title}>{channel.name}</div>
                  <p>
                    {t("count_member", {
                      count: channel.membersCount,
                    })}
                  </p>
                </span>
              </div>
            );
          })}
        {joinableList &&
          joinableList.map((joinable: TeamGroupModel) => {
            return (
              <div
                key={joinable.id}
                className={cls(styles.channel, styles.joinable)}
                onClick={() => onJoinClick(joinable.id)}
              >
                <div
                  className={cls(
                    styles.groups_icon,
                    "team-group-icon ico ico-team",
                  )}
                />
                <span className={styles.channel_info}>
                  <div className={styles.channel_title}>{joinable.name}</div>
                  <button className={styles.join_btn}>{t("Join")}</button>
                </span>
              </div>
            );
          })}
      </div>
      <CreateNewChannelModal
        isOpen={isModalOpen}
        onClose={CreateNewChannelHandler}
      />
    </div>
  );
};

export default memo(ChannelstList);
