import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";
import { MediaTypes } from "./ResourceCard/index.types";

export const useResourcesModel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.data, shallowEqual);

  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const canAddMedia =
    user && user.planPermissions.includes("upload_to_library");

  const onUpgrade = () => {
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  const onSelectMedia = (media: MediaTypes) => {
    history.push(`/resources/${media?.id}`);
  };

  return {
    user,
    t,
    showPaywallModal,
    canAddMedia,
    onUpgrade,
    closePaywall,
    onSelectMedia,
  };
};
