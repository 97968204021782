import React from "react";
import styles from "./styles.module.scss";
import duration from "app/components/inputs/SelectBox/helpers/DurationConverter";
import playIcon from "app/images/play_btn.svg";

interface Props {
  attachment: any;
}

const VideoAttachment = ({ attachment }: Props) => {
  return (
    <div className={styles.object_thumbnail_wrapper}>
      {attachment?.thumbnails?.default && (
        <img src={attachment?.thumbnails?.default} alt="thumbnail" />
      )}
      <div className={styles.object_item}>
        {attachment?.duration && duration(attachment?.duration) !== "0" && (
          <div className={styles.duraton_play_wrap}>
            <img src={playIcon} alt="play" />
            <p>{duration(attachment?.duration)}</p>
          </div>
        )}
        {/* {attachment?.tags ? (
          <div className={styles.tags_container_video}>
            {attachment?.tags.map((tag: string, index: number) => {
              return (
                <div key={index} className={styles.tag}>
                  #{tag}
                </div>
              );
            })}
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default VideoAttachment;
