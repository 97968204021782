import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";
import PagePanel from "app/components/PagePanel";
import styles from "./styles.module.scss";
import SwitchToggle from "./SwitchToggle";
import { currency_list } from "./utils";
import Navbar from "./Navbar";
import ReturnBar from "./Navbar/ReturnBar";
import OpenCalendarOptions from "./OpenCalendar";
import Reminders from "./Reminders";
import CutOffTime from "./CutOffTimes";
import InfoModal from "../Calendar/InfoModal";
import {
  schedulingFetchGet,
  schedulingFetchPatch,
} from "app/utils/request/scheduling/fetch";
import { RootState } from "typedefs";
import { useTranslation } from 'react-i18next';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { TimeZoneState } from 'app/reducers/scheduling/timeZone';
import { useTimeZonesModel } from "../Sessions/indexModel";
import { Loading } from "app/components/Wrappers";

interface FormValues {
  confirmation_required: boolean;
  reservation_open_time: number;
  reservation_cut_off_time: number;
  reminder_time: number;
  cancellation_cut_off_time: number;
  open_calendar_type: string;
  open_calendar_value: string;
  open_calendar_from_date: Date | null;
  open_calendar_to_date: Date | null;
  second_reminder_time: number | null;
  top_of_the_hour: boolean;
  sequential_booking: boolean;
  reservation_cut_off_type: string;
  cancellation_cut_off_type: string;
  currency: { value: string; label: string } | null;
}

interface InfoContent {
  title?: string;
  status?: "success" | "error";
  content?: string;
  subcontent?: string;
}

export default function Settings() {
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      confirmation_required: false,
      reservation_open_time: 0,
      reservation_cut_off_time: 0,
      reminder_time: 0,
      cancellation_cut_off_time: 0,
      open_calendar_type: "",
      open_calendar_value: "",
      open_calendar_from_date: null,
      open_calendar_to_date: null,
      second_reminder_time: null,
      top_of_the_hour: false,
      sequential_booking: false,
      reservation_cut_off_type: "",
      cancellation_cut_off_type: "",
      currency: null,
    },
  });

  const { t } = useTranslation();

  const [isSequentialBookingEnabled, setIsSequentialBookingEnabled] =
    useState(false);
  const [isTopOfTheHourEnabled, setIsTopOfTheHourEnabled] = useState(false);
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const initialInfoContent: InfoContent = {};
  const [infoPopupContent, setInfoPopupContent] = useState<InfoContent>(initialInfoContent);

  const [coachProfile, setCoachProfile] = useState<CoachProfileModel>();

  const { pending: loadingTimeZones } = useTimeZonesModel();
  const timeZones = useSelector((state: RootState): TimeZoneState => state.schedulingTimeZones);

  useEffect(() => {
    const fetchCoachProfile = async () => {
      try {
        const profileData = await schedulingFetchGet(`coach_profiles/${userId}`);
        setCoachProfile(profileData);
      } catch (error) {
        console.error("Error fetching coach profile:", error);
      }
    };
    fetchCoachProfile();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await schedulingFetchGet(`coach_profiles/${userId}/booking_policy`);
        setValue("confirmation_required", data.confirmationRequired);
        setValue("reservation_open_time", data.reservationOpenTime);
        setValue("reservation_cut_off_time", data.reservationCutOffTime);
        setValue("reminder_time", data.reminderTime);
        setValue("cancellation_cut_off_time", data.cancellationCutOffTime);
        setValue("open_calendar_type", data.openCalendarType);
        setValue("open_calendar_value", data.openCalendarValue);
        setValue(
          "open_calendar_from_date",
          data.openCalendarFromDate ? new Date(data.openCalendarFromDate) : null
        );
        setValue(
          "open_calendar_to_date",
          data.openCalendarToDate ? new Date(data.openCalendarToDate) : null
        );
        setValue("second_reminder_time", data.secondReminderTime);
        setValue("top_of_the_hour", data.topOfTheHour);
        setValue("sequential_booking", data.sequentialBooking);
        setValue("reservation_cut_off_type", data.reservationCutOffType);
        setValue("cancellation_cut_off_type", data.cancellationCutOffType);

        // Set the currency value
        const defaultCurrency = currency_list.find(
          (currency) => currency.value === data.currency
        );
        setValue("currency", defaultCurrency || null);

        setIsSequentialBookingEnabled(data.sequentialBooking);
        setIsTopOfTheHourEnabled(data.topOfTheHour);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [setValue, userId]);

  const handleSequentialBookingToggle = (checked: boolean) => {
    setIsSequentialBookingEnabled(checked);
    if (checked) {
      setIsTopOfTheHourEnabled(false);
      setValue("top_of_the_hour", false);
    }
  };

  const handleTopOfTheHourToggle = (checked: boolean) => {
    setIsTopOfTheHourEnabled(checked);
    if (checked) {
      setIsSequentialBookingEnabled(false);
      setValue("sequential_booking", false);
    }
  };

  const handleCurrencyChange = (selectedOption: any) => {
    setValue("currency", selectedOption);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formattedData = {
      ...data,
      open_calendar_from_date: data.open_calendar_from_date
        ? data.open_calendar_from_date.toISOString()
        : null,
      open_calendar_to_date: data.open_calendar_to_date
        ? data.open_calendar_to_date.toISOString()
        : null,
      currency: data.currency?.value,
    };

    try {
      await schedulingFetchPatch(
        `coach_profiles/${userId}/booking_policy`,
        formattedData
      );
      setInfoModalOpen(true);
      setInfoPopupContent({
        title: t("Changes saved"),
        status: "success",
        content: t("Schedule Options successfully saved."),
      });
    } catch (error) {
      console.error("Error updating calendar settings:", error);
    }
  };

  const customStyles: StylesConfig= {
    control: (base: any) => ({
      ...base,
      background: 'white',
      border: '1px solid #ccc',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#27a4ff',
      padding: '4px',
      borderRadius: '4px',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: 'white',
      fontSize: '16px',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: 'white',
      ':hover': {
        color: 'white',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    container: (provided: any) => ({
      ...provided,
      color: '#8C8C8C',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#8c8c8c',
    }),
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  const getTimeZoneDisplay = (timeZoneValue: string) => {
    const foundTimeZone = timeZones.data.find((tz: any) => tz.value === timeZoneValue);

    return foundTimeZone ? foundTimeZone.display : timeZoneValue;
  };

  if (loadingTimeZones) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <div>
      <ReturnBar />
      <div className={styles.container}>
        <Navbar location={"settings"} />
        <PagePanel title="Schedule Options">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.spaceFromTitle}>
              <label htmlFor="timeZone" className={styles.labelStyle}>
                {t("Timezone")}
              </label>
              <div className={styles.timeZone}>
                <input
                  id="timeZone"
                  readOnly
                  value={
                    coachProfile?.timeZone
                      ? getTimeZoneDisplay(coachProfile?.timeZone): "" }
                  disabled
                  className={styles.readOnlyInput}
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <label htmlFor="currency" className={styles.labelStyle}>
                {t("Currency")}
              </label>
            </div>
            <div className={styles.dropdown}>
              <Select
                id="currency"
                options={currency_list}
                value={watch("currency")}
                onChange={handleCurrencyChange}
                className={styles.responsiveSelect}
                styles={customStyles}
                components={{ IndicatorSeparator }}
              />
            </div>
            <div className={styles.sequetialSessions}>
              <SwitchToggle
                defaultChecked={watch("sequential_booking")}
                title={t("Enable Sequential Sessions")}
                register={register}
                name="sequential_booking"
                setValue={setValue}
                customText={t(
                  "Display sessions in a series, eliminating dead time in your schedule. For example, if you teach 45 minute sessions and start your day at 8am, athletes will only be able to book sessions at 8am, 8:45am, 9:30am, etc."
                )}
                label={t("Sequential Sessions")}
                disabled={isTopOfTheHourEnabled}
                onChange={handleSequentialBookingToggle}
              />
            </div>
            <div className={styles.horizontalLine} />
            <div style={{ marginTop: "10px", marginBottom: "20px" }}>
              <SwitchToggle
                defaultChecked={watch("top_of_the_hour")}
                title={t("Enable Top of the Hour Sessions")}
                register={register}
                name="top_of_the_hour"
                setValue={setValue}
                defaultValue={false}
                customText={t(
                  "Only allow Athletes to book sessions at the top of each hour."
                )}
                label={t("Top of the Hour")}
                disabled={isSequentialBookingEnabled}
                onChange={handleTopOfTheHourToggle}
              />
            </div>
            <div className={styles.horizontalLine} />
            <OpenCalendarOptions
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <div className={styles.horizontalLine} />
            <Reminders register={register} setValue={setValue} watch={watch} />
            <div className={styles.horizontalLine} />
            <CutOffTime
              title={t("Reservation Cut-off Time")}
              customText={t(
                "Cut-off time for Athlete-initiated bookings. Coaches can book a session at any time."
              )}
              register={register}
              setValue={setValue}
              watch={watch}
              prefix="reservation_cut_off"
            />
            <div className={styles.horizontalLine} />
            <CutOffTime
              title={t("Cancellation Cut-off Time")}
              customText={t(
                "Cut-off time for Athlete-initiated edits and cancellations. Coaches can edit or cancel a session at any time."
              )}
              register={register}
              setValue={setValue}
              watch={watch}
              prefix="cancellation_cut_off"
            />
            <div className={styles.horizontalLine} />
            <button type="submit" className={styles.submitButton}>
              {t("Save changes")}
            </button>
          </form>
          <InfoModal
            isOpen={isInfoModalOpen}
            title={infoPopupContent.title}
            status={infoPopupContent.status}
            onDismiss={() => setInfoModalOpen(false)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>{infoPopupContent.content}</div>
              <div>
                <b> {infoPopupContent.subcontent}</b>
              </div>
            </div>
          </InfoModal>
        </PagePanel>
      </div>
    </div>
  );
}
