import React from "react";
import PostingForm from "app/components/PostingForm";
import styles from "./styles.module.scss";
import { CreateReplyModel } from "./indexModel";
import { PostRepliesProps } from "../index";
import { useTranslation } from "react-i18next";

const CreateReply = ({ post, onSubmitReply }: PostRepliesProps) => {
  const { t } = useTranslation();
  const { self, canUploadReplyMedia } = CreateReplyModel();

  if (!self && !post) {
    return null;
  }

  return (
    <div className={styles.post_reply_container}>
      <PostingForm
        isReply
        className={styles.reply_container}
        hasAddMedia
        canUploadMedia={canUploadReplyMedia()}
        profilePicture={self.avatar as string}
        userName={self.displayName}
        textAreaPlaceholder={t("Say something...")}
        hasTagSelect={false}
        hasSchedule={false}
        hasLocationSelect={false}
        onPost={onSubmitReply}
        postTextIsRequired
        postButtonLabel={t("Reply")}
      />
    </div>
  );
};

export default CreateReply;
