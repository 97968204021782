import React, { useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import GroupsNotificationsModal from "./GroupNotificationsModal";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useActions } from "app/utils/hooks";
import { leaveGroup } from "app/actions/groups";
import GroupSettingsModal from "./GroupSettingsModal";
import TransferGroupModal from "./TransferGroupModal";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const GroupSettings = () => {
  const { t } = useTranslation();
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [isGroupSettingsModalOpen, setIsGroupSettingsModalOpen] =
    useState(false);
  const [isTransferGroupModalOpen, setIsTransferGroupModalOpen] =
    useState(false);
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);
  const leaveAction = useActions({ leaveGroup });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const notificationsModalHandler = () => {
    setIsNotificationsModalOpen((prev) => !prev);
  };

  const settingsModalHandler = () => {
    setIsGroupSettingsModalOpen((prev) => !prev);
  };

  const transferGroupModalHandler = () => {
    if (isGroupSettingsModalOpen) {
      setIsGroupSettingsModalOpen(false);
    }
    setIsTransferGroupModalOpen((prev) => !prev);
  };

  const onLeave = () => {
    customPrompt({
      message: t(
        `Are you sure you would like to leave {{selectedGroupName}}'s group?`,
        {
          selectedGroupName: selectedGroup.name,
        }
      ),
      onYes: () => {
        leaveAction.leaveGroup(selectedGroup.id).then(() => route("/groups"));
      },
      onCancel: () => {
        return;
      },
    });
  };

  return (
    <>
      <ul className={styles.dropdown_list}>
        {selectedGroup.permissions.includes("edit") &&
          selectedGroup.planPermissions.includes("edit") && (
            <li onClick={settingsModalHandler}>{t("Group Settings")}</li>
          )}
        <li onClick={notificationsModalHandler}>{t("Your Notifications")}</li>
        {selectedGroup.permissions.includes("leave") &&
          selectedGroup.planPermissions.includes("leave") && (
            <li className={cls("text-danger")} onClick={onLeave}>
              {t("Leave Group")}
            </li>
          )}
      </ul>
      {isNotificationsModalOpen && (
        <GroupsNotificationsModal
          isModalOpen={isNotificationsModalOpen}
          modalHandler={notificationsModalHandler}
        />
      )}
      {isGroupSettingsModalOpen && (
        <GroupSettingsModal
          isModalOpen={isGroupSettingsModalOpen}
          modalHandler={settingsModalHandler}
          transferGroupModalHandler={transferGroupModalHandler}
        />
      )}
      {isTransferGroupModalOpen && (
        <TransferGroupModal
          isModalOpen={isTransferGroupModalOpen}
          modalHandler={transferGroupModalHandler}
        />
      )}
    </>
  );
};

export default GroupSettings;
