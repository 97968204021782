import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import Portal from "app/components/portal";
import { ModalLayoutProps } from "./index.types";
import cls from "app/utils/classNames";

const ModalLayout = ({
  children,
  onClose,
  isOpen,
  classNames,
}: ModalLayoutProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.wrapper}>
        <div className={cls(styles.overlay)} onClick={onClose} />
        <div className={cls(styles.popup, classNames)}>{children}</div>
      </div>
    </Portal>
  );
};

export default ModalLayout;
