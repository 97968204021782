import React from "react";
import Post from "../Post";
import PostModel from "app/models/PostModel";
import useGroupsPostsContainerModel from "./indexModel";
// import ScheduledPostsCounter from "../ScheduledPosts/ScheduledPostsCounter";
import NoPosts from "../NoResults/NoPosts";
import { Loading } from "../Wrappers";
import ScheduledPostsCounter from "../ScheduledPosts/ScheduledPostsCounter";
import { ScheduledPostsModel } from "app/reducers/schedule";

export interface GroupsPostsContainerProps {
  schedulesGoToLocation?: string;
  canUploadReplyMedia?: boolean;
  displayPostLocations?: boolean;
  hidePinBadge?: boolean;
  no_posts_title?: string;
  no_posts_description?: string;
  no_posts_image?: string;
  no_posts_className?: string;
  posts?: PostModel[];
  scheduledPostsData: ScheduledPostsModel;
  onReport?: (post: PostModel) => void;
  onPostDelete?: (post: PostModel) => void;
  onPostUpdate?: (post: PostModel) => void;
  onPostPin?: () => void;
  containerRef?: any;
  pending?: boolean;
}

const GroupsPostsContainer: React.FC<GroupsPostsContainerProps> = ({
  schedulesGoToLocation,
  displayPostLocations,
  hidePinBadge,
  no_posts_title,
  no_posts_description,
  no_posts_image,
  no_posts_className,
  posts,
  scheduledPostsData,
  onReport,
  onPostDelete,
  onPostUpdate,
  onPostPin,
  containerRef,
  pending,
}: GroupsPostsContainerProps) => {
  const { self } = useGroupsPostsContainerModel();

  if (!self) {
    return null;
  }

  if (posts?.length === 0) {
    if (!pending) {
      return (
        <NoPosts
          title={no_posts_title}
          image={no_posts_image}
          description={no_posts_description}
          className={no_posts_className}
        />
      );
    } else {
      return <Loading isLoading loadType="spinner" />;
    }
  }

  return (
    <>
      {scheduledPostsData?.posts?.length > 0 && (
        <ScheduledPostsCounter scheduledPosts={scheduledPostsData} goToLocation={schedulesGoToLocation} />
      )}
      {posts?.map((post: PostModel) => {
        return (
          <Post
            key={post.id}
            post={post}
            user={self}
            onEdit={onPostUpdate}
            onEditTags={onPostUpdate}
            onRemove={onPostDelete}
            onPin={onPostPin}
            onReport={onReport}
            hidePinBadge={hidePinBadge}
            displayLocation={displayPostLocations}
          />
        );
      })}
      {pending && posts?.length > 0 && <Loading isLoading loadType="spinner" />}
      <div ref={containerRef} />
    </>
  );
};

export default GroupsPostsContainer;
