import React from 'react';
import { DateTime } from 'luxon';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputDatePicker from 'app/components/DatePicker/InputDatePicker';

const DateSelector = React.forwardRef((props: any, _ref) => {
  const { t } = useTranslation();
  const { name, disabled, control, maxDate } = props;

  const { field } = useController({ name, control });

  const date = field.value ? DateTime.fromJSDate(field.value) : null;

  return (
    <InputDatePicker
      placeholderText={t('Select Date')}
      setDate={(d) => field.onChange(d.toJSDate())}
      date={date}
      disabled={disabled}
      maxDate={maxDate}
    />
  );
});

export default DateSelector;
