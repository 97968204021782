import PostModel from "app/models/PostModel";

export const canMove = (post: PostModel) => {
  return (
    post?.permissions?.includes("move") &&
    post?.planPermissions?.includes("move")
  );
};

export const canCopy = (post: PostModel) => {
  return (
    post?.permissions?.includes("copy") &&
    post?.planPermissions?.includes("copy")
  );
};

export const canAddToRecruit = (post: PostModel) => {
  return (
    post?.permissions?.includes("create_recruit_milestone") &&
    post?.planPermissions?.includes("create_recruit_milestone")
  );
};

export const canTag = (post: PostModel) => {
  return post?.permissions?.indexOf("tag") !== -1;
};

export const canPin = (post: PostModel) => {
  return post?.permissions?.indexOf("pin") !== -1;
};

export const canDelete = (post: PostModel) => {
  return post?.permissions?.indexOf("delete") !== -1;
};

export const canEdit = (post: PostModel) => {
  return post?.permissions?.indexOf("edit") !== -1;
};

export const canReply = (post: PostModel) => {
  // return post && post.permissions && post.permissions.indexOf("reply") !== -1;
  if (post?.spacesCount > 0) {
    return false;
  }

  return (
    post?.permissions?.includes("reply") &&
    post?.planPermissions?.includes("reply")
  );
};

export const canSeePostData = (post: PostModel) => {
  if (post?.permissions) {
    return (
      post?.permissions?.includes("access_views") &&
      post?.permissions?.includes("access_likes")
    );
  }
  return false;
};

export const canLike = (post: PostModel) => {
  return post?.permissions?.indexOf("like") !== -1;
};

export const planCanTag = (post: PostModel) => {
  return post?.planPermissions?.indexOf("tag") !== -1;
};

export const planCanDelete = (post: PostModel) => {
  return post?.planPermissions?.indexOf("delete") !== -1;
};

export const planCanEdit = (post: PostModel) => {
  return post?.planPermissions?.indexOf("edit") !== -1;
};

export const planCanPin = (post: PostModel) => {
  return post?.planPermissions?.indexOf("pin") !== -1;
};

export const planCanReply = (post: PostModel) => {
  return post?.planPermissions?.indexOf("reply") !== -1;
};

export const planCanLike = (post: PostModel) => {
  return post?.planPermissions?.indexOf("like") !== -1;
};
