import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";

const pending = () => action("@likes.fetch.pending");

const error = (err: string) => action("@likes.fetch.error", err);

const success = (success: any) => action("@likes.fetch.success", success);

const data = (data: any[]) => action("@likes.fetch.data", data);

const postId = (postId: string) => action("@likes.fetch.postId", postId);

const hasMore = (hasMore: boolean) => action("@likes.fetch.hasMore", hasMore);

export const clearLikesState = () => action("@likes.clear");

const thisActions = {
  pending,
  error,
  success,
  data,
  hasMore,
  postId,
  clearLikesState,
};

export type currentLikesAction = ActionType<typeof thisActions>;

export function fetchcurrentLikes(postId: string, currentPage: number) {
  return (dispatch: Dispatch<currentLikesAction>) => {
    dispatch(pending());

    const url =
      config.API_SERVER + `/api/v1/posts/${postId}/likes?page=${currentPage}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((currentLikes) => dispatch(success(currentLikes)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function likePost(postId: string) {
  return (dispatch: Dispatch<currentLikesAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts/${postId}/likes`;
    const promise = request(url, "POST", {}, { version: 1 });

    return promise;
  };
}

export function unlikePost(postId: string) {
  return (dispatch: Dispatch<currentLikesAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/posts/${postId}/likes`;
    const promise = request(url, "DELETE", {}, { version: 1 });

    return promise;
  };
}
