/**
 * @module Reducers.Group
 *
 */
import { RequestError } from "typedefs";
import { GroupAction } from "app/actions/groups";
import { CreateGroupsModel, GroupsModel } from "app/models";
import UserModel from "app/models/UserModel";

export type GroupsMemberState = {
  hasMoreMembers: boolean;
  membersPage: number;
  membersData: null | UserModel[];
  membersPending: false;
  membersError: RequestError | null;
};

export type MyInformationState = {
  myInformation: UserModel | null;
  myInformationPending: boolean;
  myInformationError: RequestError | null;
};

export type GroupsState = {
  data: GroupsModel[] | null;
  error: RequestError | null;
  pending: boolean;
  newGroupData: CreateGroupsModel;
  newGroupDataError: string;
  groupPending: boolean;
  selectedGroup: GroupsModel | null;
  hasMoreMembers: boolean;
  membersPage: number;
  membersData: null | UserModel[];
  membersPending: false;
  membersError: RequestError | null;
  myInformation: UserModel | null;
  myInformationPending: boolean;
  myInformationError: RequestError | null;
  modalIsOpen: boolean;
  modalError: null | string;
  modalPending: boolean;

  newOwner?: UserModel | null;
};

const initialState: GroupsState = {
  data: null,
  error: null,
  pending: false,
  newGroupData: null,
  newGroupDataError: null,
  groupPending: false,
  selectedGroup: null,
  hasMoreMembers: true,
  membersPage: 1,
  membersData: null,
  membersPending: false,
  membersError: null,
  myInformation: null,
  myInformationPending: null,
  myInformationError: null,
  modalIsOpen: false,
  modalError: null,
  modalPending: false,
};

const initialSelectedGroupState: GroupsModel = {
  selectedGroup: null,
};

const initialMemberState: GroupsMemberState = {
  hasMoreMembers: false,
  membersPage: 1,
  membersData: null,
  membersPending: false,
  membersError: null,
};

const initialMyInformation: MyInformationState = {
  myInformation: null,
  myInformationPending: null,
  myInformationError: null,
};

export default function listReducer(
  state = initialState,
  action: GroupAction
): GroupsState {
  switch (action.type) {
    case "@groups.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@groups.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@groups.fetch.error":
      return { ...state, error: action.payload, pending: false };

    case "@groups.fetch.pendingMembers":
      return { ...state, membersError: null, pending: true };
    case "@groups.fetch.successMembers":
      return { ...state, membersData: action.payload, pending: false };
    case "@groups.fetch.errorMembers":
      return { ...state, membersError: action.payload, pending: false };

    case "@groups.fetch.myInfo":
      return { ...state, myInformation: action.payload, pending: false };
    case "@groups.fetch.myInfoPending":
      return { ...state, myInformationPending: null, pending: true };
    case "@groups.fetch.myInfoError":
      return { ...state, myInformationError: action.payload, pending: false };

    case "@groups.fetch.newGroupData":
      return {
        ...state,
        newGroupDataError: null,
        newGroupData: action.payload,
        groupPending: false,
      };
    case "@groups.fetch.newGroupDataError":
      return {
        ...state,
        newGroupDataError: action.payload,
        groupPending: false,
      };
    case "@groups.fetch.newGroupPending":
      return { ...state, error: null, groupPending: true };
    case "@groups.fetch.selectedGroupsData":
      return { ...state, selectedGroup: action.payload, pending: false };

    case "@groups.fetch.newOwner":
      return { ...state, newOwner: action.payload };
    case "@groups.isOpenSuccessModal":
      return { ...state, modalIsOpen: action.payload, modalPending: false };
    case "@groups.pendingModal":
      return { ...state, modalPending: false };
    case "@groups.errorModal":
      return { ...state, modalError: action.payload, modalPending: false };
    case "@groups.clear":
      return {
        ...state,
        ...initialState,
      };
    case "@groups.clearSelectedGroup":
      return {
        ...state,
        ...initialSelectedGroupState,
      };
    case "@groups.clearMembers":
      return {
        ...state,
        ...initialMemberState,
      };
    case "@groups.clearNewGroup":
      return {
        ...state,
        groupPending: false,
        newGroupData: null,
        newGroupDataError: null,
      };
    case "@groups.clearMyInformation":
      return {
        ...state,
        ...initialMyInformation,
      };
    case "@groups.clearNewOwner":
      return { ...state, newOwner: null };

    default:
      return state;
  }
}
