import React from 'react';
import { DateTime } from 'luxon';
import styles from './styles.module.scss';

import { EventModel } from 'app/models/EventModel';

type PropsDef = {
  date: Date;
  isToday: boolean;
  events: EventModel[];
  view: any;
};

const DayHeader = (props: PropsDef) => {
  const { date, isToday, events, view } = props;
  const tzDate = DateTime.fromJSDate(date).setZone(view.dateEnv.timeZone);

  const hasEvent = events.some((event) => {
    const eventTzDate =  DateTime.fromJSDate(event.date).setZone(view.dateEnv.timeZone);
    return eventTzDate.hasSame(tzDate, 'day');
  });

  const dayClassName = `${styles.number_container} ${
    isToday ? styles.number_circle : null
  }`;

  const eventDot = <span className={styles.dot}></span>;

  const day = tzDate.toLocaleString({ weekday: 'short' });
  const dayNumber = tzDate.toLocaleString({ day: 'numeric' });

  return (
    <div className={styles.day_container}>
      <p className={styles.day_name}>{day}</p>
      <div className={dayClassName}>
        <p className={styles.number}>{dayNumber}</p>
        {hasEvent ? eventDot : null}
      </div>
    </div>
  );
};

export default DayHeader;
