/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslation } from 'react-i18next';

/**
 * @class PrivacyPolicy
 *
 */
export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <a href="https://coachnow.io/privacy-policy">
      <FormattedMessage
        id="app.links.privacy-policy"
        defaultMessage={t("Privacy Policy")}
        description={t("Privacy policy link text")}
      />
    </a>
  );
}

