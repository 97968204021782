import React from 'react';
import styles from './DateTime.module.scss';
import TimeSlot from './TimeSlot';

const TimeSlots = ({
  timeSlots,
  noSlotsText,
  selectedSlot,
  onSlotSelect,
  onCurrentDay = false,
  multiCoach = false,
}) => {
  if (timeSlots.length === 0) {
    return <div className={styles.no_slots}>{noSlotsText}</div>;
  }

  const renderSlot = (slot) => {
    const isSelected =
      selectedSlot?.startHour === slot.timeId &&
      selectedSlot?.coachId === slot.coachProfileId;

    return (
      <TimeSlot
        key={`${slot.timeId}-${slot.coachProfileId}`}
        time={slot}
        isSelected={isSelected}
        onSlotSelect={onSlotSelect}
        onCurrentDay={onCurrentDay}
        multiCoach={multiCoach}
      />
    );
  };

  return (
    <div className={`${styles.time_slots}`}>
      {timeSlots.map(renderSlot)}
    </div>
  );
};

export default TimeSlots;
