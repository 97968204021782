/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslation } from 'react-i18next';

/**
 * @class TermsOfService
 *
 */
export default function TermsOfService() {
  const { t } = useTranslation();

  return (
    <a href="https://coachnow.io/terms-of-service">
      <FormattedMessage
        id="app.links.terms-of-service"
        defaultMessage={t("Terms of Service")}
        description={t("Terms of service link text")}
      />
    </a>
  );
}

