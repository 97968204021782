import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

export const DemoSpaceTimelineModel = () => {
  const { selectedDemoSpace } = useSelector(
    (state: RootState) => state.demoSpace, shallowEqual
  );

  return { selectedDemoSpace };
};
