import {
  fetchNotifications,
  markAllRead,
  clear,
  markAllSeen,
  getTotalUnseen,
} from "app/actions/Notification";
import { useActions, useSelector } from "app/utils/hooks";
import { useCallback, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";

export const useNotificationsModel = () => {
  const notificationAction = useActions({
    fetchNotifications,
    getTotalUnseen,
    markAllRead,
    markAllSeen,
    clear,
  });
  const { totalUnseen } = useSelector(
    (state: RootState) => state.notification,
    shallowEqual,
  );

  useEffect(() => {
    if (totalUnseen && totalUnseen?.notifications > 0) {
      notificationAction.markAllSeen();
      notificationAction.getTotalUnseen();
    }
  }, [totalUnseen?.notifications]);

  const markAllAsRead = useCallback(() => {
    if (totalUnseen?.notifications === 0) {
      return;
    }

    notificationAction.clear();
    notificationAction.markAllRead();
    notificationAction.fetchNotifications();
  }, [notificationAction, totalUnseen?.notifications]);

  return {
    markAllAsRead,
  };
};
