import { AlertAction } from "app/actions/alert";
import { Alert } from "app/models/AlertModel";
import { v4 } from "uuid";

export type AlertState = {
  alerts: Alert[];
};

const initialState: AlertState = {
  alerts: [],
};

export default function alertReducer(
  state = initialState,
  action: AlertAction
) {
  switch (action.type) {
    case "@alert.add":
      return {
        ...state,
        alerts: [...state.alerts, { ...action.payload, id: v4() }],
      };
    case "@alert.expire":
      const alert = state.alerts.find((a) => a.id === action.payload.id);

      if (alert) {
        alert.expired = true;
      }

      if (
        state.alerts.filter((a) => a.expired).length === state.alerts.length
      ) {
        return { ...state, alerts: [] };
      }

      return { ...state, alerts: state.alerts };
    default:
      return state;
  }
}
