import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import upload from "app/utils/uploader";
import { cls } from "app/utils";
import CoachCoverUpload from "../CoachCoverUpload";
import { Loading } from "../Wrappers";

interface Props {
  image?: string;
  onChange?: any;
  onDelete?: () => void;
}

const CoverImage = ({ image, onChange, onDelete }: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loadedImage, setLoadedImage] = useState(null);
  const [convertedToBlob, setConvertedToBlob] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const uploadRef = useRef(null);

  const uploadModalHandler = () => {
    setIsUploadModalOpen((prev) => !prev);
  };

  const deleteHandler = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setLoadedImage(file);
        setConvertedToBlob(URL.createObjectURL(file));
        uploadModalHandler();
      }
    },
    [setLoadedImage, setConvertedToBlob, uploadModalHandler],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  const onSubmit = async (file: File | any) => {
    try {
      setIsLoading(true);

      const abortableUpload = upload(
        file.blob || file,
        file.name,
        file.type,
        undefined,
      );
      uploadRef.current = abortableUpload;
      const response = await abortableUpload.promise;
      abortableUpload.subscribe(
        (progressData) => {
          const percentage = Math.floor(
            (progressData.loaded / progressData.total) * 100,
          );
          setProgress(percentage);
        },
        (error) => {
          setUploadError(error);
          setProgress(0);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          return onChange(response);
        },
      );
    } catch (error) {
      console.error("Error during file upload:", error);
      setUploadError(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.upload_progress}>
          <div className={styles.upload_progress_content}>
            {!uploadError && <Loading loadType="spinner" isLoading />}
            {uploadError && <p>{t("Upload failed")}</p>}

            <div className={styles.progress_bar_container}>
              <div
                className={styles.progress_bar}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!image && !isLoading) {
    return (
      <div className={styles.container}>
        <div {...getRootProps({ className: "dropzone" })}>
          <div
            className={cls(styles.container, {
              [styles.dropzone_inner_active]: isDragActive,
            })}
          >
            <div className={cls(styles.dropzone_inner)}>
              <div className={styles.content_wrapper}>
                <p className={styles.plus}>+</p>
                <p>{t("Add Cover Image")}</p>
              </div>
            </div>
          </div>
          <input {...getInputProps()} id="media__uploader" />
        </div>
        <CoachCoverUpload
          isOpen={isUploadModalOpen}
          onClose={uploadModalHandler}
          imageSrc={convertedToBlob}
          onChange={onSubmit}
          imageFile={loadedImage}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={cls(styles.container, {
          [styles.dropzone_inner_active]: isDragActive,
        })}
      >
        <div className={styles.cover_image_controls}>
          <div {...getRootProps({ className: "dropzone" })}>
            <span className={styles.control_icon_container}>
              <i className="ico ico-edit-outline" />
            </span>
          </div>
          <span
            className={styles.control_icon_container}
            onClick={deleteHandler}
          >
            <i className="ico ico-trash-outline" />
          </span>
        </div>
        <img className={styles.cover_image} src={image} alt="cover" />
      </div>
      <CoachCoverUpload
        isOpen={isUploadModalOpen}
        onClose={uploadModalHandler}
        imageSrc={convertedToBlob}
        onChange={onSubmit}
        imageFile={loadedImage}
      />
    </>
  );
};

export default CoverImage;
