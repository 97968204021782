import React from "react";
import ErrorPageLayout from "../../components/Layouts/ErrorPageLayout";
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorPageLayout title={t("404 - Page not found")}>
      <p>{t("This page doesn't exist or you don't have enough permissions.")}</p>
    </ErrorPageLayout>
  );
};

export default PageNotFound;
