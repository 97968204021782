import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import Switch from 'react-switch';
import { range } from 'lodash';

import FormItem from "app/components/inputs/FormItem";
import TimeRangeInput from "app/components/inputs/TimeRangeInput";
import DateSelector from "./DateSelector";
import RecurrenceSelector from "./RecurrenceSelector";

import styles from './styles.module.scss';

type PropsDef = {
  onDateChange: (date: Date) => void;
};

const errorMessageFor = (errors, field) => {
  if (errors[field]) {
    return errors[field].message;
  }
  return null;
};


export default function ScheduleBlockForm(props: PropsDef) {
  const { t } = useTranslation();
  const { onDateChange } = props;

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchTimeZone = watch('timeZone');
  const watchStartTime = watch('start');
  const watchEndTime = watch('end');

  const luxonStartAt = useMemo(() => {
    return DateTime.fromISO(watchStartTime, { zone: watchTimeZone });
  }, [watchStartTime]);

  const luxonEndAt = useMemo(() => {
    return DateTime.fromISO(watchEndTime, { zone: watchTimeZone });
  }, [watchEndTime]);

  const disabledEndHours = useCallback(() => {
    const disabled = range(0, 23).filter(h => h < luxonStartAt.hour);

    return disabled;
  }, [watchStartTime]);

  const disabledEndMinutes = useCallback((endHour: number) => {
    const startHour = luxonStartAt.hour;
    const startMinute = luxonStartAt.minute;
    if (endHour > startHour) {
      return [];
    }

    return [0, 15, 30, 45].filter(m => m <= startMinute);
  }, [watchStartTime]);

  const onStartTimeChange = (changeEvent) => {
    const newStartAt = DateTime.fromISO(changeEvent.target.value, { zone: watchTimeZone });
    const diff =  newStartAt.diff(luxonStartAt);

    setValue('start', changeEvent.target.value);
    if (luxonEndAt.isValid) {
      setValue('end', luxonEndAt.plus(diff));
    }
  };

  const onEndTimeChange = (changeEvent) => {
    const newEndAt = DateTime.fromISO(changeEvent.target.value, { zone: watchTimeZone });

    if (newEndAt > luxonStartAt) {
      setValue('end', changeEvent.target.value);
      return;
    }

    setValue('end', luxonStartAt.plus({ minute: 15 }).toISO());
  };

  return (
    <>
      <FormItem error={errorMessageFor(errors, 'private')} className={`${styles.input_field} ${styles.checkbox_field}`}>
        <Switch
          {...register('private')}
          checked={watch('private') || false}
          name="private"
          onChange={(checked) => {
            setValue('private', checked);
          }}
          offColor="#C2C8D8"
          onColor="#27A4FF"
          onHandleColor="#FFFFFF"
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          height={30}
          width={50}
        />

        <label>{t('Private')}</label>
      </FormItem>

      <FormItem error={errorMessageFor(errors, 'title')} className={styles.input_field}>
        <label>{t('Title')}</label>
        <input {...register('title')} placeholder={t('Event Name')} />
      </FormItem>

      <FormItem error={errorMessageFor(errors, 'date')} className={styles.input_field}>
        <label>{t('Date')}</label>
        <DateSelector {...register('date', { onChange: onDateChange })} />
      </FormItem>

      <div className={styles.form_row}>
        <FormItem error={errorMessageFor(errors, 'start')} className={styles.input_field}>
          <label>{t('Start Time')}</label>
          <TimeRangeInput {...register('start', { onChange: onStartTimeChange })} control={control} timeZone={watchTimeZone} />
        </FormItem>

        <FormItem error={errorMessageFor(errors, 'end')} className={styles.input_field}>
          <label>{t('End Time')}</label>
          <TimeRangeInput {...register('end', { onChange: onEndTimeChange })} control={control} timeZone={watchTimeZone} disabledHours={disabledEndHours} disabledMinutes={disabledEndMinutes}/>
        </FormItem>
      </div>

      <RecurrenceSelector />

      <FormItem error={errorMessageFor(errors, 'notes')} className={styles.input_field}>
        <label>{t('Notes')}</label>
        <textarea style={{ height: 100, resize: 'none' }} {...register('notes')} placeholder={t('Add note here')}/>
      </FormItem>
    </>
  );
}
