import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface Props {
  details: string;
  isDemo?: boolean;
}

const PostDetails = ({ details, isDemo }: Props) => {
  const [isReadingMore, setIsReadingMore] = useState(false);

  const [showReadMore, setShowReadMore] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const readMoreHandler = useCallback(() => {
    setIsReadingMore(true);
  }, []);

  useEffect(() => {
    if (ref.current) {
      setShowReadMore(ref.current.clientHeight !== ref.current.scrollHeight);
      setIsReadingMore(ref.current.clientHeight === ref.current.scrollHeight);
    }
  }, []);

  return (
    <>
      <div
        className={cls(styles.details, {
          [styles.demo_details]: isDemo,
          [styles.is_reading_more]: isReadingMore,
          [styles.not_reading_more]: !isReadingMore,
        })}
        ref={ref}
      >
        {details}
      </div>
      {showReadMore && !isReadingMore && (
        <p className={styles.read_more} onClick={readMoreHandler}>
          Read More
        </p>
      )}
    </>
  );
};

export default PostDetails;
