import React from 'react';
import styles from "./styles.module.scss";
import LeftArrow from "../../../../../public/images/back-icon.svg";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type PropsDef = {
  returnTo?: string;
};

export default function ReturnBar(props: PropsDef) {
  const { t } = useTranslation();
  const history = useHistory();

  const { returnTo = '/calendar' } = props;

  const handleIconClick = () => {
    history.push(returnTo);
  };

  return (
    <div className={styles.returnBar}>
      <div
        className={styles.iconWrapper}
        onClick={handleIconClick}
      >
        <img className={`${styles.icon} ${styles.iconOutside}`} src={LeftArrow} alt="info" />
      </div>

      <div className={styles.container}>
        <div
          className={`${styles.iconWrapper} ${styles.iconInsideWrapper}`}
          onClick={handleIconClick}
        >
          <img className={`${styles.icon2} ${styles.iconInside}`} src={LeftArrow} alt="info" />
        </div>
        <h1 className={styles.heading}>{t("Calendar Settings")}</h1>
      </div>
    </div>
  );
}
