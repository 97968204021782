import React, { useMemo, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { compact, isDate, range, difference } from 'lodash';

import FormItem from "app/components/inputs/FormItem";
import MultiSelect from "app/components/inputs/MultiSelect";
import TimeRangeInput from "app/components/inputs/TimeRangeInput";
import StudentSelector from "app/components/StudentSelector";
import DateSelector from "./DateSelector";
import SessionTypeSelector from "./SessionTypeSelector";
import RecurrenceSelector from "./RecurrenceSelector";

import { errorMessageFor } from './helpers';

import styles from './styles.module.scss';

import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { LessonTypeModel } from 'app/models/scheduling/LessonTypeModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';

type PropsDef = {
  lessonRateOptions: LessonRateModel[];
  lessonTypeOptions: LessonTypeModel[];
  onDateChange: (date: Date) => void;
  coachProfile: CoachProfileModel;
};

export default function SessionForm(props: PropsDef) {
  const { t } = useTranslation();
  const { lessonRateOptions, lessonTypeOptions, onDateChange, coachProfile } = props;

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchTimeZone = watch('timeZone');
  const watchLocation = watch('location');
  const watchStartTime = watch('start');
  const watchEndTime = watch('end');
  const watchLessonRateId = watch('lessonRateId');

  const luxonStartAt = useMemo(() => {
    return DateTime.fromISO(watchStartTime, { zone: watchTimeZone });
  }, [watchStartTime]);

  const luxonEndAt = useMemo(() => {
    return DateTime.fromISO(watchEndTime, { zone: watchTimeZone });
  }, [watchEndTime]);

  const disabledEndHours = useCallback(() => {
    const disabled = range(0, 23).filter(h => h < luxonStartAt.hour);

    return disabled;
  }, [watchStartTime]);

  const disabledEndMinutes = useCallback((endHour: number) => {
    const startHour = luxonStartAt.hour;
    const startMinute = luxonStartAt.minute;
    if (endHour > startHour) {
      return [];
    }

    return [0, 15, 30, 45].filter(m => m <= startMinute);
  }, [watchStartTime]);

  const onStartTimeChange = (changeEvent) => {
    const newStartAt = DateTime.fromISO(changeEvent.target.value, { zone: watchTimeZone });
    const diff =  newStartAt.diff(luxonStartAt);

    setValue('start', changeEvent.target.value);
    if (luxonEndAt.isValid) {
      setValue('end', luxonEndAt.plus(diff));
    }
  };

  const onEndTimeChange = (changeEvent) => {
    const newEndAt = DateTime.fromISO(changeEvent.target.value, { zone: watchTimeZone });

    if (newEndAt > luxonStartAt) {
      setValue('end', changeEvent.target.value);
      return;
    }

    setValue('end', luxonStartAt.plus({ minute: 15 }).toISO());
  };

  const onLessonRateChange = (changeEvent) => {
    const sessionType = changeEvent.target.value;
    setValue('lessonRateId', sessionType.id);
    setValue('rate', sessionType.rate);
    setValue('location', sessionType.location);
    setValue('lessonTypesIds', compact([sessionType.defaultLessonTypeId]));
  };

  const onLessonTypeChange = (changeEvent) => {
    const newLessonTypeIds = changeEvent.target.value;
    setValue('lessonTypesIds', newLessonTypeIds, { shouldValidate: true, shouldDirty: true });

    if (!watchLessonRateId) {
      return;
    }

    const sessionType = lessonRateOptions.find(lr => lr.id === watchLessonRateId);

    if (!sessionType) { return; }


    if (difference(newLessonTypeIds, sessionType.lessonTypes.map(lt => lt.id)).length > 0) {
      setValue('lessonRateId', null, { shouldValidate: true, shouldDirty: true });
    }

  };

  const onRateChange = (changeEvent) => {
    const newRate = changeEvent.target.value;
    setValue('rate', newRate);

    if (!watchLessonRateId) {
      return;
    }

    const sessionType = lessonRateOptions.find(lr => lr.id === watchLessonRateId);

    if (!sessionType) { return; }

    if (sessionType.rate !== newRate) {
      setValue('lessonRateId', null, { shouldValidate: true, shouldDirty: true });
    }
  };


  return (
    <div className={`${styles.form_content} form_fields`}>
      <FormItem error={errorMessageFor(errors, 'studentProfiles')} className={styles.input_field}>
        <label>{t('Participating Athletes')}</label>
        <StudentSelector {...register('studentProfiles', { validate: ids => ids.length > 0 })} control={control} facilityId={coachProfile.facilityId} />
      </FormItem>

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t('Date')}</label>
        <DateSelector
          {...register('date', { onChange: onDateChange, validate: isDate })}
          control={control}
        />
      </FormItem>

      <div className={styles.form_row}>
        <FormItem error={errorMessageFor(errors, 'start')}  className={styles.input_field}>
        <label>{t('Start Time')}</label>
          <TimeRangeInput {...register('start', { onChange: onStartTimeChange })} control={control} timeZone={watchTimeZone} />
        </FormItem>

        <FormItem error={errorMessageFor(errors, 'end')}className={styles.input_field}>
        <label>{t('End Time')}</label>
          <TimeRangeInput {...register('end', { onChange: onEndTimeChange })} control={control} timeZone={watchTimeZone} disabledHours={disabledEndHours} disabledMinutes={disabledEndMinutes} />
        </FormItem>
      </div>

      <RecurrenceSelector />

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t('Session')}</label>
        <SessionTypeSelector
          {...register('lessonRateId', { onChange: onLessonRateChange })}
          control={control}
          options={lessonRateOptions}
        />
      </FormItem>

      <FormItem error={errorMessageFor(errors, 'lessonTypesIds')} className={styles.input_field}>
        <label>{t('Type')}</label>
        <MultiSelect
          {...register('lessonTypesIds', { onChange: onLessonTypeChange, validate: (val) => val.length > 0 })}
          control={control}
          options={lessonTypeOptions.map(lt => ({ value: lt.id, label: lt.name }) )}
        />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t('Rate')}</label>
        <div className={styles.currency_container}>
          <span>{coachProfile.currencySymbol}</span>
          <input
            {...register('rate', { onChange: onRateChange })}
            placeholder="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            />
        </div>
      </FormItem>


      {watchLocation && (
        <FormItem className={styles.input_field}>
          <label>{t('Location')}</label>
          <input type="text" {...register('location')} disabled />
        </FormItem>
      )}

      <FormItem className={styles.input_field}>
       <label>{t('Notes')}</label>
        <textarea style={{ height: 100, resize: 'none' }} {...register('notes')} placeholder={t( "Add note here" )} />
      </FormItem>
    </div>
  );
}
