import { BookSessionAction } from "app/actions/scheduling/bookSession";

import { LessonSchedule } from 'app/models/EventModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { FacilityModel } from 'app/models/scheduling/FacilityModel';
import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { LessonTypeModel } from 'app/models/scheduling/LessonTypeModel';

export type BookSessionState = {
  facility: FacilityModel,
  coach: CoachProfileModel;
  lessonTypes: LessonTypeModel[];
  date: string;
  time: {
    startHour: number;
    endHour: number;
    timeZone: string;
  };
  public: boolean;
  isComplete: boolean;
  location: string;
  durationPricing: string;
  pricingValue: LessonRateModel;
  selectedSession?: LessonSchedule,
  duration: any;
};

const initialState: BookSessionState = {
  facility: null,
  coach: null,
  lessonTypes: [],
  date: null,
  time: null,
  public: false,
  isComplete: false,
  location: null,
  durationPricing: '',
  pricingValue: null,
  selectedSession: null,
  duration: {},
};


export default function bookSession(
  state = initialState,
  action: BookSessionAction
): BookSessionState {
  switch (action.type) {
    case "@bookSession.setIsComplete":
      return { ...state, isComplete: action.payload };
    case "@bookSession.init":
      return {
        ...state,
        ...action.payload,
      };
    case "@bookSession.set":
      return {
        ...state,
        ...action.payload,
        selectedSession: state.selectedSession,
      };
    case "@bookSession.setSelectedSession":
      return {
        ...state,
        selectedSession: action.payload,
      };
    case "@bookSession.reset":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
