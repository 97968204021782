import React from "react";
import styles from "./styles.module.scss";
import { useScheduleBtnModel } from "./indexModel";
import ScheduleModal from "app/components/ScheduleModal";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';

interface Props {
  onScheduleChange: (pickedAt: number, pickedTime?: string) => void;
  scheduledAt: number;
  scheduledTime?: string;
}

const ScheduleBtn = ({
  onScheduleChange,
  scheduledAt,
  scheduledTime,
}: Props) => {
  const { handleScheduleModal, isOpen, canUserSchedule, scheduledIn } =
    useScheduleBtnModel();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={cls(styles.container, {
          [styles.scheduled]: scheduledAt,
          ["btn btn-outline-secondary"]: !scheduledAt,
          ["btn btn-upgrade"]: !canUserSchedule,
        })}
        onClick={handleScheduleModal}
      >
        <i className="ico ico-time"></i>
        {!scheduledAt ? (
          <p>{t('Schedule')}</p>
        ) : (
          <p>
            <b>
              {" "}
              {t('Wait for')} {scheduledAt && Math.floor(scheduledAt)}{" "}
              {scheduledTime ? scheduledTime : scheduledIn}
              {Math.floor(scheduledAt) > 1 && t("second").charAt(0)}
            </b>{" "}
            {t('and publish')}
          </p>
        )}
      </div>

      {canUserSchedule && isOpen && (
        <ScheduleModal
          onScheduleChange={onScheduleChange}
          isOpen={isOpen}
          onClose={handleScheduleModal}
        />
      )}
    </>
  );
};

export default ScheduleBtn;
