import { uuid } from "../uuid";

export const useParseCsvData = (csvData: string) => {
  const lines = csvData.split("\n");
  const parsedData = lines.map((line) => {
    const [name, email] = line.split(",");
    return {
      email: email ? email.trim() : "",
      name: name ? name.trim() : "",
      displayName: name ? name.trim() : "",
      user_id: uuid(),
      id: uuid(),
      phone: "",
      phone_country: "",
    };
  });

  return parsedData.filter(
    (entry) => (entry.name && entry.email) || entry.email
  );
};
