import React, {useEffect, useState} from "react";
import PagePanel from "app/components/PagePanel";
import styles from "./styles.module.scss";
import AddAvailability from "./Availability/Modals/AddAvailability";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import ScheduleDropdown from "app/routes/Scheduling/Availability/ScheduleDropdown";
import AvailabilityWithDates from "app/routes/Scheduling/Availability/AvailabilityWithDates";
import {dateToString, days_of_the_week, parseSchedules, stringToDate} from "app/routes/Scheduling/utils";
import {
  schedulingFetchDelete,
  schedulingFetchGet,
  schedulingFetchPatch,
  schedulingFetchPost
} from "app/utils/request/scheduling/fetch";
import InfoModal from "app/routes/Calendar/InfoModal";
import useConfirmation from "app/routes/Calendar/hooks/useConfirmation";
import ConfirmationPrompt from "app/routes/Calendar/ConfirmationPrompt";
import ReturnBar from "app/routes/Scheduling/Navbar/ReturnBar";
import Navbar from "app/routes/Scheduling/Navbar";
import { useTranslation } from "react-i18next";
import RemoveAvailability from "./Availability/Modals/RemoveAvailability";
import {DateTime} from "luxon";

interface DailyTimeTable {
  day: number;
  active: boolean;
  startTime: string;
  endTime: string;
  value: number;
  label: string;
  short: string;
}

interface TimeTable {
  id?: string;
  name: string;
  dailyTimeTables: DailyTimeTable[];
  customPeriod: boolean;
  startDate: string | Date;
  endDate: string | Date;
}

interface InfoPopupContent {
  title: string;
  status: "success" | "error";
  content: string;
  subcontent?: string;
}

export default function Availability() {
  const { t } = useTranslation();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [infoPopupContent, setInfoPopupContent] = useState<InfoPopupContent>({
    title: "",
    status: "error",
    content: "",
  });

  const defaultSchedule = {
    name: '',
    dailyTimeTables: days_of_the_week.map((day) => ({
      ...day,
      day: day.value,
      active: false,
      startTime: '08:00',
      endTime: '17:00',
    })),
    customPeriod: false,
    startDate: DateTime.now().startOf('year').toJSDate(),
    endDate: DateTime.now().endOf('year').toJSDate(),
  };
  const [newSchedule, setNewSchedule] = useState<TimeTable>(defaultSchedule);

  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => {
    setAddModalOpen(false);
    setNewSchedule(defaultSchedule);
  };
  const openRemoveModal = (schedule: any) => {
    setRemoveModalOpen(true);
    setCurrentItem(schedule);
  };
  const closeRemoveModal = () => setRemoveModalOpen(false);

  const {
    PromptComponent,
  } = useConfirmation(props => <ConfirmationPrompt {...props} />);

  useEffect(() => {
    schedulingFetchGet<TimeTable[]>("time_tables", {})
      .then((time_tables) => {
        setSchedules(time_tables.map(parseSchedules) || []);
      }).catch(() => {
      setInfoPopupContent({title: "Oops...", status: "error", content: t("Something went wrong."), subcontent: t("Please try again.")});
    });
  }, []);

  const deleteSchedule = async (id: number) => {
   return schedulingFetchDelete(`time_tables/${id}`)
     .then(() => {
       setSchedules(schedules.filter((schedule) => schedule.id !== id));
     }).catch(() => {
       setInfoModalOpen(true);
       setInfoPopupContent({title: "Oops...", status: "error", content: t("Something went wrong."), subcontent: t("Please try again.")});

     });
  };

  const handleAddAvailability = (time_table: TimeTable) => {
    time_table.id = null;
    time_table = dateToString(time_table);
    if(schedules.filter((sch) => sch.name === time_table.name).length > 0) {
      setInfoModalOpen(true);
      setInfoPopupContent({title: t("Invalid name"), status: "error", content: t("The schedule name is already in use."), subcontent: t("Please select another name.")});
      return null;
    }
    return schedulingFetchPost<TimeTable>("time_tables", {"time_table": time_table})
      .then((new_schedule) => {
        time_table.id = new_schedule.id;
        setSchedules([stringToDate(time_table), ...schedules]);
        setInfoModalOpen(true);
        setInfoPopupContent({title: t("Success"), status: "success", content: t("Schedule successfully added.")});
      }).catch((error) => {
        if(error.errors[0]?.source?.parameter === "daily_time_tables.begin_at") {
          setInfoModalOpen(true);
          setInfoPopupContent({title: t("Invalid time"), status: "error", content: error[0]?.detail, subcontent: t("Please select another time.")});
        } else if(error.errors[0]?.source?.parameter === "active_in") {
          setInfoModalOpen(true);
          setInfoPopupContent({
            title: t("Overlap times"),
            status: "error",
            content: t("The new availability schedule overlaps with an existing availability schedule."),
            subcontent: t("Please select another time.")
          });
        }
      });
  };

  const handleUpdateAvailability = (time_tables: TimeTable[]) => {
    time_tables = time_tables.filter((sch) => sch.id).map(dateToString);
    return schedulingFetchPatch<TimeTable[]>("time_tables/bulk_update", {"time_tables": time_tables})
      .then(() => {
        setSchedules(time_tables.map(parseSchedules));
        setInfoModalOpen(true);
        setInfoPopupContent({title: t("Changes saved"), status: "success", content: t("Availability successfully saved.")});
      }).catch((error) => {
        if(error === undefined) {
          setInfoModalOpen(true);
          setInfoPopupContent({title: t("Oops..."), status: "error", content: t("Something went wrong."), subcontent: t("Please try again.")});
        }
        if(error.errors[0]?.source?.parameter === "daily_time_tables.begin_at") {
          setInfoModalOpen(true);
          setInfoPopupContent({title: t("Invalid time"), status: "error", content: error[0]?.detail, subcontent: t("Please select another time.")});
        } else if(error.errors[0]?.source?.parameter === "active_in") {
          setInfoModalOpen(true);
          setInfoPopupContent({title: t("Overlap times"), status: "error", content: t("The new availability schedule overlaps with an existing availability schedule."), subcontent: t("Please select another time.")});
        }
      });
  };

  const handleDeleteAvailability = (schedule: any) => {
    deleteSchedule(schedule.id);
    closeRemoveModal();
  };

  const onSubmit: SubmitHandler<TimeTable[]> = () => {
    handleUpdateAvailability(schedules);
  };

  const methods = useForm({
    defaultValues: schedules,
    mode: 'onChange',
  });

  const setScheduleData = (id: string, key: string, value: string) => {
    const updatedSchedules = schedules.map((schedule) => {
      if (schedule.id === id) {
        return {
          ...schedule,
          [key]: value,
        };
      }
      return schedule;
    });

    setSchedules(updatedSchedules);
  };

  const confirmButton = (
    <button
      className={`${styles.button} ${styles.confirm_button}`}
      type="submit">
      Save changes
    </button>
  );

  const {
    handleSubmit,
  } = methods;

  return (
    <>
      <ReturnBar />
      <div>
        <Navbar location={"availability"} />
        <PagePanel title={t("Availability")}
          headerChildren={(
            <div className={styles.page_panel}>
              <button type="button" className={styles.btn_create} onClick={openAddModal}>{t("+ Add Schedule")}</button>
            </div>
          )}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {schedules.map((schedule, index) => (
                <ScheduleDropdown key={schedule.id} schedule={schedule} noSchedules={schedules.length} deleteSchedule={() => openRemoveModal(schedule)} index={index}>
                  <div className={styles.elements}>
                    <div className={styles.form_row}>
                      <label>{t("Schedule Name")}</label>
                    </div>
                    <div className={styles.form_row}>
                      <input
                        id="name"
                        value={schedule.name}
                        required={true}
                        onChange={(e) => setScheduleData(schedule.id, 'name', e.target.value)}
                      />
                    </div>
                    <AvailabilityWithDates
                      schedule={schedule}
                      setScheduleData={setScheduleData}
                    />
                  </div>
                </ScheduleDropdown>
              ))}
              <div className={styles.buttons_container}>
                {confirmButton}
              </div>
            </form>
          </FormProvider>
          <AddAvailability isOpen={isAddModalOpen} onClose={closeAddModal} onAddAvailability={handleAddAvailability} schedule={newSchedule} setSchedule={setNewSchedule}/>
          <RemoveAvailability isOpen={isRemoveModalOpen} onClose={closeRemoveModal} onDelete={() => handleDeleteAvailability(currentItem)} title={currentItem?.name}/>
          <InfoModal isOpen={isInfoModalOpen} title={infoPopupContent.title} status={infoPopupContent.status} onDismiss={() => setInfoModalOpen(false)}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div>
                {infoPopupContent.content}
              </div>
              <div>
                <b> {infoPopupContent.subcontent}</b>
              </div>
            </div>
          </InfoModal>
          <PromptComponent />
        </PagePanel>
      </div>
    </>
  );
}
