/** @module actions */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, fetchPost, request } from "app/utils/request";
import { TeamGroupModel, TeamGroupMemberModel } from "app/models";
import config from "config/environment";
import { CreateTeamGroupModel } from "app/models/TeamGroupModel";
import UserModel from "app/models/UserModel";

export type ErrObj = {
  id: string;
  error: string;
};

export type BatchResult = {
  success: TeamGroupMemberModel[];
  error: ErrObj[];
};

export const selectTeam = (team: TeamGroupModel) =>
  action("@team-group.select", team);

const fetchAllPending = () => action("@team-group.fetch.pending");
const fetchAllError = (err: string) => action("@team-group.fetch.error", err);
const fetchAllSuccess = (data: TeamGroupModel[]) =>
  action("@team-group.fetch.success", data);

const addMembersPending = () => action("@team-group-member.post.pending");
const addMembersDone = (data: BatchResult) =>
  action("@team-group-member.post.done", data);
const membersList = (data: UserModel[]) =>
  action("@team-group-member.fetch.membersList", data);

const fetchPendingMembers = () =>
  action("@team-group-member.fetch.pendingMembers");

const fetchErrorMembers = (err: string) =>
  action("@team-group-member.fetch.errorMembers", err);

const fetchSuccessMembers = (members: []) =>
  action("@team-group-member.fetch.successMembers", members);

export const hasMoreMembers = (value: boolean) =>
  action("@team-group-member.fetch.hasMoreMembers", value);

export const membersPage = (page: number) =>
  action("@team-group-member.membersPage", page);

export const clearTeamGroupMembers = () =>
  action("@team-group-member.clearMembers");

export const clearTeamGroup = () => action("@team-group.clear");

/*** return actions ***/

const thisActions = {
  selectTeam,
  fetchAllPending,
  fetchAllError,
  fetchAllSuccess,
  addMembersPending,
  addMembersDone,
  membersList,
  fetchSuccessMembers,
  fetchPendingMembers,
  fetchErrorMembers,
  hasMoreMembers,
  membersPage,
  clearTeamGroupMembers,
  clearTeamGroup,
};

export type TeamGroupActions = ActionType<typeof thisActions>;

export function fetchTeamGroups(teamId: string) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    dispatch(fetchAllPending());

    return fetchGet("/team_groups", { teamId })
      .then((data: TeamGroupModel[]) => dispatch(fetchAllSuccess(data)))
      .catch((err) => dispatch(fetchAllError(err)));
  };
}

export function fetchTeamGroupData(teamId: string) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    dispatch(fetchAllPending());

    return fetchGet(`/team_groups/${teamId}`)
      .then((data: TeamGroupModel[]) => dispatch(selectTeam(data)))
      .catch((err) => dispatch(fetchAllError(err)));
  };
}

export function createTeamGroup(team_group: CreateTeamGroupModel) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    const url = config.API_SERVER + `/api/v2/team_groups`;
    const promise = request(url, "POST", { team_group }, { version: 1 });

    promise.catch((err) => dispatch(fetchAllError(err)));

    return promise;
  };
}

export function updateTeamGroup(id: string, team_group: CreateTeamGroupModel) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    const url = config.API_SERVER + `/api/v2/team_groups/${id}`;
    const promise = request(url, "PUT", { team_group }, { version: 1 });

    promise.catch((err) => dispatch(fetchAllError(err)));

    return promise;
  };
}

export function deleteTeamGroup(id: string) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    const url = config.API_SERVER + `/api/v2/team_groups/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });

    promise.catch((err) => dispatch(fetchAllError(err)));

    return promise;
  };
}

export function fetchMembersForSelect(
  id: string,
  params?: { [key: string]: any },
  signal?: AbortSignal,
) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    return fetchGet(
      `/team_groups/${id}/members_for_select`,
      params || {},
      {},
      signal,
    )
      .then((res: UserModel[]) => {
        return res;
      })
      .catch((err) => dispatch(fetchAllError(err)));
  };
}

export function addMembersToGroups(ids: string[], memberIds: string[]) {
  return (dispatch: Dispatch<TeamGroupActions>) => {
    dispatch(addMembersPending());

    const result: BatchResult = {
      success: [],
      error: [],
    };

    return Promise.all(
      ids.map((id) => {
        return fetchPost(
          `/team_groups/${id}/memberships`,
          { memberIds },
          { version: 2 },
        )
          .then(
            (model: TeamGroupMemberModel) => (
              result.success.push(model), model
            ),
          )
          .catch((e: any) => (result.error.push({ id, error: e }), undefined));
      }),
    ).then(() => dispatch(addMembersDone(result)));
  };
}

export function updateTeamGroupMember(teamId: string, membership: UserModel) {
  return () => {
    const url =
      config.API_SERVER +
      `/api/v2/team_groups/${teamId}/memberships/${membership.id}`;
    const promise = request(url, "PUT", { membership }, { version: 1 });
    return promise;
  };
}

export function addMembersToTeam(teamId: string, memberIds: string[]) {
  return () => {
    const url = config.API_SERVER + `/api/v2/team_groups/${teamId}/memberships`;
    const promise = request(url, "POST", { memberIds }, { version: 1 });
    return promise;
  };
}

export function deleteTeamGroupMember(teamId: string, memberId: string) {
  return () => {
    const url =
      config.API_SERVER +
      `/api/v2/team_groups/${teamId}/memberships/${memberId}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    return promise;
  };
}

export function fetchTeamGroupMembers(
  group_id: string,
  params?: { [key: string]: any },
  signal?: AbortSignal,
): any {
  return (dispatch: Dispatch<TeamGroupActions>, getState: () => any) => {
    const state = getState().teamGroup;
    return fetchGet<TeamGroupActions>(
      `team_groups/${group_id}/memberships`,
      params || {},
      {},
      signal,
    ).then((res: any) => {
      dispatch(fetchSuccessMembers(res));
      dispatch(membersPage(state.membersPage + 1));
      if (res.length < (params && params.per_page)) {
        dispatch(hasMoreMembers(false));
      }

      return res;
    });
  };
}

export const actions = {
  fetchTeamGroups,
  addMembersToGroups,
  fetchTeamGroupMembers,
  updateTeamGroupMember,
  fetchMembersForSelect,
  addMembersToTeam,
  deleteTeamGroupMember,
  createTeamGroup,
};
