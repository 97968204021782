import React, { memo } from "react";
import styles from "./styles.module.scss";
import TagModel from "app/models/TagModel";

interface Props {
  tags: TagModel[];
}

const PostTags = ({ tags }: Props) => {
  if (!tags) {
    return null;
  }

  return (
    <div className={styles.tags_container}>
      {tags.map((tag: TagModel, index: number) => {
        return <p key={index} className={styles.tag}>{`#${tag}`}</p>;
      })}
    </div>
  );
};

export default memo(PostTags);
