import React from "react";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import checkIcon from "../../../public/images/check-icon.svg";
import { clearCopy } from "app/actions/copy";
import { useActions } from "app/utils/hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  openCopyModal: boolean;
  setOpenCopyModal: (arg: boolean) => void;
  copyRedirect: () => void;
  countedLocation: number;
  copyPending?: boolean;
}

const CopyModal = ({
  openCopyModal,
  setOpenCopyModal,
  copyRedirect,
  countedLocation,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const postActions = useActions({ clearCopy });
  const handleClose = () => {
    setOpenCopyModal(false);
    postActions.clearCopy();
    history.goBack();
  };

  return (
    <ModalLayout isOpen={openCopyModal} onClose={handleClose}>
      <div className={styles.move_modal_container}>
        <img className={styles.check_icon} src={checkIcon} alt="check" />
        <h1 className={styles.post_info_title}>
          {t("Post successfully copied!")}
        </h1>
        <p className={styles.post_info}>
          {t("Post was copied to")} {countedLocation}{" "}
          {countedLocation > 1 ? t("locations") : t("location")}
        </p>
        <div className={styles.move_actions}>
          <button
            type="button"
            className={styles.save_btn}
            onClick={copyRedirect}
          >
            <div onClick={handleClose}>{t("Done")}</div>
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CopyModal;
