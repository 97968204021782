import React from "react";
import ErrorPageLayout from "../../components/Layouts/ErrorPageLayout";
import { useTranslation } from 'react-i18next';

const InternalServerErrorPage = () => {
  const { t } = useTranslation();
  return (
    <ErrorPageLayout title={t("500 – Internal server error")}>
      <p>
        {t("Sorry, due to server error we can't load/update some data this page has requested.")}
      </p>
      <p>{t("Don't worry – we're already working on it.")}</p>
    </ErrorPageLayout>
  );
};

export default InternalServerErrorPage;
