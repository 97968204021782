/**
 * @module Routes.Unsubscribe
 *
 */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { fetchPost } from 'app/utils/request';
import { Public } from 'app/components/Wrappers';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = RouteComponentProps<{
  invitationId: string;
}>;

type State = {
  error: string | null;
  pending: boolean;
};

const initialState: State = {
  error: null,
  pending: true,
};

/**
 * @class Invitation
 *
 */
export default function User(props: PropsDef) {
  const { t } = useTranslation();
  const invitationId = props.match.params.invitationId;
  const [ state, setState ] = useState(initialState);

  useEffect(() => {
    if (invitationId != null) {
      fetchPost(`invitations/${invitationId}/mute`, {}, { version: 1.2, noAuth: true })
        .then(() => setState({ ...state, pending: false, error: null }))
        .catch(() => setState({ ...state, pending: false, error: t('Due to server error we were not able to save your changes') }));
    } else {
      setState({ ...state, pending: false, error: t('The invitation is invalid or no longer exists.') });
    }
  }, [ invitationId ]);

  let message = (
    <h3 className="text-center">
      {t('Please, wait...')}
    </h3>
  );

  if (state.error) {
    message = (
      <h3 className="text-danger text-center">
        {state.error}
      </h3>
    );
  } else if (!state.pending) {
    message = (
      <h3 className="text-success text-center">
        {t('You have unsubscribed from email notifications for this invitation')}
      </h3>
    );
  }

  return (
    <Public isLoading={false}>
      <div className={Styles.unsubscribeMain}>
        <div className="panel">
          {message}
        </div>
      </div>
    </Public>
  );
}
