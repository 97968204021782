import React, { useEffect } from "react";
import { ApplyTemplateToProps } from "../ApplyTemplateDropdown/index.types";
import { cls } from "app/utils";
import { useActions } from "app/utils/hooks";
import { clearSpace, fetchSpaces } from "app/actions/space";
import { useTranslation } from "react-i18next";

const ApplyTemplateToSpace = ({
  className,
  spaceSelectModalHandler,
}: ApplyTemplateToProps) => {
  const { t } = useTranslation();

  const spaceQuery = {
    options: {
      include: ["team_info"],
      exclude: ["permissions", "state"],
    },
  };

  const applyTemplateActions = useActions({
    fetchSpaces,
    clearSpace,
  });

  useEffect(() => {
    applyTemplateActions.fetchSpaces(spaceQuery);

    return () => {
      applyTemplateActions.clearSpace();
    };
  }, []);

  return (
    <>
      <div className={cls(className)} onClick={spaceSelectModalHandler}>
        <i className="ico ico-tspace" />
        <p>{t('Apply to Space')}</p>
      </div>
    </>
  );
};

export default ApplyTemplateToSpace;
