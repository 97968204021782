import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { formatString } from 'app/utils/strings';

export const useCalendarUtils = () => {
  const { t } = useTranslation();

  const getNthDayOfTheMonth = (date: DateTime) => {
    const firstDay = date.startOf('month');

    const dateDiff = date.diff(firstDay, 'days').toObject().days;

    if (dateDiff < 6) {
      return t('first');
    }
    if (dateDiff < 13) {
      return t('second');
    }
    if (dateDiff < 19) {
      return t('third');
    }
    if (dateDiff < 25) {
      return t('fourth');
    }

    return t('last');
  };

  const getRecurrenceLabel = (time: DateTime, { label, value }) => {
    if (!time) {
      return { value, label };
    }

    if (!time.isValid) {
      return { value, label };
    }

    if (value === 'daily' || !value) {
      return { value, label };
    }

    const weekday = time.toLocaleString({ weekday: 'long' });

    if (value === 'weekly' || value === 'biweekly') {
      return {
        value,
        label: formatString(t(`%{label} on %{weekday}`), { label, weekday }),
      };
    }

    return {
      value,
      label: formatString(t(`%{label} on the %{day} %{weekday}`), {
        label,
        day: getNthDayOfTheMonth(time),
        weekday,
      }),
    };
  };

  return {
    getRecurrenceLabel,
  };
};
