import React, { useEffect, useState } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import {
  fetchSegmentById,
  clearSegments,
  editSegment,
} from "app/actions/segments";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";

interface Props {
  resourceId: string;
}

const EditSegment = ({ resourceId }: Props) => {
  const { t } = useTranslation();
  const segmentActions = useActions({
    fetchSegmentById,
    clearSegments,
    editSegment,
  });
  const history = useHistory();

  useEffect(() => {
    if (resourceId) {
      segmentActions.fetchSegmentById(resourceId);
    }

    return () => {
      segmentActions.clearSegments();
    };
  }, [resourceId]);

  const segment = useSelector((state: RootState) => state.segments, shallowEqual);

  const [name, setName] = useState<string | null>(
    (segment.segmentsData && segment.segmentsData.name) || null
  );
  const [error, setError] = useState<boolean>(false);

  const onChangeHandler = (e: any) => {
    setName(e);
    if (e.length <= 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const updateHandler = () => {
    segmentActions
      .editSegment(resourceId, {
        ...segment,
        name,
      })
      // .then(() => (window.location.pathname = cancelUrl));
      .finally(() => history.goBack());
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title={t("Edit List")} />
        <div className={styles.content_wrapper}>
          <span className={styles.input_wrapper}>
            <label htmlFor="segment_name">{t("Name")}</label>
            <input
              id="segment_name"
              name="segment_name"
              className={cls(styles.input, {
                [styles.error]: error,
              })}
              onChange={(e) => onChangeHandler(e.target.value)}
              defaultValue={name}
            />
          </span>
          <span className={styles.btn_wrapper}>
            <Button
              title={t("Update")}
              buttonType="primary"
              disabled={error}
              onClickFunc={updateHandler}
            />
            <Button
              title={t("Cancel")}
              buttonType="secondary-outline"
              onClickFunc={() => history.goBack()}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default EditSegment;
