import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";

interface AcceptTypes {
  [key: string]: string[];
}

interface Props {
  children?: React.ReactNode;
  onFileChange?: (file: File) => void;
  buttonLabel: string | React.ReactNode;
  accept?: AcceptTypes;
  buttonStyles?: string;
}

const FileUploadButton = ({
  children,
  onFileChange,
  buttonLabel,
  buttonStyles,
  accept,
}: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (onFileChange) {
        onFileChange(file);
      }
    }
  };

  const acceptString = accept ? Object.keys(accept).join(",") : "*";

  return (
    <div className={styles.container}>
      <div className={styles.container_wrapper}>
        {children}
        <input
          type="file"
          accept={acceptString}
          className={styles.file_uploader_input}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <div className={styles.select_button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={buttonLabel}
            onClickFunc={handleButtonClick}
            buttonStyles={buttonStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default FileUploadButton;
