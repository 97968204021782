import { fetchNotifications, markAllRead } from "app/actions/Notification";
import { NotificationsModel } from "app/models";
import { useActions, useSelector } from "app/utils/hooks";
import { notificationSortDate } from "app/utils/notificationDate";
import { useCallback, useState, useEffect } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";

type GroupNotificationTypes = {
  today: NotificationsModel[];
  thisWeek: NotificationsModel[];
  thisMonth: NotificationsModel[];
  everythingElse: NotificationsModel[];
};

export const useNotificationsListModel = () => {
  const notificationAction = useActions({
    fetchNotifications,
    markAllRead,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const defaultGroupNotifications: GroupNotificationTypes = {
    today: [],
    thisWeek: [],
    thisMonth: [],
    everythingElse: [],
  };

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<NotificationsModel[]>([]);

  const [groupedNotifications, setGroupedNotifications] =
    useState<GroupNotificationTypes>(defaultGroupNotifications);

  const [notificationsQuery] = useState({ perPage: 50 });

  const { seenCount } = useSelector((state: RootState) => state.notification, shallowEqual);

  const groupNotificationsByDate = useCallback(
    (notifications: NotificationsModel[]) => {
      const grouped: GroupNotificationTypes = {
        today: [],
        thisWeek: [],
        thisMonth: [],
        everythingElse: [],
      };

      notifications.forEach((notification: NotificationsModel) => {
        const label = notificationSortDate(notification.createdAt);
        if (label === "Today") {
          grouped.today.push(notification);
        } else if (label === "This Week") {
          grouped.thisWeek.push(notification);
        } else if (label === "This Month") {
          grouped.thisMonth.push(notification);
        } else {
          grouped.everythingElse.push(notification);
        }
      });

      return grouped;
    },
    []
  );

  const loadData = useCallback(
    async (fetchPage?: number) => {
      setLoading(true);
      const res: { notifications: NotificationsModel[] } =
        await notificationAction.fetchNotifications({
          ...notificationsQuery,
          page: fetchPage ? fetchPage : currentPage,
        });
      setLoading(false);

      if (
        !res?.notifications ||
        res?.notifications?.length < notificationsQuery.perPage
      ) {
        setHasMore(false);
        return;
      }

      if (res?.notifications?.length > 0) {
        const newGroupedNotifications = groupNotificationsByDate(
          res.notifications
        );
        if (fetchPage) {
          setCurrentPage(fetchPage + 1);
        } else {
          setCurrentPage((prev) => prev + 1);
        }
        setData((prev) => [...prev, ...res?.notifications]);
        setGroupedNotifications((prev) => ({
          today: [...prev.today, ...newGroupedNotifications.today],
          thisWeek: [...prev.thisWeek, ...newGroupedNotifications.thisWeek],
          thisMonth: [...prev.thisMonth, ...newGroupedNotifications.thisMonth],
          everythingElse: [
            ...prev.everythingElse,
            ...newGroupedNotifications.everythingElse,
          ],
        }));
      }
    },
    [currentPage, notificationsQuery, groupedNotifications]
  );

  const [notificationsContainer] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadData,
    disabled: false,
    rootMargin: "0px 0px 200px 0px",
  });

  const markAllAsSeen = async () => {
    if (seenCount === 0) {
      return;
    }

    await notificationAction.markAllRead().then(() => {
      setGroupedNotifications(defaultGroupNotifications);
      // initialLoad();
    });
  };

  const onNotificationClick = (notification: NotificationsModel) => {
    if (notification?.targetUrl) {
      route(notification.targetUrl);
    }
  };

  useEffect(() => {
    loadData();

    return () => {
      setGroupedNotifications(defaultGroupNotifications);
      setHasMore(true);
      setCurrentPage(1);
      setData([]);
    };
  }, []);

  return {
    data,
    markAllAsSeen,
    notificationsContainer,
    groupedNotifications,
    loading,
    onNotificationClick,
  };
};
