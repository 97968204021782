// import { useSelector } from "react-redux";
// import { RootState } from "typedefs";

import { SidebarProps } from "./index.types";

export const LOCAL_STORAGE_AUTH_TOKEN_NAME = "app-auth-token";
export const LOCAL_STORAGE_AUTH_TOKEN_NAME_TEST = "app-auth-token-test";

export const useSidebarModel = ({ user }: SidebarProps) => {
  // const user = useSelector((state: RootState) => state.user.data);

  const hasActiveProSubscription = () => {
    if (user !== null) {
      const plan = user.plan;

      if (plan != null) {
        return (
          !user.hasTrialSubscription &&
          plan.planCode.startsWith("coachpro+") &&
          user.subscription.status === "active"
        );
      }
    }
    return false;
  };

  const hasActivePlusSubscription = () => {
    if (user !== null) {
      const plan = user.plan;

      if (plan !== null) {
        return (
          !user.hasTrialSubscription &&
          plan.planCode.startsWith("coach+") &&
          user.subscription.status === "active"
        );
      }
    }
    return false;
  };

  const hasOrganization = () => {
    if (user !== null) {
      return user.traits && user.traits.company && user.traits.company.name;
    }
    return false;
  };

  const hasExpiredSubscription = () => {
    if (user !== null) {
      return (
        !user.hasCoachPlusSubscription &&
        !user.hasTrialSubscription &&
        user.subscription.status === "expired"
      );
    }
    return false;
  };

  const shouldShowTrialText = () => {
    if (user !== null) {
      const isTrialExpired =
        !user.hasCoachPlusSubscription &&
        user.subscription.status === "trial_expired";

      return user.hasTrialSubscription || isTrialExpired;
    }
    return false;
  };

  const trialWarningClass = () => {
    if (user !== null) {
      return (
        (user.hasTrialSubscription && user.plan.trialDaysLeft <= 3) ||
        user.subscription.status === "trial_expired"
      );
    }
    return false;
  };

  return {
    user,
    hasActiveProSubscription,
    hasActivePlusSubscription,
    hasOrganization,
    hasExpiredSubscription,
    shouldShowTrialText,
    trialWarningClass,
  };
};
