/**
 * @module Components.Selectbox
 *
 */
import { AnyObject } from "typedefs";
import React, { CSSProperties, useRef, useState, useEffect } from "react";
import { Promise } from "rsvp";
import Select, { components as comps } from "react-select";
import { SelectComponentsConfig } from "react-select/src/components";
import { Props as DefaultSelectProps } from "react-select/base";
import Creatable from "react-select/creatable";
import { ControlProps } from "react-select/src/components/Control";
import { StylesConfig } from "react-select/src/styles";
import {
  ActionTypes,
  InputActionTypes,
  ValueType as VT,
  OptionsType as OPT,
  GroupedOptionsType as GPT,
} from "react-select/src/types";
import escapeRegExp from "lodash/escapeRegExp";
import { cls } from "app/utils";
import { Spinner } from "app/components/Loading";
import css_styles from "./styles.module.scss";

export interface DefaultOption {
  label: string;
  value: string;
  id?: string;
  __isNew__?: boolean;
}

export type OptionsType<OT extends AnyObject = DefaultOption> = OPT<OT>;
export type ValueType<OT extends AnyObject = DefaultOption> = VT<OT>;
export type GroupedOptionsType<OT extends AnyObject = DefaultOption> = GPT<OT>;
export type OptionsDef<OT extends AnyObject = DefaultOption> =
  | OptionsType<OT>
  | GroupedOptionsType<OT>
  | null;

type SelectRef<OT extends AnyObject = DefaultOption> =
  | Select<OT>
  | Creatable<OT>
  | null;

export interface SelectProps<OT extends AnyObject = DefaultOption>
  extends DefaultSelectProps<OT> {
  createOption?: (label: string, isNew?: boolean) => OT;
}

interface InnerSelectProps<OT extends AnyObject = DefaultOption>
  extends SelectProps<OT> {
  forwardRef: React.MutableRefObject<StateManager<OT>>;
}

export type StateManager<OT extends AnyObject = DefaultOption> = {
  isReady: boolean;
  focus: () => Promise<boolean>;
  blur: () => Promise<boolean>;
  isFocused: () => boolean;
  selectRef: SelectRef<OT>;
  outerRef: HTMLDivElement | null;
  controlRef: HTMLDivElement | null;
  getValue: () => ValueType<OT> | null;
  setValue: (value: ValueType<OT>, type?: ActionTypes) => void;
  getInputValue: () => string | null;
  setInputValue: (value: string, type?: InputActionTypes) => void;
  createOption: (value: ValueType<OT>) => void;
  isMenuOpen: () => boolean | null;
  closeMenu: () => void;
  selectProps: SelectProps<OT>;
};

/**
 * Defautl option type creator
 */
export function createDefaultOption(
  label_t: string,
  isNew?: boolean
): DefaultOption {
  return {
    label: label_t,
    value: label_t,
    __isNew__: isNew,
  };
}

const SHOW_RESULTS = 100;

/**
 * @class Selectbox
 *
 */
function Selectbox<O extends AnyObject = DefaultOption>(
  selectProps: InnerSelectProps<O>
) {
  const {
    className,
    classNamePrefix,
    styles,
    forwardRef,
    defaultValue,
    value,
    defaultInputValue,
    inputValue,
    onCreateOption,
    onInputChange,
    options,
    components,
    createOption,
    ...rest
  } = selectProps;

  const optionCreator = createOption || createDefaultOption;
  const searchable = selectProps.isSearchable === false ? false : true;
  const selectRef = useRef<SelectRef<O>>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const [__options, setOptions] = useState<OptionsDef<O>>(null);
  const stateManager = createStateManager(
    selectRef.current,
    outerRef.current,
    controlRef.current,
    selectProps
  );

  useEffect(() => setOptions(options), [options]);

  let count: number = 0;
  const filterOption = (option: any, input: string) => {
    if (count < SHOW_RESULTS) {
      if (!input.length) {
        count += 1;
        return true;
      }

      input = escapeRegExp(input);
      const regex = new RegExp(input, "i");
      if (regex.test(option.label)) {
        count += 1;
        return true;
      }
    }
    return false;
  };

  const handleInputChange = (
    value: string,
    action: { action: InputActionTypes }
  ) => {
    count = 0;
    if (onInputChange) {
      onInputChange(value, action);
    }
  };

  useEffect(() => {
    if (stateManager.isReady && value !== undefined) {
      stateManager.setValue(value);
    }
  }, [value, stateManager.isReady]);

  useEffect(() => {
    if (stateManager.isReady && inputValue !== undefined) {
      stateManager.setInputValue(inputValue);
    }
  }, [inputValue, stateManager.isReady]);

  const handleCreateOption = (label: string) => {
    let value: ValueType<O>;
    if (onCreateOption) {
      value = onCreateOption(label);
    }

    if (value == null) {
      value = optionCreator(label, true) as O;
    }

    stateManager.createOption(value);
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLDivElement>) => {
    if (isInputTarget(controlRef.current, evt.target as HTMLElement)) {
      evt.preventDefault();
      const __inputValue = stateManager.getInputValue();

      const val = (__inputValue || "") + evt.clipboardData.getData("text");
      if (stateManager.selectRef != null) {
        stateManager.setInputValue(val, "input-change");
        (stateManager.selectRef as any).onMenuOpen();
        stateManager.focus();
      }
    }
  };

  if (forwardRef != null) {
    forwardRef.current = stateManager;
  }

  const props = {
    className: cls(
      css_styles.select,
      "needsclick",
      __options == null ? css_styles.loading : ""
    ),
    classNamePrefix: cls("needsclick", "select-box", classNamePrefix),
    styles: setCustomStyles(styles, searchable),
    defaultValue,
    defaultInputValue,
    filterOption,
    options: __options,
    onInputChange: handleInputChange,
    onCreateOption: handleCreateOption,
    components: setComponents(components, controlRef),
    ...rest,
  };

  let selectComponent: JSX.Element | null = null;
  if (selectProps.isCreatable === true) {
    const handleRef = (instance: Creatable<O>) =>
      (selectRef.current = instance);
    selectComponent = <Creatable ref={handleRef} {...props} />;
  } else {
    const handleRef = (instance: Select<O>) => (selectRef.current = instance);
    selectComponent = <Select ref={handleRef} {...props} />;
  }

  const outerProps = {
    ref: outerRef,
    className: cls(css_styles.selectContainer, className),
    contentEditable: searchable,
    onPaste: searchable ? handlePaste : undefined,
    suppressContentEditableWarning: true,
    tabIndex: -1,
    onBlur: (evt: React.FocusEvent<HTMLDivElement>) => {
      const relatedTarget = evt.relatedTarget as HTMLElement;
      if (evt.currentTarget.contains(relatedTarget)) {
        if (relatedTarget != null && relatedTarget.tagName === "INPUT") {
          relatedTarget.focus();
        } else {
          evt.currentTarget.focus();
        }
      } else {
        stateManager.closeMenu();
      }
    },
  };

  return (
    <div {...outerProps}>
      <Spinner isLoading={__options == null} />
      {selectComponent}
    </div>
  );
}

export default React.forwardRef(function SelectRefForward<
  O extends AnyObject = DefaultOption
>(props: SelectProps<O>, ref: React.MutableRefObject<StateManager<O>>) {
  return <Selectbox<O> {...props} forwardRef={ref} />;
});

const isInputTarget = (
  control: HTMLDivElement,
  target: HTMLElement
): boolean => {
  if (control != null && control.contains(target)) {
    return true;
  }
  return false;
};

function setComponents<OT extends AnyObject>(
  components: SelectComponentsConfig<OT>,
  controlRef: React.MutableRefObject<HTMLDivElement>
) {
  function Control(props: ControlProps<OT>) {
    const { onMouseDown } = props.innerProps;

    // override onMouseDown to prevent fire on context menu clicks
    props.innerProps.onMouseDown = (evt: React.MouseEvent<HTMLDivElement>) => {
      if (evt.button !== 0) {
        return evt;
      }
      return onMouseDown(evt);
    };

    // get passed in Control or use react-select Control
    const Comp =
      components != null && components.Control != null
        ? components.Control
        : comps.Control;

    return (
      <div ref={controlRef}>
        <Comp {...props} />
      </div>
    );
  }

  return Object.assign({}, components, { Control });
}

export const setCustomStyles = (
  styles: StylesConfig,
  isSearchable: boolean
): StylesConfig => {
  const defaultStyles: StylesConfig = {
    control: (base: CSSProperties) => ({
      ...base,
      width: "100%",
      display: "flex",
      alignItems: "center",
      cursor: isSearchable === false ? "cursor" : "text",
    }),
    menu: (base: CSSProperties) => ({ ...base, width: "100%" }),
    option: (base: CSSProperties) => ({ ...base, cursor: "pointer" }),
    container: (base: CSSProperties) => ({
      ...base,
      width: "100%",
    }),
    indicatorsContainer: (base: CSSProperties) => ({
      ...base,
      cursor: "pointer",
      height: "100%",
      padding: "0 12px",
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      gap: "12px",
    }),
    indicatorSeparator: (base: CSSProperties) => ({
      ...base,
      display: "none",
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      cursor: "pointer",
      borderRadius: "50%",
      backgroundColor: "#F4F4F4",
      padding: "4px",
      // margin: "10px 12px",
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      cursor: "pointer",
      borderRadius: "50%",
      backgroundColor: "#F4F4F4",
      padding: "4px",
      // margin: "10px 12px",
    }),
    input: (base: CSSProperties) => ({
      ...base,
      border: 0,
      backgroundColor: "transparent",
      transition: "initial",
      // height: "44px",
    }),
    multiValue: (base: CSSProperties) => ({
      ...base,
      backgroundColor: "#f8f8f8",
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #ddd",
      color: "#5e5e5e",
      height: "28px",
    }),
    multiValueRemove: (base: CSSProperties) => ({
      ...base,
      color: "#5e5e5e",
      padding: "6px 8px",
      borderRight: "1px solid #ddd",
      ":hover": {
        backgroundColor: "#dfdfdfdf",
        cursor: "pointer",
        fontSize: "2px",
      },
    }),
    multiValueLabel: (base: CSSProperties) => ({
      ...base,
      padding: "6px 8px 6px 8px!important",
      color: "#5e5e5e",
    }),
  };
  return Object.assign({}, defaultStyles, styles);
};

type DoneResult = "success" | "failed" | "pending";

function createStateManager<OT extends AnyObject>(
  selectRef: SelectRef<OT>,
  outerRef: HTMLDivElement | null,
  controlRef: HTMLDivElement | null,
  props: SelectProps<OT>
): StateManager<OT> {
  const isReady = selectRef != null;

  const callIfReady = (cb: (ref: SelectRef<OT>) => any) => {
    if (selectRef != null) {
      return cb(selectRef);
    }
    return undefined;
  };

  const getInputValue = (): string | null =>
    callIfReady((ref) => (ref.state as any).inputValue);
  const setInputValue = (value: string, type?: InputActionTypes) =>
    callIfReady((ref) =>
      (ref as any).onInputChange(value as any, { action: type || "set-value" })
    );

  const getValue = (): ValueType<OT> | null =>
    callIfReady((ref) => (ref.state as any).value);

  const setValue = (value: ValueType<OT>, type?: ActionTypes) =>
    callIfReady((ref) => ref.onChange(value, { action: type || "set-value" }));

  const isMenuOpen = (): boolean | null =>
    callIfReady((ref) => (ref.state as any).menuIsOpen);

  const closeMenu = () => {
    return callIfReady((ref) => {
      document.body.focus();
      if (ref.select != null) {
        setTimeout(() => {
          const evt = {};
          if (ref.select != null) {
            if ((ref.select as any).onInputBlur) {
              (ref.select as any).onInputBlur(
                evt as React.FocusEvent<HTMLInputElement>
              );
            } else if ((ref.select as any).select.onInputBlur) {
              (ref.select as any).select.onInputBlur(
                evt as React.FocusEvent<HTMLInputElement>
              );
            }
          }
        }, 1);
      }
    });
  };

  const focusInput = (): boolean => {
    if (isReady) {
      selectRef.focus();
      return true;
    }
    return false;
  };

  const blurInput = (): boolean => {
    if (isReady) {
      selectRef.blur();
      return true;
    }
    return false;
  };

  const isFocused = (): boolean => {
    return outerRef.contains(document.activeElement);
  };

  const focus = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const test = (): DoneResult => {
        const fc = focusInput();
        if (fc && isFocused()) {
          return "success";
        } else if (!fc) {
          return "failed";
        } else {
          return "pending";
        }
      };

      const cb = (result: boolean) => {
        resolve(result);
      };

      whileTest(test, cb);
    });
  };

  const blur = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const test = (): DoneResult => {
        const bc = blurInput();
        if (bc && !isFocused()) {
          return "success";
        } else if (!bc) {
          return "failed";
        } else {
          return "pending";
        }
      };

      const cb = (result: boolean) => resolve(result);

      whileTest(test, cb);
    });
  };

  const createOption = (value: ValueType<OT>) => {
    const currentValue = getValue();
    if (props.isMulti) {
      if (currentValue != null) {
        value = [].concat(currentValue).concat(value);
      }
    }

    setInputValue("");
    setValue(value, "create-option");

    if (props.closeMenuOnSelect !== false) {
      closeMenu();
    } else {
      focus();
    }
  };

  return {
    focus,
    blur,
    isFocused,
    selectRef,
    outerRef,
    controlRef,
    getValue,
    setValue,
    getInputValue,
    setInputValue,
    createOption,
    isMenuOpen,
    closeMenu,
    isReady,
    selectProps: props,
  };
}

function whileTest(
  runFn: () => DoneResult,
  callback: (result: boolean) => void,
  maxTries?: number
) {
  let _maxTries: number = maxTries || 10;
  let done: DoneResult = "pending";

  while (done === "pending") {
    done = runFn();
    _maxTries -= 1;
    if (_maxTries <= 0) {
      done = "failed";
    }
  }

  callback(done === "success" ? true : false);
}

// set the options list from the useMemo callback
// const setOptions = (options: UserOption[]) => {
//   if (dom.isDestroyed) { return; }
//   if (options != null && options.length) {
//     setState({ ...state, options: filterOptions(state.value, options).slice(0, SHOW_RESULTS), originalOptions: options.slice(0) });
//   } else {
//     setState({ ...state, options: [], originalOptions: [] });
//   }
// };

// set the value from useMemo callback or onChange
// const setValue = (val: UserOption[]) => {
//   if (dom.isDestroyed) { return; }
//   setState({ ...state, options: filterOptions(val, state.originalOptions).slice(0, SHOW_RESULTS), value: val });
// };

// set options and value after options are fetched

// set value if value not passed until after options are fetched
// useEffect(() => setValue(parseModels(value)), [ value ]);

// const onFilter = (value: string) => {
//   const regex = new RegExp(escape(value), 'i');
//   const options = state.originalOptions.filter((item: UserOption) => {
//     return regex.test(item.label);
//   });
//   setState({ ...state, options: options.slice(0, SHOW_RESULTS) });
// };
