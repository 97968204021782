import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { SelectStyles, MultiSelectStyles } from 'app/routes/Calendar/EventForm/constants';
import FormItem from "app/components/inputs/FormItem";

/* import { useTranslation } from 'react-i18next'; */

import styles from './styles.module.scss';

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <i className="ico ico-x" style={{ color: '#fff', cursor: 'pointer' }} />
    </components.MultiValueRemove>
  );
};

const SelectLessonModal = ({
  onClose,
  onSubmit,
  pricingValues,
  lessonProperties,
  lessonTypesError,
}) => {
  /* const { t } = useTranslation(); */
  const { t } = useTranslation();

  const { coach, pricingValue } = lessonProperties;

  const [sessionPricing, setSessionPricing] = useState(pricingValues.find(pv => pv.defaultRate) || pricingValues[0]);
  const [sessionTypes, setSessionTypes] = useState(sessionPricing.lessonTypes.filter(st => st.id === sessionPricing.defaultLessonTypeId));

  const handleSessionPricingChange = (newSessionPricing) => {
    setSessionPricing(newSessionPricing);
    setSessionTypes(newSessionPricing.lessonTypes.filter(st => st.id === newSessionPricing.defaultLessonTypeId));
  };

  const currencySymbol = coach.currencyYymbol;

  const onConfirm = () => {
    onSubmit(sessionPricing, sessionTypes);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className={styles.select_lesson_form}>
      <FormItem className={styles.input_field}>
        <label>{t('Session')}</label>

        <Select
          options={pricingValues}
          styles={SelectStyles}
          value={sessionPricing}
          onChange={handleSessionPricingChange}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.id}
        />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t('Types')}</label>
        <Select
          styles={MultiSelectStyles}
          options={sessionPricing.lessonTypes}
          value={sessionTypes}
          onChange={setSessionTypes}
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.id}
          isDisabled={!pricingValue?.lessonTypes}
          isClearable={false}
          isMulti
          components={{ MultiValueRemove }}
        />
        {lessonTypesError && (
          <span className={styles.error_message}>
            {lessonTypesError}
          </span>
        )}
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t('Session Rate')}</label>
        <input
          value={`${currencySymbol || '$'}${pricingValue?.rate}`}
          readOnly
        />
      </FormItem>

      <div className={styles.select_lesson_form_actions}>
        <button className="btn btn-outline-secondary" type="submit" onClick={onCancel}>
          {t('Cancel')}
        </button>

        <button className="btn btn-primary" type="submit" onClick={onConfirm} disabled={!sessionTypes || sessionTypes.length < 1}>
          {t('Confirm')}
        </button>
      </div>
    </div>
  );
};

export default SelectLessonModal;
