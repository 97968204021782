/** @module reducers */
import { TeamAction } from "app/actions/team";
import { TeamModel } from "app/models";

export interface TeamState {
  data: TeamModel[] | null;
  error: string | null;
  pending: boolean;

  teamByToken: TeamModel | null;
  teamByTokenError: string | null;
  teamByTokenPending: boolean;

  joined: boolean | null;
  joinedData: TeamModel | null;
  joinedPending: boolean;
  joinedError: string | null;
}

const initState: TeamState = {
  data: null,
  error: null,
  pending: false,

  teamByToken: null,
  teamByTokenError: null,
  teamByTokenPending: false,

  joined: null,
  joinedData: null,
  joinedPending: false,
  joinedError: null,
};

export default function teamReducer(
  state = initState,
  action: TeamAction
): TeamState {
  switch (action.type) {
    case "@team.fetch.pending":
      return { ...state, pending: true, error: null };
    case "@team.fetch.success":
      return { ...state, pending: false, data: action.payload };
    case "@team.fetch.error":
      return { ...state, pending: false, error: action.payload };

    case "@team.fetch-by-token.pending":
      return { ...state, teamByTokenPending: true, teamByTokenError: null };
    case "@team.fetch-by-token.success":
      return {
        ...state,
        teamByTokenPending: false,
        teamByToken: action.payload,
      };
    case "@team.fetch-by-token.error":
      return {
        ...state,
        teamByTokenPending: false,
        teamByTokenError: action.payload,
      };

    case "@team.join-by-token.pending":
      return { ...state, joinedPending: true, joinedError: null };
    case "@team.join-by-token.success":
      return {
        ...state,
        joinedPending: false,
        joined: true,
        joinedData: action.payload,
      };
    case "@team.join-by-token.error":
      return {
        ...state,
        joinedPending: false,
        joinedError: action.payload,
        joined: false,
      };

    default:
      return state;
  }
}
