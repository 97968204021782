import { SearchAllAction } from "app/actions/searchAll";
import { SearchAllModel } from "app/models/SearchAllModel";
import { RequestError } from "typedefs";

export type SearchAllState = {
  pendingSearch: boolean;
  errorSearch: RequestError | null;
  successSearch: SearchAllModel[];
};

const initialState: SearchAllState = {
  errorSearch: null,
  pendingSearch: false,
  successSearch: [],
};

export default function SearchAllReducer(
  state = initialState,
  action: SearchAllAction
) {
  switch (action.type) {
    case "@searchAll.create.pending":
      return {
        ...state,
        pendingSearch: true,
        errorSearch: null,
      };
    case "@searchAll.create.error":
      return {
        ...state,
        successSearch: [],
        pendingSearch: false,
        errorSearch: action.payload,
      };
    case "@searchAll.create.success":
      return {
        ...state,
        successSearch: action.payload,
        pendingSearch: false,
      };
    default:
      return state;
  }
}
