/**
 * @module Routes.Unsubscribe
 *
 */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'app/reducers';
import { cls } from 'app/utils';
import { UserSettingsModel } from 'app/models';
import { fetchUserSettings, saveUserSettings } from 'app/actions/user-settings';
import { useActions } from 'app/utils/hooks';
import { Toast } from 'app/components/helpers';
import { Public } from 'app/components/Wrappers';
import CheckBox from 'app/components/inputs/CheckBox';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = RouteComponentProps<{
  token?: string;
}>;

interface State {
  pendingInvitationsEmails: boolean;
  dailyDigestEmails: boolean;
  morningDelivery: boolean;
  afternoonDelivery: boolean;
  eveningDelivery: boolean;
  weeklyDigestEmails: boolean;
  ready: boolean;
  success: string;
  error: string;
}

const initState: State = {
  pendingInvitationsEmails: false,
  dailyDigestEmails: false,
  morningDelivery: false,
  afternoonDelivery: false,
  eveningDelivery: false,
  weeklyDigestEmails: false,
  ready: false,
  success: null,
  error: null,
};

/**
 * @class Usergt
 *
 */
export default function User(props: PropsDef) {
  const { t } = useTranslation();
  const token = props.match.params.token;
  const [ state, setState ] = useState(initState);

  const userSettings = useSelector((state: RootState) => state.userSettings, shallowEqual);
  const actions = useActions({ fetchUserSettings, saveUserSettings });

  useEffect(() => (actions.fetchUserSettings(token), undefined), []);
  useEffect(() => {
    if (userSettings.fetch.data != null) {
      const [ morningDelivery, afternoonDelivery, eveningDelivery ] = userSettings.fetch.data.dailyDigestEmailsDeliveryTimeDenormalized;
      setState({
        ...state, morningDelivery, afternoonDelivery, eveningDelivery,
        pendingInvitationsEmails: userSettings.fetch.data.pendingInvitationsEmails,
        dailyDigestEmails: userSettings.fetch.data.dailyDigestEmails,
        weeklyDigestEmails: userSettings.fetch.data.weeklyDigestEmails,
        ready: true,
      });
    }
  }, [ userSettings.fetch.data ]);

  const saveSettings = () => {
    const dailyDeliveries = ([
      state.morningDelivery ? 'morning' : null,
      state.afternoonDelivery ? 'afternoon' : null,
      state.eveningDelivery ? 'evening' : null,
    ]).filter(item => item != null);

    const settings: Partial<UserSettingsModel> = {
      dailyDigestEmails: state.dailyDigestEmails,
      weeklyDigestEmails: state.weeklyDigestEmails,
      pendingInvitationsEmails: state.pendingInvitationsEmails,
      dailyDigestEmailsDeliveryTime: dailyDeliveries,
      dailyDigestEmailsDeliveryTimeDenormalized: [
        state.morningDelivery,
        state.afternoonDelivery,
        state.eveningDelivery
      ]
    };

    actions.saveUserSettings(settings as UserSettingsModel, token)
      .then(() => setState({ ...state, success: t('Your settings have been saved!') }))
      .catch(() => setState({ ...state, error: t('Due to server error we were not able to save your changes') }));
  };

  const handleChange = (value: boolean, name: string) => {
    setState({ ...state, [name]: value });
  };

  let buttonText = t('Save settings');
  if (userSettings.save.pending) {
    buttonText = t('Please wait...');
  }

  function renderDaily() {
    if (state.dailyDigestEmails) {
      return (
        <div className={cls(Styles.section, Styles.daily)}>
          <div className={Styles.settings}>
            <CheckBox
              className={Styles.checkbox}
              checked={state.morningDelivery}
              label={t('Morning (around 8am)')}
              onChange={(e) => handleChange(e, 'morningDelivery')}
            />
            <CheckBox
              className={Styles.checkbox}
              label={t('Afternoon (around 2pm)')}
              checked={state.afternoonDelivery}
              onChange={(e) => handleChange(e, 'afternoonDelivery')}
            />
            <CheckBox
              className={Styles.checkbox}
              label={t('Evening (around 6pm)')}
              checked={state.eveningDelivery}
              onChange={(e) => handleChange(e, 'eveningDelivery')}
            />
          </div>
        </div>
      );
    }
    return (<div />);
  }

  if (userSettings.fetch.error != null) {
    return (
      <Public>
        <div className="panel">
          <h3 className="text-danger text-center">
            {t('The access token provided is not valid, or the user does not exists.')}
          </h3>
        </div>
      </Public>
    );
  }

  return (
    <Public isLoading={!state.ready}>
      <Toast
        success={state.success}
        error={state.error}
        onDone={() => setState({ ...state, error: null, success: null })}
      />

      <div className={cls(Styles.unsubscribeMain, Styles.user)}>
        <div className={Styles.section}>
          <div className={Styles.header}>{t('Subscriptions')}</div>

          <div className={Styles.settings}>
            <CheckBox
              className={Styles.checkbox}
              label={t('I want to receive weekly pending invitations reminder' )}
              checked={state.pendingInvitationsEmails}
              onChange={val => handleChange(val, 'pendingInvitationsEmails')}
            />
          </div>
        </div>

        <div className={Styles.section}>
          <div className={Styles.header}>{t('Daily digest emails')}</div>

          <div className={Styles.settings}>
            <CheckBox
              className={Styles.checkbox}
              label={t('I want to receive daily digest emails')}
              checked={state.dailyDigestEmails}
              onChange={val => handleChange(val, 'dailyDigestEmails')}
            />

            {renderDaily()}
          </div>
        </div>

        <div className={Styles.section}>
          <div className={Styles.header}>{t('Weekly digest emails')}</div>

          <div className={Styles.settings}>
            <CheckBox
              className={Styles.checkbox}
              label={t('I want to receive weekly digest emails')}
              checked={state.weeklyDigestEmails}
              onChange={val => handleChange(val, 'weeklyDigestEmails')}
            />
          </div>
        </div>

        <div className={Styles.buttonBar}>
          <button
            className="btn btn-primary"
            onClick={saveSettings}
            disabled={userSettings.save.pending}
          >{buttonText}</button>
        </div>
      </div>
    </Public>
  );
}
