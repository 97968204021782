import React, { useCallback, useEffect, useState } from "react";
import { TagSelect } from "../inputs/SelectBox";
import DropdownButton from "app/components/DropdownButton";
import styles from "./styles.module.scss";
import CheckBox from "../inputs/CheckBox";
import { filterStateDataType } from "../Filter";
import { useTranslation } from "react-i18next";

interface Props {
  onFilterChange: (filter: any) => void;
  disabled?: boolean;
}

const filterLabels = [
  { value: "image", label: "Images" },
  { value: "video", label: "Videos" },
  { value: "audio", label: "Audio" },
  { value: "datafile", label: "Documents" },
];

const FileUploadFilter = ({ onFilterChange, disabled }: Props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<{
    tags?: string[];
    type?: {
      image?: boolean;
      video?: boolean;
      audio?: boolean;
      datafile?: boolean;
    };
  }>({});

  console.log(filters);

  const onTagsChange = useCallback(
    (tags: any) => {
      const tagNames = tags.map((item: { name: string }) => item.name);

      setFilters({
        ...filters,
        tags: tagNames,
      });
      onFilterChange({
        ...filters,
        tags: tagNames,
      });
    },
    [filters, onFilterChange],
  );

  const toggleMediaType = useCallback(
    (dataType: filterStateDataType) => {
      if (!disabled) {
        // Ensure `filters.type` is initialized as an object
        const updatedType = {
          ...filters.type,
          [dataType]: !filters?.type?.[dataType], // Safely access and toggle the current media type
        };

        onFilterChange({
          ...filters,
          type: updatedType,
        });

        setFilters((prevFilters) => ({
          ...prevFilters,
          type: updatedType,
        }));
      }
    },
    [disabled, filters, onFilterChange],
  );

  useEffect(() => {
    return () => {
      setFilters({});
    };
  }, []);

  const DropdownContent = () => (
    <div className={`${styles.btn_content} btn btn-secondary-outline`}>
      {t("All Types")}
      <i className="ico ico-chevron" />
    </div>
  );

  return (
    <div className={styles.container}>
      <TagSelect
        isCreatable={false}
        isClearable
        isMulti
        onChange={onTagsChange}
      />
      <DropdownButton
        dropdownWrapperClass={styles.dropdown_content}
        btnClassName={styles.filter_btn}
        btnContent={<DropdownContent />}
      >
        <div className={styles.dropdown_container}>
          <p className={styles.filter_title}>Filter By</p>
          <div className={styles.checkbox_wrapper}>
            {filterLabels.map((item) => (
              <CheckBox
                key={item.value}
                name={item.value}
                label={item.label}
                checked={
                  filters?.type &&
                  filters?.type[item?.value as filterStateDataType]
                }
                onChange={() =>
                  toggleMediaType(item.value as filterStateDataType)
                }
              />
            ))}
          </div>
        </div>
      </DropdownButton>
    </div>
  );
};

export default FileUploadFilter;
