import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { Loading } from "app/components/Wrappers";
import ListModel from "app/models/ListModel";
import { shallowEqual, useDispatch } from "react-redux";
import { newSpaceValues } from "app/actions/spaceCreation";
import SearchBar from "app/components/inputs/SearchBar";
import Fuse from "../../../../../../../vendor/fuse";
import { useTranslation } from "react-i18next";

interface Props {
  listData?: ListModel[];
}

const SegmentsSelect = ({ listData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { pending } = useSelector(
    (state: RootState) => state.list,
    shallowEqual
  );
  const { spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [listOptions, setListOptions] = useState<ListModel[]>([]);

  const fuseOptions = {
    keys: ["name"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.2,
  };

  const fuse = useMemo(() => {
    const users = listOptions.map((list) => list);
    return new (Fuse as any)(users, fuseOptions);
  }, [listOptions, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "" || searchTerm) {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return listOptions;
    }
  }, [searchTerm, listOptions, fuse]);

  const onSearchTeam = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
  }, []);

  useEffect(() => {
    if (listData) {
      // if (spaces_import.segment_ids && spaces_import.segment_ids.length > 0) {
      //   // Filter out selected users
      //   const filteredOptions = list.data.filter(
      //     (list: { id: string }) =>
      //       !spaces_import.segment_ids.some(
      //         (selectedList) => selectedList === list.id
      //       )
      //   );
      //   setListOptions(filteredOptions);
      // } else {
      setListOptions(listData);
    }
  }, [listData, spaces_import.segment_ids]);

  if (!listData || pending) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <>
      <SearchBar
        onChange={onSearchTeam}
        placeholder={t("Search Your Lists")}
        className={styles.search}
      />
      {results && results.length > 0 ? (
        <ul className="stack-list-items fade-in">
          {/* <li className={styles.list}> */}
          {results.map((list: ListModel) => {
            return (
              <li
                key={list.id}
                className="stack-list-item selectable-item selectable-item-middle fade-in"
              >
                <label
                  className="selectable-item-trigger"
                  htmlFor={list.id}
                ></label>
                <div className="selectable-item-control">
                  <div className="checkbox">
                    <input
                      id={list.id}
                      defaultChecked={spaces_import.segment_ids.includes(
                        list.id
                      )}
                      onChange={() => {
                        dispatch(
                          newSpaceValues({
                            ...spaces_import,
                            segment_ids: spaces_import.segment_ids.includes(
                              list.id
                            )
                              ? spaces_import.segment_ids.filter(
                                  (id?: string) => id !== list.id
                                )
                              : [...spaces_import.segment_ids, list.id],
                          })
                        );
                      }}
                      type="checkbox"
                    />
                    <label htmlFor={list.id}></label>
                  </div>
                </div>

                <div className="selectable-item-details full">
                  <div className="profile-block profile-block-default">
                    <div className="profile-block-details">
                      <div className="profile-block-title ng-binding">
                        {list.name}
                      </div>

                      <div className="profile-block-summary ng-binding">
                        {list.spaceIds ? (
                          <>
                            {t("Space")} {list?.spaceIds?.length}
                          </>
                        ) : (
                          t("0 Space")
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.no_result}>{t("No Lists to Display")}</div>
      )}
    </>
  );
};

export default SegmentsSelect;
