/** @module components/Layouts */
import React from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

/***/
type PropsDef = React.PropsWithChildren<{
  className?: string;
  missingTerm?: string;
}>;

/**
 * @name NoFilteredResults
 */
export default function NoFilteredResults(props: PropsDef) {
  const { t } = useTranslation();
  return (
    <div className={cls(Styles.noFilteredResults, props.className)}>
      <h3>{t("No Results")}</h3>
      <p className={cls(Styles.message, props.missingTerm && props.missingTerm.length ? 'show' : '')}>
        {t("Nothing found for \"{{message}}\", please search for something else.", { message: props.missingTerm })}
      </p>
    </div>
  );
}
