import { useState, useCallback } from "react";

export const usePermissions = (initialPermissions: string[]) => {
  const [permissions] = useState(initialPermissions);

  const canLeave = useCallback(
    () => permissions && permissions.includes("leave"),
    [permissions]
  );
  const canResend = useCallback(
    () => permissions && permissions.includes("resend"),
    [permissions]
  );
  const canDelete = useCallback(
    () => permissions && permissions.includes("delete"),
    [permissions]
  );
  const canEdit = useCallback(
    () => permissions && permissions.includes("edit"),
    [permissions]
  );
  const canManageMembers = useCallback(
    () => permissions && permissions.includes("manage_members"),
    [permissions]
  );
  const canMakeAthlete = useCallback(
    () => permissions && permissions.includes("make_athlete"),
    [permissions]
  );
  const canPost = useCallback(
    () => permissions && permissions.includes("post"),
    [permissions]
  );
  const canReply = useCallback(
    () => permissions && permissions.includes("reply"),
    [permissions]
  );

  return {
    canLeave,
    canDelete,
    canEdit,
    canManageMembers,
    canMakeAthlete,
    canPost,
    canReply,
    canResend,
  };
};

export const useSpaceType = (initialType: "team" | "athlete" | "recruiter") => {
  const [type] = useState(initialType);

  const isForTeam = useCallback(() => type === "team", [type]);
  const isForAthlete = useCallback(() => type === "athlete", [type]);
  const isForRecruiter = useCallback(() => type === "recruiter", [type]);

  return {
    isForTeam,
    isForAthlete,
    isForRecruiter,
  };
};

export const usePlanPermissions = (
  initialPlanAccess: string[] | any,
  initialPlanPermissions: string[]
) => {
  const [planAccess] = useState(initialPlanAccess);
  const [planPermissions] = useState(initialPlanPermissions);

  const readonly = useCallback(
    () => planAccess && planAccess === "readonly",
    [planAccess]
  );
  const planCanManageMembers = useCallback(
    () => planPermissions && planPermissions.includes("manage_members"),
    [planPermissions]
  );
  const planCanEdit = useCallback(
    () => planPermissions && planPermissions.includes("edit"),
    [planPermissions]
  );
  const planCanDelete = useCallback(
    () => planPermissions && planPermissions.includes("delete"),
    [planPermissions]
  );
  const planCanLeave = useCallback(
    () => planPermissions && planPermissions.includes("leave"),
    [planPermissions]
  );
  const planCanPost = useCallback(
    () => planPermissions && planPermissions.includes("post"),
    [planPermissions]
  );
  const planCanReply = useCallback(
    () => planPermissions && planPermissions.includes("reply"),
    [planPermissions]
  );

  return {
    readonly,
    planCanManageMembers,
    planCanEdit,
    planCanDelete,
    planCanLeave,
    planCanPost,
    planCanReply,
  };
};

export const useMemberPermissions = (initialPermissions: string[]) => {
  const [permissions] = useState(initialPermissions);

  const canBecomeAthlete = useCallback(
    () => permissions && permissions.includes("become_athlete"),
    [permissions]
  );

  const canBeDeleted = useCallback(
    () => permissions && permissions.includes("be_deleted"),
    [permissions]
  );

  const canBeManaged = useCallback(
    () => permissions && permissions.includes("be_managed"),
    [permissions]
  );

  return { canBecomeAthlete, canBeDeleted, canBeManaged };
};
