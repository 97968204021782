/* eslint-disable no-console */
/**
 * @module Components.StripeForm
 *
 */
import React, { useEffect, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { ReactStripeElements } from "react-stripe-elements";
import FormItem from "app/components/inputs/FormItem";
import { useActions } from "app/utils/hooks";
import styles from "./styles.module.scss";
import { fetchPhoneCountryCodes } from "app/actions/user-settings";
import CountrySelect from "react-flags-select";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";

type ElResponse = ReactStripeElements.ElementChangeResponse & {
  // @ts-ignore
  target?: EventTarget;
};

type PropsDef = {
  value?: string;
  placeholder?: MessageDescriptor;
  onChange?: (e: ElResponse) => void;
  onFocus?: (e: ElResponse) => void;
  onBlur?: (e: ElResponse) => void;
  error?: MessageDescriptor | string | null;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function Country(props: PropsDef) {
  const { t } = useTranslation();
  const countryActions = useActions({ fetchPhoneCountryCodes });
  // const { countryCodes } = useSelector(
  //   (state: RootState) => state.userSettings.fetch
  // );
  const [selectedCountry, setSelectedCountry] = useState(
    props.value ? props.value : "",
  );

  const modifyEvent = (e: string, callback: (e: ElResponse) => void) => {
    if (callback) {
      callback({
        elementType: "country",
        complete: true,
        empty: false,
        brand: "Unknown",
        value: e,
      });
    }
  };

  useEffect(() => {
    try {
      countryActions.fetchPhoneCountryCodes();
    } catch (err) {
      console.error(err);
    }

    return () => {
      setSelectedCountry("");
    };
  }, []);

  const onChangeFunction = (e: any) => {
    modifyEvent(e, props.onChange);
    setSelectedCountry(e);
  };

  return (
    <FormItem className={styles.formControl} error={props.error}>
      <CountrySelect
        // countries={countryCodes}
        // @ts-ignore
        selected={selectedCountry}
        onSelect={(code: string) => onChangeFunction(code)}
        placeholder={t('Country')}
        disabled={props.disabled}
        className={cls("country-select", {
          ["country-select-error"]: props.error,
        })}
      />

      {/* <input
        onChange={(e) => modifyEvent(e, props.onChange)}
        onFocus={(e) => modifyEvent(e, props.onFocus)}
        onBlur={(e) => modifyEvent(e, props.onBlur)}
        value={props.value}
        disabled={props.disabled}
        readOnly={props.readOnly}
      /> */}
    </FormItem>
  );
}
