/** @module utils/request */
import { isAuthenticated, getAuth } from "app/utils/auth";
import { deserialize } from "./model";
import { QueryDef } from "./url";
import { Headers } from "./helpers";
import config from "config/environment";
import { getCookie } from "../cookie";

interface RInit extends RequestInit {
  noAuth?: boolean;
}

const defaultRequest: RequestInit = {
  method: "GET",
  mode: "cors",
  cache: "default",
  credentials: "omit",
  referrerPolicy: "no-referrer",
};

export default function createRequest(
  method: string,
  init?: RInit,
  body?: QueryDef | BodyInit | null
) {
  init = init || {};
  const noAuth = init.noAuth || false;
  if (init.noAuth != null) {
    delete init.noAuth;
  }

  const options: RequestInit = init;
  const opts = Object.assign({}, defaultRequest, options, {
    method: method != null ? method : defaultRequest.method,
    headers: new Headers(setHeaders(options.headers || {}, noAuth)),
  });

  if (body != null) {
    if (body instanceof FormData) {
      opts.body = body;
    } else if (typeof body === "object") {
      if (Object.keys(body).length > 0) {
        opts.body = JSON.stringify(
          Object.assign({}, deserialize(body as QueryDef))
        );
      }
    } else {
      opts.body = body as BodyInit;
    }
  }
  return opts;
}

type HeaderObj = { [key: string]: string };

export function setHeaders(value: HeadersInit, noAuth?: boolean): HeaderObj {
  const locale = getCookie("locale");

  // @ts-ignore
  return Object.assign(
    {},
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Edufii-Client-Id": config.API_CLIENT_ID,
      ...(locale && {
        "X-CoachNow-Locale": locale,
      }),
    },
    isAuthenticated() && !noAuth ? { "X-Edufii-Access-Token": getAuth() } : {},
    value
  );
}
