import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchConnections } from "app/actions/connection";
import { useSelector, useStoreActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import Fuse from "../../../../../vendor/fuse";
import Dropdown from "app/components/Dropdown";
import AddNewUser from "app/components/AddNewUser";
import { uuid } from "app/utils/uuid";
import UsersList from "app/components/Filter/UserSelect/UsersList";
import { useTranslation } from "react-i18next";
import { RootState } from "typedefs";
import { shallowEqual } from "react-redux";

const SpaceTemplateUsers = ({
  onChange,
  isCreatable,
  noOptionsMessage,
  addNewUserModalTitle,
  displayAddUser,
}: {
  onChange: (selectedUsers: UserModel[]) => void;
  isCreatable?: boolean;
  selectedUsersList?: UserModel[];
  noOptionsMessage?: string | null;
  addNewUserModalTitle?: string;
  displayAddUser?: boolean;
}) => {
  const { selectedTemplate } = useSelector(
    (state: RootState) => state.spaceTemplate,
    shallowEqual,
  );
  const { connection } = useStoreActions({ connection: fetchConnections });
  const [userOptions, setUserOptions] = useState<UserModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);

  useEffect(() => {
    if (connection?.data) {
      const initialUserOptions = connection.data.filter(
        (user: UserModel) =>
          !selectedTemplate?.members.some(
            (member: UserModel) => member.id === user.id,
          ),
      );
      setUserOptions(initialUserOptions);
    }
  }, [connection?.data, selectedTemplate]);

  const addNewUserModalHandler = () => {
    setAddNewUserModal((prev) => !prev);
  };

  const fuseOptions = {
    keys: ["displayName", "email"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.1,
  };

  const fuse = useMemo(() => {
    const users = userOptions.map((user: UserModel) => user);
    return new (Fuse as any)(users, fuseOptions);
  }, [userOptions, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "" || searchTerm) {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return userOptions;
    }
  }, [searchTerm, userOptions, fuse]);

  const onSearchTeam = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const toggleUserSelection = useCallback(
    (user: UserModel) => {
      const isUserSelected = selectedUsers.some(
        (selectedUser) => selectedUser.id === user.id,
      );

      const filterSelectedUsers = selectedUsers.filter(
        (selectedUser) => selectedUser.id !== user.id,
      );

      if (isUserSelected) {
        setSelectedUsers(filterSelectedUsers);
        onChange(filterSelectedUsers);
      } else {
        const addSelectedUsers = [user, ...selectedUsers];
        setSelectedUsers(addSelectedUsers);
        onChange(addSelectedUsers);
      }

      setUserOptions((prevUserOptions) =>
        prevUserOptions.filter((option) => option.id !== user.id),
      );
    },
    [selectedUsers, onChange, setUserOptions],
  );

  const removeUser = useCallback(
    (user: UserModel) => {
      const removeSelectedUser = selectedUsers.filter(
        (selectedUser) => selectedUser.id !== user.id,
      );
      setSelectedUsers(removeSelectedUser);
      onChange(removeSelectedUser);
      setUserOptions((prevUserOptions) => [...prevUserOptions, user]);
    },
    [selectedUsers],
  );

  const addNewUser = (newUser: UserModel) => {
    const newUserObj = { id: uuid(), ...newUser, displayName: newUser?.name };

    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newUserObj]);
    onChange([newUserObj, ...selectedUsers]);
    setUserOptions((prevUserOptions) =>
      prevUserOptions.filter((option) => option?.id !== newUserObj?.id),
    );
  };

  useEffect(() => {
    if (selectedTemplate?.members.length > 0) {
      setSelectedUsers(selectedTemplate.members);
    }
  }, [selectedTemplate]);

  const SelectedUsers = ({
    selectedAuthors,
    onRemoveUserClick,
  }: {
    selectedAuthors: UserModel[];
    onRemoveUserClick: (author: UserModel) => void;
  }) => {
    return (
      <table className={cls(styles.users_list)}>
        <tbody className={styles.template_members}>
          {selectedAuthors?.map((author: UserModel) => {
            return (
              <tr key={author.id}>
                <td>
                  <UserAvatar
                    className={styles.avatar}
                    sizeWidth={20}
                    sizeHeight={20}
                    altName="author"
                    userName={author.displayName}
                    srcName={author.avatar as string}
                  />
                  <b>{author.displayName}</b>
                </td>
                <td>
                  <i
                    className={cls(
                      styles.remove_member_icon,
                      "icon-remove-sign remove-member",
                    )}
                    onClick={() => onRemoveUserClick(author)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const dropdownContent = () => {
    const { t } = useTranslation();
    return (
      <span className={styles.input_container}>
        <input
          type="text"
          className={styles.input}
          onChange={(e) => onSearchTeam(e.target.value)}
          placeholder={t("Search by name or email")}
        />
        <span className={styles.svg_container}>
          <DropdownIndicator />
        </span>
      </span>
    );
  };

  return (
    <>
      <DropdownButton
        closeOnClick
        hideButtonStyles
        dropdownWrapperClass={cls(styles.dropdown)}
        btnClassName={styles.btnn}
        btnContent={dropdownContent()}
      >
        {connection?.pending ? (
          <Loading loadType="spinner" isLoading />
        ) : (
          <UsersList
            isCreatable={isCreatable}
            results={results}
            toggleUserSelection={toggleUserSelection}
            noOptionsMessage={noOptionsMessage}
            onNoOptionBtnClick={addNewUserModalHandler}
            displayAddUser={displayAddUser}
          />
        )}
      </DropdownButton>
      {isCreatable && addNewUserModal && (
        <Dropdown
          dropdownWrapperClass={styles.add_new_user_dropdown}
          isOpen={addNewUserModal}
          onClose={addNewUserModalHandler}
        >
          <AddNewUser
            title={addNewUserModalTitle}
            onAddUser={addNewUser as any}
            onCancel={addNewUserModalHandler}
            searchInput={searchTerm}
          />
        </Dropdown>
      )}

      {selectedUsers?.length > 0 && (
        <SelectedUsers
          onRemoveUserClick={removeUser}
          selectedAuthors={selectedUsers}
        />
      )}
      {selectedUsers?.length === 0 && (
        <p className={styles.no_selected_users}>
          There are no members in this template
        </p>
      )}
    </>
  );
};

export default SpaceTemplateUsers;

const DropdownIndicator = () => {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className={cls("css-6q0nyr-Svg", styles.dropdown_indicator)}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};
