import React, { LegacyRef } from 'react';
import { useController } from 'react-hook-form';

import styles from './styles.module.scss';

type PropsDef = {
  options: { value: number, label: string }[];
  control: any;
  name: string;
};

const MultiSelect = React.forwardRef((props: PropsDef, ref: LegacyRef<HTMLDivElement>) => {
  const { name, control, options } = props;

  const { field } = useController({ name, control });

  const onClickOption = (option) => {
    if (field.value.includes(option.value)) {
      field.onChange(field.value.filter((value) => value !== option.value));
    } else {
      field.onChange([...field.value, option.value]);
    }
  };

  return (
    <div ref={ref} className={styles.container}>
      {options.map((option) => {
        let cName = `${styles.option}`;
        if (field.value.includes(option.value)) {
          cName = `${cName} ${styles.selected}`;
        }
        return (
          <div className={cName} key={option.value} onClick={() => onClickOption(option)}>
            {option.label}
          </div>
        );
      })}
    </div>
  );
});

export default MultiSelect;
