import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import SuccessModal from "app/components/Layouts/SuccessModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clearNewOwnerData, modalIsOpen } from "app/actions/groups";
import { RootState } from "typedefs";
import { useActions } from "app/utils/hooks";
import { useTranslation } from "react-i18next";

const TransferSuccessModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modalIsOpen: isSuccessModalOpen, newOwner } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual
  );
  const transferActions = useActions({
    clearNewOwnerData,
  });

  const successModalHandler = useCallback(() => {
    dispatch(modalIsOpen(!isSuccessModalOpen));
    transferActions.clearNewOwnerData();
  }, [isSuccessModalOpen]);

  if (!isSuccessModalOpen) {
    return null;
  }

  const onCloseHandler = () => {
    successModalHandler();
  };

  return (
    <SuccessModal
      buttonTitle={t("Done")}
      title={t("Ownership Transferred")}
      isOpen={isSuccessModalOpen}
      onClose={onCloseHandler}
    >
      <div className={styles.success_container}>
        <h3>{t("New Owner Confirmed")}</h3>
        <p>
          {newOwner && newOwner.name}{" "}
          {t("is now the Owner of this Group. Your role has been changed to")}{" "}
          {newOwner &&
            newOwner.type &&
            newOwner.type
              .toLowerCase()
              .replace(/^\w/, (c: string) => c.toUpperCase())}
        </p>
      </div>
    </SuccessModal>
  );
};

export default TransferSuccessModal;
