import React from "react";
import styles from "./styles.module.scss";
import { SpacePageLayoutModel } from "./indexModel";
import SpaceHeader from "../../SpaceHeader";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { Loading } from "app/components/Wrappers";

interface Props {
  children: React.ReactNode;
}

const SpacePageLayout = ({ children }: Props) => {
  const { selectedSpace, onTimelineClick, onScheduleClick, onMembersClick } =
    SpacePageLayoutModel();

  if (!selectedSpace) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <SpaceHeader
          title={selectedSpace.athleteName && selectedSpace.athleteName}
          sport={selectedSpace.sport ? selectedSpace.sport : null}
          avatar={selectedSpace.avatar ? selectedSpace.avatar : null}
          onTimelineClick={onTimelineClick}
          onScheduleClick={onScheduleClick}
          onMembersClick={onMembersClick}
        />
        <div className={styles.content_container}>{children}</div>
      </div>
    </>
  );
};

export default SpacePageLayout;
