/**
 * @module Utils
 *
 */
import isEmpty from './isEmpty';

export function isHexString(value: string): boolean {
  const num = parseInt(value, 16);
  return (num.toString(16) ===value.toLowerCase());
}

export function capitalize(value: string): string {
  if (isEmpty(value)) {
    return value;
  }
  return value[0].toUpperCase() + value.slice(1);
}

export function dasherize(value: string): string {
  if (isEmpty(value)) {
    return value;
  }
  return value
    .replace(/ /g, '_') // remove spaces
    .replace(/([A-Z])/g, '_$1') // remove capital letters
    .replace(/_+/g, '-') // convert underscore and multiple underscores to a single dash
    .replace(/^-/, '') // remove leading dash if exists
    .toLowerCase();
}

export function underscore(value: string): string {
  if (isEmpty(value)) {
    return value;
  }

  return value
    .replace(/ /g, '-') // remove spaces
    .replace(/([A-Z])/g, '-$1') // remove capital letters
    .replace(/-+/g, '_') // convert dashes and multiple dashes to a single underscore
    .replace(/^_/, '') // remove leading underscore if exists
    .toLowerCase();
}

export function classify(value: string): string {
  if (isEmpty(value)) {
    return value;
  }

  return value
    .replace(/[ _-]+/g, ' ')
    .split(/ /)
    .map(w => w.slice(0, 1).toUpperCase() + w.slice(1))
    .join('');
}

export function camelize(value: string): string {
  if (isEmpty(value)) {
    return value;
  }

  const lowerFirst = (val: string): string => {
    return val.slice(0, 1).toLowerCase() + val.slice(1);
  };
  return lowerFirst(classify(value));
}

/**
 * Capitalize all words in statement and all words separated by dashes
 * do not capitalize underscores and lowercase all other letters
 *
 * @method capitalize
 * @param {string} value
 * @return {string}
 */
export function capitalizeWords(value: string): string {
  if (!value.length) {
    return value;
  }
  return value.replace(/([^\W_]+[^\s-]*) */g, (txt: string) =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

/**
 * Returns a new string with placeholders replaced with variables.
 * Used for translations
 *
 * @method formatString
 * @param {string} template
 * @param {string} variables
 * @return {string}
 */
export function formatString(template: string, variables: any): string {
  return template.replace(/%\{(\w+)\}/g, (match, variableName) => {
    return variables.hasOwnProperty(variableName)
      ? variables[variableName]
      : match;
  });
}
