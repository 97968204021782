/** @module actions */
// import { Dispatch } from 'react';
// import { ActionType, action } from 'typesafe-actions';
import { fetchGet, fetchPost, request } from "app/utils/request";
// import { actionUpdater } from 'app/utils/actions';
import { NotificationsModel, TotalUnseenModel } from "app/models";
import { action, ActionType } from "typesafe-actions";
import { Dispatch } from "react";
import { NotificationModel } from "app/models/NotificationModel";
import { NotificationState } from "app/reducers/notification";
import config from "config/environment";

const fetchSuccess = (notification: NotificationsModel[]) =>
  action("@notification.fetch.success", notification);
const unreadNotifications = (notification: {
  notifications?: NotificationsModel[];
  unreadNotificationsCount?: number;
}) => action("@notification.fetch.unreadNotifications", notification);
export const paginatedNotifications = (notification?: NotificationsModel[]) =>
  action("@notification.fetch.paginatedData", notification);
const fetchPending = () => action("@notification.fetch.pending");
const fetchError = (err: string) => action("@notification.fetch.error", err);
const seenCount = (count: number) => action("@notification.seenCount", count);
const totalUnseen = (data: TotalUnseenModel) =>
  action("@notification.totalUnseen", data);
export const hasMore = (hasMore: boolean) =>
  action("@notification.fetch.hasMore", hasMore);
export const currentPage = (currentPage: number) =>
  action("@notification.fetch.currentPage", currentPage);
export const clear = () => action("@notification.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  unreadNotifications,
  paginatedNotifications,
  seenCount,
  totalUnseen,
  hasMore,
  currentPage,
  clear,
};

export type NotificationAction = ActionType<typeof thisActions>;

// export const getUnseen = () => {
//   return fetchGet<NotificationModel>("notifications/total", {}, { version: 2 });
// };

export function setSeenNotifications() {
  return (dispatch: Dispatch<NotificationAction>) => {
    return fetchPost("notifications/seen", {}, { version: 2 }).then(
      (res: TotalUnseenModel) => {
        dispatch(seenCount(0));
        return res;
      },
    );
  };
}

export function getTotalUnseen() {
  return (dispatch: Dispatch<TotalUnseenModel>) => {
    const url = config.API_SERVER + `/api/v2/notifications/total`;
    const promise = request(url, "GET", {}, { version: 2 });
    promise.then((res) => {
      dispatch(seenCount(res?.total));
      dispatch(totalUnseen(res));
    });
    promise.catch((err) => console.error(err));
    return promise;
  };
}
export function markAllSeen() {
  return () => {
    const url = config.API_SERVER + `/api/v2/notifications/seen`;
    const promise = request(url, "POST", {}, { version: 2 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

// export const markAllSeen = () => {
//   return fetchPost<NotificationModel>("notifications/seen", {}, { version: 2 });
// };

export function markAllRead() {
  return (dispatch: Dispatch<NotificationAction>) => {
    return fetchPost<{
      total: number;
      notifications: number;
      invitations: number;
    }>("notifications/read", {}, { version: 2 }).then(() => {
      dispatch(seenCount(0));
    });
  };
}

export function fetchNotifications(
  params?: { [key: string]: any },
  signal?: AbortSignal,
): any {
  return (
    dispatch: Dispatch<NotificationAction>,
    getState: () => NotificationState,
  ) => {
    dispatch(fetchPending());

    // @ts-ignore
    const state = getState().nnotifications;

    return fetchGet<NotificationsModel>(
      "notifications",
      params || {},
      {},
      signal,
    ).then((res: NotificationModel[] | any) => {
      if (res && Array.isArray(res)) {
        if (res.length < (params && (params.perPage || params.limit))) {
          dispatch(hasMore(false));
        }
        dispatch(currentPage(state.currentPage + 1));
        dispatch(fetchSuccess(res));
      }

      return res;
    });
  };
}

export const actions = { fetchNotifications };
