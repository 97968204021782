import { useHistory, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import style from "./styles.module.scss";
import { fetchPostById, reportPost } from "app/actions/posts";
import { editPost, deletePost } from "app/actions/postingForm";
import { RootState } from "typedefs";
import Post from "../../components/Post";
import { PostModel } from "app/models";
import { Loading } from "app/components/Wrappers";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useActions } from "app/utils/hooks";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";
import { useTranslation } from "react-i18next";

const PostPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState<boolean>(false);

  const postsActions = useActions({
    editPost,
    deletePost,
    reportPost,
  });

  const { posts, user } = useSelector(
    (state: RootState) => state,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchPostById(params.id, {
        "options[include]": ["replies"],
      }),
    );
  }, []);

  useEffect(() => {
    if (!post && posts.error) {
      history.goBack();
    }
  }, [posts.error]);

  const post: PostModel = useMemo(() => posts.data, [posts.data]);

  const onPostEdit = useCallback(
    async (editedPost: PostModel) => {
      try {
        await postsActions.editPost({
          ...editedPost,
        });
      } catch (e) {
        setUpdateError(true);
      }
    },
    [post],
  );

  const onPostDelete = useCallback((post: PostModel) => {
    postsActions.deletePost(post?.id);
  }, []);

  const onPostReportClick = useCallback(() => {
    setReportModalOpen(true);
  }, []);

  const onPostReportClose = useCallback(() => {
    setReportModalOpen(false);
  }, []);

  const onReport = useCallback(
    async (message?: string) => {
      const data = await postsActions.reportPost(post.id, message);
      if (data.id) {
        setReportModalOpen(false);
      }
    },
    [post?.id],
  );

  if (!post) {
    return <Loading loadType="spinner" isLoading={posts.pending} />;
  }

  // const detectLocation = (post: PostModel) => {
  //   if (post) {
  //     if (post?.teamGroup?.id) {
  //       setPostLocationData({
  //         location: post?.teamGroup?.name,
  //       });
  //       if (post?.teamGroup?.team?.id) {
  //         setPostLocationData({
  //           location: post?.teamGroup?.team?.name,
  //           subLocation: post?.teamGroup?.name,
  //         });
  //       }
  //     } else if (post?.space?.id) {
  //       setPostLocationData({
  //         location:
  //       })
  //     }
  //   }
  // };

  function formatPostLocation(post: PostModel) {
    if (post.teamGroup != null) {
      return {
        location: post?.teamGroup?.team?.name,
        subLocation: post?.teamGroup?.name,
        avatar: post?.teamGroup?.team?.logo,
        goToUrl: `/groups/${post?.teamGroup?.team?.id}/channels/${post?.teamGroup?.id}`,
      };
    } else if (post.team != null) {
      return {
        location: post?.team?.name,
        subLocation: undefined,
        avatar: post?.team?.logo,
        goToUrl: `/groups/${post?.team?.id}`,
      };
    } else if (post.space != null) {
      return {
        location: post?.space?.athleteName,
        subLocation: post?.space?.sport,
        avatar: post?.space?.avatar,
        goToUrl: `/spaces/${post?.space?.id}`,
      };
    }

    return {
      location: user?.self?.displayName,
      subLocation: undefined as any,
      avatar: user?.self?.avatar,
      goToUrl: "/",
    };
  }

  return (
    <div className={style.container}>
      <GettingStartedProgress />
      <PagePanel
        headerWrapperClassName={style.panel_container}
        title={formatPostLocation(post)?.location}
        subTitle={formatPostLocation(post)?.subLocation}
        avatar={formatPostLocation(post)?.avatar}
        onButtonClick={() => history.push(formatPostLocation(post)?.goToUrl)}
        buttonTitle={t("Go To Timeline")}
        buttonType="outline-secondary"
        buttonStyles={style.go_to_button}
      />
      <Post
        key={post.id}
        post={post}
        onRemove={onPostDelete}
        onReport={onPostReportClick}
        onEdit={onPostEdit}
        updateError={!!updateError}
        user={self}
      />
      {isReportModalOpen && (
        <PostReportModal
          isModalOpen={isReportModalOpen}
          modalHandler={onPostReportClose}
          reportHandler={onReport}
        />
      )}
    </div>
  );
};
export default PostPage;
