import React, { useState, LegacyRef } from 'react';

import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import styles from './styles.module.scss';

type PropsDef = {
  date: DateTime;
  setDate: (DateTime) => void;
  formatDate?: (DateTime) => string;
  minDate?: Date | null;
};

const defaultDateFormat = (date: DateTime): string => {
  return date.setLocale("en-us").toLocaleString(DateTime.DATE_FULL);
};

const DatePicker = (props: PropsDef) => {
  const { date, setDate, formatDate = defaultDateFormat, minDate = null } = props;
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const CustomInput = React.forwardRef((_props, ref) => {
    return (
      <div className={styles.title_container} onClick={handleClick} ref={ref as LegacyRef<HTMLInputElement>}>
        <div className={styles.title}>{formatDate(date)}</div>
        <div className={styles.offset} style={{ transform: `${datePickerOpen ? 'rotate(180deg)' : 'none' }` }}>
          <i className="ico ico-chevron" />
        </div>
      </div>
    );
  });
  CustomInput.displayName = 'HeaderInput';

  const handleChange = (value) => {
    setDate(DateTime.fromJSDate(value));
    setDatePickerOpen(false);
  };

  const handleClick = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className={styles.header}>
        <div className={styles.date_arrow} style={{ transform: 'rotate(90deg)' }}  onClick={decreaseMonth}>
          <span> <i className="ico ico-chevron"/> </span>
        </div>
        <p className={styles.current_month}>
          {date.toLocaleString({ month: 'long', year: 'numeric' })}
        </p>
        <div className={styles.date_arrow} style={{ transform: 'rotate(-90deg)' }} onClick={increaseMonth}>
          <span> <i className="ico ico-chevron" /> </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.date_picker}>
      <ReactDatePicker
        selected={date.toJSDate()}
        onChange={(value) => handleChange(value)}
        dateFormat="MMMM, d, yyyy"
        useWeekdaysShort={true}
        renderCustomHeader={({ date: selDate, decreaseMonth, increaseMonth }) => (
          <CustomHeader
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            date={DateTime.fromJSDate(selDate)}
          />
        )}
        customInput={<CustomInput />}
        open={datePickerOpen}
        onClickOutside={() => setDatePickerOpen(false)}
        minDate={minDate}
        calendarClassName={styles.day_picker}
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default DatePicker;
