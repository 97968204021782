/**
 * @module Actions.TimeZone
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { TimeZoneModel, TimeZoneResponse } from "app/models/scheduling/TimeZoneModel";

const fetchPending = () => action("@timeZones.fetch.pending");

const fetchError = (err: string) => action("@timeZones.fetch.error", err);

const fetchSuccess = (timeZones: TimeZoneModel[]) =>
  action("@timeZones.fetch.success", timeZones);

const clear = () => action("@timeZones.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  clear,
};

export type TimeZoneAction = ActionType<typeof thisActions>;

export function fetchTimeZones() {
  return (dispatch: Dispatch<TimeZoneAction>) => {
    dispatch(fetchPending());
    return schedulingFetchGet<TimeZoneResponse>("/time_zones")
      .then((time_zones: TimeZoneResponse) => {
        const models: TimeZoneModel[] = time_zones.data.map(timeZone => {
          return new TimeZoneModel(timeZone);
        });

        dispatch(fetchSuccess(models));
      })
      .catch((err) => {
        console.error('fetchErr', err);
        dispatch(fetchError(JSON.stringify(err)));
      });
  };
}
