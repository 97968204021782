import { StylesConfig } from "react-select";
import tagsIcon from "../../../../../public/images/tags-icon.svg";
import { CSSProperties } from "react";

export const tagIcon = () => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    content: tagsIcon ? `url(${tagsIcon})` : '" "',
    marginRight: 8,
    marginLeft: 8,
    width: 20,
    height: 20,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
});

export const tagsSelectStyles: StylesConfig = {
  control: (base: CSSProperties) => ({
    ...base,
    // width: "100%",
    // display: "flex",
    // alignItems: "center",
    ...tagIcon(),
    boxShadow: "none",
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    width: "100%",
  }),
  option: (base: CSSProperties) => ({ ...base, cursor: "pointer" }),
  container: (base: CSSProperties) => ({
    ...base,
    width: "100%",
  }),
  indicatorsContainer: (base: CSSProperties) => ({
    ...base,
    cursor: "pointer",
    height: "100%",
    padding: "0 12px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  }),
  indicatorSeparator: (base: CSSProperties) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: "#F4F4F4",
    padding: "4px",
    // margin: "10px 12px",
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: "#F4F4F4",
    padding: "4px",
    // margin: "10px 12px",
  }),
  input: (base: CSSProperties) => ({
    ...base,
    border: 0,
    backgroundColor: "transparent",
    transition: "initial",
    // height: "44px",
  }),
  multiValue: (base: CSSProperties) => ({
    ...base,
    backgroundColor: "#f8f8f8",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #ddd",
    color: "#5e5e5e",
    height: "28px",
  }),
  multiValueRemove: (base: CSSProperties) => ({
    ...base,
    color: "#5e5e5e",
    padding: "6px 8px",
    borderRight: "1px solid #ddd",
    ":hover": {
      backgroundColor: "#dfdfdfdf",
      cursor: "pointer",
      fontSize: "2px",
    },
  }),
  multiValueLabel: (base: CSSProperties) => ({
    ...base,
    padding: "6px 8px 6px 8px!important",
    color: "#5e5e5e",
  }),
};
