import React, { memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import useLocationsModel from "./indexModel";
import SearchBar from "../inputs/SearchBar";
import TeamLocation from "./ObjectLocation/Team";
import AthleteSpaceLocation from "./ObjectLocation/AthleteSpace";
import ListLocation from "./ObjectLocation/List";
import { LocationsDropdownProps } from "./index.types";
import SmartListLocations from "./ObjectLocation/smartList";
import { useTranslation } from "react-i18next";

const LocationDropDown = ({
  hideSmartLists,
  hideLists,
  hideSpaces,
  hideGroups,
  classNames,
  onCheckboxChange,
}: LocationsDropdownProps) => {
  const { t } = useTranslation();

  const {
    isSmartList,
    modalHandler,
    dropdownRef,
    isOpen,
    toggleListClick,
    selectedLists,
    handleSearchChange,
    filteredDropDownData,
    searchQuery,
    pending,
    spacesData,
    containerRef,
  } = useLocationsModel({ onCheckboxChange });

  if (isSmartList) {
    return null;
  }

  return (
    <div className={cls(styles.container, classNames)}>
      <div className={styles.wrapper} onClick={modalHandler}>
        {selectedLists && (
          <p>{t("count_location", { count: selectedLists.size })}:</p>
        )}
        <i className="ico ico-chevron" />
      </div>
      {/* {isOpen && ( */}
      <div
        ref={dropdownRef}
        className={cls(styles.dropdown_container, {
          [styles.isOpen]: isOpen,
        })}
      >
        <div className={styles.search_container}>
          <SearchBar
            className={styles.search}
            placeholder={t("Search Lists")}
            onChange={handleSearchChange}
          />
        </div>
        {filteredDropDownData && (
          <div className={styles.dropdown_list_container}>
            {!hideSmartLists && filteredDropDownData.smartList && (
              <div>
                <SmartListLocations
                  lists={filteredDropDownData.smartList}
                  toggleListClick={toggleListClick}
                  selectedLists={selectedLists}
                />
              </div>
            )}
            {!hideLists &&
              filteredDropDownData.list.data &&
              filteredDropDownData.list.data.length > 0 && (
                <div>
                  <ListLocation
                    lists={filteredDropDownData.list}
                    toggleListClick={toggleListClick}
                    selectedLists={selectedLists}
                  />
                </div>
              )}
            {!hideSpaces && (
              <AthleteSpaceLocation
                toggleListClick={toggleListClick}
                selectedLists={selectedLists}
                searchTerm={searchQuery}
                containerRef={containerRef}
                pending={pending}
                spacesData={spacesData}
              />
            )}
            {!hideGroups &&
              filteredDropDownData.team.data &&
              filteredDropDownData.team.data.length > 0 && (
                <div>
                  <TeamLocation
                    teams={filteredDropDownData.team}
                    toggleListClick={toggleListClick}
                    selectedLists={selectedLists}
                  />
                </div>
              )}
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default memo(LocationDropDown);
