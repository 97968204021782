import React from 'react';
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { useForm, SubmitHandler } from 'react-hook-form';
import styles from "./styles.module.scss";
import CloseIcon from "../../../../../../public/images/close.svg";

interface EditPricingGroupProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSave: (newTitle: string) => void;
}

interface FormValues {
  pricingGroupName: string;
}

export default function EditPricingGroup({ isOpen, title, onClose, onSave }: EditPricingGroupProps) {
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = data => {
    onSave(data.pricingGroupName);
    onClose();
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onClose}/>
        <h2>Edit Pricing Group</h2>
      </div>
      <div className={styles.modal_content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="pricingGroupName">Pricing Group Name</label>
          <input
            id="pricingGroupName"
            {...register('pricingGroupName', { required: true })}
            defaultValue={title}
          />
          {errors.pricingGroupName && <span>This field is required</span>}

          <div className={styles.buttonGroup}>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className={styles.confirmButton}>
              Confirm
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
}
