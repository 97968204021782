/* eslint-disable */
import { fetchGet, request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { LibraryState } from "app/reducers/media-library-data";

const pending = () => action("@media.fetch.pending");

const error = (err: string) => action("@media.fetch.error", err);

const success = (success: any) => action("@media.fetch.success", success);

const data = (data: []) => action("@media.fetch.data", data);

export const attachedData = (data: any) =>
  action("@media.fetch.attachment", data);

export const mediaLibraryData = (data: any) =>
  action("@media.fetch.mediaLibraryData", data);

const hasMore = (hasMore: boolean) => action("@media.fetch.hasMore", hasMore);

export const page = (page: number) => action("@media.fetch.currentPage", page);

export const selectedMedia = (selectedMedia: MediaTypes) =>
  action("@media.fetch.selectedMedia", selectedMedia);

export const clearMediaState = () => action("@media.clear");

const thisActions = {
  pending,
  error,
  success,
  data,
  attachedData,
  mediaLibraryData,
  hasMore,
  selectedMedia,
  clearMediaState,
};

export type mediaAction = ActionType<typeof thisActions>;

export function fetchMedia(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<mediaAction>, getState: () => LibraryState) => {
    // @ts-ignore
    const state = getState().mediaLibraryData;
    dispatch(pending());
    return fetchGet<mediaAction>("resources", params || {}, {}, signal).then(
      (res: [] | any) => {
        if (res && Array.isArray(res)) {
          dispatch(success(res));
          if (res.length < (params && params.perPage)) {
            dispatch(hasMore(false));
          }
        }
        return res;
      }
    );
  };
}

export const fetchMediaById = (id: string) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());
    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((res) => {
      dispatch(attachedData(res));
    });
    promise.catch((err) => {
      dispatch(error(err));
    });
    return promise;
  };
};

export const deleteMedia = (id: string) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());
    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.then((res) => {
      dispatch(success(res));
    });
    promise.catch((err) => dispatch(error(err)));
    return promise;
  };
};

export const createMedia = (resource: { id: string; filename: string }) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());
    const url = config.API_SERVER + `/api/v2/resources`;
    const promise = request(url, "POST", resource, { version: 1 });
    promise.then((res: MediaTypes) => {
      let parsedRes: MediaTypes;
      try {
        parsedRes = typeof res === "string" ? JSON.parse(res) : res;
        dispatch(attachedData(parsedRes));
      } catch (error) {
        console.error("Error parsing JSON:", error);
        dispatch(error(error));
        return;
      }
    });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
};

export const editMedia = (id: string, resource: any) => {
  return (dispatch: Dispatch<mediaAction>) => {
    dispatch(pending());
    const url = config.API_SERVER + `/api/v1/resources/${id}`;
    const promise = request(url, "PUT", resource, { version: 1 });
    promise.then((data) => dispatch(success(data)));
    promise.catch((err) => dispatch(error(err)));
    return promise;
  };
};
