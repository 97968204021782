import React, { memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { useNotificationsModel } from "./indexModel";
import Button from "app/components/Button/ButtonVariant";
import NotificationsList from "../../NotificationsList";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const { markAllAsRead } = useNotificationsModel();
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const readIcon = (
    <i
      className={cls(
        "ico ico-read"
        // {
        //   [styles.read_all]:
        //     notificationData &&
        //     notificationData.unreadNotificationsCount > 0,
        // }
      )}
    />
  );

  return (
    <div className={styles.notification_container}>
      <NotificationsList />
      <div className={styles.footer}>
        <Button
          title={t("View All")}
          buttonType="secondary-outline"
          onClickFunc={() => route("/notifications")}
        />
        <Button
          title=""
          buttonStyles={styles.read_all_btn}
          icon={readIcon}
          buttonType="secondary-outline"
          onClickFunc={markAllAsRead}
        />
      </div>
    </div>
  );
};

export default memo(Notifications);
