import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import closeIcon from "../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions, useStoreActions } from "app/utils/hooks";
import CustomSelect from "app/components/inputs/CustomSelector";
import { fetchSports } from "app/actions/sports";
import { Loading } from "app/components/Wrappers";
import UserAvatar from "app/components/UserAvatar";
import {
  fetchSpaceById,
  putSpaceSettings,
  deleteSpace,
  clearSpace,
} from "app/actions/space";
import PhoneInput from "react-phone-input-2";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type Props = {
  isModalOpen: boolean;
  modalHandler: () => void;
  transferSpaceModalHandler: () => void;
};

const SpaceSettingsModal = ({
  isModalOpen,
  modalHandler,
  transferSpaceModalHandler,
}: Props) => {
  const { t } = useTranslation();
  const spaceActions = useActions({
    fetchSpaceById,
    putSpaceSettings,
    deleteSpace,
    clearSpace,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };
  const { selectedSpace } = useSelector((state: RootState) => state.space, shallowEqual);
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });

  const [spaceSettings, setSpaceSettings] = React.useState(
    selectedSpace ? selectedSpace : null
  );
  const [canTransfer] = useState(
    selectedSpace &&
      selectedSpace.permissions.includes("transfer_ownership") &&
      selectedSpace.planPermissions.includes("transfer_ownership")
  );

  const [canDelete] = useState(
    selectedSpace &&
      selectedSpace.permissions.includes("delete") &&
      selectedSpace.planPermissions.includes("delete")
  );

  const onChangeHandler = (key: string, value: boolean | string) => {
    setSpaceSettings({ ...spaceSettings, [key]: value });
  };

  const onUpdateHandler = () => {
    spaceActions.putSpaceSettings(spaceSettings).then(() => {
      spaceActions.fetchSpaceById(selectedSpace.id).then(() => modalHandler());
    });
  };

  const onDeleteGroup = () => {
    customPrompt({
      message: t(`Are you sure you would like to delete {{name}}?`, {
        name: selectedSpace.athleteName,
      }),
      onYes: () => {
        spaceActions.deleteSpace(selectedSpace.id).then(() => route("/spaces"));
      },
      onCancel: () => {
        return;
      },
    });
  };

  const copyEmail = () => {
    const email = document.getElementById("athlete_email") as HTMLInputElement;
    navigator.clipboard.writeText(email.value);
  };

  useEffect(() => {
    return () => {
      setSpaceSettings(null);
    };
  }, []);

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Space Settings")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>
        <div className={styles.space_avatar_container}>
          <UserAvatar
            altName="team"
            className={styles.space_avatar}
            userName={
              spaceSettings && spaceSettings.ownerName
                ? spaceSettings.ownerName
                : null
            }
            srcName={
              spaceSettings && spaceSettings.avatar
                ? spaceSettings.avatar
                : null
            }
          />
        </div>
        <div className={styles.text_input_wrapper}>
          <label htmlFor="athlete_name">{t("Name")}</label>
          <input
            type="text"
            name="athlete_name"
            id="athlete_name"
            defaultValue={
              spaceSettings && spaceSettings.athleteName
                ? spaceSettings.athleteName
                : null
            }
            placeholder=""
            onChange={(event) =>
              onChangeHandler("athlete_name", event.target.value)
            }
          />
        </div>

        <div className={styles.text_input_wrapper}>
          <label htmlFor="name">{t("Sport")}</label>
          {!spaceSettings || sports.pending ? (
            <Loading isLoading loadType="spinner" />
          ) : (
            <CustomSelect
              className={styles.sport_container}
              placeholder={t("Select Sport / Subject")}
              isCratable
              options={sports && sports.data}
              defaultValue={
                spaceSettings && spaceSettings.sport
                  ? {
                      label: spaceSettings.sport,
                      value: spaceSettings.sport,
                    }
                  : null
              }
              isClearable
              isSearchable
              onChange={(data: { value: string; label: string }) => {
                onChangeHandler(
                  "sport",
                  data && data.value ? data.value : null
                );
              }}
            />
          )}
        </div>

        <div className={styles.text_input_wrapper}>
          <label htmlFor="phone">{t("Athlete Phone")}</label>
          <PhoneInput
            autoFormat
            country={!spaceSettings.athletePhone && "us"}
            value={spaceSettings.athletePhone}
            onChange={(e) => onChangeHandler("athlete_phone", e)}
          />
        </div>

        <div className={styles.text_input_wrapper}>
          <label htmlFor="athlete_email">{t("Post from Email")}</label>
          <div className={styles.email_copy_container}>
            <input
              className={styles.email_input}
              readOnly
              disabled
              type="text"
              name="athlete_email"
              id="athlete_email"
              defaultValue={
                spaceSettings && spaceSettings.email
                  ? spaceSettings.email
                  : null
              }
              placeholder=""
              onChange={(event) =>
                onChangeHandler("athlete_email", event.target.value)
              }
            />
            <Button
              title=""
              icon={<i className="ico ico-copy" />}
              onClickFunc={copyEmail}
              buttonStyles={styles.copy_button}
              buttonType="secondary-outline"
            />
          </div>
          <p className={cls("text-muted", styles.email_description)}>
            {t("Email this address to post to this Space.")}
          </p>
        </div>

        <div className={styles.text_input_wrapper}>
          <div className="control-group checkbox-control-group">
            <div className="checkbox">
              <input
                id="sendInvitationReminders"
                name="sendInvitationReminders"
                type="checkbox"
                defaultChecked={
                  spaceSettings && spaceSettings.sendInvitationReminders
                }
                onChange={(e) =>
                  onChangeHandler("send_invitation_reminders", e.target.checked)
                }
              />
              <label htmlFor="sendInvitationReminders"></label>
            </div>
            <label htmlFor="sendInvitationReminders" className="ng-binding">
              {t("Send Invitation Reminders")}
            </label>
          </div>
        </div>

        {(canDelete || canTransfer) && (
          <div className={styles.options_section}>
            {canDelete && (
              <Button
                title={t("Delete Space")}
                buttonType="secondary-outline"
                buttonStyles={cls("text-danger bold", styles.option_button)}
                onClickFunc={onDeleteGroup}
              />
            )}
            {canTransfer && (
              <Button
                title={t("Transfer Space Ownership")}
                buttonType="secondary-outline"
                buttonStyles={cls("text-danger bold", styles.option_button)}
                onClickFunc={transferSpaceModalHandler}
              />
            )}
          </div>
        )}

        <div className={styles.footer_button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="primary"
            title={t("Save")}
            onClickFunc={onUpdateHandler}
            disabled={!spaceSettings}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default SpaceSettingsModal;
