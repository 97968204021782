import React from 'react';

import styles from './styles.module.scss';

export default function Option(props: any) {
  return (
    <div
      className={`${styles.option} ${props.isSelected ? styles.selected : ''}`}
      onClick={() => props.selectOption(props.data)}
      key={props.value}
    >
      <div className={styles.option_info}>
        <div className={styles.student_avatar}>
          <img src={props.data.profilePicture} alt={props.data.label} />
        </div>
        <div className="option__label">{props.label}</div>
      </div>

      <div className={`${styles.selection_status} ${props.isSelected ? styles.selected : ''}`}>
        {props.isSelected ? <i className="ico ico-check" /> : <div className={styles.blank_selection} />}
      </div>
    </div>
  );
}
