import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { AvailabilityModel } from "app/models/scheduling/AvailabilityModel";
import { CoachProfileModel } from "app/models/scheduling/CoachProfileModel";

const useAvailability = (coachProfile: CoachProfileModel, startDate: DateTime, endDate: DateTime) => {
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(true);

  const coachProfileId = coachProfile ? coachProfile.id : null;

  const start: string = startDate ? startDate.toISODate() : null;
  const end: string = endDate ? endDate.toISODate() : null;

  useEffect(() => {
    setLoading(true);

    if (!coachProfileId || !start || !end) {
      return;
    }

    const params = {
      start_date: start,
      end_date: end,
    };

    schedulingFetchGet(`/coach_profiles/${coachProfileId}/availability`, params)
      .then((availabilities) => {
        const models = availabilities.map((availability) => {
          return new AvailabilityModel(availability);
        });
        setAvailability(models);
        setLoading(false);

      });
  }, [coachProfileId, start, end]);

  return { availability, loading };
};

export default useAvailability;
