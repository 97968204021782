import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

const useTemplatePostsContainerModel = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  return {
    t,
    self,
  };
};

export default useTemplatePostsContainerModel;
