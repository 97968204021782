/**
 * @module Router
 *
 */
import React, { useEffect, useMemo } from "react";
import { AnalyticsService } from "app/services";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";

/** Routes */
import Benefits from "app/routes/Benefits";
import SignIn from "app/routes/SignIn";
import SignUp from "app/routes/SignUp";
import ForgotPassword from "app/routes/ForgotPassword";
import ResetPassword from "app/routes/ResetPassword";
import Upgrade from "app/routes/Upgrade";
import Trial from "app/routes/Trial";
import {
  UnsubscribeInvitation,
  UnsubscribeSpace,
  UnsubscribeUser,
} from "app/routes/Unsubscribe";

import PrivateRoute from "./components/Wrappers/PrivateRoute";
import { routerData } from "./RouterData";
import { v4 } from "uuid";
import { LOCAL_STORAGE_AUTH_TOKEN_NAME, LOCAL_STORAGE_AUTH_TOKEN_NAME_TEST } from "./components/Sidebar/indexModel";
import useStoreActions from "./utils/hooks/useStoreActions";
import { fetchSelf } from "./actions/user";
import i18n from "app/i18n";
import { getCookie, setCookie } from "./utils/cookie";

/**
 * @function AppRouter
 *
 */
const AppRouter = () => {
  useEffect(() => {
    AnalyticsService.page();
  }, []);

  const { user } = useStoreActions({ user: fetchSelf });
  const pending = user.pending || user.data == null;

  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_NAME) ?? localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_NAME_TEST);

  useMemo(() => {
    if(token &&  !pending && user && user.data?.locale) {
      i18n.changeLanguage(user.data?.locale);
      setCookie("locale", user.data?.locale, 365);
    } else if (token) {
      const locale = getCookie('locale');

      if(i18n.language !== locale) {
        i18n.changeLanguage(locale);
      }
    } else {
      i18n.changeLanguage(
        (navigator.language || navigator.userLanguage).split('-')[0]
      );
    }
  }, [pending, user, token]);

  return (
    <Switch>
      {/* Public routes */}
      <Route path="/benefits" component={Benefits} />
      <Route path="/reset" component={ForgotPassword} />
      <Route path="/upgrade" component={Upgrade} />
      <Route path="/trial" component={Trial} />
      <Route path="/password/edit" component={ResetPassword} />
      <Route path="/login" component={() => <Redirect to="/signin" />} />
      <Route path="/signin" component={SignIn} />
      <Route
        path={[
          "/signup/:user_role?",
          "/i/:team_token/:team_role?",
          "/invitation/:invitation_token",
          "/l/:invitation_token",
        ]}
        component={SignUp}
      />
      <Route
        path="/public/unsubscribe/invitation/:invitationId"
        component={UnsubscribeInvitation}
      />
      <Route
        path="/public/unsubscribe/user/:token"
        component={UnsubscribeUser}
      />
      <Route
        path="/public/unsubscribe/:spaceId/:token"
        component={UnsubscribeSpace}
      />

      {/* Private routes */}
      {routerData.map((route, index) => (
        <Route key={index} path={route.path} exact={route.exact}>
          {route.redirectTo ? (
            <Redirect from={route.path} to={route.redirectTo} />
          ) : (
            <PrivateRoute
              permission={route?.permission || ""}
              permissionObject={route?.permissionObject || ""}
            >
              <route.main resourceId={v4()} />
            </PrivateRoute>
          )}
        </Route>
      ))}
    </Switch>
  );
};

export default withRouter(AppRouter);
