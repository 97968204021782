import { currentLikesAction } from "app/actions/currentLikes";
import { RequestError } from "typedefs";

export type CurrentLikesState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;

  hasMore: boolean;
};

const initialState: CurrentLikesState = {
  data: null,
  error: null,
  pending: true,

  hasMore: true,
};

export default function likesReducer(
  state = initialState,
  action: currentLikesAction
) {
  switch (action.type) {
    case "@likes.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@likes.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@likes.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@likes.fetch.hasMore":
      return { ...state, hasMore: action.payload, pending: false };
    case "@likes.clear":
      return {
        ...state,
        // @ts-ignore
        data: null,
        pending: false,
        loadMore: true,
        currentPage: 1,
      };
    default:
      return state;
  }
}
