/** @module reducers */
import {
  ImportModel,
  BatchResult,
  TeamGroupMemberImportActions,
} from "app/actions/team-group-member-import";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import UserModel from "app/models/UserModel";

export type TeamGroupMemberImportState = {
  importPending: boolean;
  importStatus: ImportModel;
  importDone: BatchResult;
  invitationModalType: GroupInviteModalTypes;
  modalInviteIsOpen: boolean;
  modalInviteError: null | string;
  modalInvitePending: boolean;
  membersListToInvite: UserModel[] | null;
  selectedTeams: any | null;
  selectedMembersRole: string | null;
};

const initialState: TeamGroupMemberImportState = {
  importPending: false,
  importStatus: null,
  importDone: null,
  invitationModalType: null,
  modalInviteIsOpen: false,
  modalInviteError: null,
  modalInvitePending: false,
  membersListToInvite: null,
  selectedTeams: null,
  selectedMembersRole: "player",
};

export default function teamGroupMemberImportReducer(
  state = initialState,
  action: TeamGroupMemberImportActions
): TeamGroupMemberImportState {
  switch (action.type) {
    case "@team-group-member-import.post.pending":
      return {
        ...state,
        importStatus: null,
        importDone: null,
        importPending: true,
      };
    case "@team-group-member-import.post.status":
      return { ...state, importStatus: action.payload };
    case "@team-group-member-import.post.done":
      return { ...state, importDone: action.payload, importPending: false };
    case "@team-group-member-import.invitationModalType":
      return { ...state, invitationModalType: action.payload };
    case "@team-group-member-import.isOpenSuccessInviteModal":
      return { ...state, modalInviteIsOpen: action.payload };
    case "@team-group-member-import.pendingInviteModal":
      return { ...state, modalInvitePending: false };
    case "@team-group-member-import.membersToInvite":
      return { ...state, membersListToInvite: action.payload };
    case "@team-group-member-import.selectTeams":
      return { ...state, selectedTeams: action.payload };
    case "@team-group-member-import.selectedMembersRole":
      return { ...state, selectedMembersRole: action.payload };
    case "@team-group-member-import.errorInviteModal":
      return {
        ...state,
        modalInviteError: action.payload,
        modalInvitePending: false,
      };
    case "@team-group-member-import.clear":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
