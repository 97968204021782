/** @module config/environment */
import featureFlags from './features';
import { getWithDefault, featureOverrides } from './helpers';
import moment from 'moment';
/**
 * Web App Config
 */
const settings = {
  VERSION: getWithDefault(ENV, 'version', '0.0.0-test'),
  ENVIRONMENT: getWithDefault(ENV, 'environment', 'test'),
  BUILD: getWithDefault(ENV, 'build', 'test'),
  DEBUG: true,
  PUBLIC_URL: 'https://staging.coachnow.io',
  API_URL: 'https://api-staging.coachnow.io',
  API_SERVER: 'https://api-staging.coachnow.io',
  API_CLIENT_ID: '44bc56c490ecc81b859789e6b6cc36d5',
  AUTH_STORE_KEY: 'app-auth-token',
  STRIPE_API_KEY: 'pk_test_WX9I6OJDzG2bClxhzxKBL6fb',
  SEGMENT_WRITE_ID: '9h7eltyu6E0S3srsiDmYUQPetEXc2ndg',
  FACEBOOK_APP_ID: 2372070003058415,
  BUGSNAG_API_KEY: getWithDefault(ENV, 'bugsnag_api_key', ''), // '24c7ec697646887ca55bc4f01ea74618',
  SUPPORT_URL: 'https://help.coachnow.io/',
  SUPPORT_EMAIL: 'support@coachnow.io',
  CALENDLY_LINK: 'geraldo-iii/demo-staging-test',
  INCLUDE_SERVICE_WORKER: false,
  FEATURES: featureFlags,
  CURRENT_YEAR: moment().format('YYYY'),
  SCHEDULING_API_URL: 'https://dev-coaching-business-app.gg-cluster.com',
  SCHEDULING_API_SERVER: 'https://dev-coaching-business-app.gg-cluster.com',
};

/**
 * DEVELOPMENT
 */
if (settings.ENVIRONMENT === 'development') {
  // general dev setting overrides
  settings.PUBLIC_URL = getWithDefault(ENV, 'public_url', 'http://localhost:4200');
  settings.DEBUG = getWithDefault(ENV, 'debug_mode', settings.DEBUG);
  settings.CALENDLY_LINK = getWithDefault(ENV, 'calendly_link', settings.CALENDLY_LINK);
  // settings.CALENDLY_LINK = 'coachnow1/demo-with-coachnow-product-specialists';

  // use default API_URL or allow it to be set from .env file
  // setting `api_url=self` in a .env will result in api calls to http://localhost:4200
  const api_url = getWithDefault(ENV, 'api_url', settings.API_URL);
  settings.API_URL = api_url === 'self' ? '' : api_url;

  // toggle service worker
  settings.INCLUDE_SERVICE_WORKER = getWithDefault(ENV, 'include_service_wroker', false);

  // default analyticsTool feature to off
  // but allow a .env file to override it
  settings.FEATURES.analyticsTool = false;
  // disable bugsnag in dev mode
  settings.FEATURES.bugsnagTool = false;

  // dev features can be turned on/off by setting true/false
  // in the .env file for a flagName
  featureOverrides(settings.FEATURES);
}

/**
 * TESTING
 */
if (settings.ENVIRONMENT === 'test') {
  settings.AUTH_STORE_KEY = 'app-auth-token-test';
  settings.FEATURES.analyticsTool = false;
  settings.FEATURES.bugsnagTool = false;
}

/**
 * STAGING
 */
if (settings.ENVIRONMENT === 'staging') {
  settings.FACEBOOK_APP_ID = 225370460929680;
  settings.INCLUDE_SERVICE_WORKER = false;
}

/**
 * PRODUCTION
 */
if (settings.ENVIRONMENT === 'production') {
  settings.PUBLIC_URL = 'https://app.coachnow.io';
  settings.API_URL = 'https://api.coachnow.io';
  settings.API_SERVER = 'https://api.coachnow.io';
  settings.DEBUG = false;
  settings.FACEBOOK_APP_ID = 1672800346194024;
  settings.STRIPE_API_KEY = 'pk_live_FyxenkZnSQx8AZBh8yj9KlSH';
  settings.SEGMENT_WRITE_ID = 'yEjZevqpOjUZtavDt1Xzf7HG5oCGlf2c';
  settings.CALENDLY_LINK = 'coachnowliveevents';
  settings.SCHEDULING_API_URL = 'https://api-coaching-business.golfgenius.com/';
  settings.SCHEDULING_API_SERVER = 'https://api-coaching-business.golfgenius.com/';
}

export default settings;
