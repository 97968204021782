import React, { memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { sidebarItems } from "./sidebarItems";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActivitySidebar = () => {
  const { t } = useTranslation();
  const activePath = window.location.pathname;
  const history = useHistory();

  return (
    <div className={styles.container}>
      {sidebarItems(t).map((item) => (
        <div
          onClick={() => history.push(item.path)}
          key={item.title}
          className={cls(styles.list_item, {
            [styles.active]: activePath === item.path ? styles.active : "",
          })}
        >
          {item.icon}
          <span className={styles.list_title}>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(ActivitySidebar);
