import React from "react";
import styles from "./styles.module.scss";
import DemoSpacesPageLayout from "app/components/Layouts/DemoSpacesPageLayout";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import SpaceMemberCard from "app/components/SpaceMemberCard";
import SpaceMemberModel from "app/models/SpaceMemberModel";

const DemoSpaceMembers = () => {
  const { selectedDemoSpace } = useSelector(
    (state: RootState) => state.demoSpace, shallowEqual
  );

  return (
    <DemoSpacesPageLayout>
      <div className={styles.container}>
        <label>Members</label>
        <div className={styles.members_wrapper}>
          {selectedDemoSpace?.members.map((member: SpaceMemberModel) => (
            <div key={member.id} className={styles.grid}>
              <SpaceMemberCard member={member} hasMenu={false} />
            </div>
          ))}
        </div>
      </div>
    </DemoSpacesPageLayout>
  );
};

export default DemoSpaceMembers;
