import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ModalLayoutWithTitle from "../Layouts/ModalLayoutWithTitle";
import Button from "../Button/ButtonVariant";
import moment from "moment";

interface ITogglePinModal {
  postId: string;
  isOpen: boolean;
  onClose: () => void;
  isPinned: boolean;
  onPinHandler: (pinTill: string) => void;
}

const TogglePinModal = ({
  isOpen,
  onClose,
  isPinned,
  onPinHandler,
}: ITogglePinModal) => {
  const { t } = useTranslation();
  const [isPinTillClicked, setPinTillClicked] = useState(false);
  const [pinTill, setPinTill] = useState(null);

  const onClickPinTill = useCallback(() => {
    setPinTillClicked(!isPinTillClicked);
  }, [isPinTillClicked]);

  const onDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPinTill(e.target.value);
  }, []);

  const onPin = useCallback(async () => {
    const unformattedDate = pinTill ? new Date(pinTill).toISOString() : null;
    await onPinHandler(unformattedDate);
    onClose();
  }, [pinTill, onPinHandler]);

  const PinModal = useCallback(() => {
    return (
      <div className={styles.pin_container}>
        {!isPinTillClicked && (
          <Button
            buttonType="secondary-outline"
            title={
              pinTill
                ? t("Pin till {{date}}", {
                    date: moment(pinTill).format("LL"),
                  })
                : t("Pin till...")
            }
            onClickFunc={onClickPinTill}
          />
        )}

        {isPinTillClicked && (
          <div className={styles.pin_till_container}>
            <input
              type="date"
              min={moment().add(1, "days").format("YYYY-MM-DD")}
              max="9999-12-31"
              value={pinTill || ""}
              className={styles.date_picker_input}
              id="post_pinned_till"
              name="pinned_till"
              onChange={onDateChange}
            />
            <Button
              buttonType="secondary-outline"
              title={t("Select Date")}
              onClickFunc={onClickPinTill}
            />
          </div>
        )}
      </div>
    );
  }, [t, isPinTillClicked, pinTill]);

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={!isPinned ? t("Pin this post?") : t("Unpin from top?")}
    >
      <div className={styles.container}>
        <div className={styles.header_description}>
          {!isPinned
            ? t(
                "This post will be pinned to the top for everyone in this Space."
              )
            : t(
                "This post will be unpinned from top for everyone in this Space."
              )}
        </div>
        <div className={styles.content_container}>
          <Button
            buttonType="primary"
            title={!isPinned ? t("Pin") : t("Unpin")}
            onClickFunc={onPin}
          />
          {!isPinned && <PinModal />}
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={onClose}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default TogglePinModal;
