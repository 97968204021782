import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";

import styles from './styles.module.scss';

type PropsDef = {
  onConfirm: () => void;
  onClose: () => void;
};

export default function CancelConfirmation(props: PropsDef) {
  const { onConfirm, onClose } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout isOpen classNames={`${styles.modal}`}>
      <div className={styles.confirmation_modal_content}>
        <div className={styles.close_button} onClick={onClose}>
          <i className="ico ico-x" />
        </div>
        <div className={styles.title}>
          <i className={`ico ico-x ${styles.danger}`} />
          <h2>{t("Cancel Session")}</h2>
        </div>
        <div className={styles.content}>
          {t("Please confirm")} <br /> {t("that you would like to cancel this session.")}
        </div>
        <div className={styles.actions}>
          <button type="button" className="btn btn-outline-secondary" onClick={onClose}>
            {t("Cancel")}
          </button>
          <button type="button" className={`btn ${styles.btn_danger}`} onClick={onConfirm}>
            {t("Confirm")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
}
