import { SearchAllModel } from "app/models/SearchAllModel";
import React from "react";
import styles from "./styles.module.scss";
import pdfIcon from "../../../../../public/images/ico_pdf.png";
import docIcon from "../../../../../public/images/ico_doc.png";
import xlsIcon from "../../../../../public/images/ico_xls.png";
import pptIcon from "../../../../../public/images/ico_ppt.png";
import audioIcon from "../../../../../public/images/ico_audio.png";
import classNames from "app/utils/classNames";
import { Link } from "react-router-dom";

export interface SearchCardPropsDef {
  searchValue: SearchAllModel;
}

const SearchCard = (props: SearchCardPropsDef) => {
  const { searchValue } = props;
  if (searchValue.object === "team_group") {
    return (
      <Link
        to={`/groups/${searchValue?.team?.id}/channels/${searchValue.id}`}
        className={styles.searchCard}
      >
        <img
          src={searchValue.team.logo}
          alt="image"
          className={classNames(styles.image, styles.circle)}
        />
        <div className={styles.searchCardInfo}>
          <h2>
            <div className={styles.name}>{searchValue.name}</div>
            {searchValue.team && (
              <div className={styles.team_name}>{searchValue.team.name}</div>
            )}
          </h2>
        </div>
      </Link>
    );
  }

  if (searchValue.object === "note") {
    return (
      <Link to={`/posts/${searchValue.id}`} className={styles.searchCard}>
        <div className={styles.note}>Aa</div>
        <div className={styles.searchCardInfo}>
          <h2>
            <div className={styles.name}>{searchValue.details}</div>
            <div className={styles.team_name}>{searchValue.details}</div>
            {searchValue.tags && (
              <div className={styles.team_name}>
                {searchValue.tags.map((tag) => `#${tag}`).join(" ")}
              </div>
            )}
          </h2>
        </div>
      </Link>
    );
  }

  if (searchValue.object === "datafile") {
    return (
      <Link to={`/posts/${searchValue.id}`} className={styles.searchCard}>
        {searchValue.datafile.extension === "docx" && (
          <img src={docIcon} alt="image" className={classNames(styles.image)} />
        )}
        {searchValue.datafile.extension === "pdf" && (
          <img src={pdfIcon} alt="image" className={classNames(styles.image)} />
        )}
        {searchValue.datafile.extension === "xlsx" && (
          <img src={xlsIcon} alt="image" className={classNames(styles.image)} />
        )}
        {searchValue.datafile.extension === "pptx" && (
          <img src={pptIcon} alt="image" className={classNames(styles.image)} />
        )}
        <div className={styles.searchCardInfo}>
          <h2>
            <div className={styles.name}>{searchValue.details}</div>
            <div className={styles.team_name}>{searchValue.details}</div>
            <div className={styles.team_name}>
              {searchValue.tags.map((tag) => `#${tag}`).join(" ")}
            </div>
          </h2>
        </div>
      </Link>
    );
  }

  if (searchValue.object === "audio") {
    return (
      <div className={styles.searchCard}>
        <img src={audioIcon} alt="image" className={classNames(styles.image)} />
        <div className={styles.searchCardInfo}>
          <h2>
            <div className={styles.name}>{searchValue.details}</div>
            <div className={styles.team_name}>{searchValue.details}</div>
            <div className={styles.team_name}>
              {searchValue.tags.map((tag) => `#${tag}`).join(" ")}
            </div>
          </h2>
        </div>
      </div>
    );
  }

  if (searchValue.object === "video") {
    return (
      <div className={styles.searchCard}>
        <img
          src={searchValue.video.smallThumbnailUrl}
          alt="image"
          className={classNames(styles.image, styles.radius)}
        />
        <div className={styles.searchCardInfo}>
          <h2>
            <Link to={`/posts/${searchValue.id}`} className={styles.name}>
              {searchValue.details}
            </Link>
            <span className={styles.team_name}>{searchValue.details}</span>
          </h2>
        </div>
      </div>
    );
  }

  return null;
};

export { SearchCard };
