import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useParams } from "react-router-dom";
import PostModel from "app/models/PostModel";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useActions } from "app/utils/hooks";
import { fetchPosts } from "app/actions/posts";
import { clearPost, deletePost, editPost } from "app/actions/postingForm";
import useGroupAndSortPosts from "app/utils/hooks/useGroupAndSortPosts";

export const TemplateTimelineModel = () => {
  const postsActions = useActions({
    fetchPosts,
    clearPost,
    editPost,
    deletePost,
  });

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { id: templateId } = useParams<{ id: string }>();
  const { selectedTemplate } = useSelector(
    (state: RootState) => state.spaceTemplate,
    shallowEqual,
  );

  const { pending } = useSelector(
    (state: RootState) => state.posts,
    shallowEqual,
  );

  const [data, setData] = useState<PostModel[]>([]);
  const [groupedPosts, setGroupedPosts] = useState<Record<string, PostModel[]>>(
    {},
  );

  const postsParams = {
    spaceTemplateId: templateId,
    orderBy: "scheduled_in",
    order: "asc",
    // page: 1,
    // perPage: 50,
  };

  // const [query, setQuery] = useState(postsParams);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async () => {
    // let postQuery = { ...query };

    // if (page === 1) {
    //   postQuery = { ...query, page: 1 };
    // }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      setGroupedPosts(null);
      const res = await postsActions.fetchPosts(
        // postQuery,
        postsParams,
        abortControllerRef.current.signal,
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData) => [...prevData, ...res]);
            setGroupedPosts((prevData) => useGroupAndSortPosts(res, prevData));
          } else {
            setData(res);
          }
          // setQuery({
          //   ...postQuery,
          //   page: postQuery.page + 1,
          // });
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
    }
  };

  const initialLoadPosts = () => {
    setData([]);
    setGroupedPosts(null);
    fetchPage();
  };

  const loadMore = () => {
    if (!pending) {
      fetchPage();
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: false,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    initialLoadPosts();
    return () => {
      // dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setGroupedPosts(null);
      // setQuery(postsParams);
    };
  }, []);

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?",
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          const filteredData = data.filter(
            (filteredPost: PostModel) => filteredPost.id !== post.id,
          );
          setData(filteredData);
          setGroupedPosts(useGroupAndSortPosts(filteredData, null));
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post).then(() => {
        fetchPage();
      });
    }
  };

  // const onPost = (post: PostModel) => {
  //   setData([post, ...data]);
  //   setGroupedPosts(useGroupAndSortPosts([post], groupedPosts));
  // };
  const onPost = async (post: PostModel) => {
    if (!post) {
      return;
    }

    setData([{ ...post, userAvatar: self?.avatar }, ...data]);
    setGroupedPosts(useGroupAndSortPosts([post], groupedPosts));
  };

  return {
    selectedTemplate,
    data,
    groupedPosts,
    pending,
    onPostDelete,
    onPostUpdate,
    sentryRef,
    onPost,
  };
};
