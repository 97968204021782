/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslation } from 'react-i18next';

/**
 * @class Contact
 *
 */
export default function Contact() {
  const { t } = useTranslation();
  return (
    <a href="https://coachnow.io/contact-us">
      <FormattedMessage
        id="app.links.contact-us"
        defaultMessage={t("Contact Us")}
        description={t("Contact page link text")}
      />
    </a>
  );
}

