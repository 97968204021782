/** @module routes/spaces */
import React, { useState } from 'react';
import { AnalyticsService } from 'app/services';
import { cls } from 'app/utils';
import { useStoreActions } from 'app/utils/hooks';
import { route } from 'app/utils/router';
import { fetchDemoSpaceAction, DemoSpaceModel } from 'app/actions/DemoSpace';
import { NoResults, GridItem } from 'app/components/Layouts';
import { Loading } from 'app/components/Wrappers';
import { isAuthenticated } from 'app/utils/auth';
import UserAvatar from 'app/components/UserAvatar';
import no_space_png from 'app/images/null-training-space.png';
import Styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

type PropsDef = {
  sport?: string;
};

// const HOVER_CLASS = 'hover';

/**
 * @class SpaceDemoList
 *
 */
export default function SpaceDemoList(props: PropsDef) {
  const { t } = useTranslation();
  const { demoSpace } = useStoreActions({ demoSpace: () => fetchDemoSpaceAction(null, props.sport) }, { noAuth: true });

  const spaceClicked = (model: DemoSpaceModel) => {
    const trackProps = { 'Demo Space Name': model.space.athleteName };
    AnalyticsService.track('Demo Space Viewed', trackProps, () => routeToSpace(model));
  };

  const routeToSpace = (model: DemoSpaceModel) => {
    const params: { back?: string, frame?: string } = {};
    if (!isAuthenticated()) {
      params.back = 'true';
    }
    if (/(\?|&)frame/.test(window.location.search)) {
      params.frame = 'true';
    }
    // route to demo space
    route(`/spaces/demo/${model.demoSpaceId}`, false, params);
  };

  let content: JSX.Element;
  if (demoSpace.data != null && demoSpace.data.length) {
    let spaceList: JSX.Element[] = [];
    spaceList = demoSpace.data.map((demoSpace: DemoSpaceModel, key: any) => {
      return (<SpaceItem key={key} demoSpace={demoSpace} onClick={spaceClicked} />);
    });

    content = (
      <div className={Styles.spaceContainer}>
        {spaceList}
        <div className={Styles.clear} />
      </div>
    );
  } else {
    // const filterFunc = () => {
    //   demoSpace.fetchUpdate(undefined, null);
    // };

    content = (
      <NoResults
        image={no_space_png}
        text={t('Inspirational Spaces')}
        children={
            (
            <Trans
              i18nKey="no_space_examples"
              components={{ strong: <strong />, click: <a href="#" /> }}
              values={{ sport: props.sport }}
            />
          )}
      />
    );
  }

  return (
    <Loading
      className={Styles.spaceList}
      isLoading={demoSpace.pending}
    >{content}</Loading>
  );
}

type ItemPropsDef = {
  demoSpace: DemoSpaceModel;
  onClick: (model: DemoSpaceModel) => void;
};

function SpaceItem({ demoSpace, onClick }: ItemPropsDef) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const addHover = () => setIsHover(true);
  const removeHover = () => setIsHover(false);

  return (
    <GridItem className={Styles.grid} onClick={() => onClick(demoSpace)}>
      <div className={cls(Styles.expandingBox, isHover ? Styles.hover : '')}>
        <div className={Styles.expander}>
          <span className={Styles.leftBox} onMouseOver={addHover} onMouseOut={removeHover}>
            <div className={Styles.avatar}>
              <UserAvatar srcName={demoSpace.space.athleteAvatar} altName={demoSpace.space.athleteName} />
            </div>
            <div className={Styles.title}>
              <h4>{demoSpace.space.athleteName}</h4>
              <h6>{demoSpace.space.sport}</h6>
            </div>
          </span>
          <span className={Styles.rightBox}>
            <div className={Styles.description}>{demoSpace.description}</div>
          </span>
        </div>
      </div>
    </GridItem>
  );
}


