import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useActions, useSelector, useStoreActions } from "app/utils/hooks";
import { fetchLists, clearList } from "app/actions/list";
import ListModel from "app/models/ListModel";
import ContentCard from "../ContentCard";
import null_list from "../../../public/images/null-lists.png";
import { Spinner } from "../Loading";
import { cls } from "app/utils";
import { RootState } from "typedefs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";

const SegmentList = () => {
  const { t } = useTranslation();
  const { list } = useStoreActions({ list: () => fetchLists() });
  const { segmentsPending: pending } = useSelector(
    (state: RootState) => state.segments,
    shallowEqual
  );

  const segmentsAction = useActions({ clearList });

  useEffect(() => {
    return () => {
      segmentsAction.clearList();
    };
  }, []);

  if ((!list || !list.data || !list.data.length) && pending) {
    return (
      <Spinner className={cls(styles.spinner, "spinner")} isLoading={pending} />
    );
  } else if ((!list || !list.data || !list.data.length) && !pending) {
    return (
      <div className={styles.null_list}>
        <img src={null_list} alt="no list yet" />
        <h3>{t("No Lists yet")}</h3>
        <p>{t("All lists created by you will appear here")}</p>
      </div>
    );
  }

  return (
    <>
      <div className={styles.list_title}>{t("My Lists")}</div>
      <div className={styles.container}>
        {list?.data?.map((item: ListModel) => {
          return (
            <div key={item?.id} className={styles.list_item_wrapper}>
              <Link to={`/lists/${item?.id}/spaces`}>
                <ContentCard
                  icon="ico ico-groups"
                  title={item?.name}
                  subtitle={t("count_space", { count: item?.spaceIds?.length })}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SegmentList;
