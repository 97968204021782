import { action, ActionType } from "typesafe-actions";
import { AddAlert } from "app/models/AlertModel";

export const addAlert = (params: AddAlert) =>
  action("@alert.add", params);

export const expireAlert = (id: string) => action("@alert.expire", { id });

const thisActions = {
  expireAlert,
  addAlert,
};

export type AlertAction = ActionType<typeof thisActions>;
