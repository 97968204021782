import React, { ReactNode } from "react";
import CircleInfoError from "../../../public/images/circle-info-error.svg";
import styles from './stylesToolTip.module.scss';

type PropsDef = {
  text: string;
  orientation?: 'left' | 'right' | 'center';
  selected?: boolean;
  isError?: boolean;
  children?: ReactNode;
  showIcon?: boolean;
  iconColor?: string | null;
  fontSize?: string;
  title?: string;
  subTitle?: string;
};

export default function ToolTip({
  text,
  orientation = 'left',
  isError = false,
  children = null,
  showIcon = true,
  iconColor = null,
  fontSize = '18px',
  title,
  subTitle,
}: PropsDef) {
  let orientationStyle;

  if (orientation === 'left') {
    orientationStyle = styles.tooltiptext_left;
  } else if (orientation === 'right') {
    orientationStyle = styles.tooltiptext_right;
  } else if (orientation === 'center') {
    orientationStyle = styles.tooltiptext_center;
  }

  return (
    <div className={styles.tooltip}>
      {showIcon ? (
        isError ? (
          <img className={styles.icon} src={CircleInfoError} alt="info" />
        ) : (
          <i className={`ico ico-info ${iconColor === 'white' ? styles.iconWhite : ''}`} style={{ fontSize }} />
        )
      ) : null}
      {children}
      <div
        className={`${styles.tooltiptext} ${orientationStyle} ${
          typeof text === 'string' && text.length > 40 ? styles.multiline : ''
        }`}
      >
        {title && <div className={styles.tooltipTitle}>{title}</div>}
        {text}
        {subTitle && <div className={styles.tooltipTitle}>{subTitle}</div>}
      </div>
    </div>
  );
}
