import CheckBox from "app/components/inputs/CheckBox";
import React, { memo } from "react";
import styles from "../styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import SpaceModel from "app/models/SpaceModel";

interface Props {
  toggleListClick: (item: {}) => void;
  selectedLists: any;
  searchTerm?: string | null;
  spacesData?: SpaceModel[];
  pending?: boolean;
  containerRef?: any;
}

const AthleteSpaceLocation = ({
  toggleListClick,
  selectedLists,
  // searchTerm,
  pending,
  spacesData,
  containerRef,
}: Props) => {
  const selectedSpaceArray = Array.from(selectedLists);

  const isSpaceSelected = (spaceId: string) => {
    return selectedSpaceArray.some((space: any) => space.id === spaceId);
  };

  if (spacesData && spacesData.length < 1 && !pending) {
    return null;
  }

  return (
    <div>
      <label className={styles.list_title}>Spaces</label>
      {spacesData &&
        spacesData.map(
          (data) =>
            data &&
            data?.space && (
              <div
                ref={containerRef}
                key={data.id}
                onClick={() =>
                  data?.space?.planPermissions?.includes("post") &&
                  toggleListClick(data.space)
                }
              >
                <div className={styles.dropdownItem}>
                  <UserAvatar
                    altName="space_icon"
                    sizeWidth={37.5}
                    sizeHeight={37.5}
                    userName={data?.space?.ownerName}
                    srcName={data?.space?.avatar}
                    className="avatar"
                  />
                  <div
                    className={cls(styles.name, {
                      [styles.disabled]:
                        !data?.space?.planPermissions?.includes("post"),
                    })}
                  >
                    {data?.space?.athleteName}
                    <div className={styles.sport}>{data?.space?.sport}</div>
                  </div>
                  <CheckBox
                    className={styles.checkbox}
                    name={data?.space?.athleteName}
                    checked={isSpaceSelected(data.space.id)}
                  />
                </div>
              </div>
            )
        )}
      {pending && (
        <Loading isLoading loadType="spinner" className={styles.pending} />
      )}
    </div>
  );
};

export default memo(AthleteSpaceLocation);
