import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchTags, clearTags } from "app/actions/tag";
import { RootState } from "typedefs";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';
import { shallowEqual } from "react-redux";

const TagsList = () => {
  const { t } = useTranslation();
  const tagActions = useActions({
    fetchTags,
    clearTags,
  });

  const tag = useSelector((state: RootState) => state.tag, shallowEqual);
  useEffect(() => {
    if (tag && tag.data && tag.data.length) {
      tagActions.clearTags();
    }
    tagActions.fetchTags();
    return () => {
      tagActions.clearTags();
    };
  }, []);

  if (tag.pending) {
    return (
      <div className="spinner_container">
        <div className="spinner"></div>
      </div>
    );
  }

  const dropdownData = [
    {
      title: t("Edit Name"),
      destination: "edit",
    },
    {
      title: t("Delete"),
      destination: "delete",
      textDanger: true,
    },
  ];

  return (
    <>
      {tag && tag.data && tag.data.length > 0 && (
        <div className={styles.container}>
          {tag.data.map((tag: { name: string; id: string }) => {
            return (
              <div key={tag.id} className={styles.tag_wrapper}>
                <div className={styles.tag_title}>
                  <i className="ico ico-tags-o" />
                  {tag.name && <h2>{tag.name}</h2>}
                </div>
                <DropdownButton btnContent={<i className="ico ico-options" />}>
                  <ul className={styles.dropdown_list_wrapper}>
                    {dropdownData.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={`/account/tags/${tag.id}/${item.destination}`}
                            className={cls({
                              [styles.danger]: item.textDanger,
                            })}
                          >
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </DropdownButton>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TagsList;
