import React, { useEffect } from "react";
import AccountWrap from "..";
import PagePanel from "app/components/PagePanel";
import BillingInfoView from "app/components/subscription/BillingInfo/View";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchSelf } from "app/actions/user";
import {
  fetchPaymentInfo,
  fetchRecurlyPaymentInfo,
} from "app/actions/payment-info";
import { RootState } from "typedefs";
import RecurlyBillingInfo from "app/components/subscription/RecurlyBillingInfo";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";

const BillingInfo = () => {
  const { t } = useTranslation();
  const actions = useActions({
    fetchSelf,
    fetchPaymentInfo,
    fetchRecurlyPaymentInfo,
  });
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [isStripeAccountType] = React.useState(() => {
    return self && self.customerType.toLowerCase() === "stripe";
  });
  useEffect(() => {
    if (!self) {
      return;
    }

    if (isStripeAccountType) {
      actions.fetchSelf(), actions.fetchPaymentInfo();
    } else if (!isStripeAccountType) {
      actions.fetchRecurlyPaymentInfo();
    }
  }, []);

  if (!self) {
    return null;
  }

  return (
    <AccountWrap>
      <PagePanel title={t("Billing")}>
        {isStripeAccountType ? <BillingInfoView /> : <RecurlyBillingInfo />}
      </PagePanel>
    </AccountWrap>
  );
};

export default BillingInfo;
