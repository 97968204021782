import React from 'react';
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import DeleteIcon from "../../../../../../public/images/delete-icon.svg";
import { useTranslation } from 'react-i18next';

interface RemovePricingProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
}

const RemovePricing: React.FC<RemovePricingProps> = ({ isOpen, onClose, onDelete, title }) => {
  const { t } = useTranslation();
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <img  style={{width: '40px', height: '40px', display: 'block', margin: '0 auto'}} src={DeleteIcon} alt="info" onClick={onClose}/>
      <div className={styles.modal_header}>
        <h2>{t("Delete booking option?")}</h2>
      </div>
      <div className={styles.modal_content}>
        <p>Are you sure you want to delete the <strong>{title}</strong> pricing?</p>
        <div className={styles.buttonGroup}>
          <button type="button" className={styles.cancelButton} onClick={onClose}>
            {t("Cancel")}
          </button>
          <button type="button" className={styles.confirmButton} onClick={onDelete}>
            {t("Delete")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RemovePricing;
