import React from "react";
import styles from "./styles.module.scss";
import { Spinner } from "app/components/Loading";
import { MediaTypes } from "../ResourceCard/index.types";
import ResourceCard from "../ResourceCard";
import NoResources from "../NoResources";

interface Props {
  data: MediaTypes[];
  pending?: boolean;
  containerRef?: any;
  onSelectMedia?: (media: MediaTypes) => void;
  thumbnailClassname?: string;
  hasGradientOverlay?: boolean;
  showPlayIcon?: boolean;
  showTags?: boolean;
}

const ResourcesList = ({
  data,
  pending,
  containerRef,
  onSelectMedia,
  thumbnailClassname,
  hasGradientOverlay,
  showPlayIcon,
  showTags,
}: Props) => {
  if (pending && !data) {
    return (
      <div className={styles.loading_container}>
        <div className={styles.loading_spinner}>
          <Spinner className="spinner" />
        </div>
      </div>
    );
  }

  if (data?.length === 0 && !pending) {
    return <NoResources />;
  }

  return (
    <div className={styles.container}>
      {data?.length > 0 && (
        <div className={styles.items_container}>
          {data?.map((item: MediaTypes) => (
            <div key={item && item.id} className={styles.media_container}>
              {/* <ResourceCard data={item} /> */}
              <ResourceCard
                key={item.id}
                data={item}
                thumbnailClassname={thumbnailClassname}
                hasGradientOverlay={hasGradientOverlay}
                showPlayIcon={showPlayIcon}
                showTags={showTags}
                onSelectMedia={onSelectMedia}
              />
            </div>
          ))}
        </div>
      )}
      {containerRef && <div ref={containerRef} />}
    </div>
  );
};

export default ResourcesList;
