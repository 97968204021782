/**
 * @module Model.Event
 *
 */
import { Model } from "typedefs";

export class StudentProfileModel implements Model {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  profilePicture: string;
  calendarSubscriptionUrl: string;

  constructor(studentProfile: any) {
    this.id = studentProfile.id;
    this.firstName = studentProfile.firstName;
    this.lastName = studentProfile.lastName;
    this.email = studentProfile.email;
    this.phone = studentProfile.phone;
    this.profilePicture = studentProfile.profilePicture;
    this.calendarSubscriptionUrl = studentProfile.calendarSubscriptionUrl;
  }

  public get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
