import {
  ColumnBox,
  GridItem,
  NoFilteredResults,
  NoResults,
} from "app/components/Layouts";
import React from "react";
import styles from "./styles.module.scss";
import { SpaceModel } from "app/models";
import UserModel from "app/models/UserModel";
import { cls } from "app/utils";
import no_space_png from "app/images/null-training-space.png";
import { Loading } from "app/components/Wrappers";
import ContentCard from "app/components/ContentCard";
import useSpacesListModel from "./indexModel";
import null_list from "../../../../public/images/null-lists.png";
import { useTranslation } from "react-i18next";
import { uuid } from "app/utils/uuid";
export interface SpacesListProps {
  user?: UserModel;
  searchTerm?: string | null;
}

const SpacesList = ({ user, searchTerm }: SpacesListProps) => {
  const { t } = useTranslation();
  const { spacesData, spaceClicked, pending, containerRef, selectedSmartList } =
    useSpacesListModel({ searchTerm });

  if (searchTerm.length >= 3 && !pending && spacesData && !spacesData.length) {
    return <NoFilteredResults missingTerm={searchTerm} />;
  }

  if (searchTerm.length < 3 && !pending && spacesData && !spacesData.length) {
    if (selectedSmartList.object === "segment") {
      return (
        <NoResults
          image={null_list}
          text={t("No Spaces in this list yet")}
          subtext={t(
            "All spaces that you create in this list will appear here.",
          )}
          className={styles.noResults}
        />
      );
    } else {
      return (
        <NoResults
          image={no_space_png}
          text={t("Welcome to Spaces")}
          subtext={t("Work one-on-one with a coach or player.")}
          className={styles.noResults}
        />
      );
    }
  }

  return (
    <div
      className={cls(styles.spaceList, {
        [styles.needs_only_onboarding]:
          user &&
          user.needsOnboarding &&
          selectedSmartList.object !== "smart_list",
        [styles.selected_smart_list]:
          selectedSmartList.object === "smart_list" &&
          user &&
          !user.needsOnboarding,
        [styles.selected_smart_list_and_onboarding]:
          selectedSmartList.object === "smart_list" &&
          user &&
          user.needsOnboarding,
      })}
    >
      <ColumnBox columns={2}>
        {spacesData?.map((item: SpaceModel) => {
          if (item?.space && item?.object === "space_membership") {
            return (
              <GridItem
                containerRef={containerRef}
                key={item?.space?.id || item?.id || uuid()}
                className={styles.grid}
                onClick={() =>
                  spaceClicked(
                    item?.space?.timelineUrl
                      ? item?.space?.timelineUrl
                      : item?.space?.id,
                  )
                }
              >
                <ContentCard
                  img={item.space.athleteAvatar || item.space.avatar}
                  title={item.space.athleteName}
                  subtitle={item.space.sport}
                />
              </GridItem>
            );
          } else {
            return (
              <GridItem
                containerRef={containerRef}
                key={item.id || uuid()}
                className={styles.grid}
                onClick={() =>
                  spaceClicked(item?.timelineUrl ? item?.timelineUrl : item?.id)
                }
              >
                <ContentCard
                  img={item?.athleteAvatar || item?.avatar}
                  title={item?.athleteName}
                  subtitle={item?.sport}
                />
              </GridItem>
            );
          }
        })}
      </ColumnBox>
      {pending && (
        <Loading isLoading loadType="spinner" className={styles.loading} />
      )}

      <div ref={containerRef} />
    </div>
  );
};

export default SpacesList;
