import React, { memo } from "react";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/Dropdown";
import CheckBox from "app/components/inputs/CheckBox";
import SelectBox from "app/components/inputs/SelectBox";
import Userselect from "./UserSelect";
import { cls } from "app/utils";
import { FilterDropdownModel } from "./indexModel";
import Button from "app/components/Button/ButtonVariant";
import DatePicker from "./DatePicker";
import { initialFilterState } from "app/reducers/filter";
import TagsSelect from "../inputs/SelectBox/TagsSelect";
import { useTranslation } from "react-i18next";

export type filterStateDataType = "image" | "video" | "audio" | "datafile";

interface Props {
  filterClickHandler?: (filters: any) => void;
  onFilterClear?: (filters: any) => void;
  hideNotes?: boolean;
  hideAuthors?: boolean;
}

const FilterDropdown: React.FC<Props> = ({
  filterClickHandler,
  onFilterClear,
  hideNotes,
  hideAuthors,
}: Props) => {
  const { t } = useTranslation();

  const {
    filterState,
    isFiltered,
    mediaTypeOptions,
    sortByOptions,
    sortByValue,
    authorsSelectHandler,
    toggleMediaType,
    onSortBySelect,
    onTagsChange,
    selectedTags,
    isDropdownOpen,
    filterModalHandler,
    clearFiltersHandler,
    onFilterClick,
    // isDropdownOpen,
  } = FilterDropdownModel({
    filterClickHandler,
    onFilterClear,
    hideNotes,
  });

  const FilterBtn = () => (
    <span className={styles.filter_btn_wrapper} onClick={filterModalHandler}>
      <i className="ico ico-funnel" />
      <p>{t("Filter")}</p>
    </span>
  );

  const displayResetButton = () => {
    if (
      JSON.stringify(filterState.data) !==
        JSON.stringify(initialFilterState.data) ||
      isFiltered
    ) {
      return (
        <Button
          title={t("Show All")}
          buttonType="secondary-outline"
          onClickFunc={clearFiltersHandler}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <FilterBtn />
      <DropdownButton
        dropdownWrapperClass={styles.dropdown_container}
        isOpen={isDropdownOpen}
        onClose={filterModalHandler}
      >
        <div className={styles.dropdown_list_wrapper}>
          <div className={styles.dropdown_list}>
            <label>{t("Media Type")}</label>
            {mediaTypeOptions.map(
              (item, index) =>
                !item?.hide && (
                  <div
                    key={index}
                    className={styles.dropdown_item}
                    onClick={() =>
                      toggleMediaType(item.value as filterStateDataType)
                    }
                  >
                    <CheckBox
                      key={index}
                      name={item.value}
                      className={styles.checkbox_input}
                      checked={
                        filterState.data &&
                        filterState.data.type &&
                        filterState.data.type[item.value as filterStateDataType]
                      }
                    />
                    <div className={styles.name}>{item.label}</div>
                  </div>
                ),
            )}
          </div>
          {!hideAuthors && (
            <div className={styles.dropdown_list}>
              <label htmlFor="author">{t("Author")}</label>
              <Userselect onChange={authorsSelectHandler} />
            </div>
          )}
          <div className={styles.dropdown_list}>
            <label>{t("Sort by")}</label>
            <SelectBox
              className={styles.select_box}
              placeholder={sortByValue}
              isClearable={false}
              isCreatable={false}
              isMulti={false}
              options={sortByOptions}
              onChange={(sortValue: { value: "created_at" | "captured_at" }) =>
                onSortBySelect(sortValue.value)
              }
            />
          </div>
          <div className={styles.dropdown_list}>
            <label>{t("Tags")}</label>
            <TagsSelect
              className={styles.select_box}
              placeholder={t("Add some tags.")}
              isClearable
              value={selectedTags}
              isCreatable={false}
              isMulti={true}
              onChange={onTagsChange}
            />
          </div>
          <DatePicker />
          <div className={cls(styles.dropdown_buttons)}>
            <Button
              disabled={filterState.error as any}
              title={t("Filter")}
              buttonType="primary"
              onClickFunc={onFilterClick}
            />
            {displayResetButton()}
          </div>
        </div>
      </DropdownButton>
    </div>
  );
};

export default memo(FilterDropdown);
