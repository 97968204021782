import React, { memo } from "react";
import styles from "./styles.module.scss";
import ActivityLayout from "app/components/Layouts/ActivityPageLayout";
import NotificationsList from "app/components/NotificationsList";
import { useNotificationsModel } from "app/components/NotificationsDropdown/Notifications/indexModel";
import { useTranslation } from "react-i18next";

const ActivityNotifications = () => {
  const { t } = useTranslation();
  const { markAllAsRead } = useNotificationsModel();

  return (
    <div className="notifications-container">
      <ActivityLayout>
        <div className={styles.panel}>
          <div className={styles.panel_title_contaniner}>
            <h2>{t("Notifications")}</h2>
          </div>
          <span className={styles.icon_btn}>
            <i
              className="btn ico ico-read btn-outline-secondary"
              onClick={markAllAsRead}
            />
          </span>
        </div>
        <NotificationsList containerClassName={styles.notifications_list} />
      </ActivityLayout>
    </div>
  );
};

export default memo(ActivityNotifications);
