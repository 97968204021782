/**
 * @module Reducers.UserSettings
 *
 */
import { RequestError } from "typedefs";
import { CoachProfileModel } from "app/models";
import { CoachProfileAction } from "app/actions/coachNowCoachProfile";

type CoachProfileState = {
  data: CoachProfileModel;
  error: RequestError | null;
  pending: boolean;
};

const initialState: CoachProfileState = {
  data: [],
  error: null,
  pending: false,
};

export default function userReducer(
  state = initialState,
  action: CoachProfileAction,
): CoachProfileState {
  switch (action.type) {
    case "@coach-profile.success.get":
      return { ...state, data: action.payload, pending: false, error: null };
    case "@coach-profile.error.get":
      return { ...state, pending: false, error: action.payload };
    case "@coach-profile.pending.get":
      return { ...state, pending: true, error: null };
    case "@coach-profile.clear":
      return { data: [], pending: false, error: null };
    default:
      return state;
  }
}
