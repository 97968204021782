import PagePanel from "app/components/PagePanel";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useHistory, useParams } from "react-router-dom";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import {
  editSpaceTemplate,
  fetchSpaceTemplateById,
} from "app/actions/spaceTemplate";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';

const TemplateSpaceEdit = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();

  const spaceTemplateActions = useActions({
    fetchSpaceTemplateById,
    editSpaceTemplate,
  });

  const [templateData, setTemplateData] = useState({});
  const [templateName, setTemplateName] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [nameInputError, setNameInputError] = useState(false);

  function onCancel() {
    history.goBack();
  }

  useEffect(() => {
    setLoadingData(true);
    spaceTemplateActions.fetchSpaceTemplateById(params.id).then((res: any) => {
      if (res) {
        setTemplateData(res);
        setTemplateName(res.name);
        setLoadingData(false);
      }
    });

    return () => {
      setTemplateData({});
      setLoadingData(false);
      setNameInputError(false);
      setLoadingUpdate(false);
    };
  }, []);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    if (name.length < 1) {
      setNameInputError(true);
    } else {
      setNameInputError(false);
    }
    setTemplateName(name);
  };

  const onUpdateName = () => {
    setLoadingUpdate(true);
    spaceTemplateActions
      .editSpaceTemplate({ ...templateData, name: templateName })
      .then(() => {
        history.goBack();
        setLoadingUpdate(false);
      });
  };

  return (
    <div className={styles.container}>
      <PagePanel>
        <h1 className={styles.title}>{t("Edit Template")}</h1>
      </PagePanel>
      <PagePanel>
        <div className={styles.input_wrapper}>
          <label htmlFor="template_name">{t("Template name")}</label>
          <input
            disabled={loadingData || loadingUpdate}
            type="text"
            name="template_name"
            id="template_name"
            value={templateName}
            onChange={onChangeName}
            className={cls({ [styles.name_input_error]: nameInputError })}
          />
        </div>

        <div className={styles.button_wrapper}>
          <Button
            title={loadingUpdate ? t("Please wait...") : t("Update")}
            buttonType="primary"
            onClickFunc={onUpdateName}
            disabled={loadingUpdate || loadingData || nameInputError}
          />
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            onClickFunc={onCancel}
          />
        </div>
      </PagePanel>
    </div>
  );
};

export default TemplateSpaceEdit;
