import { segmentsAction } from "app/actions/segments";
import { RequestError } from "typedefs";

export type segmentsState = {
  segmentsData: any | null;
  segmentsError: RequestError | null;
  segmentsPending: boolean;
};

const initialState: segmentsState = {
  segmentsData: null,
  segmentsError: null,
  segmentsPending: false,
};

export default function segmentsReducer(
  state = initialState,
  action: segmentsAction
) {
  switch (action.type) {
    case "@segments.fetch.pending":
      return { ...state, segmentsPending: true, segmentsData: null };
    case "@segments.fetch.error":
      return {
        ...state,
        segmentsPending: false,
        // @ts-ignore
        segmentsData: null,
        error: action.payload,
      };
    case "@segments.fetch.success":
      return {
        ...state,
        segmentsPending: false,
        segmentsData: action.payload,
        // @ts-ignore
        errsegmentsErroror: null,
      };
    case "@segments.clear":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
