import { createPost, fetchPost } from "app/actions/postingForm";
import { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onPost?: (post: PostingModel) => void;
}

export const GroupPostingFormModel = ({ onPost }: Props) => {
  const groupPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user.planPermissions.includes("upload_to_library") &&
      user.planPermissions.includes("post_media") &&
      selectedGroup &&
      selectedGroup.planPermissions.includes("post_media")
    );
  };

  // posting form submit handler
  const onSubmit = (data: PostingModel) => {
    // check if selected group exists in redux to use id from it
    if (selectedGroup) {
      groupPostActions
        .createPost({ ...data, team_ids: [selectedGroup.id] })
        .then((res) => {
          if (res && onPost) {
            onPost(res);
          }
        });
    }
  };

  return {
    canUploadMedia,
    user,
    onSubmit,
  };
};
