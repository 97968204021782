/**
 * @module Routes.Spaces
 *
 */
import React, { useCallback } from "react";

// import UserAvatar from 'app/components/UserAvatar';
import SearchBar from "app/components/inputs/SearchBar";
import Styles from "./styles.module.scss";
import SmartList from "app/components/SmartList";
import { cls } from "app/utils";
import feedPostIcon from "../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../public/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "../../../public/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "../../../public/images/smart-list/60-plus-calendar-icon.svg";
import "react-virtualized/styles.css";

import SpacesList from "./SpacesList";
import { SpaceListContainerModel } from "./SpaceListContainerModel";
import { Trans, useTranslation } from "react-i18next";

/**
 * @class SpaceListContainer
 *
 */
export default function SpaceListContainer() {
  const { t } = useTranslation();

  const {
    selectedSmartList,
    hasSpacesData,
    onPostToListClick,
    user,
    userIsCoach,
    updateSearch,
    searchTerm,
  } = SpaceListContainerModel();

  const getHeaderContentInfo = () => {
    if (selectedSmartList) {
      if (selectedSmartList?.name?.includes("Unanswered")) {
        return {
          icon: feedPostIcon,
          title: t("Unanswered by me"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_athlete_post_no_reply"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedSmartList?.name?.includes("15 days inactive")) {
        return {
          icon: fifteenDaysIcon,
          title: t("15 days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_15_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedSmartList?.name?.includes("15 days active")) {
        return {
          icon: fifteenDaysIcon,
          title: t("15 days active"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_posted_15_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedSmartList?.name?.includes("30")) {
        return {
          icon: thirtyDaysIcon,
          title: t("30 days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_30_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedSmartList?.name?.includes("60+")) {
        return {
          icon: sixtyDaysIcon,
          title: t("60+ days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_60_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      }
    }
    return null;
  };

  const HeaderTitle = useCallback(() => {
    const contentInfo = getHeaderContentInfo();

    return (
      contentInfo && (
        <div className={Styles.content_header_info}>
          <div className={Styles.main_content}>
            <span className={Styles.img_wrapper}>
              <img src={contentInfo.icon} alt="" />
            </span>
            <div className={Styles.content_header_content}>
              {contentInfo.title && <h3>{contentInfo.title}</h3>}
              {contentInfo.infoText && <p>{contentInfo.infoText}</p>}
            </div>
          </div>

          {hasSpacesData && selectedSmartList.object === "smart_list" && (
            <button
              className={cls("btn btn-primary", Styles.post_to_list_btn)}
              onClick={onPostToListClick}
            >
              {t("Post to list")}
            </button>
          )}
        </div>
      )
    );
  }, [selectedSmartList, hasSpacesData]);

  return (
    <div
      className={cls(Styles.spaceListContainer, {
        [Styles.column]: !userIsCoach,
        ["space-container-athlete"]: userIsCoach,
      })}
    >
      <div className={Styles.spaceListMenu}>
        <span className={Styles.smartList}>
          <SearchBar
            className={Styles.search}
            placeholder={t("Search spaces...")}
            onChange={updateSearch}
          />
        </span>
        {userIsCoach && <SmartList />}
      </div>
      <div className={Styles.spaceListWrapper}>
        {userIsCoach && selectedSmartList && <HeaderTitle />}
        <SpacesList user={user} searchTerm={searchTerm} />
      </div>
    </div>
  );
}
