import React, { useEffect, useState } from 'react';

import { AlertType, Alert as IAlert } from '../../models/AlertModel';
import styles from "./styles.module.scss";

interface AlertProps {
  alert: IAlert;
  setExpired(id: string): void;
}


const Alert = (props: AlertProps) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      props.setExpired(props.alert.id);
    }, props.alert.ms ?? 2000);
  }, []);


  return (show) ?
  (
    <div className={styles.container + ` ${!props.alert.type || props.alert.type === AlertType.SUCCESS ? styles.success : styles.danger}`}>
      <p>{props.alert.message}</p>
    </div>
  ): (<></>);
};

export default Alert;
