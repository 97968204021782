import { TimezoneAction } from "app/actions/timezone";
import { RequestError } from "typedefs";

export type TimezoneState = {
  data: any | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: TimezoneState = {
  data: null,
  error: null,
  pending: false,
};

export default function timezoneReducer(
  state = initialState,
  action: TimezoneAction
) {
  switch (action.type) {
    case "@timezone.fetch.pending":
      return { ...state, pending: true, data: null };
    case "@timezone.fetch.error":
      return { ...state, pending: false, data: null, error: action.payload };
    case "@timezone.fetch.success":
      return { ...state, pending: false, data: action.payload, error: null };

    case "@timezone.clear":
      return {
        ...state,
        data: null,
        pending: null,
        error: null,
      };
    default:
      return state;
  }
}
