/**
 * @module Routes.Reset
 *
 */
import i18n from "app/i18n";
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "reset-password.title",
    defaultMessage: i18n.t("Reset Password"),
  },
  new_pass_placeholder: {
    id: "reset-password.new-pass-placeholder",
    defaultMessage: i18n.t("New Password"),
  },
  confirm_placeholder: {
    id: "reset-password.confirm-placeholder",
    defaultMessage: i18n.t("Confirm New Password"),
  },
  reset_button: {
    id: "reset-password.button",
    defaultMessage: i18n.t("Reset password"),
  },
  back_link: {
    id: "reset-password.back-link",
    defaultMessage: i18n.t("I know my password"),
  },
  password_mismatch: {
    id: "reset-password.mismatch",
    defaultMessage: i18n.t("Passwords do not match"),
  },
  password_failure: {
    id: "reset-password.failure",
    defaultMessage: i18n.t(
      "Password must be between 6 and 128 characters long.",
    ),
  },
  unknown_error: {
    id: "forgot-password.unknown-error",
    defaultMessage: i18n.t("An unknown error occurred. Please try again."),
  },
  reset_success_status: {
    id: "reset-password.success.subtitle",
    defaultMessage: i18n.t("Your password has been reset!"),
  },
  reset_success_info: {
    id: "reset-password.success.info",
    defaultMessage: i18n.t(
      "Redirecting to the Sign In page in <b>{time}</b> seconds",
    ),
  },
  reset_success_link: {
    id: "reset-password.success.link",
    defaultMessage: i18n.t("Take me to Sign In now"),
  },
});
