/**
 * @module Actions.Event
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { LessonTypeModel  } from "app/models/scheduling/LessonTypeModel";

const fetchPending = () => action("@lessonTypes.fetch.pending");

const fetchError = (err: string) => action("@lessonTypes.fetch.error", err);

const fetchSuccess = (lessonTypes: LessonTypeModel[]) =>
  action("@lessonTypes.fetch.success", lessonTypes);

const lessonTypesClear = () => action("@lessonTypes.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  lessonTypesClear,
};

export type LessonTypeAction = ActionType<typeof thisActions>;

export function fetchLessonTypes(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<LessonTypeAction>) => {
    dispatch(fetchPending());
    return schedulingFetchGet<LessonTypeModel>("lesson_types", params || {})
      .then((lesson_types: LessonTypeModel[]) => {

        const models: LessonTypeModel[] = lesson_types.map(lesson_type => {
          return new LessonTypeModel(lesson_type);
        });

        dispatch(fetchSuccess(models));
      })
      .catch((err) => dispatch(fetchError(err)));
  };
}
