import { fetchGet, request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { SpaceTemplatesModel } from "app/models";

const pending = () => action("@spaceTemplate.fetch.pending");
const error = (err: string) => action("@spaceTemplate.fetch.error", err);
const success = (data: SpaceTemplatesModel[]) =>
  action("@spaceTemplate.fetch.success", data);
const selectedTemplate = (data: SpaceTemplatesModel) =>
  action("@spaceTemplate.fetch.selectedTemplate", data);

export const spaceTemplate = (location: any) =>
  action("@spaceTemplate.fetch.spaceTemplate", location);

export const paginatedData = (data: SpaceTemplatesModel[]) =>
  action("@spaceTemplate.fetch.paginatedData", data);

export const currentPage = (currentPage: number) =>
  action("@spaceTemplate.fetch.currentPage", currentPage);

export const showPerPage = (showPerPage: number) =>
  action("@spaceTemplate.fetch.showPerPage", showPerPage);

export const hasMore = (hasMore: boolean) =>
  action("@spaceTemplate.fetch.hasMore", hasMore);

export const clearSpaceTemplate = () => action("@spaceTemplate.clear");

const thisActions = {
  pending,
  error,
  success,
  selectedTemplate,
  spaceTemplate,
  paginatedData,
  currentPage,
  showPerPage,
  hasMore,
  clearSpaceTemplate,
};

export type SpaceTemplateAction = ActionType<typeof thisActions>;

// export function fetchSpaceTemplate(params?: { [key: string]: any }) {
//   return (dispatch: Dispatch<SpaceTemplateAction>) => {
//     dispatch(pending());

//     const url = config.API_SERVER + `/api/v1/space_templates`;
//     const promise = request(url, "GET", { params }, { version: 1 });
//     promise.then((spaceTemplate) => dispatch(success(spaceTemplate)));
//     promise.catch((err) => dispatch(error(err)));

//     return promise;
//   };
// }

export function fetchSpacesTemplates(signal?: AbortSignal) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    dispatch(pending());

    return fetchGet<SpaceTemplateAction>(
      "space_templates",
      {},
      {},
      signal
    ).then((res: SpaceTemplatesModel[] | any) => {
      dispatch(success(res));
      return res;
    });
  };
}

export function fetchSpaceTemplateById(id: string) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/space_templates/${id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((spaceTemplate) => dispatch(selectedTemplate(spaceTemplate)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function editSpaceTemplate(space_template?: { [key: string]: any }) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    const url =
      config.API_SERVER + `/api/v1/space_templates/${space_template.id}`;
    const promise = request(url, "PUT", { space_template }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function createSpaceTemplate(space_template?: { [key: string]: any }) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/space_templates`;
    const promise = request(url, "POST", { space_template }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function deleteSpaceTemplate(id: string) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/space_templates/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.then((spaceTemplate) => dispatch(success(spaceTemplate)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function sortSpaceTemplate({ id, posts }: { id: string; posts: {} }) {
  return (dispatch: Dispatch<SpaceTemplateAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/space_templates/${id}/posts/sort`;
    const promise = request(url, "POST", { posts }, { version: 1 });
    promise.then((spaceTemplate) => dispatch(success(spaceTemplate)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}
