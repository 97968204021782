import React from "react";
import styles from "./styles.module.scss";
import DemoSpacesPageLayout from "app/components/Layouts/DemoSpacesPageLayout";
import { DemoSpaceTimelineModel } from "./indexModel";
import DemoSpaceFeedPostingForm from "../../../components/DemoSpaceFeedPostingForm";
import Filter from "./DemoFeedFilter";
import DemoTimelinePosts from "./DemoTimelinePosts";

const DemoSpaceTimeline = () => {
  const { selectedDemoSpace } = DemoSpaceTimelineModel();

  return (
    <DemoSpacesPageLayout>
      {selectedDemoSpace && (
        <div className={styles.content_container}>
          <DemoSpaceFeedPostingForm />
          <Filter />
          <DemoTimelinePosts posts={selectedDemoSpace.posts} />
        </div>
      )}
    </DemoSpacesPageLayout>
  );
};

export default DemoSpaceTimeline;
