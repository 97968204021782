import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBookSession } from 'app/actions/scheduling/bookSession';
import { DateTime } from 'luxon';
import { RootState } from 'typedefs';
import { useTranslation } from 'react-i18next';

import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { AvailableSlotModel } from 'app/models/scheduling/AvailableSlotModel';

import ShowDate from './ShowDate';
import TimeSlots from './TimeSlots';

import styles from './DateTime.module.scss';

type PropsDef = {
  coach: CoachProfileModel;
  day: DateTime;
  current?: boolean;
  timeSlots: AvailableSlotModel[];
  singleDay?: boolean;
  isPublicPage: boolean;
  multiCoach?: boolean;
};

const DayRow = ({
  coach,
  day,
  current = false,
  timeSlots,
  singleDay = false,
  multiCoach = false
}: PropsDef) => {
  const dispatch = useDispatch();
  const time = useSelector((state: RootState) => state.bookSession.time);
  const { t } = useTranslation();

  const onSlotSelect = (slot) => {
    dispatch(
      setBookSession({
        date: slot.startHour.startOf('day').toISODate(),
        time: {
          startHour: slot.startHour.toMillis(),
          endHour: slot.endHour.toMillis(),
          timeZone: slot.startHour.get('zone').zoneName,
          coachId: slot.coachProfileId,
        },
        coach,
        pricingValue: coach.defaultLessonRate,
        duration: slot.duration,
      })
    );
  };

  return (
    <div
      className={`${styles.day_row} ${current && !singleDay && styles.current_day}`}>

      {!singleDay && <ShowDate date={day} />}

      <TimeSlots
        timeSlots={timeSlots}
        onCurrentDay={current}
        noSlotsText={
          current
            ? t('No available timeslots on the selected day.')
            : t('No available timeslots on this day.')
        }
        selectedSlot={time}
        onSlotSelect={onSlotSelect}
        multiCoach={multiCoach}
      />

    </div>
  );
};

export default DayRow;
