/**
 * @module Actions.Group
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, request } from "app/utils/request";
import { CreateGroupsModel, GroupsModel } from "app/models";
import config from "config/environment";
import UserModel from "app/models/UserModel";
import { GroupsState } from "app/reducers/groups";

const fetchPending = () => action("@groups.fetch.pending");

const fetchError = (err: string) => action("@groups.fetch.error", err);

const fetchSuccess = (groups: GroupsModel[]) =>
  action("@groups.fetch.success", groups);

export const groupData = (group: CreateGroupsModel) =>
  action("@groups.fetch.newGroupData", group);

export const selectedGroupsData = (group: CreateGroupsModel) =>
  action("@groups.fetch.selectedGroupsData", group);

export const newGroupDataError = (err: string) =>
  action("@groups.fetch.newGroupDataError", err);

export const groupPending = () => action("@groups.fetch.newGroupPending");

const fetchPendingMembers = () => action("@groups.fetch.pendingMembers");

const fetchErrorMembers = (err: string) =>
  action("@groups.fetch.errorMembers", err);

const fetchSuccessMembers = (members: []) =>
  action("@groups.fetch.successMembers", members);

export const membersPage = (page: number) =>
  action("@groups.fetch.membersPage", page);

export const myInformation = (userInfo: UserModel) =>
  action("@groups.fetch.myInfo", userInfo);

const myInformationPending = () => action("@groups.fetch.myInfoPending");

const myInformationError = (err: string) =>
  action("@groups.fetch.myInfoError", err);

export const hasMoreMembers = (value: boolean) =>
  action("@groups.fetch.hasMoreMembers", value);

export const newOwnerData = (userInfo: UserModel) =>
  action("@groups.fetch.newOwner", userInfo);

export const clearGroup = () => action("@groups.clear");

export const clearGroupMembers = () => action("@groups.clearMembers");

export const clearNewGroupData = () => action("@groups.clearNewGroup");

export const clearSelectedGroupData = () =>
  action("@groups.clearSelectedGroup");

export const clearMyInformationData = () =>
  action("@groups.clearMyInformation");

export const clearNewOwnerData = () => action("@groups.clearNewOwner");

const modalPending = () => action("@groups.pendingModal");

const modalError = (err: string) => action("@groups.errorModal", err);

export const modalIsOpen = (isOpen: boolean) =>
  action("@groups.isOpenSuccessModal", isOpen);

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  groupData,
  newGroupDataError,
  groupPending,
  selectedGroupsData,
  fetchSuccessMembers,
  fetchErrorMembers,
  fetchPendingMembers,
  membersPage,
  hasMoreMembers,
  myInformation,
  myInformationPending,
  myInformationError,
  modalPending,
  modalError,
  modalIsOpen,
  newOwnerData,
  clearGroup,
  clearNewGroupData,
  clearSelectedGroupData,
  clearGroupMembers,
  clearMyInformationData,
  clearNewOwnerData,
};

export type GroupAction = ActionType<typeof thisActions>;

export function fetchGroups(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<GroupAction>, getState: () => GroupsState) => {
    // @ts-ignore
    const state = getState().groups;
    dispatch(fetchPending());
    return fetchGet<GroupsModel>("teams", params || {})
      .then((model: GroupsModel[]) => {
        dispatch(fetchSuccess(model));
        // dispatch(membersPage(state.membersPage + 1));
      })
      .catch((err) => dispatch(fetchError(err)));
  };
}

export function fetchGroupById(id?: any) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url = config.API_SERVER + `/api/v2/teams/${id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((res) => {
      dispatch(selectedGroupsData(res));
    });
    promise.catch((err) => dispatch(fetchError(err)));
    return promise;
  };
}

export function createGroup(team: { [key: string]: CreateGroupsModel }) {
  return (dispatch: Dispatch<GroupAction>) => {
    const url = config.API_SERVER + `/api/v2/teams`;
    const promise = request(url, "POST", { team }, { version: 1 });
    promise.catch((err) => dispatch(newGroupDataError(err)));
    return promise;
  };
}

export function putGroupSettings(team?: { [key: string]: any }) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url = config.API_SERVER + `/api/v2/teams/${team.id}`;
    const promise = request(url, "PUT", { team }, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function deleteGroup(id: string) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url = config.API_SERVER + `/api/v2/teams/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function getMe(teamId: string) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(myInformationPending());
    const url = config.API_SERVER + `/api/v2/teams/${teamId}/memberships/me`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((res) => {
      dispatch(myInformation(res));
    });
    promise.catch((err) => dispatch(myInformationError(err)));
    return promise;
  };
}

export function putGroupNotifications(membership: {
  teamId: string;
  id: string;
}) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url =
      config.API_SERVER +
      `/api/v2/teams/${membership.teamId}/memberships/${membership.id}`;
    const promise = request(url, "PUT", { membership }, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function fetchGroupMembers(
  group_id: string,
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<GroupAction>, getState: () => any) => {
    dispatch(fetchPending());
    // @ts-ignore
    const state = getState().groups;
    return fetchGet<GroupAction>(
      `teams/${group_id}/memberships`,
      params || {},
      {},
      signal
    ).then((res: any) => {
      if (res && Array.isArray(res)) {
        dispatch(fetchSuccessMembers(res as any));
        dispatch(membersPage(state.membersPage + 1));
        if (res.length < (params && params.per_page)) {
          dispatch(hasMoreMembers(false));
        }
      }
      return res;
    });
  };
}

export function deleteGroupMember(teamId: string, memberId: string) {
  return () => {
    const url =
      config.API_SERVER + `/api/v2/teams/${teamId}/memberships/${memberId}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    return promise;
  };
}

export function updateGroupMember(teamId: string, membership: UserModel) {
  return () => {
    const url =
      config.API_SERVER +
      `/api/v2/teams/${teamId}/memberships/${membership.id}`;
    const promise = request(url, "PUT", { membership }, { version: 1 });
    return promise;
  };
}

export function leaveGroup(id: string) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url = config.API_SERVER + `/api/v2/teams/${id}/leave`;
    const promise = request(url, "POST", {}, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function transferGroupOwnership(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<GroupAction>) => {
    dispatch(fetchPending());
    const url =
      config.API_SERVER + `/api/v2/teams/${params.id}/transfer_ownership`;
    const promise = request(
      url,
      "POST",
      { membership: { email: params.email } },
      { version: 1 }
    );
    promise.then((res) => {
      dispatch(newOwnerData(res));
    });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export const actions = {
  fetchGroups,
};
