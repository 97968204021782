import React from 'react';
import { DateTime } from 'luxon';

import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { AvailableSlotModel } from 'app/models/scheduling/AvailableSlotModel';

import DayRow from './DayRow';

import styles from './DateTime.module.scss';

type PropsDef = {
  currentDay: DateTime;
  coach: CoachProfileModel;
  timeSlots: AvailableSlotModel[];
  isPublicPage: boolean;
};

const DaysPanel = ({ currentDay, coach, timeSlots, isPublicPage }: PropsDef) => {
  const previousDay = currentDay.minus({ days: 1 });
  const nextDay = currentDay.plus({ days: 1 });

  return (
    <div className={styles.days_panel_container}>
      <DayRow
        coach={coach}
        day={previousDay}
        timeSlots={timeSlots.filter((s) =>
          s.startHour.hasSame(previousDay, 'day')
        )}
        isPublicPage={isPublicPage}
      />
      <DayRow
        coach={coach}
        day={currentDay}
        current
        timeSlots={timeSlots.filter((s) =>
          s.startHour.hasSame(currentDay, 'day')
        )}
        isPublicPage={isPublicPage}
      />
      <DayRow
        coach={coach}
        day={nextDay}
        timeSlots={timeSlots.filter((s) =>
          s.startHour.hasSame(nextDay, 'day')
        )}
        isPublicPage={isPublicPage}
      />
    </div>
  );
};

export default DaysPanel;
