import { useSelector } from "app/utils/hooks";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";

export const CreateReplyModel = () => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const canUploadReplyMedia = () => {
    if (!self) {
      return null;
    }
    return (
      self.planPermissions.includes("upload_to_library") &&
      self.planPermissions.includes("post_media")
    );
  };

  return { self, canUploadReplyMedia };
};
