import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmitHandler?: (value: GroupInviteModalTypes) => void;
}

const GroupInviteMembersWith: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmitHandler,
}: Props) => {
  const { t } = useTranslation();
  const listData = [
    {
      title: t("Invite with Email"),
      icon: <i className="ico ico-mail" />,
      value: "invite-with-email",
    },
    {
      title: t("Share a link"),
      icon: <i className="ico ico-link" />,
      value: "invite-with-link",
    },
    {
      title: t("Import CSV or Excel File"),
      icon: <i className="ico ico-document" />,
      value: "invite-with-csv",
    },
  ];

  const onSubmit = (value: GroupInviteModalTypes) => {
    if (value) {
      onSubmitHandler(value);
    }
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Invite Members to Group")}
    >
      <div className={styles.button_wrapper}>
        {listData?.map((list, index) => (
          <Button
            key={index}
            buttonType="primary"
            title={list?.title}
            icon={list?.icon}
            childStyles={styles.btn_styles}
            onClickFunc={() => onSubmit(list?.value as GroupInviteModalTypes)}
          />
        ))}
      </div>
      <div className={styles.cancel_btn}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onClose}
        />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default GroupInviteMembersWith;
