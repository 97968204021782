/** @module components/paywalls */
import React from 'react';
import ModalWrapper from 'app/components/modals/ModalWrapper';
import { openIntercom } from 'app/utils/intercom';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = {
  show?: boolean;
  error?: string;
  onClose?: () => void;
};

export default function UpgradeErrorModal(props: PropsDef) {
  const { t } = useTranslation();

  const handleClick = () => {
    (window as any).location = '/account/billing_info';
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ModalWrapper
      isOpen={props.show}
      onRequestClose={handleClose}
      title={t("Upgrade error")}
    >
      <div className={styles.upgradeErrorModal}>
        <div className={styles.upper}>
          <div className={styles.error}>
            {props.error}
          </div>
        </div>

        <div className={styles.lower}>
          <button className="btn btn-secondary pull-left" onClick={openIntercom}>
           {t("Contact Support")}
          </button>

          <button className="btn btn-primary pull-right" onClick={handleClick}>
            {t("Update Billing Info")}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
