import TagModel from "app/models/TagModel";
import { useMemo } from "react";

type TruncateTagsProps = {
  tags: TagModel[];
  limit: number;
};

const useTruncateTags = ({ tags, limit }: TruncateTagsProps) => {
  const truncatedTags = useMemo(() => {
    const stringifiedTags = tags.join(", ");
    if (stringifiedTags.length > limit) {
      return stringifiedTags.substring(0, (limit ??= 40)) + "...";
    } else {
      return stringifiedTags;
    }
  }, [tags, limit]);

  return truncatedTags;
};

export default useTruncateTags;
