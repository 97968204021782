import React from 'react';
import { useController } from 'react-hook-form';
import TimePicker from 'rc-time-picker';
import './timepicker.scss';
import { DateTime } from 'luxon';
import I18n from 'app/i18n';

import styles from './styles.module.scss';

class MomentWrapper {
  value: DateTime;
  timeZone: string;

  constructor(date, timeZone) {
    this.timeZone = timeZone;

    if (!date) {
      this.value = null;
    } else {
      this.value = DateTime.fromISO(date).setZone(timeZone);
    }
  }

  format() {
    if (this.isBlank()) {
      return I18n.t('Select');
    }
    return this.value.toLocaleString(DateTime.TIME_SIMPLE);
  }

  hour(arg) {
    if (arg) {
      if (this.isBlank()) {
        this.value = DateTime.now().setZone(this.timeZone);
        this.value = this.value.set({ hour: arg, minute: 0, second: 0 });
        return this;
      }

      this.value = this.value.set({ hour: arg });
      return this;
    } else {
      if (this.isBlank()) {
        return this;
      }

      return this.value.hour;
    }
  }

  minute(arg) {
    if (arg || arg === 0) {
      if (this.isBlank()) {
        this.value = DateTime.now().setZone(this.timeZone);
        this.value = this.value.set({ minute: arg, second: 0 });
        return this;
      }
      this.value = this.value.set({ minute: arg });
      return this;
    } else {
      if (this.isBlank()) {
        return this;
      }

      return this.value.minute;
    }
  }

  second(arg) {
    if (arg) {
      if (this.isBlank()) {
        this.value = DateTime.now().setZone(this.timeZone);
        this.value = this.value.set({ second: arg });
        return this;
      }

      this.value = this.value.set({ second: arg });
      return this;
    } else {
      if (this.isBlank()) {
        return this;
      }

      return this.value.second;
    }
  }

  clone() {
    if (this.isBlank()) {
      return new MomentWrapper(null, this.timeZone);
    }

    return new MomentWrapper(this.value, this.timeZone);
  }

  toISO() {
    return this.value.toISO();
  }

  isBlank() {
    return !this.value;
  }
}

type PropsDef = {
  name: string;
  control: any;
  timeZone: string;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
};

const TimeRangeInput = React.forwardRef((props: PropsDef, ref) => {
  const {
    name,
    control,
    timeZone,
    disabledHours = () => [],
    disabledMinutes = () => [],
  } = props;

  const { field } = useController({ name, control });

  const value = new MomentWrapper(field.value, timeZone);

  return (
    <div className={styles.container}>
      {/* @ts-expect-error */}
      <TimePicker
        className="time-picker"
        popupClassName="time-picker-panel"
        use12Hours
        minuteStep={15}
        showSecond={false}
        value={value}
        onChange={(value: MomentWrapper) => {
          field.onChange(value.toISO());
        }}
        inputRef={ref}
        inputIcon={<i className="timepicker_input_icon ico ico-chevron" />}
        clearIcon={<span></span>}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  );
});

export default TimeRangeInput;
