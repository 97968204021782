import React from "react";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface Props {
  template: any;
  onDelete: (id: string) => void;
}

const TemplateCard: React.FC<Props> = ({ template, onDelete }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const menuList = [
    {
      label: t("Edit Name"),
      onClick: () => history.push(`/space_templates/${template.id}/edit`),
    },
    {
      label: t("Manage Posts"),
      onClick: () => history.push(`/space_templates/${template.id}/posts`),
    },
    {
      label: t("Manage Members"),
      onClick: () => history.push(`/space_templates/${template.id}/members`),
    },
    {
      label: t("Delete"),
      className: styles.delete,
      onClick: () => onDelete(template.id),
    },
  ];

  const renderMenu = () => {
    return (
      <ul className={styles.menu}>
        {menuList.map((item) => (
          <li
            key={item.label}
            className={cls(styles.menu_item, item.className && item.className)}
            onClick={item.onClick}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  const mainRoute = `/space_templates/${template.id}/posts`;

  const route = () => {
    history.push(mainRoute);
  };

  const dropdown = (
    <DropdownButton
      btnClassName={styles.dropdown_btn}
      dropdownWrapperClass={styles.dropdown_wrapper}
      btnContent={<i className="bullets" />}
      closeOnClick
    >
      {renderMenu()}
    </DropdownButton>
  );

  return (
    <div className={styles.container}>
      <div className={styles.left_content} onClick={route}>
        <span className={styles.icon_wrapper}>
          <i className={cls(styles.icon, "ico ico-templates")} />
        </span>
        {template.name && (
          <h3
            className={cls(
              styles.title,
              template.className && template.className
            )}
          >
            {template.name}
          </h3>
        )}
      </div>
      {dropdown}
    </div>
  );
};

export default TemplateCard;
