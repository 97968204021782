/**
 * @module Components.SignUp
 *
 */
import React from 'react';
import { Token } from 'app/utils/stripe';
import { AuthService } from 'app/services';
import StripeForm from 'app/components/StripeForm';
import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

interface PropsDef {
  onDone: (id: string) => void;
  onCancel: () => void;
}

export default function BillingInfo(props: PropsDef) {
  const { t } = useTranslation();

  const btnTitle = t("Done");

  const createConsentToken = (token?: Token | null) => {
    if (token != null) {
      (AuthService as any)
        .createConsentToken(token.id)
        .then((data: { chargeId: string }) => props.onDone(data.chargeId));
    }
  };

  return (
    <div className={styles.billingInfo}>
      <h1>{t("Billing Information")}</h1>
      <p>
       <Trans i18nKey="parental_consent_card_charge_refund" components={{ strong: <strong /> }}/>
      </p>

      <StripeForm
        onToken={createConsentToken}
        submitTitle={btnTitle}
        onCancel={() => props.onCancel()}
      />
    </div>
  );
}
