import React, { useEffect, useState } from "react";
import { fetchTags } from "app/actions/tag";
import { useStoreActions } from "app/utils/hooks";
import TagModel from "app/models/TagModel";
import { uuid } from "app/utils/uuid";
import Select, { OptionsType, ValueType } from "react-select";
import CreatableSelect from "react-select/creatable";
import { tagsSelectStyles } from "./tagsSelectStyles";
import { useTranslation } from "react-i18next";

type DefaultOptionTypes = {
  label: string;
  value: string;
  id?: string;
  __isNew__?: boolean;
};

interface TagOption extends DefaultOptionTypes {
  model: TagModel;
}

interface Props {
  onChange?: (value: TagModel[]) => void;
  ignore?: TagModel[];
  placeholder?: string;
  defaultValue?: TagModel[];
  value?: TagModel[] | string[];
  className?: string;
  tagValContainer?: string;
  isCreatable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
}

const TagsSelect: React.FC<Props> = ({
  tagValContainer,
  className,
  placeholder,
  onChange,
  value,
  isCreatable,
  isClearable,
  defaultValue,
  ignore,
  isMulti,
  ...rest
}: Props) => {
  const { tag } = useStoreActions({ tag: fetchTags });
  const [options, setOptions] = useState<OptionsType<TagOption>>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (tag.data != null) {
      setOptions(tag.data.map(createOption));
    }
  }, [tag.data]);

  function createOption(
    val?: TagModel | string | null,
    isNew?: boolean,
  ): TagOption | undefined {
    if (val == null) {
      return undefined;
    }

    if (typeof val === "string") {
      if (!val.length) {
        return undefined;
      }

      let tagModel: TagModel;
      if (tag.data && tag.data.length) {
        tagModel = tag.data.find(
          (item: TagModel) => item.name === (val as string),
        );
      }

      if (tagModel != null) {
        val = tagModel;
      } else {
        val = { id: uuid(), name: val, object: "tag" };
      }
    }

    return { value: val.id, label: val.name, model: val, __isNew__: isNew };
  }

  function createValueOpt(val?: TagModel[] | TagModel | null) {
    if (typeof val === "object" && Array.isArray(val)) {
      return (val as TagModel[]).map((v: any) => createOption(v));
    } else {
      return createOption(val);
    }
  }

  function handleChange(inputVal: ValueType<TagOption>) {
    if (onChange) {
      if (!inputVal) {
        onChange([]);
      } else if (!Array.isArray(inputVal)) {
        const selectedOption = inputVal as TagOption;
        if (selectedOption.__isNew__) {
          onChange([
            {
              id: selectedOption.model.id,
              name: selectedOption.label,
              object: "tag",
            },
          ]);
        } else {
          onChange([selectedOption.model]);
        }
      } else {
        const selectedOptions = inputVal as TagOption[];
        const selectedTags = selectedOptions.map((option) =>
          option.model
            ? option.model
            : { id: option.value, name: option.label, object: "tag" },
        );
        onChange(selectedTags as any);
      }
    }
  }

  const params = {
    closeMenuOnSelect: false,
    value: createValueOpt(value),
    defaultValue: createValueOpt(defaultValue),
    isMulti,
    isClearable,
    placeholder: placeholder ?? t("Add some tags."),
    options,
    onChange: handleChange,
    // onCreateOption: handleCreateOption,
    styles: tagsSelectStyles,
    ...rest,
  };

  if (isCreatable) {
    return <CreatableSelect {...params} />;
  } else {
    return <Select {...params} />;
  }
};

export default TagsSelect;
