import React from "react";
import GroupPageLayout from "../../../../components/Layouts/GroupPageLayout";
import { GroupTimelineModel } from "./indexModel";
import GroupPostingForm from "../../../../components/GroupPostingForm";
import styles from "./styles.module.scss";
import GroupTabsContainer from "../GroupTabsContainer";
import Filter from "app/components/Filter";
import GroupsPostsContainer from "app/components/GroupsPostsContainer";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";

const GroupTimeline = () => {
  const {
    data,
    scheduledPostsData,
    onPost,
    pending,
    onPostDelete,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
  } = GroupTimelineModel();

  return (
    <GroupPageLayout>
      <div className={styles.group_tabs_container}>
        <GroupTabsContainer />
      </div>
      <GroupPostingForm onPost={onPost} />
      <div className={styles.dropdown_container}>
        <hr />
        <Filter
          filterClickHandler={filterHandler}
          onFilterClear={onClearFilters}
        />
      </div>
      <GroupsPostsContainer
        scheduledPostsData={scheduledPostsData}
        displayPostLocations={false}
        hidePinBadge={false}
        onPostDelete={onPostDelete}
        onPostUpdate={onPostUpdate}
        onReport={onPostReportClick}
        onPostPin={onPostPin}
        containerRef={sentryRef}
        posts={data}
        pending={pending}
      />
      {reportModal.isOpen && (
        <PostReportModal
          isModalOpen={reportModal.isOpen}
          modalHandler={reportModalClose}
          reportHandler={onReportHandler}
        />
      )}
    </GroupPageLayout>
  );
};

export default GroupTimeline;
