import PostModel from "app/models/PostModel";

const useGroupAndSortPosts = (
  posts: PostModel[],
  currentGroups: Record<string, PostModel[]>
) => {
  const newGroups = { ...currentGroups };

  posts.forEach((post) => {
    const scheduledIn = post.scheduledIn ?? "0";
    if (!newGroups[scheduledIn]) {
      newGroups[scheduledIn] = [];
    }
    newGroups[scheduledIn].push(post);
  });

  // sorting function for posts
  const sortPosts = (a: PostModel, b: PostModel) => {
    if (a.pinnedAt !== b.pinnedAt) {
      return (a.pinnedAt ?? 0) - (b.pinnedAt ?? 0);
    }
    if (a.scheduledIn === null && b.scheduledIn === null) {
      return (a.position ?? 0) - (b.position ?? 0);
    }
    return 0;
  };

  // sort posts within each group
  Object.keys(newGroups).forEach((key) => {
    newGroups[key].sort(sortPosts);
  });

  // create orderedGroups object with "0" first
  const orderedGroups: Record<string, PostModel[]> = {};

  // add "0" group first if it exists
  if (newGroups["0"]) {
    orderedGroups["0"] = newGroups["0"];
  }

  // add other scheduled groups in their original order
  Object.keys(newGroups).forEach((key) => {
    if (key !== "0") {
      orderedGroups[key] = newGroups[key];
    }
  });

  return orderedGroups;
};

export default useGroupAndSortPosts;
