import React, { memo } from "react";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const CreateNavBtn = () => {
  const { t } = useTranslation();
  const menuItems = [
    {
      lable: t("New Space"),
      icon: "ico ico-tspace",
      url: "/spaces/new",
    },
    {
      lable: t("New Group"),
      icon: "ico ico-team",
      url: "/groups",
    },
    {
      lable: t("New Tag"),
      icon: "ico ico-tags-o",
      url: "/account/tags/new",
    },
    {
      lable: t("New List"),
      icon: "ico ico-groups",
      url: "/lists",
    },
    {
      lable: t("New Template"),
      icon: "ico ico-templates",
      url: "/space_templates",
    },
  ];

  return (
    <DropdownButton
      btnContent={t("+ Create")}
      dropdownWrapperClass={styles.dropdown_wrapper}
      btnClassName={styles.create_btn}
    >
      <ul className={styles.container}>
        {menuItems.map((item, index) => (
          <li className={styles.list} key={index}>
            <Link to={item.url} className={styles.link}>
              <i className={item.icon} />
              {item.lable}
            </Link>
          </li>
        ))}
      </ul>
    </DropdownButton>
  );
};

export default memo(CreateNavBtn);
