import { cls } from "app/utils";
import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";

interface DropdownProps {
  children: React.ReactNode;
  hideButtonStyles?: boolean;
  closeOnClick?: boolean;
  closeModal?: any;
  isOpen: boolean;
  onClose: () => void;
  dropdownWrapperClass?: string;
}

const DropdownButton = ({
  children,
  isOpen,
  onClose,
  closeOnClick = false,
  dropdownWrapperClass,
}: DropdownProps) => {
  const ref = useRef(null);

  const handleClickOutside = (e: any) => {
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      if (
        !ref.current.contains(e.target) &&
        !e.target.closest(`.${styles.dropdown_container}`)
      ) {
        onClose();
      }
      if (closeOnClick) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  return (
    <div
      ref={ref}
      className={cls(styles.dropdown_container, dropdownWrapperClass, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      {children}
    </div>
  );
};

export default DropdownButton;
