// t => useTranslation().t
export const getInvitationGroupTitle = (
  key: "today" | "thisWeek" | "thisMonth" | "everythingElse",
  t: (input: string) => string,
) => {
  switch (key) {
    case "today":
      return t("Today");
    case "thisWeek":
      return t("This Week");
    case "thisMonth":
      return t("This Month");
    default:
      return t("Everything Else");
  }
};
