import { createPost, fetchPost } from "app/actions/postingForm";
import PostModel, { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "typedefs";

interface Props {
  onPost?: (post: PostModel) => void;
}

export const TemplatePostingFormModel = ({ onPost }: Props) => {
  const { selectedTemplate } = useSelector(
    (state: RootState) => state.spaceTemplate,
    shallowEqual,
  );

  const feedPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const { id: templateId } = useParams<{ id: string }>();

  if (!selectedTemplate) {
    return null;
  }

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return user?.planPermissions?.includes("upload_to_library");
  };

  const onSubmit = (data: PostingModel) => {
    if (templateId) {
      feedPostActions
        .createPost({ ...data, space_template_id: templateId })
        .then((res) => {
          if (onPost) {
            onPost({ ...res, userAvatar: user.avatar });
          }
        });
    }
  };

  return {
    canUploadMedia,
    user,
    onSubmit,
  };
};
