import React from "react";
import OptionButtonGroup from "app/components/OptionButtonGroup";
import SwitchToggle from "app/components/OptionButtonGroup/SwitchToggle";
import CustomOpenCalendarOptions from "app/components/OptionButtonGroup/CustomOpenCalendarOptions";
import {parseTime} from "app/routes/Scheduling/utils";
import { useTranslation } from "react-i18next";

interface DailyProps {
  day: number;
  active: boolean;
  startTime: string;
  endTime: string ;
  value: number;
  label: string;
  short: string;
}

interface ScheduleProps {
  id?: string;
  name: string;
  dailyTimeTables: DailyProps[];
  customPeriod: boolean;
  startDate: string | Date;
  endDate: string | Date;
}

interface AvailabilityWithDatesProps {
  schedule: ScheduleProps;
  setScheduleData: any;
}

const AvailabilityWithDates = ({ schedule, setScheduleData }: AvailabilityWithDatesProps) => {
  const { t } = useTranslation();

  const onDaySelect = (selectedDay: number) => {
    const updatedDaily = schedule.dailyTimeTables.map((daily) => {
      if (selectedDay !== daily.day) {
        return daily;
      }

      daily.active = !daily.active;

      if (daily.active && (!daily.startTime || !daily.endTime)) {
        daily.startTime = '08:00';
        daily.endTime = '17:00';
      }

      return daily;
    });
    setScheduleData('dailyTimeTables', updatedDaily);
  };

  const onHourChange = (selectedDay: number, time: any, type: string) => {
    const updatedDaily = schedule.dailyTimeTables.map((daily) => {
      if (selectedDay !== daily.day) {
        return daily;
      }
      daily[type] = time.value;
      daily['beginAt'] = parseTime(daily['startTime']);
      daily['endAt'] = parseTime(daily['endTime']);
      return daily;
    });
    setScheduleData('dailyTimeTables', updatedDaily);
  };

  return (
    <>
      <label>{t("Days Available")}</label>
        <OptionButtonGroup
          options={schedule.dailyTimeTables}
          selected={schedule.dailyTimeTables.filter((oh) => oh.active)}
          setSelected={onDaySelect}
          onChange={onHourChange}
          shortButtons={true}
        />
        <SwitchToggle
          checked={schedule.customPeriod}
          onChange={(value: boolean) => {
            if(schedule.id) {
              setScheduleData(schedule.id, 'customPeriod', value);
            } else {
              setScheduleData('customPeriod', value);
            }
          }}
          title={t('This availability is active during a specific part of the year')}
        />
        {schedule.customPeriod && (
          <CustomOpenCalendarOptions
            setFromDate={(date: Date) => {
              if(schedule.id) {
                setScheduleData(schedule.id, 'startDate', date);
              } else {
                setScheduleData('startDate', date);
              }
            }
            }
            setToDate={(date: Date) => {
              if(schedule.id) {
                setScheduleData(schedule.id, 'endDate', date);
              } else {
                setScheduleData('endDate', date);
              }
            }}
            schedule={schedule}
          />
        )}
    </>
  );
};

export default AvailabilityWithDates;
