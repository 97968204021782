import React from 'react';
import styles from '../DatePicker/styles.module.scss';
import ReactDatePicker from "react-datepicker";

interface CalendarPickerProps {
  date: Date;
  setDate: (date: Date) => void;
  minDate?: Date;
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({ date, setDate, minDate }) => {

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className={styles.header}>
        <div className={styles.date_arrow} style={{ transform: 'rotate(90deg)' }}  onClick={decreaseMonth}>
          <span> <i className="ico ico-chevron"/> </span>
        </div>
        <p className={styles.current_month}>
          {date.toLocaleString(navigator.language, { month: 'long', year: 'numeric' })}
        </p>
        <div className={styles.date_arrow} style={{ transform: 'rotate(-90deg)' }} onClick={increaseMonth}>
          <span> <i className="ico ico-chevron" /> </span>
        </div>
      </div>
    );
  };
  if(typeof date === 'string') {
    if(date === 'Invalid DateTime') {
      date = new Date();
    }
    date = new Date(date);
  }

  return (
    <ReactDatePicker
      selected={date}
      onChange={(date: Date) => setDate(date)}
      dateFormat="MMM d, yyyy"
      minDate={minDate || null}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <CustomHeader
          increaseMonth={increaseMonth}
          decreaseMonth={decreaseMonth}
          date={date}
        />
      )}
      calendarClassName={styles.day_picker}
    />
  );
};

export default CalendarPicker;
