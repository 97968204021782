/**
 * @module Utils.Hooks
 *
 */
import { useMemo, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators, ActionCreator, ActionCreatorsMapObject } from 'redux';

interface ActionLike { type: string; }
type AnyFunc = (...args: any[]) => any;
type ThunkFunc = ((...args: any[]) => ((dispatch: Dispatch<any>) => any));
export type ThunkFuncReducer<A extends AnyFunc = AnyFunc> = A extends ThunkFunc
  ? ((...args: Parameters<A>) => ReturnType<ReturnType<A>>)
  : ReturnType<A> extends ActionLike ? ((...args: Parameters<A>) => void) : A;

/**
 * enforeces actions passed to useAction are either ActionCreator | ActionCreatorsMapObject
 */
export type SingleOrMappedActions<A> = A extends ActionCreatorsMapObject<any> ? A : (A extends ActionCreator<any> ? A : never);

/**
 * Converts ActionCreator to ((args) => return val) for
 * better types when using methods
 *
 */
type SingleOrMappedActionThunk<A> = A extends ActionCreatorsMapObject<any>
  ? {[K in keyof A]: ThunkFuncReducer<A[K]>;}
  : A extends ActionCreator<any> ? ThunkFuncReducer<A> : never;


/**
 * Gets dispatch ready actions
 *
 * @method useActions
 */
export default function useActions<A extends any>(actions: SingleOrMappedActions<A>, deps?: any[]) {
  deps = deps != null ? deps : [];
  const dispatch = useDispatch();
  // @ts-ignore
  return useMemo(() => bindActionCreators(actions, dispatch) as SingleOrMappedActionThunk<A>, deps);
}

