/**
 * @module Utils.Hooks
 *
 */
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useActions from "./useActions";
import { RootState } from "app/reducers";
import { UserState } from "app/reducers/user";
import { fetchSelf } from "app/actions/user";
import { isAuthenticated } from "app/utils/auth";

/**
 *
 * @method useAuth
 */
export default function useAuth(): [UserState, boolean] {
  const actions = useActions({ fetchSelf });
  const user = useSelector((state: RootState) => state.user, shallowEqual);

  useEffect(() => {
    if (user.data == null) {
      actions.fetchSelf();
    }
  }, []);

  return [user, isAuthenticated()];
}
