import { fetchTeamGroups } from "app/actions/team-group";
import {
  fetchInvitedTeamGroupMembers,
  fetchJoinableTeam,
  inviteTeamGroupMembers,
  membersToInvite,
} from "app/actions/team-group-member-import";
import TeamModel from "app/models/TeamModel";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";

export const useInviteWithEmailModel = () => {
  const { t } = useTranslation();
  const inviteWithEmailActions = useActions({
    inviteTeamGroupMembers,
    fetchInvitedTeamGroupMembers,
    fetchTeamGroups,
    fetchJoinableTeam,
  });
  const dispatch = useDispatch();
  const { membersListToInvite, selectedTeams } = useSelector(
    (state: RootState) => state.teamGroupMemberImport, shallowEqual
  );
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);
  const [addMembersToChannelIsOpen, setAddMembersToChannelIsOpen] =
    useState(false);
  const [confirmInvitesModalOpen, setConfirmInvitesModalOpen] = useState(false);

  const [members, setMembers] = useState<UserModel[]>(
    membersListToInvite
      ? membersListToInvite
      : [
          { email: "", name: "", number: "", position: "", role: "player" },
          { email: "", name: "", number: "", position: "", role: "player" },
          { email: "", name: "", number: "", position: "", role: "player" },
        ]
  );
  const [nameErrors, setNameErrors] = useState<string[]>([]);
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [invitationPending, setInvitationPending] = useState(false);
  const [invitationSuccess, setInvitationSuccess] = useState(false);

  const addMember = () => {
    setMembers([
      ...members,
      { email: "", name: "", number: "", position: "", role: "player" },
    ]);
  };

  const handleEmailChange = (index: number, value: string) => {
    const updatedMembers = [...members];
    updatedMembers[index].email = value;
    setMembers(updatedMembers);

    // check if email is repeating in other member objects
    const emailCount = updatedMembers.reduce((count, member, i) => {
      if (member.email.trim() === value.trim() && i !== index) {
        return count + 1;
      }
      return count;
    }, 0);

    if (emailCount > 0) {
      setEmailErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = t("This email is already used");
        return newErrors;
      });
    } else {
      setEmailErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  const handleNameChange = (index: number, value: string) => {
    const updatedMembers = [...members];
    updatedMembers[index].name = value;
    setMembers(updatedMembers);
    if (value.trim() === "") {
      setNameErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        // @ts-ignore
        newErrors[index] = t(`Name is required Line`) + new Error().lineNumber;
        return newErrors;
      });
    } else {
      setNameErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  const handleRoleChange = (index: number, value: string) => {
    const updatedMembers = [...members];
    updatedMembers[index].role = value;
    setMembers(updatedMembers);
  };

  const validateMembers = () => {
    let hasErrors = false;
    const emailErrorsCopy = Array(members.length).fill("");
    const nameErrorsCopy = Array(members.length).fill("");

    // check if any member object is filled
    const hasFilledMember = members.some(
      (member) => member.email.trim() !== "" || member.name.trim() !== ""
    );

    // if no member object is filled, set errors for all
    if (!hasFilledMember) {
      emailErrorsCopy.fill(t("Email is required"));
      nameErrorsCopy.fill(t("Name is required"));
      setEmailErrors(emailErrorsCopy);
      setNameErrors(nameErrorsCopy);
      return true;
    }

    members.forEach((member, index) => {
      // check if email or name is missing in a filled member object
      if (
        (member.email.trim() === "" || member.name.trim() === "") &&
        (member.email.trim() !== "" || member.name.trim() !== "")
      ) {
        if (member.email.trim() === "") {
          emailErrorsCopy[index] = t("Email is required");
          hasErrors = true;
        }
        if (member.name.trim() === "") {
          nameErrorsCopy[index] = t("Name is required");
          hasErrors = true;
        }
      }
    });

    setEmailErrors(emailErrorsCopy);
    setNameErrors(nameErrorsCopy);

    return hasErrors;
  };

  const onSubmitHandler = () => {
    if (!validateMembers()) {
      const validMembers = members.filter(
        (member) => member.email.trim() !== "" || member.name.trim() !== ""
      );
      dispatch(membersToInvite(validMembers));
      setAddMembersToChannelIsOpen(true);
      return;
    }
  };

  const teamDataIdsFilter = useCallback((teamData: TeamModel[]) => {
    const teamDataIds: string[] = teamData.map((data) => data.id);

    return teamDataIds;
  }, []);

  const inviteMembersHandler = () => {
    setConfirmInvitesModalOpen(false);
    setInvitationPending(true);
    inviteWithEmailActions
      .inviteTeamGroupMembers(
        selectedGroup.id,
        membersListToInvite,
        teamDataIdsFilter(selectedTeams)
      )
      .then((res: any) => {
        return Promise.all([
          inviteWithEmailActions.fetchInvitedTeamGroupMembers(
            selectedGroup.id,
            res.id
          ),
          inviteWithEmailActions.fetchTeamGroups(selectedGroup.id),
          inviteWithEmailActions.fetchJoinableTeam(selectedGroup.id),
        ]).then(() => {
          setInvitationPending(false);
          setInvitationSuccess(true);
        });
      });
  };

  useEffect(() => {
    return () => {
      setAddMembersToChannelIsOpen(false);
      setConfirmInvitesModalOpen(false);
      setInvitationPending(false);
      setInvitationSuccess(false);
    };
  }, []);

  return {
    addMembersToChannelIsOpen,
    setAddMembersToChannelIsOpen,
    setConfirmInvitesModalOpen,
    confirmInvitesModalOpen,
    membersListToInvite,
    selectedTeams,
    members,
    handleEmailChange,
    handleNameChange,
    emailErrors,
    nameErrors,
    addMember,
    handleRoleChange,
    onSubmitHandler,
    inviteMembersHandler,
    invitationPending,
    invitationSuccess,
  };
};
