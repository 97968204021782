import React, { useState } from "react";
import styles from "./styles.module.scss";
import PostModel from "app/models/PostModel";
import DropdownButton from "app/components/DropdownButton";
import { useActions } from "app/utils/hooks";
import { requestPostLocations } from "app/actions/posts";
import { Loading } from "app/components/Wrappers";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  post: PostModel;
}

const PostLocations = ({ post }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const postLocationActions = useActions({ requestPostLocations });
  const spacesCount = post.spacesCount;
  const [dropDownData, setDropdownData] = useState(null);
  const route = (url: string) => {
    history.push(url);
  };

  const renderLocationsText = () => {
    if (spacesCount > 1) {
      return <>{t("In {{spacesCount}} Locations", { spacesCount })}</>;
    }

    if (spacesCount <= 1) {
      if (post.team) {
        return (
          <p onClick={() => route(`groups/${post.team.id}`)}>
            {t("In {{postTeamName}}", {
              postTeamName: post.team.name,
            })}{" "}
            / {t("General")}
          </p>
        );
      } else if (post.teamGroup) {
        return (
          <p
            onClick={() =>
              route(
                `groups/${post.teamGroup.team.id}/channels/${post.teamGroup.id}`
              )
            }
          >
            {t("In {{teamGroupName}}", {
              teamGroupName: post.teamGroup.team.name,
            })}{" "}
            / {post.teamGroup.name}
          </p>
        );
      } else if (post.space) {
        return (
          <p onClick={() => route(`spaces/${post.space.id}`)}>
            {t("In {{athleteName}}", {
              athleteName: post.space.athleteName,
            })}{" "}
            / {post.space.sport}
          </p>
        );
      }
    }

    if (!post.published) {
      return (
        <span className={styles.not_published}>
          {t("Your post is being published.")}
        </span>
      );
    }

    return null;
  };

  const renderLocationsInDropdown = (postData: PostModel) => {
    if (postData.team) {
      return (
        <p onClick={() => route(`groups/${postData.team.id}`)}>
          {postData.team.name} General
        </p>
      );
    } else if (postData.teamGroup) {
      return (
        <p
          onClick={() =>
            route(
              `groups/${postData.teamGroup.team.id}/channels/${postData.teamGroup.id}`
            )
          }
        >
          {postData.teamGroup.team.name} / {postData.teamGroup.name}
        </p>
      );
    } else if (postData.space) {
      return (
        <div className={styles.location_flex} onClick={() => route(`spaces/${postData.space.id}`)}>
          <p className={styles.item_name}>{postData.space.athleteName}</p>
          &nbsp;&nbsp;
          <p className={styles.item_location}>{postData.space.sport}</p>
        </div>
      );
    }

    return null;
  };

  const onLocationsClick = () => {
    if (!dropDownData) {
      postLocationActions.requestPostLocations(post.id).then((res) => {
        setDropdownData(res);
      });
    }
  };

  return (
    location && (
      <div className={styles.location}>
        {post.team || post.teamGroup || post.space || !post.published ? (
          renderLocationsText()
        ) : (
          <>
            <DropdownButton
              btnContent={<p>In {spacesCount} Locations</p>}
              btnClassName={styles.dropdown_btn}
              onOpen={onLocationsClick}
              dropdownClassname={styles.dropdown_container}
              dropdownWrapperClass={styles.dropdown_content}
            >
              {dropDownData ? (
                dropDownData.map((item: PostModel) => {
                  return (
                    <div key={item.id} className={styles.item_wrapper}>
                      {renderLocationsInDropdown(item)}
                    </div>
                  );
                })
              ) : (
                <div className={styles.loading_wrapper}>
                  <Loading isLoading loadType="spinner" />
                </div>
              )}
            </DropdownButton>
          </>
        )}
      </div>
    )
  );
};

export default PostLocations;
