import React from "react";
import styles from "./styles.module.scss";
import GettingStartedModal from "app/components/Layouts/GettingStartedModal";
import { GettingStartedModalProps } from "../index.types";
import GroupsImageDesktop from "../../../../public/images/groups-img-desktop.png";
import GroupsImageMobile from "../../../../public/images/groups-img-mobile.png";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const GroupsModal = ({ onClose, isOpen, url }: GettingStartedModalProps) => {
  const { t } = useTranslation();

  return (
    <GettingStartedModal onClose={onClose} isOpen={isOpen}>
      <Link to={url ? (url as string) : "#"} className={styles.container}>
        <div className={styles.information_content}>
          <div className={styles.info_title}>
            {t("Groups - your one-to-many platform")}
          </div>
          <p>
            {t(
              'Coaching a group of athletes? “Groups” are like Spaces, but designed with features that support a community environment. Within "Groups", create channels of common topics, allowing your members to easily communicate and achieve their goals faster than ever.'
            )}
          </p>
          <div className={styles.create_group_btn}>{t("Create new Group")}</div>
        </div>
        <div className={styles.gallery_content}>
          <img
            className={cls(styles.gallery_group_desktop, styles.gallery_group)}
            src={GroupsImageDesktop}
            alt="Groups"
          />
          <img
            className={cls(styles.gallery_group_mobile, styles.gallery_group)}
            src={GroupsImageMobile}
            alt="Groups"
          />
        </div>
      </Link>
    </GettingStartedModal>
  );
};

export default GroupsModal;
