import { createPost } from "app/actions/postingForm";
import { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "typedefs";

export const usePostToSmartListModel = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const feedPostActions = useActions({ createPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const [filteredDestinations, setFilteredDestinations] = useState({
    smart_list_ids: [id],
  });

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user.planPermissions.includes("upload_to_library") &&
      user.planPermissions.includes("post_media")
    );
  };

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations,
    shallowEqual,
  );

  useEffect(() => {
    if (selectedDestinations && selectedDestinations.length > 0) {
      setFilteredDestinations({
        smart_list_ids: [id],
      });
    }
  }, [selectedDestinations]);

  // posting form submit handler
  const onSubmit = (data: PostingModel) => {
    // check if location(s) are selected in redux to use id from it
    if (selectedDestinations) {
      feedPostActions
        .createPost({ ...data, ...filteredDestinations })
        .then(() => {
          history.goBack();
        });
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return {
    canUploadMedia,
    user,
    onSubmit,
    onCancel,
  };
};
