import { fetchTeamGroups } from "app/actions/team-group";
import {
  fetchJoinableTeam,
  getUploadInvitationFile,
  membersToInvite,
  uploadInvitationFile,
} from "app/actions/team-group-member-import";
import { useActions } from "app/utils/hooks";
import { useParseCsvData } from "app/utils/hooks/useParseCsvData";
import upload from "app/utils/uploader";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";

export const useInviteWithEmailModel = () => {
  const dispatch = useDispatch();

  const importActions = useActions({
    fetchJoinableTeam,
    fetchTeamGroups,
    upload,
    uploadInvitationFile,
    getUploadInvitationFile,
  });

  const { membersListToInvite, selectedTeams } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual
  );

  const [importPending, setImportPending] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [csvModalOpen, setCsvModalOpen] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectRoleModalOpen, setSelectRoleModalOpen] = useState(false);
  const [importedUsersModalOpen, setImportedUsersModalOpen] = useState(false);
  const [confirmImportsModalOpen, setConfirmImportsModalOpen] = useState(false);
  const [addMembersToChannelIsOpen, setAddMembersToChannelIsOpen] =
    useState(false);

  const handleCsvImport = (data: any) => {
    dispatch(membersToInvite(data));
    setCsvModalOpen(false);
    setSelectRoleModalOpen(true);
  };

  const handleGetFile = (file: File) => {
    if (file) {
      setUploadedFile(file);
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target && (event.target.result as string);

      try {
        const parsedData = useParseCsvData(csvData);
        handleCsvImport(parsedData);
      } catch (err) {
        console.error("CSV parsing error:", err);
        return;
      }

      setCsvModalOpen(false);
      setSelectRoleModalOpen(true);
    };

    reader.onerror = () => {
      console.error("File reading error");
    };

    reader.readAsText(file);
  };

  const onNextSelectRoleModal = () => {
    setSelectRoleModalOpen(false);
    setImportedUsersModalOpen(true);
  };

  const onBackConfirmImportsModal = () => {
    setImportedUsersModalOpen(false);
    setSelectRoleModalOpen(true);
  };

  const onNextImportedUsersModal = () => {
    setImportedUsersModalOpen(false);
    setAddMembersToChannelIsOpen(true);
  };

  const onBackAddMembersToChannelIsOpen = () => {
    setAddMembersToChannelIsOpen(false);
    setImportedUsersModalOpen(true);
  };

  const onNextAddMembersToChannelIsOpen = () => {
    setAddMembersToChannelIsOpen(false);
    setConfirmImportsModalOpen(true);
  };

  const importMembersHandler = async () => {
    try {
      setImportPending(true);
      const abortableUpload = upload(
        uploadedFile,
        uploadedFile.name,
        undefined,
        "file"
      );
      const response = await abortableUpload.promise;
      const res = await importActions.uploadInvitationFile({
        teamId: selectedGroup.id,
        file: response,
        team_group_ids:
          selectedTeams && selectedTeams.map((team: any) => team.id),
      });

      if (res?.status === "in_progress") {
        // make a poll request
        const pollRequest = async () => {
          const pollRes = await importActions.getUploadInvitationFile(
            selectedGroup.id,
            res.id
          );

          // check if the response status is completed stop the polling and return promise all
          if (pollRes.status === "success") {
            await Promise.all([
              importActions.fetchTeamGroups(selectedGroup?.id),
              importActions.fetchJoinableTeam(selectedGroup?.id),
            ]);
            setImportPending(false);
            setImportSuccess(true);
          } else {
            setTimeout(pollRequest, 4000);
          }
        };

        pollRequest();
      }
      setConfirmImportsModalOpen(false);
      setImportPending(true);
    } catch (error) {
      console.error("Error during file upload:", error);
      setImportPending(false);
    }
  };

  return {
    membersListToInvite,
    selectedTeams,
    importedUsersModalOpen,
    csvModalOpen,
    handleGetFile,
    selectRoleModalOpen,
    onNextSelectRoleModal,
    onNextImportedUsersModal,
    confirmImportsModalOpen,
    onBackConfirmImportsModal,
    onNextAddMembersToChannelIsOpen,
    onBackAddMembersToChannelIsOpen,
    addMembersToChannelIsOpen,
    importPending,
    importSuccess,
    importMembersHandler,
  };
};
