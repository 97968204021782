import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

const useTimelinePostsContainerModel = () => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const { scheduledPostsSuccess: scheduledPostsData } = useSelector(
    (state: RootState) => state.schedulePost,
    shallowEqual
  );

  return {
    self,
    scheduledPostsData,
  };
};

export default useTimelinePostsContainerModel;
