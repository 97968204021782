/**
 * @module Components.Selectbox
 *
 */
import React, { useState, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import Selectbox, { SelectProps, OptionsType, DefaultOption } from 'app/components/inputs/SelectBox/Base';
import { useLoc } from 'app/utils/hooks';
import { cls } from 'app/utils';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type CoachType = 'coach' | 'head_coach' | 'instructor' | 'trainer';
type AthleteType = 'player' | 'pupil' | 'student' | 'trainee';
type FanType = 'fan' | 'official' | 'parent' | 'recruiter';

type OptionMapType = {
  coach: CoachType[],
  athlete: AthleteType[],
  other: FanType[]
};

const OPTION_MAP: OptionMapType = {
  coach: ['coach', 'head_coach', 'instructor', 'trainer'],
  athlete: ['player', 'pupil', 'student', 'trainee'],
  other: ['fan', 'official', 'parent', 'recruiter']
};

type RoleTitleProps = SelectProps & {
  type?: keyof OptionMapType
};

/**
 * @class RoleSelect
 *
 */
export default function RoleSelect(props: RoleTitleProps) {
  const { t } = useTranslation();

  const LOCALES = defineMessages({
    coach: { id: 'app.title.coach', defaultMessage: t('Coach') },
    head_coach: { id: 'app.title.head-coach', defaultMessage: t('Head Coach') },
    instructor: { id: 'app.title.instructor', defaultMessage: t('Instructor') },
    trainer: { id: 'app.title.trainer', defaultMessage: t('Trainer') },

    player: { id: 'app.title.player', defaultMessage: t('Player') },
    pupil: { id: 'app.title.pupil', defaultMessage: t('Pupil') },
    student: { id: 'app.title.student', defaultMessage: t('Student') },
    trainee: { id: 'app.title.trainee', defaultMessage: t('Trainee') },

    fan: { id: 'app.title.fan', defaultMessage: t('Fan') },
    official: { id: 'app.title.official', defaultMessage: t('Official') },
    parent: { id: 'app.title.parent', defaultMessage: t('Parent') },
    recruiter: { id: 'app.title.recruiter', defaultMessage: t('Recruiter') },

    placeholder: { id: 'signup.about-you.role-placeholder', defaultMessage: t('A Friendly Name or Title (Optional)') },

    notOptsMess: { id: 'signup.about-you.role.no-options-text', defaultMessage: t('I like to be called "{value}"') },
  });

  const { className, type, ...rest } = props;
  const { formatMessage } = useLoc();
  const [ options, setOptions ] = useState<OptionsType>(null);

  const loadOptions = (type: 'coach' | 'athlete' | 'other' | null | undefined) => {
    type = type != null ? type : 'coach';

    const createOpt = (value: CoachType | AthleteType | FanType): DefaultOption => {
      const translated = formatMessage(LOCALES[value]);
      return { value: translated, label: translated };
    };

    const list = (OPTION_MAP[type] as Array<CoachType | AthleteType | FanType>);
    setOptions(list.map(createOpt));
  };

  useEffect(() => loadOptions(type), [ type ]);

  const formatCreateLabel = (value: string) => {
    return formatMessage(LOCALES.notOptsMess, { value });
  };

  return (
    <Selectbox
      isCreatable={true}
      isClearable={true}
      className={cls(styles.select, "role-title", className)}
      placeholder={formatMessage(LOCALES.placeholder)}
      formatCreateLabel={formatCreateLabel}
      options={options}
      {...rest}
    />
  );
}



