import { SearchAciton } from "app/actions/search";
import { RequestError } from "typedefs";

export type AddToRecruitPostState = {
  pendingSearch: boolean;
  errorSearch: RequestError | null;
  successSearch: boolean;
};

const initialState: AddToRecruitPostState = {
  errorSearch: null,
  pendingSearch: false,
  successSearch: false,
};

export default function addToRecruitPostReducer(
  state = initialState,
  action: SearchAciton
) {
  switch (action.type) {
    case "@search.create.pending":
      return {
        ...state,
        pendingSearch: true,
        errorSearch: null,
      };
    case "@search.create.error":
      return {
        ...state,
        successSearch: false,
        pendingSearch: false,
        errorSearch: action.payload,
      };
    case "@search.create.success":
      return {
        ...state,
        successSearch: action.payload,
        errorSearch: null,
        pendingSearch: false,
      };
    case "@search.clear":
      return {
        ...state,
        successSearch: false,
        errorSearch: null,
        pendingSearch: false,
      };
    default:
      return state;
  }
}
