/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'config/environment';
import { useTranslation } from 'react-i18next';

/**
 * @class Support
 *
 */
export default function Support() {
  const { t } = useTranslation();
  return (
    <a href={config.SUPPORT_URL}>
      <FormattedMessage
        id="app.links.support"
        defaultMessage={t("Support")}
        description={t("Support link text")}
      />
    </a>
  );
}

