import React, { memo, useCallback } from "react";
import styles from "./styles.module.scss";
import { useSmartLists } from "./indexModel";
import { cls } from "app/utils";
import SmartListModel from "app/models/SmartListModel";
import SmartListsUpgradeModal from "../Layouts/SmartListsUpgradeModal";
import { Loading } from "../Wrappers";
import feedPostIcon from "../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../public/images/smart-list/15-calendar-icon.svg";
import spacesIcon from "../../../public/images/smart-list/spaces-icon.svg";
import inspirationIcon from "../../../public/images/smart-list/inspiration-icon.svg";
import listIcon from "../../../public/images/smart-list/list-icon.svg";

const SmartList = () => {
  const {
    user,
    data,
    handleDataClick,
    selectedList,
    isDropdownOpen,
    handleDropdownBtn,
    isUpgradeModalOpen,
    handleUpgradeModal,
    sentryRef,
    segmentsLoading,
  } = useSmartLists();

  const renderIcon = useCallback(() => {
    switch (selectedList?.name) {
      case "Unanswered by me":
        return feedPostIcon;
      case "15 days inactive":
      case "15 days active":
        return fifteenDaysIcon;
      case "All spaces":
        return spacesIcon;
      case "Inspiration":
        return inspirationIcon;
      default:
        return listIcon;
    }
  }, [selectedList]);

  return (
    <>
      <div
        className={cls(
          styles.container,
          user && user.needsOnboarding && styles.needsOnboarding,
        )}
      >
        {selectedList && (
          <div className={styles.dropdown_btn} onClick={handleDropdownBtn}>
            <div className={styles.selected_value}>
              <img src={renderIcon()} alt="icon" />
              <p>{selectedList.name}</p>
            </div>
            <i className="ico ico-chevron" />
          </div>
        )}
        <div
          className={cls(styles.list_wrapper, {
            [styles.open]: isDropdownOpen,
            [styles.close]: !isDropdownOpen,
          })}
        >
          {data?.map((dataItem: SmartListModel[], index: number) => {
            return (
              <div key={index} className={styles.menu_list_container}>
                {dataItem?.map((item: SmartListModel) => {
                  return (
                    <div
                      key={item.id}
                      className={cls(styles.menu, {
                        [styles.active]: selectedList?.id === item?.id,
                      })}
                      onClick={() =>
                        handleDataClick({
                          name: item.name,
                          id: item.id ? item.id : null,
                          object: item.object ? item.object : "athlete_space",
                        })
                      }
                    >
                      <div className={styles.menu_item}>
                        <img
                          src={item.icon}
                          alt="menuIcon"
                          className={styles.icon}
                        />
                        <p>{item.name}</p>
                      </div>
                    </div>
                  );
                })}
                {index !== data.length - 1 &&
                  data[index + 1] &&
                  data[index + 1].length !== 0 && (
                    <hr className={styles.separator} />
                  )}
              </div>
            );
          })}
          {segmentsLoading && (
            <Loading isLoading={segmentsLoading} loadType="spinner" />
          )}
          <div ref={sentryRef} />
        </div>
      </div>
      <SmartListsUpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleUpgradeModal}
      />
    </>
  );
};

export default memo(SmartList);
