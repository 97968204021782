import { fetchSmartLists, clearSmartList } from "app/actions/smartList";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next';
import { shallowEqual } from "react-redux";

const SmartListPostingTitle = () => {
  const { t } = useTranslation();
  const [headerTitle, setHeaderTitle] = useState<string>("");
  const url = window.location.pathname;

  const segments = url.split("/");
  const id = segments[segments.length - 1];
  const spaceQuery = {
    smart_list_ids: [id],
  };

  const postingFormAction = useActions({
    smartList: fetchSmartLists,
    clearSmartList,
  });

  const smartLists = useSelector(
    (state: RootState) => state.smartList.smartListsData, shallowEqual
  );

  useEffect(() => {
    postingFormAction.smartList(spaceQuery);
    // return () => postingFormAction.clearSmartList();
  }, []);

  useMemo(() => {
    for (const smartList in smartLists) {
      if (smartLists[smartList].id.includes(id)) {
        setHeaderTitle(smartLists[smartList].name);
      }
    }
  }, [smartLists]);

  return (
    <h1 className={cls("panel panel-title", styles.panelTitle)}>
      {t("Post in")} {headerTitle}
    </h1>
  );
};

export default SmartListPostingTitle;
