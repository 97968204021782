/**
 * @module Reducers.UserSettings
 *
 */
import { combineReducers } from "redux";
import { RequestError } from "typedefs";
import { UserSettingsModel } from "app/models";
import { UserSettingsAction } from "app/actions/user-settings";

type UserSettingsType = {
  data: UserSettingsModel | null;
  error: RequestError | null;
  pending: boolean;
  countryCodes: string[];
  countryCodeErr: RequestError | null;
  fetchVerificationSuccess: [] | null;
  fetchVerificationError: RequestError | null;
  fetchVerificationPending: boolean;
};

export type UserSettingsState = {
  fetch: UserSettingsType;
  save: UserSettingsType;
};

const initialState: UserSettingsType = {
  data: null,
  countryCodes: null,
  countryCodeErr: null,
  fetchVerificationSuccess: null,
  fetchVerificationError: null,
  fetchVerificationPending: null,
  error: null,
  pending: false,
};

export default combineReducers<UserSettingsState, UserSettingsAction>({
  fetch: (state = initialState, action) => {
    switch (action.type) {
      case "@user-settings.success.get":
        return { ...state, data: action.payload, pending: false, error: null };
      case "@user-settings.error.get":
        return { ...state, pending: false, error: action.payload };
      case "@user-settings.pending.get":
        return { ...state, pending: true, error: null };
      case "@user-settings.countryCodes.get":
        return {
          ...state,
          countryCodes: action.payload,
          pending: false,
          error: null,
        };
      case "@user-settings.fetchCountryCodesErr.get":
        return { ...state, pending: false, error: action.payload };

      case "@user-settings.verificationSuccess.get":
        return {
          ...state,
          verificationSuccess: action.payload,
          pending: false,
          error: null,
        };
      case "@user-settings.verificationError.get":
        return { ...state, pending: false, verificationError: action.payload };
      case "@user-settings.verificationPending.get":
        return { ...state, verificationPending: true, error: null };

      default:
        return state;
    }
  },

  save: (state = initialState, action) => {
    switch (action.type) {
      case "@user-settings.success.put":
        return { ...state, pending: false, error: null };
      case "@user-settings.error.put":
        return { ...state, pending: false, error: action.payload };
      case "@user-settings.pending.put":
        return { ...state, pending: true, error: null };
      default:
        return state;
    }
  },
});
