import React from "react";
import styles from "./styles.module.scss";
import SpacePageLayout from "app/components/Layouts/SpacesPageLayout";
import { SpaceMembersModel } from "./indexModel";
import { Loading } from "app/components/Wrappers";
import SpaceMembersList from "./SpaceMembersList";
import SearchBar from "app/components/inputs/SearchBar";
import SpaceMembersInvite from "./SpaceMembersInvite";
import SpaceMemberInviteModal from "./SpaceMemberInviteModal";
import { useTranslation } from 'react-i18next';

const SpaceMembers = () => {
  const { t } = useTranslation();

  const {
    loading,
    members,
    membersRef,
    invitedMembers,
    invitedMembersRef,
    onMakeAthlete,
    onRemoveUser,
    memberSearchHandler,
    onMemberInvite,
    onCopyLink,
    onEdit,
    onResend,
    invitationError,
    handleInviteModal,
    inviteModalIsOpen,
    inviteUrl,
  } = SpaceMembersModel();

  return (
    <SpacePageLayout>
      <div className={styles.container}>
        <SpaceMembersInvite
          onMemberInvite={onMemberInvite}
          invitationError={invitationError}
        />
        {invitedMembers && invitedMembers.length > 0 && (
          <SpaceMembersList
            label={t("Invitations")}
            members={invitedMembers}
            containerRef={invitedMembersRef}
            onEdit={onEdit}
            onResend={onResend}
            onCopyLink={onCopyLink}
            onRemoveUser={onRemoveUser}
          />
        )}
        <div>
          <label>{t("Find by Name")}</label>
          <SearchBar
            className={styles.search}
            placeholder={t("Enter member name")}
            onChange={memberSearchHandler}
          />
        </div>
        {loading && !members.length && (
          <Loading isLoading={loading} loadType="spinner" />
        )}
        <SpaceMembersList
          label={t("members")}
          members={members}
          onMakeAthlete={onMakeAthlete}
          onRemoveUser={onRemoveUser}
          containerRef={membersRef}
          onEdit={onEdit}
          onResend={onResend}
          onCopyLink={onCopyLink}
        />
        {loading && members.length > 0 && (
          <Loading isLoading={loading} loadType="spinner" />
        )}
      </div>
      {inviteModalIsOpen && (
        <SpaceMemberInviteModal
          isOpen={inviteModalIsOpen}
          onClose={handleInviteModal}
          url={inviteUrl}
        />
      )}
    </SpacePageLayout>
  );
};

export default SpaceMembers;
