import React from "react";
import styles from "./styles.module.scss";
import PostModel from "app/models/PostModel";
import Post from "app/components/Post";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  posts: PostModel[];
}

const DemoTimelinePosts = ({ posts }: Props) => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  return (
    <div className={styles.container}>
      {posts?.map((post) => (
        <Post
          key={post.id}
          post={post}
          displayLocation={false}
          user={self}
          isDemo
        />
      ))}
    </div>
  );
};

export default DemoTimelinePosts;
