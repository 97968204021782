import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { RootState } from 'typedefs';

import DatePicker from 'app/components/DatePicker';
import CoachInfo from './CoachInfo';
import DaysPanel from './DaysPanel';
import DaysPanelForAllCoaches from './DayPanelForAllCoaches';
import { Loading } from "app/components/Wrappers";
import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';
import { AvailableSlotModel } from 'app/models/scheduling/AvailableSlotModel';
import { LessonSchedule } from 'app/models/EventModel';

import styles from './DateTime.module.scss';

type SlotParams = {
  coach_profile_ids: string[];
  from: string;
  to?: string;
  slot_duration: number;
  ignore_lesson_id?: number | null;
};

const timeSlotsForCoaches = (params: SlotParams) => {
  return schedulingFetchGet('/available_times', params)
    .then(rawSlots => {
      const parsedSlots = {};

      rawSlots.forEach((coachSlots: any) => {
        const coachId = coachSlots.coachProfileId;
        const slotModels = coachSlots.slots?.map(slot => {
          return new AvailableSlotModel(slot);
        }) || [];

        parsedSlots[coachId] = slotModels;
      });

      return parsedSlots;
    }).catch(e => console.log(e));
};

const getPublicTimeSlots = () => {
  return Promise.resolve([]);
};

const DateAndTime = ({
  viewDate,
  setViewDate,
  coaches,
  duration,
  isPublicPage = false,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  const lesson = useSelector((state: RootState): LessonSchedule => state.bookSession.selectedSession);

  const dayStr = viewDate.toISODate();
  const minutesDuration = duration?.durationInMinutes;

  const singleCoach = coaches.length === 1;
  const coachIdsStr = coaches
    .filter((c) => !c.skipSlots)
    .map((c) => c.id)
    .join('-');

  const timeZone = coaches[0].timeZone;

  useEffect(() => {
    if(!minutesDuration) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let params: SlotParams = {
      slot_duration: minutesDuration,
      from: dayStr,
      coach_profile_ids: !isPublicPage
        ? coachIdsStr.split('-')
        : coachIdsStr.split('-'),

      ignore_lesson_id: lesson?.id,
    };

    if (singleCoach) {
      const date = DateTime.fromISO(dayStr, { zone: timeZone }).startOf('day');
      params = {
        ...params,
        from: date.minus({ days: 1 }).toISO(),
        to: date.plus({ days: 1 }).endOf('day').toISO(),
      };
    }

    if (!isPublicPage) {
      timeSlotsForCoaches(params).then((slots: any[]) => {
        setTimeSlots(slots);
        setLoading(false);
      });
    } else {
      getPublicTimeSlots().then((slots) => {
        setTimeSlots(slots);
        setLoading(false);
      });
    }
  }, [
    coachIdsStr,
    dayStr,
    minutesDuration,
    lesson?.id,
    duration,
    singleCoach,
    timeZone,
    isPublicPage,
  ]);

  const renderLoading = () => {
    return (
      <div className={styles.coach_list}>
        <Loading isLoading loadType="spinner" />
      </div>
    );
  };

  const renderCoaches = () => {
    return (
      <div className={styles.coach_list}>
        {coaches.map((coach, index) => (
          <div key={coach.id} className={singleCoach ? styles.single_list : styles.multi_list}>
            {!singleCoach && (
              <CoachInfo coach={coach} singleCoach={singleCoach} />
            )}
            {singleCoach ? (
              <DaysPanel
                currentDay={viewDate}
                coach={coach}
                timeSlots={timeSlots[coach.id] || []}
                isPublicPage={isPublicPage}
              />
            ) : (
              <DaysPanelForAllCoaches
                currentDay={viewDate}
                coach={coach}
                timeSlots={timeSlots[coach.id] || []}
                index={index}
                isPublicPage={isPublicPage}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.datepicker}>
          <DatePicker date={viewDate} setDate={setViewDate} minDate={new Date()} />
        </div>
        {singleCoach && (
          <CoachInfo coach={coaches[0]} singleCoach={singleCoach} />
        )}
      </div>
      {loading ? renderLoading() : renderCoaches()}
    </div>
  );
};

export default DateAndTime;
