import React from "react";
import styles from "./styles.module.scss";
import useCopyMovePostModel from "./indexModel";
import MoveModal from "../MoveModal";
import CopyModal from "../CopyModal";
import LocationDropDown from "../LocationsDropdown";
import { useTranslation } from "react-i18next";
import AttachmentPreview from "../AttachmentPreview";
import PagePanel from "../PagePanel";
import TagsSelect from "../inputs/SelectBox/TagsSelect";

const CopyMovePost = () => {
  const {
    isMove,
    handleCancel,
    post,
    postPending,
    setPostDetails,
    postDetails,
    createMovePost,
    openMoveModal,
    setOpenMoveModal,
    movePostReplies,
    sendNotification,
    setMovePostReplies,
    setSendNotification,
    errorMessage,
    handleMoveClick,
    openCopyModal,
    setOpenCopyModal,
    copyRedirect,
    btnLabel,
    selectedDestinations,
    onTagsChange,
    postTags,
    isPostMove,
    handleCopyClick,
  } = useCopyMovePostModel();
  const { t } = useTranslation();

  if (postPending) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    post && (
      <>
        <MoveModal
          createMovePost={createMovePost}
          openMoveModal={openMoveModal}
          setOpenMoveModal={setOpenMoveModal}
          movePostReplies={movePostReplies}
          sendNotification={sendNotification}
          setMovePostReplies={setMovePostReplies}
          setSendNotification={setSendNotification}
        />
        <CopyModal
          countedLocation={selectedDestinations && selectedDestinations.length}
          openCopyModal={openCopyModal}
          setOpenCopyModal={setOpenCopyModal}
          copyRedirect={copyRedirect}
        />
        <div className={styles.container}>
          <PagePanel title={!isMove ? t("Copy Post") : t("Move Post")} />
          <form className={styles.posting_container}>
            <textarea
              className={styles.text_input}
              placeholder={t("Create post...")}
              value={postDetails}
              onChange={(e) => setPostDetails(e.target.value)}
            />
            {post?.tags && (
              <TagsSelect
                isCreatable
                isClearable
                value={postTags}
                onChange={onTagsChange}
                isMulti
              />
            )}
            {post?.resources?.[0] && (
              <AttachmentPreview
                uploadedMedia={post?.resources?.[0]}
              />
            )}
            <div className={styles.form_buttons_wrapper}>
              <div className={styles.location_media_wrapper}>
                <LocationDropDown hideLists={isMove} hideSmartLists={isMove} />
                <div className={styles.media_disabled}>
                  <i className="ico ico-media" />
                  <p>{t("Media")}</p>
                </div>
              </div>

              <div className={styles.save_cancel_container}>
                <button className={styles.cancel_btn} onClick={handleCancel}>
                  <div>{t("Cancel")}</div>
                </button>

                <button
                  type="button"
                  className={styles.save_btn}
                  onClick={isPostMove ? handleMoveClick : handleCopyClick}
                >
                  <div>{btnLabel}</div>
                </button>
              </div>
            </div>
            {errorMessage && <p className={styles.error_msg}>{errorMessage}</p>}
          </form>
        </div>
      </>
    )
  );
};

export default CopyMovePost;
