import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import PostViewsModal from "../PostViewsModal";
import NoEye from "../../../public/images/no-eye.svg";
import PostNoViewsModal from "../PostNoViewsModal";
import PostNoSubscriptionModal from "../PostNoSubscriptionModal";
import { useTranslation } from 'react-i18next';

interface Props {
  isDemoSpace?: boolean;
  viewsCount: number;
  viewers: any;
  postId: string;
  canSeePostData: boolean;
  planPermissions: Array<string> | string;
  CoachKind: string;
}

const PostViewsCountedView = ({
  isDemoSpace,
  viewers,
  viewsCount,
  postId,
  canSeePostData,
  planPermissions,
  CoachKind,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isNoViewsOpen, setIsNoViewsOpen] = React.useState(false);

  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  const noViewModalToggle = () => {
    setIsNoViewsOpen((prev) => !prev);
  };

  const checkPermissions = useCallback(() => {
    return (
      planPermissions &&
      planPermissions.includes("access_views") &&
      planPermissions.includes("access_likes")
    );
  }, [planPermissions]);

  if (CoachKind !== "coach") {
    return <div></div>;
  }

  if (!canSeePostData) {
    return (
      <>
        <div className={styles.noViews} onClick={noViewModalToggle}>
          <img src={NoEye} alt="icon" />
          <span>{t("Views")}</span>
        </div>
        <PostNoViewsModal
          isDemoSpace={isDemoSpace}
          onClose={noViewModalToggle}
          isOpen={isNoViewsOpen}
        />
      </>
    );
  }

  return (
    <>
      <div className={styles.container} onClick={modalHandler}>
        {viewers && viewers.length > 0 && (
          <div className={styles.avatars_container}>
            {viewers &&
              viewers.map((item: any, index: number) => {
                if (item.viewer && item.viewer.avatar) {
                  return (
                    <img
                      key={index}
                      src={item.viewer.avatar}
                      alt={item.alt}
                      className={styles.avatar}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        )}
        <div className={styles.views_counter}>
          {viewsCount > 0 && t("Seen by {{quantity}}", { quantity: viewsCount })}
        </div>
      </div>
      {checkPermissions() ? (
        <PostViewsModal
          isOpen={isOpen}
          onCloseFunc={modalHandler}
          postId={postId}
        />
      ) : (
        <PostNoSubscriptionModal isOpen={isOpen} onClose={modalHandler} />
      )}
    </>
  );
};

export default PostViewsCountedView;
