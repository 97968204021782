import { useState, useEffect } from "react";
import moment from "moment";

interface Props {
  attachment: any;
  isDemo: boolean;
}

const useVideoDuration = ({ attachment, isDemo }: Props) => {
  const [duration, setDuration] = useState("00:00");

  useEffect(() => {
    const calculateDuration = () => {
      if (isDemo) {
        if (
          attachment.versions &&
          attachment.versions.default &&
          attachment.versions.default.durationInMs
        ) {
          setDuration(
            moment.utc(attachment.versions.default.durationInMs).format("mm:ss")
          );
        } else {
          setDuration("00:00");
        }
      } else {
        if (attachment.duration) {
          setDuration(moment.utc(attachment.duration).format("mm:ss"));
        } else {
          setDuration("00:00");
        }
      }
    };

    calculateDuration();
  }, [attachment, isDemo]);

  return duration;
};

export default useVideoDuration;
