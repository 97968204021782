/** @module actions */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { syncRequest, fetchPost, request, fetchGet } from "app/utils/request";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import UserModel from "app/models/UserModel";
import config from "config/environment";
import TeamGroupModel from "app/models/TeamGroupModel";

export type ErrObj = {
  teamGroupId: string;
  error: string;
};

export type ImportModel = {
  id?: string;
  status?: string;
  importedMembersCount?: number;
  totalMembersCount?: number;
  teamGroupId?: string;
};

export type MemberModel = {
  email?: string;
  name?: string;
  number?: string;
  position?: string;
  role?: string;
};

export type BatchResult = {
  success: ImportModel[];
  error: ErrObj[];
};

const importPending = () => action("@team-group-member-import.post.pending");
const importStatus = (status: ImportModel) =>
  action("@team-group-member-import.post.status", status);
const importDone = (data: BatchResult) =>
  action("@team-group-member-import.post.done", data);
export const inviteModalType = (type: GroupInviteModalTypes) =>
  action("@team-group-member-import.invitationModalType", type);
export const inviteModalIsOpen = (isOpen: boolean) =>
  action("@team-group-member-import.isOpenSuccessInviteModal", isOpen);
const inviteModalPending = () =>
  action("@team-group-member-import.pendingInviteModal");
export const membersToInvite = (members: UserModel[]) =>
  action("@team-group-member-import.membersToInvite", members);
export const selectTeams = (teamIds: TeamGroupModel[]) =>
  action("@team-group-member-import.selectTeams", teamIds);
const inviteModalError = (err: string) =>
  action("@team-group-member-import.errorInviteModal", err);
export const setSelectedMembersRole = (role: string) =>
  action("@team-group-member-import.selectedMembersRole", role);
export const clearState = () => action("@team-group-member-import.clear");

export function importTeamGroupMembers(
  ids: string[],
  memberships: MemberModel[],
  teamGroupIds: string[]
) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    dispatch(importPending());

    const result: BatchResult = {
      success: [],
      error: [],
    };

    const imports: ImportModel[] = [];

    return Promise.all(
      ids.map((teamGroupId: string) => {
        return fetchPost(
          `/team_groups/${teamGroupId}/memberships/imports`,
          { memberships, teamGroupIds },
          { version: 2 }
        )
          .then(
            (model: ImportModel) => (
              imports.push({ ...model, teamGroupId }), model
            )
          )
          .catch(
            (e: any) => (
              result.error.push({ teamGroupId, error: e }), undefined
            )
          );
      })
    ).then(() => {
      return Promise.all(
        result.success.map((item) => {
          return new Promise((resolve) => {
            syncRequest({
              request: {
                path: `/team_groups/${item.teamGroupId}/memberships/import/${item.id}`,
                query: {},
                options: { version: 2 },
              },
              success: (model: ImportModel) => {
                if (model.status === "in_progress") {
                  dispatch(importStatus(model));
                  return 1;
                } else {
                  result.success.push(item);
                  dispatch(importStatus(model));
                  // @ts-ignore
                  resolve();
                  return 0; // stop sync
                }
              },
            });
          });
        })
      ).then(() => dispatch(importDone(result)));
    });
  };
}

export function inviteTeamGroupMembers(
  teamId: string,
  memberships: MemberModel[],
  teamGroupIds: string[]
) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    const url =
      config.API_SERVER + `/api/v2/teams/${teamId}/memberships/imports`;
    const promise = request(
      url,
      "POST",
      { memberships, teamGroupIds },
      { version: 2 }
    );
    promise.catch((err: ImportModel | null) => dispatch(importStatus(err)));
    return promise;
  };
}

export function fetchInvitedTeamGroupMembers(teamId: string, importId: string) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    const url =
      config.API_SERVER +
      `/api/v2/teams/${teamId}/memberships/imports/${importId}`;
    const promise = request(url, "GET", {}, { version: 2 });
    promise.then((result) => dispatch(importDone(result)));
    promise.catch((err: ImportModel | null) => dispatch(importStatus(err)));
    return promise;
  };
}

export function fetchJoinableTeam(teamId: string) {
  return () => {
    return fetchGet("team_groups/joinable", { teamId });
  };
}

export function fetchJoinTeam(teamId: string) {
  return () => {
    return fetchGet("team_groups/join", { teamId });
  };
}

export function fetchInviteUrl(
  teamId: string,
  role: "coach" | "player" | "viewer"
) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    const url = config.API_SERVER + `/api/v2/teams/${teamId}/short_link`;
    const promise = request(url, "POST", { role }, { version: 2 });
    promise.catch((err: ImportModel | null) => dispatch(importStatus(err)));
    return promise;
  };
}

export function uploadInvitationFile({
  teamId,
  file,
  team_group_ids,
}: {
  teamId: string;
  file: File;
  team_group_ids?: string[];
}) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    dispatch(importPending());

    const obj: any = { file };

    if (team_group_ids) {
      obj["team_group_ids"] = team_group_ids;
    }

    const url =
      config.API_SERVER +
      `/api/v2/teams/${teamId}/memberships/imports/from_file`;
    const promise = request(url, "POST", { ...obj }, { version: 2 });
    promise.catch((err: ImportModel | null) => dispatch(importStatus(err)));
    return promise;
  };
}

export function getUploadInvitationFile(teamId: string, responseId: string) {
  return (dispatch: Dispatch<TeamGroupMemberImportActions>) => {
    dispatch(importPending());
    const url =
      config.API_SERVER +
      `/api/v2/teams/${teamId}/memberships/imports/${responseId}`;
    const promise = request(url, "GET", {}, { version: 2 });
    promise.catch((err: ImportModel | null) => dispatch(importStatus(err)));
    return promise;
  };
}

/*** return actions ***/

const thisActions = {
  importPending,
  importStatus,
  importDone,
  setSelectedMembersRole,
  inviteModalType,
  inviteModalIsOpen,
  inviteModalError,
  inviteModalPending,
  membersToInvite,
  selectTeams,
  clearState,
};

export type TeamGroupMemberImportActions = ActionType<typeof thisActions>;

export const actions = {
  importTeamGroupMembers,
};
