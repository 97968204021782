import React from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import { useSelector } from "app/utils/hooks";
import DropdownButton from "app/components/DropdownButton";
import { Link } from "react-router-dom";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
// import acountGroupIcon from "../../../public/images/account-group.svg";

interface Props {
  children: React.ReactNode;
}

interface MenuTypes {
  label: string;
  icon: string;
  url: string;
  role: string[];
  permission?: boolean;
}

const AccountWrap = ({ children }: Props) => {
  const { t } = useTranslation();
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );

  const accountSettingsRoute: MenuTypes[] = [
    {
      label: t("Profile"),
      icon: "tab-icon ico ico-tspace",
      url: "/account/edit",
      role: ["coach", "athlete", "other"],
    },
    {
      label: t("Coach Profile"),
      // todo: fix icon
      icon: "tab-icon ico ico-grouped-people-outline",
      url: "/account/coach-profile",
      role: ["coach"],
    },
    {
      label: t("Password"),
      icon: "tab-icon ico ico-lock-o",
      url: "/account/password",
      role: ["coach", "athlete", "other"],
    },
    {
      label: t("Notifications"),
      icon: "tab-icon ico ico-notifications-o",
      url: "/account/settings",
      role: ["coach", "athlete", "other"],
    },
    {
      label: t("Tags"),
      icon: "tab-icon ico ico-tags-o",
      url: "/account/tags",
      role: ["coach", "athlete", "other"],
    },
    {
      label: t("Billing"),
      icon: "tab-icon ico ico-dollar",
      url: "/account/billing_info",
      role: ["coach", "athlete", "other"],
      permission: user?.hasBillingInfo,
    },
    {
      label: t("Subscriptions"),
      icon: "tab-icon ico ico-coach",
      url: "/account/subscriptions",
      role: ["coach", "other"],
    },
  ];

  const location = window.location.pathname;

  if (!user) {
    return null;
  }

  const renderDropdownMenu = () => {
    return (
      <DropdownButton
        btnContent={
          <i className="ico ico-cog btn btn-outline-secondary dropdown-toggle mobile-only-large" />
        }
        dropdownClassname={cls(styles.dropdown_btn, "mobile-only-large")}
        hideButtonStyles
        closeOnClick
      >
        <div className={styles.mobile_menu_list_container}>
          {accountSettingsRoute.map((menu: MenuTypes) => {
            if (
              !menu.role.includes(user.kind) ||
              (menu.permission !== undefined && !menu.permission)
            ) {
              return null;
            }
            return (
              <Link
                key={menu.url}
                className={styles.mobile_menu_list}
                to={menu.url}
              >
                <p>{menu.label}</p>
              </Link>
            );
          })}
        </div>
      </DropdownButton>
    );
  };

  return (
    <div className={cls(styles.container, "account-settings-container")}>
      <GettingStartedProgress />
      <PagePanel title={t("Settings")} headerChildren={renderDropdownMenu()} />

      <div className={styles.content_wrapper}>
        <div className={styles.left_nav_container}>
          <div className={cls(styles.nav_container, "desktop-only-large")}>
            {accountSettingsRoute.map((menu: MenuTypes) => {
              if (
                !menu.role.includes(user.kind) ||
                (menu.permission !== undefined && !menu.permission)
              ) {
                return null;
              }
              return (
                <div
                  key={menu.url}
                  className={cls(styles.nav_item, {
                    [styles.active]: location === menu.url,
                  })}
                >
                  <Link
                    className={cls("f f-middle", styles.link)}
                    to={menu.url}
                  >
                    <i className={cls(styles.menu_icon, menu.icon)}></i>
                    {menu.label}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.content_container}>{children}</div>
      </div>
    </div>
  );
};

export default AccountWrap;
