import React from 'react';

import styles from './styles.module.scss';

type PropsDef = {
  event: any,
  timeText: string,
};

export default function LessonSchedule(props: PropsDef) {
  const { startTimeString, durationInMinutes } = props.event.extendedProps;
  return (
    <div className={`${styles.event_container} ${durationInMinutes < 30 ? styles.small_event : ''}`}>
      <div className={styles.lesson_schedule_bar}></div>

      <div className={styles.lesson_schedule_event}>
        <div className={styles.event_title}>
          {props.event.title}
        </div>
        <div className={styles.event_time}>
          {startTimeString}
        </div>
      </div>
    </div>
  );
}
