import { useEffect, useState, useRef } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface ApiParams {
  [key: string]: any;
}

interface PagerProps {
  api: (id?: string, params?: ApiParams, signal?: AbortSignal) => any;
  query: ApiParams;
  totalPages: number;
  currentPage: number;
  fetchId?: string;
  afterFetch?: (data: any) => void;
}

export const usePager = ({
  api,
  fetchId,
  currentPage,
  query,
  totalPages,
  afterFetch,
}: PagerProps) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(currentPage);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (pageNumber?: number) => {
    setLoading(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      let postQuery = {
        ...query,
      };

      if (query.limit && query.limit > 0) {
        postQuery = {
          ...postQuery,
          skip,
        };
      } else {
        postQuery = {
          ...postQuery,
          page: pageNumber,
        };
      }

      const res = await api(
        fetchId,
        postQuery,
        abortControllerRef.current.signal
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData: any) => [...prevData, ...res]);
            if (afterFetch) {
              afterFetch(res);
            }
            setHasMore(res.length >= totalPages);
          } else {
            setData(res);
          }
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (!loading) {
      if (query && query.limit) {
        fetchPage();
        setSkip((prevSkip) => prevSkip + query.limit);
      } else {
        fetchPage(page + 1);
        setPage(page + 1);
      }
    }
  };

  const initialLoad = () => {
    setData([]);
    if (query && query.limit) {
      fetchPage();
      setSkip(0 + query.limit);
    } else {
      fetchPage(1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: "0px 0px 0px 0px",
  });

  useEffect(() => {
    initialLoad();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setPage(1);
      setHasMore(true);
      setLoading(false);
      setError(null);
    };
  }, []);

  return {
    data,
    setData,
    setHasMore,
    loading,
    currentPage: page,
    error,
    sentryRef,
    fetchPage,
    initialLoad,
  };
};
