import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../ModalLayout/ModalLayout";
import { cls } from "app/utils";
import smartListsImg from "../../../../public/images/smart-list/smart_lists_modal.png";
import { useTranslation } from "react-i18next";
import { UpgradeToPro } from "app/components/paywalls";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SmartListsUpgradeModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const onUpgrade = () => {
    onClose();
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <div className={styles.container}>
          <div className={styles.content_wrapper}>
            <h1>{t("Smart lists - Available for CoachNow PRO")}</h1>
            <p>
              {t(
                "Smart lists automatically update in real time, based on the behavior of your athletes."
              )}
              <br />
              <br />
              {t(
                "Gain insight into which athletes haven't been engaged, and quickly bulk message them all at once to bring them back online."
              )}
            </p>
            <button
              className={cls("btn btn-primary", styles.button)}
              onClick={onUpgrade}
            >
              {t("Upgrade Now")}
            </button>
          </div>
          <img src={smartListsImg} alt="smart lists" />
        </div>
      </ModalLayout>
      <UpgradeToPro show={showPaywallModal} onClose={closePaywall} />
    </>
  );
};

export default SmartListsUpgradeModal;
