import React, { useState } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import TeamsSettingsModal from "./TeamsSettingsModal";

const TeamsSettings = () => {
  const [isTeamsSettingsModalOpen, setIsTeamsSettingsModalOpen] =
    useState(false);
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);

  const settingsModalHandler = () => {
    setIsTeamsSettingsModalOpen((prev) => !prev);
  };

  return (
    <>
      <ul className={styles.dropdown_list}>
        {selectedGroup.permissions.includes("edit") &&
          selectedGroup.planPermissions.includes("edit") && (
            <li onClick={settingsModalHandler}>Settings</li>
          )}
      </ul>
      {isTeamsSettingsModalOpen && (
        <TeamsSettingsModal
          isOpen={isTeamsSettingsModalOpen}
          onClose={settingsModalHandler}
        />
      )}
    </>
  );
};

export default TeamsSettings;
