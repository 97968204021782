import React from "react";
import styles from "./styles.module.scss";
import CalendarPicker from "app/components/OptionButtonGroup/CalendarPicker";
import { useTranslation } from "react-i18next";

interface CustomOpenCalendarOptionsProps {
  schedule: any;
  setFromDate: any;
  setToDate: any;
}

const CustomOpenCalendarOptions = ({
                                     schedule = null,
                                     setFromDate,
                                     setToDate,
                                   }: CustomOpenCalendarOptionsProps) => {

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const firstDay = new Date(currentYear, 0, 1);
  const lastDay = new Date(currentYear, 11, 31);

  return (
    <div className={styles.openCalendar}>
      <div className={styles.container} data-testid="custom-options">
        <div className={styles.row}>
          <div className={styles.label}>{t("From")}</div>
          <CalendarPicker
            date={schedule.startDate || firstDay}
            setDate={setFromDate}
          />
        </div>
        <div className={styles.row}>
          <div className={`${styles.label} ${styles.to_side}`}>{t("To")}</div>
          <CalendarPicker
            date={schedule.endDate || lastDay}
            setDate={setToDate}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomOpenCalendarOptions;
