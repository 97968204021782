/**
 * @module Components.Wrappers
 *
 */
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Loc } from 'app/components/helpers';
import { Spinner } from 'app/components/Loading';
import TermsOfService from 'app/components/links/TermsOfService';
import PrivacyPolicy from 'app/components/links/PrivacyPolicy';
import Styles from './styles.module.scss';
import config from 'config/environment';
import edufii_logo from 'app/images/edufii-logo.png';

type PropsDef = PropsWithChildren<{
  isLoading?: boolean;
}>;

export default function Public(props: PropsDef) {
  const navlink = (
    <Link to="/">
      <Loc value={locales.dashboard} />
    </Link>
  );

  let content = props.children;
  if (props.isLoading) {
    content = (<Spinner />);
  }

  return (
    <div className={Styles.publicWrapper}>
      <div className={Styles.navbar}>
        <span className={Styles.logo}>
          <Link to="/">
            <img src={edufii_logo} alt="CoachNow" />
          </Link>
        </span>
        <span className={Styles.navlink}>{navlink}</span>
      </div>

      <div className={Styles.content}>
        <div>{content}</div>
      </div>

      <div className={Styles.footer}>
        <div>
          <div className={Styles.right}>
            <a href={config.SUPPORT_URL} target="_blank" rel="noreferrer">
              <Loc value={locales.support} />
            </a>
            <PrivacyPolicy />
            <TermsOfService />
          </div>

          <div className={Styles.left}>
            <p className="muted">
              &copy;
              <FormattedMessage
                id="app.copyright"
                defaultMessage="{year} CoachNow"
                description="copyright statement"
                values={{ year: config.CURRENT_YEAR }}
              />
            </p>
          </div>

          <div className={Styles.clear} />
        </div>
      </div>
    </div>
  );
}

const locales = defineMessages({
  dashboard: {
    id: 'wrappers.public.dashboard',
    defaultMessage: 'Dashboard',
  },
  support: {
    id: 'wrappers.public.support',
    defaultMessage: 'Support & FAQ'
  },
});
