import { createPost, fetchPost } from "app/actions/postingForm";
import PostModel, { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onPost?: (post: PostModel) => void;
}

export const FeedPostingFormModel = ({ onPost }: Props) => {
  const feedPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const [filteredDestinations, setFilteredDestinations] = useState({
    smart_list_ids: [],
    space_id: [],
    team_ids: [],
    segment_ids: [],
    team_group_ids: [],
  });

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user.planPermissions.includes("upload_to_library") &&
      user.planPermissions.includes("post_media")
    );
  };

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations,
    shallowEqual,
  );

  useEffect(() => {
    if (selectedDestinations && selectedDestinations.length > 0) {
      selectedDestinations.forEach((obj: { id: string; object: string }) => {
        const idx =
          obj.object === "smart_list"
            ? "smart_list_ids"
            : obj.object === "athlete_space"
              ? "space_id"
              : obj.object === "team"
                ? "team_ids"
                : obj.object === "segment"
                  ? "segment_ids"
                  : "team_group_ids";

        setFilteredDestinations((prevState: any) => ({
          ...prevState,
          [idx]: [...prevState[idx], obj.id],
        }));
      });
    }
  }, [selectedDestinations]);

  // posting form submit handler
  const onSubmit = (data: PostingModel) => {
    // check if location(s) are selected in redux to use id from it
    if (selectedDestinations) {
      feedPostActions
        .createPost({ ...data, ...filteredDestinations })
        .then((res) => {
          if (!res?.scheduledAt && onPost) {
            onPost({ ...res, userAvatar: user.avatar });
          }
        });
    }
  };

  return {
    canUploadMedia,
    user,
    onSubmit,
  };
};
