import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import PostViewsCountedView from "../PostViewsCountedView";
import PostLikes from "./InnerPost/PostLikes";
import PostTags from "./InnerPost/PostTags";
import PostModel from "app/models/PostModel";
import Replies from "./InnerPost/Replies";
import { PostMedia } from "./InnerPost/PostMedia";
import PostMediaModal from "./InnerPost/PostMedia/PostMediaView/PostMediaModal";
import { canReply, canSeePostData } from "./Post";

import EditPost from "./InnerPost/EditPost";
import { cls } from "app/utils";
import { usePostModel } from "./indexModel";
import PostHeader from "./InnerPost/PostHeader";
import { usePostViewsTracking } from "app/utils/hooks/usePostViewsTracking";
import PostDetails from "./InnerPost/PostDetails";
import TogglePinModal from "../TogglePinModal";
import UserModel from "app/models/UserModel";
import PinedPostBadge from "./InnerPost/PinedPostBadge";
import { useTranslation } from "react-i18next";
interface Props {
  post: PostModel;
  user?: UserModel;
  isDemo?: boolean;
  onEdit?: (post: PostModel) => void;
  onEditTags?: (post: PostModel) => void;
  onMediaLockedClick?: () => void;
  onRemove?: (post: PostModel) => void;
  onReport?: (post: PostModel) => void;
  onPin?: () => void;
  canPinPost?: boolean;
  onUpgradeToPostClick?: () => void;
  hidePinBadge?: boolean;
  displayLocation?: boolean;
  dropdownButtonClassName?: string;
  hideReply?: boolean;
  hideLikes?: boolean;
  updateError?: boolean;
}

const Post: React.FC<Props> = ({
  post,
  user,
  isDemo,
  onEdit,
  // todo: add this onMediaLockedClick for teams and team_groups
  onRemove,
  onPin,
  canPinPost,
  onReport,
  hidePinBadge,
  displayLocation,
  dropdownButtonClassName,
  hideReply,
  hideLikes,
  updateError,
}: Props) => {
  const {
    modalHandler,
    isModalOpen,
    onCopyClick,
    onMoveClick,
    onPinClick,
    onPinHandler,
    pinModalOpen,
    isEditingPost,
    isEditingTags,
    onEditClick,
    onEditClose,
    onEditTagsClick,
    onEditTagsClose,
    onPostUpdate,
    canLikePost,
  } = usePostModel({ post, onEdit, onPin });
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>();

  const { createObserver } = usePostViewsTracking();

  useEffect(() => {
    createObserver(ref, post);
  }, [post, createObserver]);

  return (
    <>
      <div
        className={cls(styles.container, {
          // [styles.padding_bottom]: hideLike && hideViews,
        })}
        ref={ref}
        data-id={post?.id}
      >
        {!hidePinBadge && post.pinnedAt && (
          <PinedPostBadge
            pinnedByName={post.pinnedByName}
            pinnedTill={post?.pinnedTill}
          />
        )}
        <PostHeader
          post={post}
          onEditClick={onEditClick}
          onEditTagsClick={onEditTagsClick}
          onCopyClick={onCopyClick}
          displayLocation={displayLocation}
          dropdownButtonClassName={dropdownButtonClassName}
          onPinClick={onPinClick}
          onPostDelete={onRemove}
          onPostReport={onReport}
          isDemo={isDemo}
          canPinPost={canPinPost}
          onMoveClick={onMoveClick}
        />
        <div className={styles.post_inner}>
          {!isEditingPost ? (
            <div
              className={cls(
                { [styles.post_details_tags]: post.resources },
                styles.post_details_container,
              )}
            >
              {/* post text */}
              {post?.details && (
                <PostDetails isDemo={isDemo} details={post?.details} />
              )}
              {/* post tags */}
              {post.tags && <PostTags tags={post.tags} />}
            </div>
          ) : (
            <EditPost
              classNames={cls(styles.edit_post, {
                [styles.edit_post_container]: post.resources,
              })}
              post={post}
              onCancel={onEditClose}
              onUpdate={onPostUpdate}
            />
          )}

          {isEditingTags && (
            <div className={styles.tags_edit_container}>
              <EditPost
                classNames={cls(styles.edit_post, {
                  [styles.edit_post_container]: post.resources,
                })}
                post={post}
                onCancel={onEditTagsClose}
                onUpdate={onPostUpdate}
                editTags
              />
            </div>
          )}
          {post.resources && (
            <div
              className={cls(styles.post_attachment, {
                [styles.shrink]:
                  post.resources[0].type !== "image" &&
                  post.resources[0].type !== "video",
              })}
            >
              <PostMedia onClick={modalHandler} mediaData={post.resources[0]} />
            </div>
          )}
          {canLikePost() && post.published && (
            <div className={styles.like_view_container}>
              <PostViewsCountedView
                viewers={post.postViews}
                viewsCount={post.postViewsCount}
                postId={post.id}
                canSeePostData={canSeePostData(post)}
                planPermissions={post.planPermissions}
                CoachKind={user.kind}
                isDemoSpace={isDemo}
              />
              {!hideLikes && canLikePost() && (
                <PostLikes
                  postId={post.id}
                  likesCount={post.likesCount}
                  liked={post.liked}
                  likable={post.likable}
                  isDemo={isDemo}
                />
              )}
            </div>
          )}
        </div>
        {updateError && (
          <div className={styles.error}>{t("Something went wrong")}</div>
        )}
        {post.published &&
          ((!isDemo && canReply(post)) || isDemo) &&
          !hideReply && <Replies post={post} isDemo={isDemo} />}
        {isModalOpen && (
          <PostMediaModal
            onClose={modalHandler}
            isOpen={isModalOpen}
            mediaData={post.resources[0]}
          />
        )}
        {pinModalOpen && (
          <TogglePinModal
            postId={post.id}
            isOpen={pinModalOpen}
            onClose={onPinClick}
            isPinned={post.pinnedByName}
            onPinHandler={onPinHandler}
          />
        )}
      </div>
    </>
  );
};

export default Post;
