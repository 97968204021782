import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

export const defaultSelection = (bookLessonData) => {
  const { facilities, coaches, lessonTypes, pricingValues } =
    bookLessonData;
  const selectedFacility = facilities[0];
  const facilityCoaches = sortBy(coaches?.[selectedFacility?.id], [
    'order_number',
  ]);
  const selectedCoach = facilityCoaches[0];

  const selectedPricingValue = pricingValues[selectedCoach.id]?.find(
    (rate) => rate.defaultRate
  );

  const defaultLessonTypeId = selectedPricingValue?.defaultLessonTypeId;
  const selectedLessonTypes = lessonTypes?.filter(
    (lessonType) => lessonType.id === parseInt(defaultLessonTypeId, 10)
  );

  const date = DateTime.utc().plus({ days: 2 }).startOf('day');

  return {
    facility: selectedFacility,
    coach: selectedCoach,
    lessonTypes: selectedLessonTypes,
    pricingValue: selectedPricingValue,
    date,
    duration: {},
  };
};

export const selectionForLesson = (bookLessonData, lesson) => {
  const { facilities, coaches } = bookLessonData;

  const selectedCoach = coaches[lesson.facilityId].find(
    (coach) => coach.id === lesson.coachProfile.id
  );

  const selectedFacility = facilities.find(
    (facility) => facility.id === lesson.facilityId
  );

  const selectedLessonTypes = lesson?.lessonTypesIds?.map((ltid) => {
    return bookLessonData?.lessonTypes?.find((lt) => lt.id === ltid);
  });

  const selectedPricingValue = bookLessonData?.pricingValues[
    selectedCoach.id
  ]?.find((pv) => pv.id === lesson.lessonRateId);
  // selectedPricingValue.lessonTypes = selectedLessonTypes;

  const date = DateTime.fromISO(lesson.start, {
    zone: selectedCoach.timeZone,
  });

  return {
    facility: selectedFacility,
    coach: selectedCoach,
    lessonTypes: selectedLessonTypes,
    pricingValue: selectedPricingValue,
    date,
    time: lesson.time,
  };
};
