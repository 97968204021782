/**
 * @module Components.StripeForm
 *
 */
import React, { useState, FormEvent } from "react";
import { injectStripe } from "react-stripe-elements";
import { MessageDescriptor } from "react-intl";
import { cls } from "app/utils";
import { useError, usePending } from "app/utils/hooks";
import { Token, TokenResponse, ElementChangeResponse } from "app/utils/stripe";
import FormItem from "app/components/inputs/FormItem";
import { Spinner } from "app/components/Loading";
import CardName from "./CardName";
import CardNumber from "./CardNumber";
import CardExpiry from "./CardExpiry";
import CardCVC from "./CardCVC";
import PostalCode from "./PostalCode";
import styles from "./styles.module.scss";
import AddressLineOne from "./AddressLineOne";
import AddressLinetwo from "./AddressLineTwo";
import City from "./City";
import Country from "./Country";
import UsState from "./usState";
import { useTranslation } from "react-i18next";

export type PropsDef = {
  disabled?: boolean;
  cardName?: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardSecurityCode?: string;
  country?: string;
  usState?: string;
  city?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  postalCode?: string;
  submitTitle?: string;
  onToken?: (token: Token) => void;
  onCancel?: () => void;
  stripe?: { [key: string]: any };
};

type ErrorModel = {
  cardNameError?: MessageDescriptor | null;
  cardNumberError?: string | null;
  cardExpiryError?: string | null;
  cardSecurityCodeError?: string | null;
  countryError?: string;
  usStateError?: string;
  cityError?: string;
  addressLineOneError?: string;
  addressLineTwoError?: string;
  postalCodeError?: string | null;
  formError?: string | null;
};

type State = ErrorModel & {
  cardName: ElementChangeResponse | null;
  cardNumber: ElementChangeResponse | null;
  cardExpiry: ElementChangeResponse | null;
  cardSecurityCode: ElementChangeResponse | null;
  country?: ElementChangeResponse | null;
  usState?: ElementChangeResponse | null;
  city?: ElementChangeResponse | null;
  addressLineOne?: ElementChangeResponse | null;
  addressLineTwo?: ElementChangeResponse | null;
  postalCode: ElementChangeResponse | null;
};

const initError: ErrorModel = {
  cardNameError: null,
  cardNumberError: null,
  cardExpiryError: null,
  cardSecurityCodeError: null,
  countryError: null,
  usStateError: null,
  cityError: null,
  addressLineOneError: null,
  addressLineTwoError: null,
  postalCodeError: null,
  formError: null,
};

const initState: State = {
  cardName: null,
  cardNumber: null,
  cardExpiry: null,
  country: null,
  // @ts-ignore
  usState: null,
  city: null,
  cardSecurityCode: null,
  addressLineOne: null,
  addressLineTwo: null,
  postalCode: null,
};

function StripeForm(props: PropsDef) {
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const [pending, startPending, stopPending] = usePending();
  const [errors, addErrors, clearErrors] = useError(initError);
  const [state, setState] = useState(() =>
    mergePropsWithState(props, initState),
  );

  const formClass = cls(styles.stripeCardForm, isReady ? "" : "hidden");
  const disabled = props.disabled || pending.status;

  const cancelUpgrade = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const onStateChange = (name: string, el: ElementChangeResponse) => {
    clearErrors();
    setState({ ...state, [name]: el });
  };

  const handleCreateToken = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.stripe == null) {
      throw new Error(
        "cannont call handleCreateToken with out initializing stripe first!",
      );
    }

    const { cardNumber, cardExpiry, cardSecurityCode } = state;

    // trim cardName postalCode to remove extra spaces
    const cardName =
      state.cardName && state.cardName.value
        ? (state.cardName.value as string).trim()
        : null;
    const country =
      state.country && state.country.value
        ? (state.country.value as string)
        : null;
    const usState =
      state.usState && state.usState.value
        ? (state.usState.value as string)
        : null;
    const city =
      state.city && state.city.value
        ? (state.city.value as string).trimLeft()
        : null;
    const addressLineOne =
      state.addressLineOne && state.addressLineOne.value
        ? (state.addressLineOne.value as string).trimLeft()
        : null;
    const addressLineTwo =
      state.addressLineTwo && state.addressLineTwo.value
        ? (state.addressLineTwo.value as string).trimLeft()
        : null;
    const postalCode =
      state.postalCode && state.postalCode.value
        ? (state.postalCode.value as string).trim()
        : null;

    // validate input for errors
    const errors = validate(
      cardName,
      cardNumber,
      cardExpiry,
      cardSecurityCode,
      country,
      usState,
      city,
      addressLineOne,
      // addressLineTwo,
      postalCode,
      t,
    );
    if (Object.keys(errors).length) {
      addErrors(errors);
      return;
    }

    startPending();
    props.stripe
      .createToken({
        name: cardName,
        address_country: country,
        address_city: city,
        address_state: usState ? usState : "",
        address_line1: addressLineOne,
        address_line2: addressLineTwo ? addressLineTwo : "",
        address_zip: postalCode,
      })
      .then((token: TokenResponse) => {
        if (token.error) {
          let error: ErrorModel = { formError: token.error.message };
          if (token.error.code === "incomplete_zip") {
            error = { postalCodeError: token.error.message };
          } else if (token.error.code === "card_declined") {
            error = { cardNumberError: token.error.message };
          } else if (token.error.code === "expired_card") {
            error = { cardExpiryError: token.error.message };
          }
          addErrors(error);
          return;
        } else {
          if (props.onToken) {
            props.onToken(token.token);
          }
          return;
        }
      })
      .finally(() => stopPending());
  };

  const handleKeyboard = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      handleCreateToken(e);
    }
  };

  return (
    <div className={cls(styles.rcStripeForm, disabled ? "disable" : "")}>
      {!isReady && <Spinner />}

      <form
        className={formClass}
        onKeyDown={handleKeyboard}
        onSubmit={handleCreateToken}
        target="_self"
      >
        <CardName
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardName", el)
          }
          value={props.cardName}
          error={errors.cardNameError}
          disabled={disabled}
        />
        <CardNumber
          onReady={() => setIsReady(true)}
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardNumber", el)
          }
          value={props.cardNumber}
          error={errors.cardNumberError}
          disabled={disabled}
        />
        <CardExpiry
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardExpiry", el)
          }
          value={props.cardExpiry}
          error={errors.cardExpiryError}
          disabled={disabled}
        />
        <CardCVC
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardSecurityCode", el)
          }
          value={props.cardSecurityCode}
          error={errors.cardSecurityCodeError}
          disabled={disabled}
        />
        <Country
          onChange={(el: ElementChangeResponse) => onStateChange("country", el)}
          value={props.country}
          error={errors.countryError}
          disabled={disabled}
        />
        {state.country &&
          state.country.value &&
          state.country.value === "US" && (
            <UsState
              onChange={(el: ElementChangeResponse) =>
                onStateChange("usState", el)
              }
              value={props.usState}
              error={errors.usStateError}
              disabled={disabled}
            />
          )}
        <City
          onChange={(el: ElementChangeResponse) => onStateChange("city", el)}
          value={props.city}
          error={errors.cityError}
          disabled={disabled}
        />
        <AddressLineOne
          onChange={(el: ElementChangeResponse) =>
            onStateChange("addressLineOne", el)
          }
          value={props.addressLineOne}
          error={errors.addressLineOneError}
          disabled={disabled}
        />
        <AddressLinetwo
          onChange={(el: ElementChangeResponse) =>
            onStateChange("addressLineTwo", el)
          }
          value={props.addressLineTwo}
          // error={errors.addressLineTwoError}
          disabled={disabled}
        />
        <PostalCode
          onChange={(el: ElementChangeResponse) =>
            onStateChange("postalCode", el)
          }
          value={props.postalCode}
          error={errors.postalCodeError}
          disabled={disabled}
        />

        <FormItem className={styles.formControl} error={errors.formError}>
          <button
            tabIndex={-1}
            className="btn btn-outline-secondary"
            onClick={(e: FormEvent<HTMLButtonElement>) => cancelUpgrade(e)}
            disabled={disabled}
          >
            {t("Cancel")}
          </button>

          <button type="submit" className="btn btn-primary" disabled={disabled}>
            {props.submitTitle}
          </button>
        </FormItem>
      </form>
    </div>
  );
}

export default injectStripe(StripeForm);

const mergePropsWithState = (props: PropsDef, state: State): State => {
  return Object.assign({}, state, {
    cardName: props.cardName,
    cardNumber: props.cardNumber,
    cardExpiry: props.cardExpiry,
    cardSecurityCode: props.cardSecurityCode,
    country: props.country,
    usState: props.usState,
    city: props.city,
    addressLineOne: props.addressLineOne,
    addresslineTwo: props.addressLineTwo,
    postalCode: props.postalCode,
  });
};

const validate = (
  cardName: string | null,
  cardNumber: ElementChangeResponse | null,
  cardExpiry: ElementChangeResponse | null,
  cardSecurityCode: ElementChangeResponse | null,
  country: string | null,
  usState: string | null,
  city: string | null,
  addressLineOne: string | null,
  // addressLineTwo: string | null,
  postalCode: string | null,
  t: any,
) => {
  const errors: ErrorModel = {};

  if (!(cardName && cardName.length)) {
    errors.cardNameError = {
      id: "error.required.card-name",
      defaultMessage: t(
        "Please enter the name that appears on your credit card.",
      ),
    };
  }

  if (!cardNumber) {
    errors.cardNumberError = t("Please enter your credit card number.");
  }

  if (!cardExpiry) {
    errors.cardExpiryError = t("Please enter your cards expiration date.");
  }

  if (!cardSecurityCode) {
    errors.cardSecurityCodeError = t(
      "Please enter the security code that appears on your card.",
    );
  }

  if (!country) {
    errors.countryError = t("Please select a country.");
  }

  if (country && country === "US" && !usState) {
    errors.usStateError = t("Please select state.");
  }

  if (!city) {
    errors.cityError = t("Please enter a valid city.");
  }

  if (!addressLineOne) {
    errors.addressLineOneError = t("Please enter a valid city.");
  }

  // if (!addressLineTwo) {
  //   errors.addressLineTwoError = formatMessage(
  //     LOCALES.error_required_address_line_two
  //   );
  // }

  if (!postalCode) {
    errors.postalCodeError = t("A postal code is required.");
  }
  return errors;
};
