import UserModel from "app/models/UserModel";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

interface Props {
  member: UserModel;
  onMemberUpdate?: (member: UserModel) => void;
}

export const useEditMemberModal = ({ member, onMemberUpdate }: Props) => {
  const { t } = useTranslation();

  const memberSettingsData = [
    {
      label: t("Coach/Admin"),
      type: "coach",
      description: t("Can post and interact, manage the Group, manage Group members, and manage Channels."),
    },
    {
      label: t("Member"),
      type: "player",
      description: t("Can post and interact."),
    },
    {
      label: t("Viewer"),
      type: "viewer",
      description: t("View only."),
    },
  ];

  const [memberData, setMemberData] = useState<UserModel>({
    ...member,
    type: member.type || "viewer",
  });

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setMemberData({ ...memberData, name: value });
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setMemberData({ ...memberData, email: value });
  };

  const handleNicknameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setMemberData({ ...memberData, nickname: value });
  };

  const typeHandler = (type: string) => {
    setMemberData({ ...memberData, type });
  };

  const onSave = () => {
    onMemberUpdate(memberData);
  };

  return {
    memberSettingsData,
    handleNameChange,
    handleEmailChange,
    handleNicknameChange,
    typeHandler,
    memberData,
    onSave,
  };
};
