/**
 * @module Actions
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { request } from "app/utils/request";
import { CoachProfileModel } from "app/models";
import config from "config/environment";

const fetchPending = () => action("@coach-profile.pending.get");
const fetchError = (err: any) => action("@coach-profile.error.get", err);
const fetchSuccess = (data: CoachProfileModel) =>
  action("@coach-profile.success.get", data);
export const clearCoachProfile = () => action("@coach-profile.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  clearCoachProfile,
};

export type CoachProfileAction = ActionType<typeof thisActions>;

export function fetchCoachProfile(coach_id: string) {
  return (dispatch: Dispatch<CoachProfileAction>) => {
    dispatch(fetchPending());

    const url = config.API_SERVER + `/api/v2/coach_profiles/${coach_id}`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((res) => dispatch(fetchSuccess(res)));
    promise.catch((err) => dispatch(fetchError(err)));

    return promise;
  };
}
export function updateCoachProfile(coach_profile: CoachProfileModel) {
  return (dispatch: Dispatch<CoachProfileAction>) => {
    dispatch(fetchPending());

    const url = config.API_SERVER + `/api/v2/coach_profile`;
    const promise = request(url, "PUT", { coach_profile }, { version: 1 });
    promise.then((res) => dispatch(fetchSuccess(res)));
    promise.catch((err) => dispatch(fetchError(err)));

    return promise;
  };
}

export const actions = {
  fetchCoachProfile,
  updateCoachProfile,
};
