/**
 * @module Components.Subscription
 *
 */
import React from "react";
import moment from "moment";
import { RootState } from "app/reducers";
import { useSelector } from "react-redux";

import { Spinner } from "app/components/Loading";
import { Loc } from "app/components/helpers";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BillingInfo = () => {
  return (
    <div className={styles.billingInfoView}>
      <RenderChild />
    </div>
  );
};

function RenderChild() {
  const { t } = useTranslation();
  const history = useHistory();
  const route = (path: string) => history.push(path);
  const state = useSelector((_state: RootState) => _state);

  if (state.paymentInfo.error != null) {
    return (
      <div className={styles.error}>
        <Loc value={state.paymentInfo.error} />
      </div>
    );
  }

  if (state.paymentInfo.data != null) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          {t("Credit Card on file")}
        </div>

        <div className={styles.details}>
          <div className={styles.rowItem}>
            <span>
             {t("Credit Card")}
            </span>
            <span>
              XXXX XXXX XXXX {state.paymentInfo.data.cardLast4} / &nbsp;
              {state.paymentInfo.data.cardType}
            </span>
            <div className={styles.clear}></div>
          </div>

          <div className={styles.rowItem}>
            <span>
              {t("Valid Till")}
            </span>
            <span>
              {moment(state.paymentInfo.data.cardExpiry).format("MM / YY")}
            </span>
            <div className={styles.clear}></div>
          </div>
        </div>

        <a
          className="btn btn-outline-secondary"
          onClick={() => route("/account/billing_info/edit")}
        >
         {t("Update Credit Card")}
        </a>
      </div>
    );
  }

  return <Spinner />;
}

export default BillingInfo;
