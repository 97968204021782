import React from "react";
import styles from "./styles.module.scss";
import nullFeedIcon from "../../../../public/images/null-feed.png";
import { useTranslation } from "react-i18next";
import { cls } from "app/utils";

interface Props {
  title?: string;
  description?: string;
  image?: string;
  className?: string;
}

const NoPosts: React.FC<Props> = ({
  title,
  description,
  image = nullFeedIcon,
  className,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cls(styles.container, className)}>
      <img src={image} alt="null result" />
      <h3>{title ?? t("No Posts Yet")}</h3>
      <p>{description ?? t("All posts you and other members make will appear here.")}</p>
    </div>
  );
};

export default NoPosts;
