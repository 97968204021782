/**
 * @module Components.Wrappers
 *
 */
import React, { PropsWithChildren } from "react";
import { Redirect } from "react-router-dom";
import { useStoreActions } from "app/utils/hooks";
import { fetchSelf } from "app/actions/user";
import Authenticated from "./Authenticated";
import { Wrapper as OnboardingWrapper } from "app/components/Onboarding";
import { hasCoachPlus, hasCoachPlusTrial } from "app/utils/subscription";

type PropsDef = PropsWithChildren<{
  ignoreReturn?: boolean;
  trialNotAllowed?: boolean;
}>;

/**
 * @class ProRedirect
 *
 */
export default function ProRedirect(props: PropsDef) {
  const { user } = useStoreActions({ user: fetchSelf });
  const pending = user.pending || user.data == null;

  let isAllowed = false;
  if (!pending) {
    if (hasCoachPlus(user.data)) {
      // has coach plus subscription
      if (props.trialNotAllowed) {
        // disallow trials
        if (!hasCoachPlusTrial(user.data)) {
          // has coach plus trial
          isAllowed = true;
        }
      } else {
        isAllowed = true;
      }
    }
  }

  let child = props.children;
  if (pending) {
    child = <OnboardingWrapper loading={true} />;
  } else if (!isAllowed) {
    // not authorized, redirect to signin page
    return <Redirect to="/" />;
  }

  return <Authenticated>{child}</Authenticated>;
}
