import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import TabButton from "app/components/Button/TabButton";
import { cls } from "app/utils";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import ApplyTemplateDropdown from "../Button/ApplyTemplateDropdown";
import DropdownButton from "../DropdownButton";
import { useActions } from "app/utils/hooks";
import { deleteSpaceTemplate } from "app/actions/spaceTemplate";
import customPrompt from "../inputs/SelectBox/helpers/customPrompt";

interface Props {
  hideTabs?: boolean;
  hideHeaderMenu?: boolean;
}

const SpaceTemplatesHeader = ({ hideHeaderMenu, hideTabs }: Props) => {
  const { selectedTemplate } = useSelector(
    (state: RootState) => state.spaceTemplate, shallowEqual
  );
  const templateActions = useActions({ deleteSpaceTemplate });

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const location = useLocation();

  const { id: templateId } = useParams<{ id: string }>();

  const breadCrumb = useCallback(() => {
    return (
      <span className={styles.breadcrumb}>
        <Link to="/space_templates">Templates</Link>&nbsp; / &nbsp;
        <Link to={`/space_templates/${templateId}/posts`}>
          {selectedTemplate?.name}
        </Link>{" "}
        {location.pathname.includes("reorder") && <>&nbsp; / &nbsp; Reorder</>}
      </span>
    );
  }, [location.pathname, selectedTemplate, templateId]);

  const onTimelineClick = () => {
    route(`/space_templates/${templateId}/posts`);
  };

  const onHistoryClick = () => {
    route(`/space_templates/${templateId}/history`);
  };

  const onDelete = useCallback(
    (id: string) => {
      customPrompt({
        message: `Are you sure you would like to delete ${selectedTemplate?.name}?`,
        onYes: () => {
          templateActions
            .deleteSpaceTemplate(id)
            .then(() => route("/space_templates"));
        },
        onCancel: () => {
          return;
        },
      });
    },
    [templateId]
  );

  const menuList = [
    {
      label: "Edit Name",
      onClick: () => history.push(`/space_templates/${templateId}/edit`),
    },
    {
      label: "Delete",
      className: styles.delete,
      onClick: () => onDelete(templateId),
    },
    {
      label: "Reorder",
      onClick: () =>
        history.push(`/space_templates/${templateId}/posts/reorder`),
    },
  ];

  const renderMenu = () => {
    return (
      <ul className={styles.menu}>
        {menuList.map((item) => (
          <li
            key={item.label}
            className={cls(styles.menu_item, item.className && item.className)}
            onClick={item.onClick}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  const renderTemplateMenus = () => {
    return (
      <div className={styles.template_menu_wrapper}>
        <ApplyTemplateDropdown
          templateId={templateId}
          templateName={selectedTemplate?.name}
          className={styles.apply_template_btn}
        />
        <DropdownButton
          btnContent={(
            <button className="btn btn-outline-secondary dropdown-toggle needsclick ng-isolate-scope">
              <i className="icon-cog" />
              &nbsp;
              <i className="ico ico-chevron" />
            </button>
    )}
        >
          {renderMenu()}
        </DropdownButton>
      </div>
    );
  };

  return (
    <PagePanel
      panelClassName={cls({ [styles.page_panel]: !hideTabs })}
      title={selectedTemplate?.name}
      subTitle={breadCrumb()}
      headerChildren={!hideHeaderMenu && renderTemplateMenus()}
      headerWrapperClassName={styles.header_wrapper}
    >
      {!hideTabs && (
        <div className={styles.navbar_wrapper}>
          {onTimelineClick && (
            <TabButton
              label={"Posts"}
              icon={<i className={cls("tab-icon ico ico-feed-o")} />}
              isActive={window.location.pathname.includes("posts")}
              onClick={onTimelineClick}
              className={styles.mobile}
              labelClassName={styles.tab_label}
            />
          )}
          {onHistoryClick && (
            <TabButton
              label={"History"}
              icon={<i className={cls("tab-icon ico ico-templates")} />}
              isActive={window.location.pathname.includes("history")}
              onClick={onHistoryClick}
              className={styles.mobile}
              labelClassName={styles.tab_label}
            />
          )}
        </div>
      )}
    </PagePanel>
  );
};

export default SpaceTemplatesHeader;
