import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PhoneInput from "react-phone-input-2";
import { IUserPhone } from "app/interfaces/UserTypes";
import { useActions } from "app/utils/hooks";
import { fetchSpaceInvitationById } from "app/actions/space";
import UserModel from "app/models/UserModel";
import { editInvitation } from "app/actions/invitation";
import Button from "app/components/Button/ButtonVariant";
import { Loading } from "app/components/Wrappers";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const EditSpaceInvitation = () => {
  const { t } = useTranslation();
  const inviteEditActions = useActions({
    fetchSpaceInvitationById,
    editInvitation,
  });
  const [selectedUser, setSelectedUser] = useState<UserModel>(null);
  const [invitationError, setInvitationError] = useState<string | null>(null);

  const url = window.location.pathname;
  const parts = url.split("/");
  const spaceId = parts[parts.indexOf("spaces") + 1];
  const userId = parts[parts.indexOf("invitations") + 1];

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (spaceId) {
        try {
          const res = await inviteEditActions.fetchSpaceInvitationById(
            spaceId,
            userId
          );
          setSelectedUser(res);
        } catch (error) {
          console.error("Failed to fetch space:", error);
        }
      }
    };

    fetchData();

    return () => {
      setSelectedUser(null);
    };
  }, [spaceId, userId, inviteEditActions]);

  const onChangeHandler = (user: { [key: string]: any }) => {
    setSelectedUser({ ...selectedUser, ...user });
  };

  const setPhoneNumber = (value: string, data: IUserPhone) => {
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();
    onChangeHandler({ phone, phoneCountry });
  };

  const handleSave = async () => {
    try {
      await inviteEditActions.editInvitation(selectedUser);
      setInvitationError(null);
    } catch (error) {
      setInvitationError(t("An error occurred while saving the invitation."));
    }
  };

  const cancelHandler = () => {
    route(`/spaces/${spaceId}/members`);
  };

  if (!selectedUser) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <h1 className={styles.header_title}>{t('Edit Invitation')}</h1>
        <div className={styles.section_wrapper}>
          <label htmlFor="role">{t("Role")}</label>
          <select
            name="role"
            id="role"
            value={selectedUser.role}
            onChange={(e) => onChangeHandler({ role: e.target.value })}
          >
            <option value="coach">{t("Coach")}</option>
            <option value="contributor">{t("Member")}</option>
          </select>
        </div>
        <div className={styles.section_wrapper}>
          <label htmlFor="username">{t("Name")}</label>
          <input
            type="text"
            name="username"
            id="username"
            value={selectedUser.name || ""}
            placeholder={t("Name")}
            onChange={(e) => onChangeHandler({ name: e.target.value })}
          />
        </div>
        {selectedUser.email !== null && (
          <div className={styles.section_wrapper}>
            <label htmlFor="email">{t("Email")}</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder={t("Email")}
              value={selectedUser.email}
              onChange={(e) => onChangeHandler({ email: e.target.value })}
            />
          </div>
        )}
        {selectedUser.phoneNormalized && (
          <div className={styles.section_wrapper}>
            <label htmlFor="phone">{t("Phone")}</label>
            <PhoneInput
              country={selectedUser.phoneCountry.toLowerCase()}
              value={selectedUser.phoneNormalized}
              autoFormat
              onChange={setPhoneNumber}
              containerClass={styles.phone_input_container}
              inputClass={styles.form_control}
            />
          </div>
        )}
        {invitationError && <p className={styles.error}>{invitationError}</p>}
        <div className={styles.button_wrapper}>
          <Button
            title={t("Save changes")}
            buttonType="primary"
            onClickFunc={handleSave}
          />
          <Button
            title={t("Cancel")}
            onClickFunc={cancelHandler}
            buttonStyles={styles.cancel_btn}
          />
        </div>
      </div>
    </>
  );
};

export default EditSpaceInvitation;
