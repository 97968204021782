/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import SegmentList from "app/components/SegmentList";
import { useActions, useSelector } from "app/utils/hooks";
import { createSegment } from "../../actions/segments";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import Button from "app/components/Button/ButtonVariant";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import SegmentsPaywall from "app/components/paywalls/Segments";
import { UpgradeToPro } from "app/components/paywalls";

const Segments = () => {
  const { t } = useTranslation();
  const { self: user, pending } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const segmentActions = useActions({ createSegment });
  const [newListModalOpen, setNewListModalOpen] = useState<boolean>(false);
  const [newListName, setNewListName] = useState<string | null>(null);
  const [newListError, setNewListError] = useState<boolean>(false);
  const [canCreateSegments, setCanCreateSegments] = useState(false);
  const [showModal, setPaywallModalOpen] = useState(false);

  useEffect(() => {
    if (user && user.planPermissions) {
      setCanCreateSegments(user.planPermissions.includes("create_segments"));
    }
  }, [user]);

  const modalHandler = () => {
    setNewListModalOpen((prev) => !prev);
  };

  const onNewListClick = () => {
    if (canCreateSegments) {
      modalHandler();
    } else {
      onSegmentPaywallOpen();
    }
  };

  const onSegmentPaywallOpen = useCallback(() => {
    setPaywallModalOpen(true);
  }, []);

  const onSegmentPaywallClose = useCallback(() => {
    setPaywallModalOpen(false);
  }, []);

  const onNewListNameChange = (e: any) => {
    const name = e.target.value;
    setNewListName(name);
    if (name && name.length > 0) {
      setNewListError(false);
    } else {
      setNewListError(true);
    }
  };

  const history = useHistory();
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const onUpgrade = () => {
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  const onSubmit = async () => {
    if (canCreateSegments) {
      if (!newListName || newListName.trim().length === 0) {
        setNewListError(true);
        return;
      }

      try {
        const res = await segmentActions.createSegment({
          name: newListName.trim(),
        });

        if (res.id) {
          const cancelUrl = `/lists/${res.id}/spaces/edit`;
          // window.location.pathname = cancelUrl;
          // route(cancelUrl);
          history.push(cancelUrl);
        } else {
          console.error("Segment creation response is missing 'id'.");
        }
      } catch (error) {
        console.error("Error creating segment:", error);
      }
    } else {
      onUpgrade();
    }
    modalHandler();
  };

  return (
    <SegmentsLayout isLoading={pending}>
      <div className={styles.container}>
        <PagePanel
          title={t("Lists")}
          buttonTitle={t("+ New List")}
          buttonStyles={cls({
            ["btn-primary"]: canCreateSegments,
            ["btn-upgrade"]: !canCreateSegments,
          })}
          onButtonClick={onNewListClick}
        />
        <SegmentList />
      </div>
      <SegmentsPaywall show={showModal} onClose={onSegmentPaywallClose} />
      <ModalLayout
        classNames={styles.modal_container}
        isOpen={newListModalOpen}
        onClose={modalHandler}
      >
        <div className={styles.modal_content_wrapper}>
          <p>{t("New List")}</p>
          <span className={styles.input_wrapper}>
            <label htmlFor="new-list">{t("List Name")}</label>
            <input
              id="new-list"
              name="new-list"
              onChange={(e) => onNewListNameChange(e)}
              className={cls({
                [styles.error]: newListError,
              })}
            />
            {newListError && (
              <p
                className={cls({
                  [styles.error_text]: newListError,
                })}
              >
                {t("Name Required")}
              </p>
            )}
          </span>
          <span className={styles.buttons_container}>
            <Button
              title={t("Cancel")}
              buttonType="secondary-outline"
              onClickFunc={modalHandler}
            />
            <Button
              title={t("Create")}
              buttonType="primary"
              onClickFunc={onSubmit}
              disabled={newListName && newListName.length <= 0}
            />
          </span>
        </div>
      </ModalLayout>
      <UpgradeToPro show={showPaywallModal} onClose={closePaywall} />
    </SegmentsLayout>
  );
};

export default Segments;
