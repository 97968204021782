import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import icon_pdf from "app/images/ico_pdf.svg";
import icon_ppt from "app/images/ico_ppt.svg";
import icon_xls from "app/images/ico_xls.svg";
import icon_doc from "app/images/ico_doc.svg";

interface IDatafile {
  type: string;
  title: string;
}

const convertImageNameByType = (type: string) => {
  // eslint-disable-next-line no-undef
  const imageTypes = new Map<string, any>([
    ["pdf", icon_pdf],
    ["pptx", icon_ppt],
    ["ppt", icon_ppt],
    ["xlsx", icon_xls],
    ["xlxx", icon_xls],
    ["docx", icon_doc],
    ["doc", icon_doc],
  ]);
  return imageTypes.get(type);
};

const Datafile: FC<IDatafile> = ({ title, type }: IDatafile) => {
  const [dataIcon] = useState(() => convertImageNameByType(type));
  const classNameByType = `classNameByType ${type}`;

  if (type) {
    return (
      <div className={styles.datafileWrapper}>
        <div className={classNameByType}>
          <img src={dataIcon} alt="" />
          <span>{title}</span>
        </div>
      </div>
    );
  }

  return null;
};

export default Datafile;
