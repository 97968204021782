import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  buttonLabel?: string;
}

const InspirationalModal = ({
  isOpen,
  onClose,
  title = "Inspirational Spaces",
  text = "This is just an example Space for inspiration so you can’t schedule posts here. But if it was a real space, this is where you would.",
  buttonLabel = "Ok, Got It",
}: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <i className={cls(styles.icon, "ico ico-feed-o")} />
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.info}>{text}</p>
        <button
          className={cls(styles.done_button, "btn btn-primary")}
          onClick={onClose}
        >
          {buttonLabel}
        </button>
      </div>
    </ModalLayout>
  );
};

export default InspirationalModal;
