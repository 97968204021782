/**
 * @module Reducers.TimeZone
 *
 */
import { RequestError } from "typedefs";
import { TimeZoneAction } from "app/actions/scheduling/timeZone";
import { TimeZoneModel } from "app/models/scheduling/TimeZoneModel";

export type TimeZoneState = {
  data: TimeZoneModel[] | [];
  error: RequestError | null;
  pending: boolean;
};

const initialState: TimeZoneState = {
  data: [],
  error: null,
  pending: true,
};

export default function listReducer(
  state = initialState,
  action: TimeZoneAction
): TimeZoneState {
  switch (action.type) {
    case "@timeZones.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@timeZones.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@timeZones.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@timeZones.clear":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
