/**
 * @module Components.Links
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslation } from 'react-i18next';

/**
 * @class Cookies
 *
 */
export default function Cookies() {
  const { t } = useTranslation();
  return (
    <a href="https://coachnow.io/cookies">
      <FormattedMessage
        id="app.links.cookies"
        defaultMessage={t("Cookie Policy")}
        description={t("Cookie policy link text")}
      />
    </a>
  );
}

