import React from "react";
import styles from "./styles.module.scss";
import closeIcon from "../../../public/images/close-icon.svg";
// import dummyAvatar from "../../../public/images/role-coach.png";
import likedIcon from "../../../public/images/liked-icon.svg";
import { IModalProps } from "./index.types";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import IndexModel from "./indexModel";
import { Spinner } from "../Loading";
import { useTranslation } from 'react-i18next';

const LikesViewsModal = ({
  isOpen,
  onCloseFunc,
  likesCounter,
  postId,
}: IModalProps) => {
  const { t } = useTranslation();
  const { data, pending, containerRef } = IndexModel(isOpen, postId);

  return (
    <ModalLayout onClose={onCloseFunc} isOpen={isOpen}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Likes")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onCloseFunc}
          />
        </div>
        {likesCounter > 0 && data ? (
          <>
            <div className={styles.modal_description}>
              {t("People who like this post")}
            </div>
            <div className={styles.modal_content_container}>
              {data.map(
                (
                  item: {
                    id: string;
                    user: {
                      avatar: string;
                      displayName: string;
                      _id: string;
                    };
                  },
                  index: number
                ) => {
                  if (index === data.length - 1) {
                    return (
                      <div
                        className={styles.modal_body_container}
                        key={item.id}
                        ref={containerRef}
                      >
                        <div className={styles.body_content}>
                          <div className={styles.content_info_wrapper}>
                            <img
                              className={styles.avatar}
                              src={item.user.avatar}
                              alt="avatar"
                            />
                            <div className={styles.user_info}>
                              <h3 className={styles.username}>
                                {item.user.displayName}
                              </h3>
                              <p className={styles.role}>{t("Coach")}</p>
                            </div>
                          </div>
                          <img
                            src={likedIcon}
                            alt="liked"
                            className={styles.seen_timer}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={styles.modal_body_container}
                        key={item.id}
                      >
                        <div className={styles.body_content}>
                          <div className={styles.content_info_wrapper}>
                            <img
                              className={styles.avatar}
                              src={item.user.avatar}
                              alt="avatar"
                            />
                            <div className={styles.user_info}>
                              <h3 className={styles.username}>
                                {item.user.displayName}
                              </h3>
                              <p className={styles.role}>{t("Coach")}</p>
                            </div>
                          </div>
                          <img
                            src={likedIcon}
                            alt="liked"
                            className={styles.seen_timer}
                          />
                        </div>
                      </div>
                    );
                  }
                }
              )}
            </div>
          </>
        ) : (
          <div className={styles.no_likes}>{!pending && t("No likes yet")}</div>
        )}
        {pending && <Spinner />}
      </div>
    </ModalLayout>
  );
};

export default LikesViewsModal;
