/**
 * @module Routes
 *
 */
import React from 'react';
import { defineMessages } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
// import { useActions } from 'app/utils/hooks';
import { RootState } from 'app/reducers';
// import { fetchSelf } from 'app/actions/user';
import { Loc } from 'app/components/helpers';
import { Dots } from 'app/components/Loading';
import config from 'config/environment';

/**
 * @class UpgradePlus
 *
 */
export default function UpgradePlus() {
  // const actions = useActions({ fetchSelf });
  const user = useSelector((state: RootState) => state.user, shallowEqual);
  // useEffect(() => (actions.fetchSelf(), undefined), [actions]);

  let showing = (<ShowDefault />);
  if (user.pending || user.self == null) {
    showing = (<Dots active={true} />);
  } else if (user.self != null) {
    if (user.self.hasCoachPlusSubscription) {
      (window as any).location = '/';
      return (<div />);
    }

    const { upgrade } = user.self;
    if (upgrade != null) {
      showing = (<ShowUpgrade {...upgrade} />);
    }
  }

  return (
    <div className="panel">
      {showing}
    </div>
  );
}

type ActionLinkProps = {
  url: string;
  title: string;
};

function ActionLink(props: ActionLinkProps) {
  let path = props.url;
  if (/http/.test(path)) {
    path = path.replace(/^https?:\/\/[^\/]*(.*)$/, '$1');
  }
  const clickHandler = () => (window as any).location = path;

  return (
    <a className="btn btn-large btn-primary mr-1 mb-1" onClick={clickHandler}>{props.title}</a>
  );
}

type ActionTypeProps = {
  actions: ActionLinkProps[];
};

function ActionTypes(props: ActionTypeProps) {
  const actions = props.actions || [];
  return (
    <div>{actions.map((action, idx) => (<ActionLink key={idx} {...action} />))}</div>
  );
}

type UpgradeProps = {
  title: string;
  body: string;
  actions: ActionLinkProps[];
};

function ShowUpgrade(props: UpgradeProps) {
  const body = props.body && props.body.length ? props.body.replace(/\&(\s.+)?\s\%ACTION\%/, "") : '';
  return (
    <div>
      <div className="panel-header">
        <h1 className="panel-title">{props.title}</h1>
      </div>
      <h4 className="mb-3">{body}</h4>
      <div>
        <ActionTypes actions={props.actions} />
      </div>
    </div>
  );
}

function ShowDefault() {
  return (
    <div>
      <div className="panel-header">
        <h1 className="panel-title">
          <Loc value={locales.upgrade_header_error} />
        </h1>
      </div>

      <h4>
        <Loc value={locales.upgrade_note_pre} />&nbsp;
        <ContactSupport />&nbsp;
        <Loc value={locales.upgrade_note} />&nbsp;
        <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a>.
      </h4>
    </div>
  );
}

function ContactSupport() {
  return (
    <a className="intercom-help-trigger"><Loc value={locales.contact_support} /></a>
  );
}

const locales = defineMessages({
  upgrade_header_error: { id: 'upgrade-plus.error.header', defaultMessage: 'Oops, something went wrong' },
  upgrade_note_pre: { id: 'upgrade-plus.error.pre-note', defaultMessage: 'Please,' },
  upgrade_note: { id: 'upgrade-plus.error.note', defaultMessage: 'or email us at'},
  contact_support: { id: 'upgrade-plus.intercom.message', defaultMessage: 'contact support' },
});
