import React from "react";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import { NotificationModel } from "app/models/NotificationModel";
import UserAvatar from "app/components/UserAvatar";
import { getNotificationLabel } from "../getNotificationLabels";
import { notificationDate } from "app/utils/notificationDate";
import { NotificationsModel } from "app/models";

interface Props {
  notification: NotificationModel;
  onNotificationClick?: (notification: NotificationModel) => void;
}

const SingleNotification = ({ notification, onNotificationClick }: Props) => {
  const formatLocation = (notification: NotificationsModel) => {
    const meta = notification.meta;

    if (meta.teamGroup) {
      const team = meta.team || {};
      return ` • ${team.teamName || team.name} / ${
        meta.teamGroup.teamGroupName || meta.teamGroup.name
      }`;
    } else if (meta.team) {
      return ` • ${meta.team.teamName || meta.team.name} / General`;
    } else if (meta.space) {
      return ` • ${meta.space.athleteName} / ${meta.space.sport}`;
    }
    return "";
  };

  return (
    <>
      <div
        className={cls(styles.notification, {
          [styles.unread]: !notification.read,
        })}
        onClick={() => onNotificationClick(notification)}
      >
        <div className={styles.left_side}>
          <UserAvatar
            altName="user avatar"
            sizeHeight={44}
            sizeWidth={44}
            className={styles.avatar}
            srcName={notification.initiator && notification.initiator.avatarUrl}
            userName={notification.initiator && notification.initiator.name}
          />
          <div className={styles.content_wrapper}>
            <span className={styles.content_message}>
              <p className={styles.text_message}>
                {getNotificationLabel(notification)}
              </p>
            </span>
            <span className={styles.content_info}>
              <b>
                {notification.createdAt &&
                  notificationDate(notification.createdAt)}
                {formatLocation(notification)}
              </b>
            </span>
          </div>
        </div>
        {notification.meta.post &&
          notification.meta.post.type !== "note" &&
          (notification.meta.post.type === "audio" ? (
            <i
              className={cls(
                "ico ico-microphone",
                styles.notification_attachment_icon
              )}
            />
          ) : (
            <img
              src={notification.meta.post.mediaThumbnail}
              alt="post image"
              className={styles.notification_image}
            />
          ))}
      </div>
    </>
  );
};

export default SingleNotification;
