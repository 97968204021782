import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { filterData, filterError } from "../../../actions/filter";
import { RootState } from "typedefs";
import {
  DaySelect,
  MonthSelect,
  YearSelect,
} from "app/components/inputs/SelectBox";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const DatePicker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterState = useSelector((state: RootState) => state.filter.data, shallowEqual);

  const dateOption = useCallback(() => {
    return {
      minDate: new Date(2012, 0).toISOString().toString(),
      maxDate: new Date().getFullYear().toString(),
    };
  }, [filterState]);

  const [error, setError] = useState({
    fromError: null,
    toError: null,
  });

  // @ts-ignore
  const onDateChange = (type, value) => {
    if (type && value) {
      switch (value.toLowerCase()) {
        case "day":
        case "month":
        case "year":
          break;
        default:
          dispatch(
            filterData({
              ...filterState,
              pickedDateState: {
                ...filterState.pickedDateState,
                [type]: value,
              },
            })
          );
          // setDate((prevDate) => ({
          //   ...prevDate,
          //   [type]: value,
          // }));
          break;
      }
    }
  };

  const filtersFrom = moment()
    .year(
      parseInt(filterState.pickedDateState.fromYear, 10) ||
        new Date().getFullYear()
    )
    .month(filterState.pickedDateState.fromMonth || 0)
    .date(parseInt(filterState.pickedDateState.fromDay, 10 || 1))
    .toISOString();

  const filtersTo = moment()
    .year(
      parseInt(filterState.pickedDateState.toYear, 10) ||
        new Date().getFullYear()
    )
    .month(parseInt(filterState.pickedDateState.toMonth, 10) || 0)
    .month(filterState.pickedDateState.toMonth || 0)
    .date(parseInt(filterState.pickedDateState.toDay, 10 || 1))
    .toISOString();

  const handleDateValidation = () => {
    if (
      filterState.pickedDateState.fromDay ||
      filterState.pickedDateState.fromMonth ||
      filterState.pickedDateState.fromYear ||
      filterState.pickedDateState.toDay ||
      filterState.pickedDateState.toMonth ||
      filterState.pickedDateState.toYear
    ) {
      if (filterState.pickedDateState.fromYear) {
        dispatch(
          filterData({
            ...filterState,
            from: filtersFrom,
          })
        );
        dispatch(filterError(null));
        setError({ ...error, fromError: null });

        if (
          filterState.pickedDateState.toYear ||
          filterState.pickedDateState.toMonth ||
          filterState.pickedDateState.toDay
        ) {
          if (
            filterState.pickedDateState.toYear &&
            filterState.pickedDateState.fromYear
          ) {
            setError({ fromError: null, toError: null });
            dispatch(filterError(null));

            dispatch(
              filterData({
                ...filterState,
                from: filtersFrom,
                to: filtersTo,
              })
            );
          } else {
            setError({ fromError: null, toError: t("Please select a year") });
            dispatch(filterError(t("Please select a year")));
          }
        }
      } else {
        setError({ fromError: t("Please select a year"), toError: null });
        dispatch(filterError(t("Please select a year")));
      }
    }
  };

  useEffect(() => {
    handleDateValidation();
  }, [filterState.pickedDateState]);

  return (
    <>
      <div className={cls(styles.dropdown_list)}>
        <label>{t("From")}</label>
        <div className={styles.from_till_select}>
          <DaySelect
            year={filterState.pickedDateState.fromYear}
            month={filterState.pickedDateState.fromMonth}
            onChange={(e: any) => onDateChange("fromDay", e.value)}
            value={
              filterState.pickedDateState.fromDay
                ? filterState.pickedDateState.fromDay
                : t("Day")
            }
          />
          <MonthSelect
            onChange={(e: any) => onDateChange("fromMonth", e.value)}
            value={
              filterState.pickedDateState.fromMonth
                ? filterState.pickedDateState.fromMonth
                : t("Month")
            }
          />
          <YearSelect
            onChange={(e: any) => onDateChange("fromYear", e.value)}
            value={
              filterState.pickedDateState.fromYear
                ? filterState.pickedDateState.fromYear
                : t("Year")
            }
            minDate={dateOption().minDate}
          />
        </div>
        {error.fromError && (
          <p className={styles.form_error}>{error.fromError}</p>
        )}
      </div>
      <div className={cls(styles.dropdown_list)}>
        <label>{t("Till")}</label>
        <div className={styles.from_till_select}>
          <DaySelect
            year={filterState.pickedDateState.toYear}
            month={filterState.pickedDateState.toMonth}
            onChange={(e: any) => onDateChange("toDay", e.value)}
            value={
              filterState.pickedDateState.toDay
                ? filterState.pickedDateState.toDay
                : t("Day")
            }
          />
          <MonthSelect
            onChange={(e: any) => onDateChange("toMonth", e.value)}
            value={
              filterState.pickedDateState.toMonth
                ? filterState.pickedDateState.toMonth
                : t("Month")
            }
          />
          <YearSelect
            onChange={(e: any) => onDateChange("toYear", e.value)}
            minDate={
              filterState.pickedDateState.fromYear
                ? new Date(
                    parseInt(filterState.pickedDateState.fromYear, 10),
                    0
                  ).toISOString()
                : dateOption().minDate
            }
            value={
              filterState.pickedDateState.toYear
                ? filterState.pickedDateState.toYear
                : t("Year")
            }
          />
        </div>
        {error.toError && <p className={styles.form_error}>{error.toError}</p>}
      </div>
    </>
  );
};

export default DatePicker;
