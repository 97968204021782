import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import closeIcon from "../../../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import {
  fetchGroupMembers,
  getMe,
  modalIsOpen,
  transferGroupOwnership,
} from "app/actions/groups";
import UserModel from "app/models/UserModel";
import TeamAvatar from "app/components/TeamAvatar";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  isModalOpen: boolean;
  modalHandler: () => void;
}

const TransferGroupModal = ({ isModalOpen, modalHandler }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transferActions = useActions({
    fetchGroupMembers,
    getMe,
    transferGroupOwnership,
  });
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);
  const [groupCoaches, setGroupCoaches] = useState(null);
  const [error, setError] = useState({
    youOwnGroup: false,
    noEmail: false,
    invalidMember: false,
    groupTitle: false,
  });
  const [enteredEmail, setEnteredEmail] = useState(null);
  const [enteredGroupName, setEnteredGroupName] = useState(null);
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    transferActions
      .fetchGroupMembers(selectedGroup.id, { type: "coach" })
      .then((res: UserModel[]) => {
        setGroupCoaches(res);
      });

    return () => {
      setGroupCoaches(null);
    };
  }, []);

  const emailHandler = (e: any) => {
    setEnteredEmail(e.target.value);
  };

  const groupNameHandler = (e: any) => {
    setEnteredGroupName(e.target.value);
  };

  const saveHandler = () => {
    // checks for any input error
    const updatedError = {
      youOwnGroup:
        selectedGroup.ownerId === self.id && self.email === enteredEmail,
      noEmail: !enteredEmail || !enteredEmail.trim().length,
      invalidMember:
        groupCoaches &&
        !groupCoaches.some((coach: UserModel) => coach.email === enteredEmail),
      groupTitle: selectedGroup.name !== enteredGroupName,
    };

    setError(updatedError);

    if (
      !updatedError.youOwnGroup &&
      !updatedError.noEmail &&
      !updatedError.invalidMember &&
      !updatedError.groupTitle
    ) {
      transferActions
        .transferGroupOwnership({
          id: selectedGroup.id,
          email: enteredEmail,
        })
        .then(() => {
          route("/groups");
          dispatch(modalIsOpen(true));
        });
    }
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Transfer Group Ownership")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>

        <div className={styles.content_container}>
          <TeamAvatar
            sizeHeight={44}
            sizeWidth={44}
            altName="group"
            className={styles.header_logo}
            srcName={selectedGroup && selectedGroup.logo}
          />
          <b>{t("New Owner")}</b>
          <p>
            {t(
              "Enter the email of a Coach within the Group to transfer ownership."
            )}
          </p>
        </div>

        <div className={styles.text_content_container}>
          <label htmlFor="owner">{t("New Owner's Email")}</label>
          <input
            className={cls({
              [styles.error]:
                error.youOwnGroup || error.noEmail || error.invalidMember,
            })}
            type="email"
            name="owner"
            id="owner"
            onChange={emailHandler}
          />
          {error.youOwnGroup && (
            <div className="helper-text error">
              {t("But you already own this Group.")}
            </div>
          )}
          {(error.noEmail || error.invalidMember) && (
            <div className="helper-text error">
              {t("Please enter an email of a Coach within the Group.")}
            </div>
          )}
        </div>

        <div className={styles.text_content_container}>
          <label htmlFor="name">{t("Group Name to Confirm")}</label>
          <input
            className={cls({ [styles.error]: error.groupTitle })}
            type="text"
            name="name"
            id="name"
            onChange={groupNameHandler}
          />
          {error.groupTitle && (
            <div className="helper-text error">
              {t("Entered name does not match Group name")}
            </div>
          )}
        </div>

        <section className={styles.rights}>
          {t(
            'By clicking "Confirm and Transfer", you are transferring Group ownership and permissions to this Coach. You will still be part of the Group, but moved to a Coach role.'
          )}
        </section>

        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="primary"
            title={t("Confirm and Transfer")}
            onClickFunc={saveHandler}
            disabled={!selectedGroup}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default TransferGroupModal;
