import { RootState } from "typedefs";
import { useEffect } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchEvents } from "../../actions/events";
import { fetchTimeZones } from "app/actions/scheduling/timeZone";
import { DateTime } from "luxon";

export const useEventsModel = (startDate: DateTime, endDate: DateTime) => {
  const eventActions = useActions({
    fetchEvents,
  });

  const start: string = startDate.toISODate();
  const end: string = endDate.toISODate();

  useEffect(() => {
    eventActions.fetchEvents({ start, end });
  }, [start, end]);

  const events = useSelector((state: RootState) => state.events);

  return { events };
};

export const useTimeZonesModel = () => {
  const actions = useActions({
    fetchTimeZones,
  });

  useEffect(() => {
    actions.fetchTimeZones();
  }, []);

  const timeZones = useSelector(
    (state: RootState) => state.schedulingTimeZones,
  );

  return timeZones;
};
