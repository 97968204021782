import React from "react";
import styles from "./styles.module.scss";
import { newSpaceValues } from "app/actions/spaceCreation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import UserSelect from "app/components/Filter/UserSelect";
import Button from "app/components/Button/ButtonVariant";
import { useCreateNewSpaceModel } from "../../index.model";
import { useTranslation } from "react-i18next";

const InvitationToSpace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual
  );

  const { stepForward, stepBackward } = useCreateNewSpaceModel();

  const onCoachSelect = (selectedValue: UserModel[]) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        coaches: selectedValue,
      })
    );
  };

  const onMemberSelect = (selectedValue: UserModel[]) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        contributors: selectedValue,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.panel_title_container}>
        <h4 className={styles.panel_title}>
          {t("Who else would you like to invite?")}
        </h4>
        <p>
          {t(
            "You can invite coaches, trainers, parents, fans, or anyone else in the athlete's support network."
          )}
        </p>
      </div>
      <div className={styles.select_input_container}>
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="space_template">
            {t("Coaches")}
          </label>
          <UserSelect
            isCreatable
            noOptionsMessage={t("Add a New Coach")}
            onChange={(selectedValue: UserModel[]) => {
              onCoachSelect(selectedValue);
            }}
            addNewUserModalTitle={t("Add a New Coach")}
          />
        </div>
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="space_template">
            {t("Members")}
          </label>
          <span className={styles.select_with_description}>
            <UserSelect
              isCreatable
              noOptionsMessage={t("Add a New Athlete")}
              onChange={(selectedValue: UserModel[]) =>
                onMemberSelect(selectedValue)
              }
              addNewUserModalTitle={t("Add a New Athlete")}
            />
            <p>
              {t("Invite friends & family that will keep this athlete going!")}
            </p>
          </span>
        </div>
      </div>
      <div className={styles.button_container}>
        <Button
          title={t("Back")}
          buttonType="secondary-outline"
          onClickFunc={stepBackward}
        />
        <Button
          title={t("Next")}
          buttonType="primary"
          onClickFunc={stepForward}
        />
      </div>
    </div>
  );
};

export default InvitationToSpace;
