import React, { useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { DefaultOption, setCustomStyles } from "../SelectBox/Base";
import { cls } from "app/utils";
import { uuid } from "app/utils/uuid";
import { useTranslation } from 'react-i18next';

interface ValueTypes {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface Props {
  isClearable?: boolean;
  isSearchable?: boolean;
  isCratable?: boolean;
  isDisabled?: boolean;
  options: any[];
  defaultValue?: ValueTypes;
  placeholder?: string;
  onChange?: (e: ValueTypes) => void;
  className?: string;
}

const CustomSelect = ({
  className,
  isClearable,
  isSearchable,
  isCratable,
  isDisabled,
  defaultValue,
  options,
  placeholder,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [_options, setOptions] = useState(null);

  const createOption = (
    data: { label: string; value: string; id?: string } | string
  ): DefaultOption => {
    if (typeof data === "string") {
      return { value: data, label: data };
    } else {
      return {
        value: data.value,
        label: data.label,
        id: data.id ? data.id : uuid(),
      };
    }
  };

  useEffect(() => {
    if (options != null) {
      setOptions(options.map(createOption));
    }
  }, [options]);

  return (
    <>
      {!isCratable ? (
        <Select
          className={cls("user-select", className)}
          styles={{
            // @ts-ignore
            ...setCustomStyles(),
          }}
          classNamePrefix="needsclick select-box"
          defaultValue={defaultValue ? defaultValue : null}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name="select"
          options={_options}
          onChange={onChange}
          placeholder={placeholder ? placeholder : t("Select...")}
        />
      ) : (
        <Creatable
          className={cls("user-select")}
          styles={{
            // @ts-ignore
            ...setCustomStyles(),
          }}
          classNamePrefix="needsclick select-box"
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name="select"
          options={_options}
          onChange={onChange}
          placeholder={placeholder ? placeholder : t("Select...")}
        />
      )}
    </>
  );
};

export default CustomSelect;
