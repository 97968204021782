/**
 * @module Components.Input
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

/***/
type PropsDef = React.PropsWithChildren<{
  clearInput?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: (value: string, key: string) => void;
  onClick?: (value: string, key: string) => void;
  onFocus?: (value: boolean) => void;
  value?: string;
}>;

/**
 * @class SearchBar
 *
 */
export default function SearchBar(props: PropsDef) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const placeholder = props.placeholder || t("Search");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.clearInput) {
      clearInput();
    }
  }, [props.clearInput]);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (props.onFocus) {
      props.onFocus(isFocused);
    }
  }, [isFocused]);

  const handleChange = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    setShowClearIcon(ref.current.value !== null && ref.current.value !== "");

    // Handle Enter press
    if (evt.which === 13) {
      handleClick();
    }

    // Handle Esc press
    if (evt.which === 27) {
      clear();

      return;
    }

    if (props.onChange != null) {
      props.onChange(ref.current.value, evt.key);
    }
  };

  const handleClick = () => {
    if (props.onClick != null) {
      props.onClick(ref.current.value, "Enter");
    }
  };

  const clearInput = () => {
    ref.current.value = null;
    setValue("");
    setShowClearIcon(false);
  };

  const clear = () => {
    clearInput();

    if (props.onClick != null) {
      props.onClick(ref.current.value, "Enter");
    }

    if (props.onChange != null) {
      props.onChange(ref.current.value, "Enter");
    }
  };

  return (
    <div className={cls(Styles.searchBar, props.className)}>
      <input
        ref={ref}
        type="text"
        placeholder={placeholder}
        onKeyUp={handleChange}
        onChange={(e) => setValue(e.target.value)}
        id="search-bar-input"
        name="search-bar-input"
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
      />
      {showClearIcon && (
        <i className={cls(Styles.iconClear, "ico ico-x")} onClick={clear} />
      )}
      <i
        className={cls(Styles.iconSearch, "ico ico-search")}
        onClick={handleClick}
      />
    </div>
  );
}
