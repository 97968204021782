import React from "react";
import { ApplyTemplateToProps } from "../ApplyTemplateDropdown/index.types";
import { cls } from "app/utils";
import { useStoreActions } from "app/utils/hooks";
import { fetchTeams } from "app/actions/team";
import { useTranslation } from 'react-i18next';

const ApplyTemplateToGroup = ({
  className,
  groupSelectModalHanlder,
}: ApplyTemplateToProps) => {
  const { t } = useTranslation();

  useStoreActions({
    team: () => fetchTeams({ "options[include]": ["team_groups"] }),
  });

  return (
    <>
      <div className={cls(className)} onClick={groupSelectModalHanlder}>
        <i className="ico ico-team" />
        <p>{t('Apply to Group')}</p>
      </div>
    </>
  );
};

export default ApplyTemplateToGroup;
