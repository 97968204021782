export interface Alert {
  id: string;
  message: string;
  ms?: number;
  expired?: true;
  type?: AlertType;
}


export enum AlertType {
  SUCCESS = "success",
  DANGER = "danger",
}

export interface AddAlert {
  message: string;
  ms?: number;
  type?: AlertType;
}
