import { useState } from "react";
import { RootState } from "typedefs";
import { useActions, useSelector } from "app/utils/hooks";
import { deleteTeamGroup, updateTeamGroup } from "app/actions/team-group";
import TeamGroupModel from "app/models/TeamGroupModel";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";

export const TeamsSettingsModel = () => {
  const { t } = useTranslation();
  const teamGroupActions = useActions({ updateTeamGroup, deleteTeamGroup });
  const groupId = useSelector(
    (state: RootState) => state.groups.selectedGroup.id,
    shallowEqual
  );
  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual
  );
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const ChannelSettingsData = [
    {
      label: t("Private"),
      value: "private",
      description: t(
        "Only visible to Group Members. Only coaches can add Group Members."
      ),
    },
    {
      label: t("Joinable"),
      value: "joinable",
      description: t(
        "Visible to all Group Members. Any Group Member can join."
      ),
    },
    {
      label: t("Auto-Join"),
      value: "auto_join",
      description: t(
        "All current and new Group members will be automatically added to the Group."
      ),
    },
  ];

  const [newChannelData, setNewChannelData] =
    useState<TeamGroupModel>(selectedTeam);

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, name: value });
  };

  const groupTypeHandler: React.MouseEventHandler<HTMLDivElement> = (value) => {
    setNewChannelData({
      ...newChannelData,
      groupType: value as any,
    });
  };

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, description: value });
  };

  const handleTemplateSelect = (value: any) => {
    if (value) {
      setNewChannelData({ ...newChannelData, space_template_id: value.id });
    }
  };

  const updateHandler = () => {
    const trimmedName = newChannelData.name.trim();
    if (trimmedName.length !== 0) {
      teamGroupActions
        .updateTeamGroup(selectedTeam.id, newChannelData)
        .then((res) => {
          if (res) {
            route(`/groups/${groupId}/channels/${res.id}`);
          }
        });
    }
  };

  const deleteHandler = () => {
    customPrompt({
      message: t(
        `Are you sure you would like to remove the channel {{selectedTeam}} from this Group?`,
        {
          selectedTeam: selectedTeam.name,
        }
      ),
      onYes: () => {
        teamGroupActions
          .deleteTeamGroup(selectedTeam.id)
          .then(() => route(`/groups/${groupId}`));
      },
      onCancel: () => {
        return;
      },
    });
  };

  const canDelete = () => {
    return (
      selectedTeam.permissions.includes("delete") &&
      selectedTeam.planPermissions.includes("delete")
    );
  };

  const canEdit = () => {
    return (
      selectedTeam.permissions.includes("edit") &&
      selectedTeam.planPermissions.includes("edit")
    );
  };

  return {
    ChannelSettingsData,
    setNewChannelData,
    newChannelData,
    handleNameChange,
    groupTypeHandler,
    handleDescriptionChange,
    handleTemplateSelect,
    updateHandler,
    deleteHandler,
    canDelete,
    canEdit,
  };
};
