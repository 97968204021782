import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import SpaceTemplatesHeader from "app/components/SpaceTemplatesHeader";
import { useParams } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import {
  clearSpaceTemplate,
  fetchSpaceTemplateById,
} from "app/actions/spaceTemplate";

interface Props {
  children: React.ReactNode;
  hideTabs?: boolean;
  hideHeaderMenu?: boolean;
}

const TemplatePageLayout = ({ children, hideHeaderMenu, hideTabs }: Props) => {
  const templateActions = useActions({
    fetchSpaceTemplateById,
    clearSpaceTemplate,
  });

  const { id: templateId } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      if (templateId) {
        try {
          await templateActions.fetchSpaceTemplateById(templateId);
        } catch (error) {
          console.error("Failed to fetch space:", error);
        }
      }
    };

    fetchData();
  }, [templateId]);

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <SpaceTemplatesHeader
          hideHeaderMenu={hideHeaderMenu}
          hideTabs={hideTabs}
        />
        <div className={styles.content_container}>{children}</div>
      </div>
    </>
  );
};

export default TemplatePageLayout;
