/**
 * @module Components.Inputs
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CheckBox, { PropsDef } from 'app/components/inputs/CheckBox/Base';
import { useTranslation } from 'react-i18next';

export default function AgeConsent(props: PropsDef) {
  const { t } = useTranslation();

  const consentText = (
    <div className="age-consent-disclaimer">
      <FormattedMessage
        id="app.form-label.age-consent"
        defaultMessage={t("I'm older than 13")}
      />
      <div className="text-muted">
        <FormattedMessage
          id="app.form-label.age-disclaimer"
          defaultMessage={t("If not, billing information is needed to confirm parental consent.")}
        />
      </div>
    </div>
  );

  return (
    <CheckBox
      {...props}
      name="ageConsent"
      label={consentText}
    />
  );
}

