import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "../UserAvatar";
import SidebarMenuList from "./SidebarMenuList";
import SidebarDropdown from "./SidebarDropdown";
import pro_icon from "../../../public/images/pro.svg";
import edufii_logo from "app/images/Logo.svg";
import { useSidebarModel } from "./indexModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const history = useHistory();

  const onLogoClick = () => {
    history.push("/");
  };

  const {
    hasActiveProSubscription,
    hasActivePlusSubscription,
    hasExpiredSubscription,
    shouldShowTrialText,
  } = useSidebarModel({ user });

  const PlanTypeTitle = () => {
    if (hasActiveProSubscription()) {
      return (
        <>
          Coachnow <img src={pro_icon} alt="coachnow pro" />
        </>
      );
    } else if (hasActivePlusSubscription()) {
      return (
        <>
          Coachnow <i className="plus-icon" />
        </>
      );
    } else if (hasExpiredSubscription()) {
      return <>{t("Expired")}</>;
    } else if (shouldShowTrialText()) {
      return (
        <>
          {user.plan.trialDaysLeft}{" "}
          {t("Days left", { days: user.planTrialDaysLeft })}
          <Link to={"/upgrade"} className={styles.upgrade_btn}>
            {t("Upgrade")}
          </Link>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.sidebar_menu_container}>
      <div className={styles.site_sidebar}>
        <div className={styles.logo} onClick={onLogoClick}>
          <img src={edufii_logo} alt="CoachNow" />
        </div>
        <div className={styles.profile_info_container}>
          <div className={styles.profile_info_wrapper}>
            {user?.avatar && (
              <UserAvatar
                className={styles.avatar}
                srcName={user.avatar as string}
                userName={user.displayName}
              />
            )}
            <div className={styles.name_membership_container}>
              <div className={styles.display_name}>{user?.displayName}</div>
              <div className={styles.plan_type}>
                <PlanTypeTitle />
              </div>
            </div>
          </div>
          <SidebarDropdown user={user} />
        </div>
        <SidebarMenuList user={user} />
      </div>
    </div>
  );
};

export default Sidebar;
