/**
 * @module Model.TimeZone
 *
 */
import { Model } from "typedefs";

interface TimeZone {
  display: string;
  value: string;
  code: string;
}

export interface TimeZoneResponse {
  data: TimeZone[];
}

export class TimeZoneModel implements Model {
  display: string;
  value: string;
  code: string;

  constructor(timeZone: TimeZone) {
    this.display = timeZone.display;
    this.value = timeZone.value;
    this.code = timeZone.code;
  }
}
