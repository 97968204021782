import React from "react";
import styles from './styles.module.scss';
import SelectBox from "app/components/OptionButtonGroup/SelectBox";
import { useTranslation } from "react-i18next";

interface LabelTimeOptionProps {
  index: number;
  label: string;
  timeFrom: string;
  timeTo: string;
  onChange: any;
}

const LabelTimeOption = ({ index, label, timeFrom, timeTo, onChange }: LabelTimeOptionProps) => {
  const { t } = useTranslation();
  const dateList = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const ampm = hour >= 12 ? 'PM' : 'AM';
      let hourLabel = hour > 12 ? hour - 12 : hour;
      if (hour === 0 && ampm === 'AM') {
        hourLabel = 12;
      }

      const label = `${hourLabel}:${minute
        .toString()
        .padStart(2, '0')} ${ampm}`;
      dateList.push({
        value: `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`,
        label,
      });
    }
  }

  return (
    <div className={styles.label_textbox_container}>
      <div className={styles.text_wrapper}>
        <label className={styles.label_text}>{label}</label>
      </div>
      <div className={styles.from_to_date}>
        <div className={styles.flex_row}>
          <span className={styles.gray_text}>{t('From')}</span>
          <SelectBox
            options={dateList}
            value={dateList.find((el) => el.value === timeFrom) || null}
            setValue={(fromTime: string) => onChange(index, fromTime, 'startTime')}
          />
        </div>
        <div className={styles.flex_row}>
          <span className={styles.gray_text}>{t('To')}</span>
          <SelectBox
            options={dateList}
            value={dateList.find((el) => el.value === timeTo) || null}
            setValue={(toTime: string) => onChange(index, toTime, 'endTime')}
          />
        </div>
      </div>
    </div>
  );
};

export default LabelTimeOption;
