import { useEffect, useRef, useState } from "react";
import { clearPost, fetchPosts, reportPost } from "app/actions/posts";
import { fetchScheduledPosts, scheduledPosts } from "app/actions/schedule";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { deletePost, editPost } from "app/actions/postingForm";
import { allowMultipleSelect } from "app/actions/destinations";
import moment from "moment";
import { useParams } from "react-router-dom";
import PostModel from "app/models/PostModel";
import { FilterModel } from "app/models/FilterModel";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface QueryParams {
  page?: number;
  spaceId?: string;
  perPage?: number;
  reply_limit?: number;
  throttle?: number;
}

export const SpacesTimelineModel = () => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const spaceParams = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const postsActions = useActions({
    fetchPosts,
    fetchScheduledPosts,
    editPost,
    clearPost,
    reportPost,
    deletePost,
  });
  const { selectedSpace } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );

  const { pending, hasMore } = useSelector(
    (state: RootState) => state.posts,
    shallowEqual
  );

  const { scheduledPostsSuccess: scheduledPostsData } = useSelector(
    (state: RootState) => state.schedulePost,
    shallowEqual
  );

  const [reportModal, setReportModal] = useState({ isOpen: false, post: null });
  const [data, setData] = useState<PostModel[]>([]);

  const onReport = (post: PostModel, message?: string) => {
    postsActions
      .reportPost(post.id, message)
      .then(() => {
        setData(
          data.filter((filteredPost: PostModel) => filteredPost.id !== post.id)
        );
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  const onPostReportClick = (post: PostModel) => {
    setReportModal({ isOpen: true, post });
  };

  const reportModalClose = () => {
    setReportModal({ isOpen: false, post: null });
  };

  const onReportHandler = (reason: string) => {
    onReport(reportModal?.post, reason);
    reportModalClose();
  };

  const postsParams = {
    "options[include]": ["replies"],
    spaceId: spaceParams.id,
    page: 1,
    perPage: 20,
    reply_limit: 3,
    throttle: 10000,
  };

  const scheduledPostsParams = {
    spaceId: spaceParams.id,
    "options[include]": ["posts"],
    orderBy: "scheduled_at",
    order: "asc",
    timezoneOffset: moment().utcOffset().toString(),
  };

  const [query, setQuery] = useState<QueryParams>(postsParams);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (queryVal: QueryParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      const res = await postsActions.fetchPosts(
        queryVal,
        abortControllerRef.current.signal
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData: any) => [...prevData, ...res]);
          } else {
            setData(res);
          }
          setQuery(queryVal);
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
    }
  };

  const initialLoadPosts = (queryVal: QueryParams) => {
    setData([]);
    fetchPage(queryVal);
  };

  const loadMore = () => {
    if (!pending && hasMore) {
      const newQuery = { ...query, page: (query.page || 1) + 1 };
      fetchPage(newQuery);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    postsActions.fetchScheduledPosts(scheduledPostsParams);
    initialLoadPosts(postsParams);
    dispatch(allowMultipleSelect(true));
    return () => {
      // dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setQuery(postsParams);
      dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
    };
  }, [postsActions, dispatch]);

  const onPostDelete = async (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmation) {
      await postsActions.deletePost(post.id);
      setData(
        data.filter((filteredPost: PostModel) => filteredPost.id !== post.id)
      );
    }
  };

  const onPostUpdate = async (post: PostModel) => {
    if (post) {
      await postsActions.editPost(post);
    }
  };

  const onPostPin = () => {
    initialLoadPosts(postsParams);
  };

  function filterHandler(filters: FilterModel) {
    const filteredQuery = { ...postsParams, ...filters };
    postsActions.clearPost();
    initialLoadPosts(filteredQuery);
  }

  function onClearFilters() {
    postsActions.clearPost();
    initialLoadPosts(postsParams);
  }

  const onPost = async (post: PostModel) => {
    if (!post) {
      return;
    }

    if (!post?.scheduledAt) {
      if (!data?.[0]?.pinnedByName) {
        setData([{ ...post, userAvatar: self.avatar }, ...data]);
      } else if (data?.[0]?.pinnedByName) {
        // if the first post is pinned, insert the new post at the second index
        const newData = data?.length > 0 ? [...data] : [];
        newData?.splice(1, 0, {
          ...post,
          userAvatar: self.avatar,
        });
        setData(newData);
      }
    } else {
      postsActions.fetchScheduledPosts(scheduledPostsParams);
    }
  };

  return {
    selectedSpace,
    spaceId: spaceParams.id,
    data,
    scheduledPostsData,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
  };
};
