import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import Button from "app/components/Button/ButtonVariant";
import { useActions, useSelector } from "app/utils/hooks";
import {
  fetchMediaById,
  deleteMedia,
  editMedia,
  attachedData,
} from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import VideoPlayer from "app/components/VideoPlayer";
import { TagSelect } from "app/components/inputs/SelectBox";
import TagModel from "app/models/TagModel";
import AudioPlayer from "app/components/AudioPlayer";
import { shallowEqual, useDispatch } from "react-redux";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import Datafile from "app/components/Datafile";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

const EditResource = () => {
  const { t } = useTranslation();
  const resourceId = window.location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const resourceActions = useActions({
    fetchMediaById,
    deleteMedia,
    editMedia,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };
  const mediaData = useSelector(
    (state: RootState) => state.mediaLibraryData,
    shallowEqual,
  );
  const [tags, selectedTags] = useState<string[]>([]);

  useEffect(() => {
    resourceActions.fetchMediaById(resourceId);

    return () => {
      dispatch(attachedData(null));
    };
  }, []);

  const onTagsChange = useCallback(
    (tags) => {
      const tagNames = tags.map((tag: TagModel) => tag.name);

      selectedTags(tagNames);
    },
    [tags],
  );

  const onSaveChanges = () => {
    if (mediaData) {
      resourceActions
        .editMedia(mediaData.attachment.id, {
          ...mediaData.attachment,
          tags,
        })
        .then(() => (window.location.pathname = "/resources"));
    }
  };

  const handleDeleteMedia = useCallback(() => {
    if (window.confirm(t("Are you sure you want to delete this media file?"))) {
      resourceActions
        .deleteMedia(resourceId)
        .finally(() => route("/resources"));
    } else {
      return null;
    }
  }, []);

  const getButtonTitle = () => {
    if (window.innerWidth <= 768) {
      return t("Create post");
    } else {
      return t("Create post with media");
    }
  };

  const getDeleteButtonTitle = () => {
    if (window.innerWidth <= 768) {
      return "";
    } else {
      return t("Delete");
    }
  };

  const headerComponent = () => {
    return (
      <span className={styles.header_buttons}>
        <Link to={`/resources/${resourceId}/post`}>
          <Button
            icon={<i className="icon-plus icon-white" />}
            childStyles={cls(styles.btn, styles.create_post_btn)}
            title={getButtonTitle()}
            buttonType="primary"
          />
        </Link>
        <Button
          title={getDeleteButtonTitle()}
          icon={<i className="icon-trash icon-white" />}
          childStyles={styles.btn}
          buttonType="danger"
          onClickFunc={handleDeleteMedia}
        />
      </span>
    );
  };

  return (
    <SegmentsLayout
      isLoading={mediaData.pending || !mediaData.attachment}
      displayNoResult={!resourceId}
    >
      <div className={styles.container}>
        <PagePanel title={t("Edit Media")} headerChildren={headerComponent()} />

        <div className={styles.content_wrapper}>
          <Media />
          <TagSelect
            defaultValue={
              mediaData && mediaData.attachment && mediaData.attachment.tags
            }
            onChange={onTagsChange}
          />
          <span className={styles.header_buttons}>
            <Link to="/resources">
              <Button
                title={t("Cancel")}
                buttonType="secondary-outline"
                buttonStyles={styles.control_btn}
              />
            </Link>
            <Button
              title={t("Save changes")}
              buttonType="primary"
              onClickFunc={onSaveChanges}
            />
          </span>
        </div>
      </div>
    </SegmentsLayout>
  );
};

export default EditResource;

export const Media = () => {
  const mediaData = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
    shallowEqual,
  );

  if ((mediaData && mediaData.type === "image") || mediaData.type === "video") {
    return (
      <div
        className={cls(styles.preview_container, {
          [styles.video_content_width]: mediaData?.type === "video",
        })}
      >
        {mediaData.type === "image" && (
          <a
            href={mediaData.originalUrl ? mediaData.originalUrl : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mediaData.originalUrl} alt="media" />
          </a>
        )}
        {mediaData.type === "video" && <VideoPlayer src={mediaData} />}
      </div>
    );
  } else if (mediaData.type === "datafile") {
    return (
      <a
        className={cls(styles.datafile_container)}
        href={mediaData.url ? mediaData.url : ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Datafile title={mediaData.filename} type={mediaData.extension} />
      </a>
    );
  } else if (mediaData.type === "audio") {
    return <AudioPlayer data={mediaData} />;
  } else {
    return null;
  }
};
