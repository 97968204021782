import React from "react";
import styles from "./styles.module.scss";
import PanelWrapper from "app/components/PanelWrapper";
import { ScheduledPostsModel } from "app/reducers/schedule";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  scheduledPosts: ScheduledPostsModel;
  goToLocation?: string;
}

const ScheduledPostsCounter = ({ scheduledPosts, goToLocation }: Props) => {
  const { t } = useTranslation();
  const scheduledPostsLength = scheduledPosts?.posts?.length;
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const onClickHandler = () => {
    if (goToLocation) {
      route(goToLocation);
      return;
    }
    route("/schedule");
  };

  return (
    <PanelWrapper>
      <div className={styles.container} onClick={onClickHandler}>
        <div className={styles.scheduled_icon_wrapper}>
          <i className="ico ico-time" />
        </div>
        <strong className={styles.text}>
          {scheduledPostsLength === 1 ? t("count_scheduled_post", { count: scheduledPostsLength }) : t("count_scheduled_post_plural", { count: scheduledPostsLength })}
        </strong>
      </div>
    </PanelWrapper>
  );
};

export default ScheduledPostsCounter;
