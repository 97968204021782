import React from "react";
import styles from "./styles.module.scss";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import GroupIcon from "../../../../../../public/images/group-icon.png";
import Button from "app/components/Button/ButtonVariant";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmitHandler?: (value: GroupInviteModalTypes) => void;
}

const GroupCreatedModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmitHandler,
}: Props) => {
  const { t } = useTranslation();
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Group Created")}
    >
      <div className={styles.container}>
        <img src={GroupIcon} alt="group icon" className={styles.group_icon} />
        <span>
          {selectedGroup && (
            <b>
              {t(`{{groupName}} Created!`, {
                groupName: selectedGroup.name,
              })}
            </b>
          )}
          <p>{t("Invite members to build your Group.")}</p>
        </span>
        <div className={styles.cancel_btn}>
          <Button
            buttonType="primary"
            title={t("Invite Members to Group")}
            onClickFunc={onSubmitHandler}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default GroupCreatedModal;
