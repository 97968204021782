import { RootState } from "typedefs";
import { useEffect } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchEvents } from "../../actions/events";
import { fetchCoachProfile } from "../../actions/scheduling/coachProfile";
import { fetchLessonTypes } from "../../actions/lessonTypes";
import { DateTime } from "luxon";

export const useEventsModel = (coachProfileId: string, startDate: DateTime, endDate: DateTime) => {
  const lessonTypesPending = useSelector((state: RootState) => state.lessonTypes.pending);
  const eventActions = useActions({
    fetchEvents,
  });

  const coachProfileActions = useActions({
    fetchCoachProfile,
  });

  const start: string = startDate ? startDate.toISODate() : null;
  const end: string = endDate ? endDate.toISODate() : null;

  useEffect(() => {
    coachProfileActions.fetchCoachProfile(coachProfileId);
  }, [coachProfileId]);

  useEffect(() => {
    if(lessonTypesPending || !start || !end) {
      return;
    }
    eventActions.fetchEvents({ start, end });
  }, [start, end, coachProfileId, lessonTypesPending]);

  const events = useSelector((state: RootState) => state.events);
  const coachProfile = useSelector((state: RootState) => state.coachProfile);

  return { events, coachProfile };
};


export const useLessonTypesModel = () => {
  const actions = useActions({
    fetchLessonTypes,
  });

  useEffect(() => {
    actions.fetchLessonTypes();
  }, []);

  const lessonTypes = useSelector((state: RootState) => state.lessonTypes);

  return { lessonTypes };
};
