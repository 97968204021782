import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";
import { OrganizationModel } from "app/models/OrganizationModel";

const success = (post: OrganizationModel[]) => action("@organization.fetch.success", post);
const pending = () => action("@organization.fetch.pending");
const error = (err: string) => action("@organization.fetch.error", err);

export const clear = () => action("@organization.clear");

const thisActions = {
  pending,
  error,
  success,
  clear,
};

export type OrganizationAction = ActionType<typeof thisActions>;

export function fetchOrganization() {
  return (dispatch: Dispatch<OrganizationAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/organizations`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((views: OrganizationModel[]) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const actions = {
  fetchOrganization,
};
