/**
 * @module Model.Availability
 *
 */
import { Model } from "typedefs";
import { DateTime } from "luxon";

export class AvailabilityModel implements Model {
  date: DateTime;
  daysOfWeek: number[];
  startTime: string;
  endTime: string;

  constructor(availability: any) {
    this.date = DateTime.fromISO(availability.date);
    this.daysOfWeek = [availability.dayOfWeek];
    this.startTime = availability.startTime;
    this.endTime = availability.endTime;
  }
}
