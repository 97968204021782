import { OrganizationAction } from "app/actions/organizations";
import { OrganizationModel } from "app/models/OrganizationModel";
import { RequestError } from "typedefs";

export type OrganizationState = {
  data: OrganizationModel[] | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: OrganizationState = {
  data: null,
  error: null,
  pending: false,
};

export default function timezoneReducer(
  state = initialState,
  action: OrganizationAction
) {
  switch (action.type) {
    case "@organization.fetch.pending":
      return { ...state, pending: true, data: null };
    case "@organization.fetch.error":
      return { ...state, pending: false, data: null, error: action.payload };
    case "@organization.fetch.success":
      return { ...state, pending: false, data: action.payload, error: null };

    case "@organization.clear":
      return {
        ...state,
        data: null,
        pending: null,
        error: null,
      };
    default:
      return state;
  }
}
