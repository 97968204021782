import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SearchBar from "../inputs/SearchBar";
import { cls } from "app/utils";
import CreateNavBtn from "./CreateNavBtn";
import edufii_logo_dark from "app/images/Logo_dark.svg";
import NotificationsDropdown from "../NotificationsDropdown";

import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface Props {
  sidebarExpanded: boolean;
  toggleSidebar: () => void;
}

const SiteNavbar = ({ sidebarExpanded, toggleSidebar }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (history.location.search.split("=")[0].includes("q")) {
      setSearch(history.location.search.split("=")[1]);
    }
  }, [history.location.search]);

  const onSearchEnterPressed = useCallback(
    (e) => {
      if (isFocused && search.length && e.key === "Enter") {
        history.push(`/search?q=${search}`);
      }
    },
    [isFocused, search],
  );

  useEffect(() => {
    window.addEventListener("keydown", onSearchEnterPressed);
    return () => window.removeEventListener("keydown", onSearchEnterPressed);
  }, [onSearchEnterPressed]);

  const handleLogoClick = () => {
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <nav className={styles.navbar}>
        <div className={styles.mobile_only}>
          <div className={cls(styles.site_sidebar_toggle, "mobile-only")}>
            <button
              onClick={toggleSidebar}
              className={cls("burger", { ["expanded"]: !sidebarExpanded })}
            >
              <div className="burger-holder">
                <div className="bars-black burger-bars"></div>
              </div>
            </button>
          </div>
        </div>
        <div className={cls(styles.logo, "mobile-only")}>
          <img
            src={edufii_logo_dark}
            alt="CoachNow"
            onClick={handleLogoClick}
          />
        </div>
        <div className={styles.pull_right}>
          <SearchBar
            placeholder={t("Search posts, people or spaces")}
            className={styles.search}
            value={search}
            onChange={(val) => setSearch(val)}
            onFocus={(val) => setIsFocused(val)}
          />
          <div className={styles.create_btn_container}>
            <CreateNavBtn />
          </div>
          <div className={styles.notifications_container}>
            <NotificationsDropdown />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default memo(SiteNavbar);
