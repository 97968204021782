/**
 * @module Actions.DemoSpace
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { actionUpdater } from "app/utils/actions";
import { fetchGet } from "app/utils/request";
import { ErrorResponse } from "app/utils/request/errorHandler";
import { serialize } from "app/utils/request/model";
import { SpaceModel } from "app/models";
// import { uuid } from 'app/utils/uuid';
import HashMap, { createMap } from "app/utils/HashMap";

export interface DemoSpaceModel extends SpaceModel {
  demoSpaceId?: string;
  posts: any[];
}

export type DemoSpaceMap = HashMap<DemoSpaceModel>;

type ApiDemoSpace = {
  id: string;
  object: "demo_space";
  description: string;
  data: string;
  sports: string[];
};

const addSpaceProps = (demoSpaceId: string, space: SpaceModel) => {
  space = (space || {}) as SpaceModel;
  space.can = {
    manageMembers: false,
    delete: false,
    edit: false,
    post: false,
    postMedia: false,
    reply: false,
    makeAthlete: false,
  };
  space.isDemo = true;
  space.isForAthlete = space.type === "athlete";
  space.isForTeam = space.type === "team";
  space.teamName = () => (space.team != null ? space.team.name : null);
  space.membersUrl = `/spaces/demo/${demoSpaceId}/members`;
  space.timelineUrl = `/spaces/demo/${demoSpaceId}`;

  // remove private data
  space.athleteEmail = "";
  space.email = "";

  return space;
};

const addPostProps = (demoSpaceId: string, posts: any[]) => {
  posts = posts || [];
  return posts.map((model: any) => {
    model.can = {
      like: false,
      reply: true,
      tag: false,
      delete: false,
      edit: false,
      share: false,
      report: false,
    };
    model.isDemo = true;
    model.isReply = !!model.rootId;
    model.isAuthor = (userId: string) => model.userId === userId;
    model.isImage = () => model.type === "image";
    model.isNote = () => model.type === "note";
    model.isDatafile = () => model.type === "datafile";
    model.isAudio = () => model.type === "audio";
    model.isVideo = () => model.type === "video";

    model.replies = addPostProps(demoSpaceId, model.replies);
    model.space = addSpaceProps(demoSpaceId, model.space);

    return model;
  });
};

const addMemberProps = (members: any[]) => {
  members = members || [];
  return members.map((model: any) => {
    model.can = { becomeAthlete: false, beManaged: false, beDeleted: false };
    model.isDemo = true;

    // hide private data
    model.email = "";
    model.phone = "";
    model.phoneCountry = "";
    model.gender = "";

    return model;
  });
};

const parseDemoSpace = (model: ApiDemoSpace): DemoSpaceModel => {
  if (model != null) {
    const demoSpace = {
      demoSpaceId: model.id,
      sports: model.sports,
      description: model.description,
      ...serialize(JSON.parse(model.data)),
    };
    demoSpace.space = addSpaceProps(model.id, demoSpace.space);
    demoSpace.posts = addPostProps(model.id, demoSpace.posts);
    demoSpace.members = addMemberProps(demoSpace.members);
    return demoSpace;
  }
  return null;
};

export function fetchDemoSpaces(
  demoSpaceId?: string,
  sport?: string,
  signal?: AbortSignal,
): Promise<DemoSpaceMap> {
  let url = "/demo_spaces";
  if (demoSpaceId != null) {
    url = `/demo_spaces/${demoSpaceId}`;
  }

  const sportSortFn = (a: DemoSpaceModel, b: DemoSpaceModel) => {
    const aMatch: number = a.sports.indexOf(sport);
    const bMatch: number = b.sports.indexOf(sport);

    if (aMatch === bMatch) {
      return 0;
    } else if (aMatch !== -1) {
      return -1;
    } else if (bMatch !== -1) {
      return 1;
    } else {
      return 0;
    }
  };

  // const args: { sport?: string } = {};
  // if (sport != null) {
  //   args.sport = sport;
  // }

  return fetchGet(url, {}, {}, signal).then(
    (data: ApiDemoSpace | ApiDemoSpace[]): DemoSpaceMap => {
      if (!Array.isArray(data)) {
        data = [data];
      }
      const demoSpaces = data.map(parseDemoSpace).sort(sportSortFn);
      return createMap(demoSpaces, "demoSpaceId");
    },
  );
}

const pending = () => action("@demo-space.fetch.pending");
const error = (err: string) => action("@demo-space.fetch.error", err);
const success = (model: DemoSpaceMap, updater: typeof fetchDemoSpaceAction) =>
  action("@demo-space.fetch.success", { model, updater });
const selectedDemoSpace = (demoSpaceId: DemoSpaceModel) =>
  action("@demo-space.selected", demoSpaceId);

const thisActions = { pending, error, success, selectedDemoSpace };
export type DemoSpaceAction = ActionType<typeof thisActions>;

export function fetchDemoSpaceAction(demoSpaceId?: string, sport?: string) {
  return (dispatch: Dispatch<DemoSpaceAction>) => {
    dispatch(pending());

    const convertHashMapToObject = (hashMap: any) => {
      if (!hashMap || !hashMap._hash) {
        return null;
      }

      const result: { [key: string]: any } = {};
      const keys = Object.keys(hashMap._hash);

      keys.forEach((key) => {
        const item = hashMap._hash[key];
        if (item && item.demoSpaceId) {
          result[item.demoSpaceId] = item;
        }
      });

      return result;
    };

    const updateFunc = actionUpdater(
      dispatch,
      fetchDemoSpaceAction,
      demoSpaceId,
      sport,
    );

    const promise = fetchDemoSpaces(demoSpaceId, sport);
    promise.then((model) => {
      const demoSpace = convertHashMapToObject(model);
      dispatch(selectedDemoSpace(demoSpace[demoSpaceId]));
      dispatch(success(model, updateFunc));
    });
    promise.catch((e: ErrorResponse) => dispatch(error(e.errors[0])));
    return promise;
  };
}

export const actions = {
  fetchDemoSpaceAction,
};
