import React from 'react';

import LessonSchedule from './LessonSchedule';
import ScheduleBlock from './ScheduleBlock';

type PropsDef = {
  event: any,
  timeText: string,
};

export default function Event(props: PropsDef) {
  if (props.event.extendedProps.type === 'lesson_schedule') {
    return <LessonSchedule {...props} />;
  } else if (props.event.extendedProps.type === 'coach_schedule_block') {
    return <ScheduleBlock {...props} />;
  } else {
    return <span>{props.timeText}</span>;
  }
}
