/** @module components/subscription/upgrade */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLoc, useActions } from "app/utils/hooks";
import { RootState } from "app/reducers";
import { fetchPlans } from "app/actions/subscription-plan";
import { cls } from "app/utils";
import { SubscriptionPlanModel } from "app/models";
import CheckBox from "app/components/inputs/CheckBox";
import { Loc } from "app/components/helpers";
import styles from "./styles.module.scss";
import i18n from "app/i18n";

type PlanTypeDef = {
  id: string;
  title: string;
  subtitle: string;
};

const ENTERPRISE_PLAN: SubscriptionPlanModel = {
  id: "coach+enterprise",
  amount: 0,
  currency: "usd",
  termKind: "Enterprise",
  productKind: "CoachPlus",
};

export const PLAN_TYPES: PlanTypeDef[] = [
  {
    id: "coach+yearly",
    title: i18n.t("CoachNow+ Yearly"),
    subtitle: i18n.t("{{cost}} / year"),
  },
  {
    id: "coach+monthly",
    title: i18n.t("CoachNow+ Monthly"),
    subtitle: i18n.t("{{cost}} / month"),
  },
  {
    id: "coachpro+yearly",
    title: i18n.t("CoachNow PRO Yearly"),
    subtitle: i18n.t("{{cost}} / year"),
  },
  {
    id: "coachpro+monthly",
    title: i18n.t("CoachNow PRO Monthly"),
    subtitle: i18n.t("{{cost}} / month"),
  },
  {
    id: "coach+enterprise",
    title: i18n.t("CoachNow+ Business"),
    subtitle: i18n.t("Contact us for pricing"),
  },
];

type PropsDef = {
  selectPlan: string;
  onlyPlans?: string | null; // coach+ or coachpro+ – a condition to filter out plans, null by default (all plans)
  disabled?: boolean;
  onChange?: (value: SubscriptionPlanModel) => void;
};

const defaultPlan = "coach+yearly";

/**
 * @class PlanTypes
 *
 */
export default function PlanTypes(props: PropsDef) {
  const actions = useActions({ fetchPlans });
  const subscriptionPlan = useSelector(
    (state: RootState) => state.subscriptionPlan,
    shallowEqual
  );
  const [selected, setSelected] = useState<SubscriptionPlanModel>(null);

  useEffect(() => (actions.fetchPlans(), undefined), []);

  const getPlan = (planId: string): SubscriptionPlanModel | null => {
    if (planId === "coach+enterprise") {
      return ENTERPRISE_PLAN;
    } else if (subscriptionPlan.data != null) {
      return subscriptionPlan.data.find(
        (m: SubscriptionPlanModel) => m.id === planId
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    const planId = props.selectPlan || defaultPlan;
    if (subscriptionPlan.data != null) {
      setSelected(getPlan(planId));
    }
  }, [subscriptionPlan.data, props.selectPlan]);

  if (selected === null) {
    return <div />;
  }

  const children = PLAN_TYPES.map((plan: PlanTypeDef) => {
    const planModel = getPlan(plan.id);

    if (props.onlyPlans !== null && !plan.id.match(props.onlyPlans)) {
      return <div key={plan.id} />;
    }

    return (
      <CheckboxContainer
        key={plan.id}
        type={plan}
        model={planModel}
        selected={selected}
        onChange={() => setSelected(planModel)}
        disabled={props.disabled}
      />
    );
  });

  return <div className={styles.planTypes}>{children}</div>;
}

type CheckProps = {
  key: string;
  type: PlanTypeDef;
  model: SubscriptionPlanModel;
  selected: SubscriptionPlanModel;
  onChange: () => void;
  disabled: boolean;
};

const CheckboxContainer = (props: CheckProps) => {
  const { formatNumber } = useLoc();
  const name = "plan_type";

  // convert cost to a monthly cost
  let costNum: number = props.model.amount;
  // if (props.type.id === 'coach+yearly' || props.type.id === 'coachpro+yearly') {
  //   costNum = Math.floor(costNum / 12);
  // }

  // convert cost to dollars
  costNum = costNum / 100;

  const cost = formatNumber(costNum, {
    style: "currency",
    currency: props.model.currency.toUpperCase(),
  });
  const title = props.type.title;
  const subtitle = props.type.subtitle.replace("{{cost}}", cost);
  const label = (
    <div className={styles.label}>
      <h3>
        <Loc value={title} />
      </h3>
      <h4>
        <Loc value={subtitle} />
      </h4>
    </div>
  );

  const isSelected = props.selected.id === props.type.id;

  return (
    <div className={cls(styles.radio, isSelected ? styles.selected : "")}>
      <CheckBox
        name={name}
        label={label}
        checked={isSelected}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <div className={styles.clearFloat}></div>
    </div>
  );
};
