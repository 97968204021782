import React from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { setSelectedMembersRole } from "app/actions/team-group-member-import";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onNext?: () => void;
}

const SelectMemberRoleModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onNext,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedMembersRole } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );

  const ChannelSettingsData = [
    {
      label: t("Coach/Admin (Invite with email only)"),
      value: "coach",
      description: t(
        "Can post and interact, manage the Group, manage Group members, and manage Channels."
      ),
    },
    {
      label: t("Member"),
      value: "player",
      description: t("Can post and interact."),
    },
    {
      label: t("Parent / Fan"),
      value: "fan",
      description: t("Can post and interact."),
    },
    {
      label: t("Viewer"),
      value: "viewer",
      description: t("View only."),
    },
  ];

  const handleMemberRoleChange = (role: string) => {
    dispatch(setSelectedMembersRole(role));
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Select Role")}
    >
      <div className={styles.container}>
        <div className={styles.header_text_wrapper}>
          <p>
            <b>{t("Select Member Role")}</b>
          </p>
          <p>{t("Imported members will be given the following role.")}</p>
        </div>
        <ul className={cls("stack-list-items")}>
          {ChannelSettingsData.map((setting) => (
            <li
              key={setting.label}
              className={cls("stack-list-item selectable-item", {
                active: selectedMembersRole === setting.value,
              })}
            >
              <label
                className="selectable-item-trigger"
                htmlFor={setting.label}
              ></label>
              <div className="selectable-item-control">
                <div className="radio">
                  <input
                    type="radio"
                    name="inviteMemberType"
                    id={setting.label}
                    value={setting.value}
                    defaultChecked={selectedMembersRole === setting.value}
                    onClick={() => handleMemberRoleChange(setting.value as any)}
                  />
                  <label className="opaque" htmlFor={setting.label}></label>
                </div>
              </div>

              <div className="selectable-item-details">
                <div className="selectable-item-label ng-binding">
                  {setting.label}
                </div>
                <div className="selectable-item-summary ng-binding">
                  {setting.description}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.btn_wrapper}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onClose}
        />
        <Button buttonType="primary" title={t("Next")} onClickFunc={onNext} />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default SelectMemberRoleModal;
