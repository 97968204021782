import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles.module.scss";
import feedPostIcon from "../../../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../../../public/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "../../../../../public/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "../../../../../public/images/smart-list/60-plus-calendar-icon.svg";
import listIcon from "../../../../../public/images/smart-list/list-icon.svg";
import CheckBox from "app/components/inputs/CheckBox";
import { cls } from "app/utils";
import SmartListModel from "app/models/SmartListModel";

interface Props {
  lists: any;
  toggleListClick: (item: {}) => void;
  selectedLists: any;
}

const SmartListLocations = ({
  lists,
  toggleListClick,
  selectedLists,
}: Props) => {
  const { t } = useTranslation();

  const getIcon = (name: string) => {
    if (name.includes("15")) {
      return fifteenDaysIcon;
    } else if (name.includes("30")) {
      return thirtyDaysIcon;
    } else if (name.includes("60+")) {
      return sixtyDaysIcon;
    } else if (name.includes("Unanswered")) {
      return feedPostIcon;
    } else if (name.includes("segment")) {
      return listIcon;
    }
    return null;
  };

  if (!lists || (lists && lists.length <= 0)) {
    return null;
  }

  return (
    <>
      <label className={styles.list_title}>{t("Smart Lists")}</label>
      {lists &&
        lists.map((smartList: SmartListModel) => (
          <div key={smartList.id} onClick={() => toggleListClick(smartList)}>
            <div className={styles.dropdownItem}>
              <div className="avatar">
                <img
                  src={getIcon(smartList.name)}
                  alt={smartList.name}
                  className={styles.smartListIcon}
                />
              </div>
              <div
                className={cls(styles.name, {
                  // [styles.disabled]: !smartList.spaceIds,
                })}
              >
                {smartList.name}
              </div>
              <CheckBox
                className={styles.checkbox}
                name={smartList as any}
                checked={selectedLists.has(smartList)}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default memo(SmartListLocations);
