import React from "react";
import UserAvatar from "../UserAvatar";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import Button from "../Button/ButtonVariant";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";

const SelectedUsers = ({
  users,
  checkIfItsMe,
  onRemoveUserClick,
}: {
  users: UserModel[];
  checkIfItsMe?: boolean;
  onRemoveUserClick?: (user: UserModel) => void;
}) => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.self, shallowEqual);

  if (Array.isArray(users) && users.length === 0) {
    return null;
  }

  const handleRemoveUser = (user: UserModel) => {
    if (onRemoveUserClick) {
      onRemoveUserClick(user);
    }
  };

  return (
    <div className={styles.users_list}>
      {users.map((user: UserModel) => {
        return (
          <div className={styles.user_container} key={user.id}>
            <div className={styles.user_info_container}>
              <UserAvatar
                className={styles.avatar}
                sizeWidth={44}
                sizeHeight={44}
                altName="user"
                userName={user.displayName}
                srcName={user.avatar as string}
              />
              <div className={styles.user_info}>
                <h4>
                  {user.displayName}{" "}
                  {checkIfItsMe && user.id === userData.id && t("(you)")}
                </h4>
                <p>{user.email || user.phoneNormalized}</p>
              </div>
            </div>
            {onRemoveUserClick && (
              <Button
                buttonStyles={styles.btn}
                title={<i className={cls("ico ico-x text-danger")} />}
                onClickFunc={() => handleRemoveUser(user)}
                buttonType="secondary-outline"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SelectedUsers;
