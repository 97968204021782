import React from "react";
import styles from "./styles.module.scss";
import InvitationModel from "app/models/InvitationModel";
import { cls } from "app/utils";
import UserAvatar from "app/components/UserAvatar";
import Button from "app/components/Button/ButtonVariant";
import { notificationDate } from "app/utils/notificationDate";
import { useHistory } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import { acceptInvitation } from "app/actions/invitation";
import { getTotalUnseen } from "app/actions/Notification";
import { useTranslation } from "react-i18next";

type SingleInvitationProps = {
  invitation: InvitationModel;
  onDecline?: (inviteId: string) => void;
};

const SingleInvitation = ({ invitation, onDecline }: SingleInvitationProps) => {
  const { t } = useTranslation();
  const invitationAction = useActions({ acceptInvitation, getTotalUnseen });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const formatSummary = (invite: InvitationModel) => {
    if (invite.object === "space_invitation") {
      return `${invite.inviterNameShort} ${t("invited you to")} ${
        invite.spaceName
      } / ${invite.spaceName}`;
    } else if (invite.object === "team_invitation") {
      return `${invite.inviterNameShort} ${t("invited you to")} ${
        invite.teamName
      } ${invite.sport ? `/ ${invite.sport}` : ""}`;
    } else {
      return null;
    }
  };

  const formatAcceptButton = (invite: InvitationModel) => {
    if (invite.object === "space_invitation") {
      return t("Join Space");
    }

    return t("Join Group");
  };

  const onJoin = async (invite: InvitationModel) => {
    if (invite) {
      try {
        await invitationAction.acceptInvitation(invite.id);
        await invitationAction.getTotalUnseen();
        if (invite.object === "space_invitation") {
          route(`/spaces/${invite.spaceId}`);
        }
      } catch (error) {
        route("/500");
      }
    }
  };

  return (
    <div className={cls(styles.invitation)}>
      <UserAvatar
        altName={t("user avatar")}
        sizeHeight={44}
        sizeWidth={44}
        className={styles.avatar}
        srcName={
          (invitation.initiator && invitation.initiator.avatarUrl) ||
          invitation?.inviterAvatar ||
          invitation?.teamLogo
        }
        userName={
          (invitation.initiator && invitation.initiator.name) ||
          invitation?.inviterName ||
          invitation?.name
        }
      />
      <div className={styles.content_container}>
        <div className={styles.content_info}>
          <div className={styles.content_wrapper}>
            <span className={styles.content_message}>
              <p className={styles.text_message}>{formatSummary(invitation)}</p>
            </span>
            <span className={styles.content_info}>
              <b>
                {invitation.createdAt && notificationDate(invitation.createdAt)}
              </b>
            </span>
          </div>
        </div>
        <div className={styles.buttons_wrapper}>
          <Button
            title={formatAcceptButton(invitation)}
            buttonType="primary"
            onClickFunc={() => onJoin(invitation)}
          />
          <Button
            title={t("Decline")}
            buttonType="secondary-outline-danger"
            onClickFunc={() => onDecline(invitation.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleInvitation;
