/** @module reducers */
import { InvitationAction } from "app/actions/invitation";
import { InvitationModel } from "app/models";

export interface InvitationState {
  data: InvitationModel | null;
  error: string | null;
  joined: boolean | null;
  pending: boolean;
  outgoingInvitations: number;
}

const initState: InvitationState = {
  data: null,
  error: null,
  joined: null,
  pending: false,
  outgoingInvitations: 0,
};

export default function teamReducer(
  state = initState,
  action: InvitationAction
): InvitationState {
  switch (action.type) {
    case "@invite.fetch.pending":
      return { ...state, pending: true, error: null };
    case "@invite.fetch.success":
      return { ...state, pending: false, data: action.payload };
    case "@invite.fetch.error":
      return { ...state, pending: false, error: action.payload };

    case "@invite.fetch.outgoing":
      return { ...state, outgoingInvitations: action.payload };

    case "@invite.join.pending":
      return { ...state, pending: true, error: null };
    case "@invite.join.success":
      return { ...state, pending: false, joined: true };
    case "@invite.join.error":
      return { ...state, pending: false, error: action.payload, joined: false };
    case "@invite.clear":
      return {
        ...state,
        ...initState,
      };

    default:
      return state;
  }
}
