export default function duration(ms: number) {
  if (ms === 0) {
    // @ts-ignore
    return;
  }

  let seconds: string | number = Math.floor((ms / 1000) % 60);
  let minutes: string | number = Math.floor((ms / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((ms / (1000 * 60 * 60)) % 24);
  let finalTime = "";

  if (hours !== 0) {
    hours = hours < 10 ? "0" + hours : hours;
    finalTime += hours + ":";
  }

  if (minutes === 0) {
    finalTime += minutes + ":";
  } else {
    minutes = minutes < 10 ? "0" + minutes : minutes;
    finalTime += minutes + ":";
  }

  seconds = seconds < 10 ? "0" + seconds : seconds;
  finalTime += seconds;

  return finalTime;
}
