import React from "react";
import styles from "./styles.module.scss";
import GettingStartedModal from "app/components/Layouts/GettingStartedModal";
import { GettingStartedModalProps } from "../index.types";
import { useTranslation } from 'react-i18next';
import SpaceDemoList from "app/routes/Spaces/SpaceDemoList";

const InspirationSpaceModal = ({
  onClose,
  isOpen,
}: GettingStartedModalProps) => {
  const { t } = useTranslation();

  return (
    <GettingStartedModal onClose={onClose} isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.information_content}>
          <div className={styles.info_title}>{t('Inspiration Spaces')}</div>
          <p>
            {t('Looking for the CoachNow quick start cheat code? Look no further than Inspiration Spaces. In just a few minutes you\'ll see exactly how coaches like you are connecting and coaching using CoachNow.')}
          </p>
        </div>
        <div className={styles.suggested_accounts}>
          <SpaceDemoList />
        </div>
      </div>
    </GettingStartedModal>
  );
};

export default InspirationSpaceModal;
