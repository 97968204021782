/**
 * @module Components.Wrappers
 *
 */
import { useStoreActions } from "app/utils/hooks";
import { fetchSelf } from "app/actions/user";
import React, { memo } from "react";
import Authenticated from "./Authenticated";
import SiteWrapper from "../Layouts/SiteWrapper";
import { useHistory } from "react-router-dom";
import { hasFeature } from "app/utils/hasFeature";
import i18n from "app/i18n";

interface IPrivateRouteProps {
  children: React.ReactNode;
  ignoreReturn?: boolean;
  trialNotAllowed?: boolean;
  permission?: string;
  permissionObject?: string;
}

function PrivateRoute(props: IPrivateRouteProps) {
  const { user } = useStoreActions({ user: fetchSelf });
  const pending = user.pending || user.data == null;
  const history = useHistory();

  if (!pending && user) {
    i18n.changeLanguage(user.data?.locale);

    let allowed = true;

    if (props?.permissionObject === "feature") {
      if (
        props.permission &&
        !hasFeature(user?.data?.features, props.permission)
      ) {
        allowed = false;
      }
    }

    if (!allowed) {
      history.push("/403");
    }
  }

  return (
    <Authenticated>
      <SiteWrapper>{props.children}</SiteWrapper>
    </Authenticated>
  );
}

export default memo(PrivateRoute);
