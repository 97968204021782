import React from "react";
import styles from "./styles.module.scss";
import { OutgoingInvitationsModel } from "./indexModel";
import { Loading } from "app/components/Wrappers";
import InvitationModel from "app/models/InvitationModel";
import SingleOutgoingInvitation from "app/components/InvitationsList/SingleOutgoingInvitation";
import { getInvitationGroupTitle } from "app/components/helpers/getInvitationGroupTitle";
import { useTranslation } from 'react-i18next';

interface Props {
  outgoingInvitationsCount?: number;
}

const OutgoingInvitations = ({ outgoingInvitationsCount }: Props) => {
  const { t } = useTranslation();
  const { loading, outgoingInvitations, containerRef, onCancelInvite } =
    OutgoingInvitationsModel();

  if (
    (!outgoingInvitationsCount || outgoingInvitationsCount === 0) &&
    !loading
  ) {
    return (
      <div className={styles.empty_container}>
        <p className={styles.empty_text}>{t("No invitations")}</p>
      </div>
    );
  }

  return (
    <>
      {outgoingInvitations &&
        Object.entries(outgoingInvitations).map(
          ([key, invitations]: any) =>
            invitations.length > 0 && (
              <div className={styles.invitations_list} key={key}>
                <label className={styles.group_label}>
                  {getInvitationGroupTitle(key, t)}
                </label>
                <div className={styles.list}>
                  {invitations.map((notification: InvitationModel) => {
                    return (
                      <div key={notification.id}>
                        <SingleOutgoingInvitation
                          invitation={notification}
                          onCancelInvite={onCancelInvite}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )
        )}
      {loading && <Loading loadType="spinner" isLoading={loading} />}
      <div ref={containerRef} />
    </>
  );
};

export default OutgoingInvitations;
