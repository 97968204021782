import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";

interface Props {
  isOpen?: boolean;
  onBack?: (colName: string) => void;
  changeFor: string;
  onConfirm?: (value: string, data: string[]) => void;
}

const ChangeImportedColumnModal: React.FC<Props> = ({
  isOpen,
  onBack,
  changeFor,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();
  const { membersListToInvite } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );

  const [pagesCount, setPagesCount] = useState(1);
  const [selectedColumnPage, setSelectedColumnPage] = useState(1);
  const [selectedColumnData, setSelectedColumnData] = useState([]);

  useEffect(() => {
    if (membersListToInvite && membersListToInvite.length !== 0) {
      let totalCount = 0;
      membersListToInvite.forEach((member: UserModel[]) => {
        const { email, name, position, jerseyNumber }: any = member;

        totalCount =
          (email && email.length ? 1 : 0) +
          (name && name.length ? 1 : 0) +
          (position && position.length ? 1 : 0) +
          (jerseyNumber && jerseyNumber.length ? 1 : 0);
      });
      setPagesCount(totalCount);

      setSelectedColumnData(
        Array.from({ length: totalCount }, (_, index) => {
          return membersListToInvite.map((member: UserModel) => {
            const { email, name, position, jerseyNumber }: any = member;
            if (index === 0) {
              return email;
            } else if (index === 1) {
              return name;
            } else if (index === 2) {
              return position;
            } else {
              return jerseyNumber;
            }
          });
        })
      );
    } else {
      setPagesCount(1);
    }
  }, [membersListToInvite]);

  const renderPageData = (page: number) => {
    if (!selectedColumnData.length) {
      return null;
    }

    const pageData = selectedColumnData[page - 1];
    if (!pageData) {
      return null;
    }

    return (
      <table className="csv-column-table">
        {pageData.map((data: string, index: number) => (
          <tr key={index}>
            <td>{data}</td>
          </tr>
        ))}
      </table>
    );
  };

  const onPagePrev = () => {
    if (selectedColumnPage === 1) {
      return;
    } else {
      setSelectedColumnPage(selectedColumnPage - 1);
    }
  };

  const onPageNext = () => {
    if (selectedColumnPage === pagesCount) {
      return;
    } else {
      setSelectedColumnPage(selectedColumnPage + 1);
    }
  };

  const onPageSelect = (page: number) => {
    setSelectedColumnPage(page);
  };

  const onColumnChangeConfirm = (data: string[]) => {
    onConfirm(changeFor, data);
  };

  const titleNameRender = () => {
    if (!changeFor) {
      return null;
    }
    if (changeFor === "email") {
      return t("email addresses?");
    } else if (changeFor === "name") {
      return t("names?");
    } else if (changeFor === "position") {
      return t("positions?");
    } else {
      return t("jersey numbers?");
    }
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={() => onBack("")}
      headerTitle={t("Select Email Column")}
    >
      <div className={styles.header_text_wrapper}>
        <p>
          {t("Which column from your file is for")} <b>{titleNameRender()}</b>{" "}
          {t("Use the numbers or arrows to switch columns.")}
        </p>
      </div>
      <div className={styles.list_wrapper}>
        <div className={styles.csv_column_preview}>
          <div className={styles.pager}>
            <Button
              icon={<i className="ico ico-back" />}
              title=""
              buttonType="secondary-outline"
              disabled={selectedColumnPage === 1}
              onClickFunc={onPagePrev}
              buttonStyles={cls({
                [styles.inactive_page_btn]: selectedColumnPage !== 1,
              })}
            />
            {Array.from({ length: pagesCount }, (_, index) => index + 1).map(
              (page) => (
                <Button
                  key={page}
                  title={page.toString()}
                  onClickFunc={() => onPageSelect(page)}
                  disabled={selectedColumnPage === page}
                  buttonStyles={cls({
                    [styles.inactive_page_btn]: selectedColumnPage !== page,
                  })}
                  buttonType={
                    selectedColumnPage === page ? null : "secondary-outline"
                  }
                />
              )
            )}
            <Button
              icon={<i className="ico ico-forward" />}
              title=""
              onClickFunc={onPageNext}
              disabled={selectedColumnPage === pagesCount}
              buttonType="secondary-outline"
              buttonStyles={cls({
                [styles.inactive_page_btn]: selectedColumnPage !== pagesCount,
              })}
            />
          </div>
          <div className={styles.list_data_container}>
            {/* <table className="csv-column-table">
              {selectedColumnData &&
                selectedColumnData.map((data: string, index: number) => (
                  <tr key={index}>
                    <td>{data}</td>
                  </tr>
                ))}
            </table> */}
            {renderPageData(selectedColumnPage)}
          </div>
        </div>
        <div className={styles.select_column_btn_container}>
          <Button
            title={t("Select This Column")}
            buttonType="primary"
            icon={<i className="ico ico-check" />}
            onClickFunc={() =>
              onColumnChangeConfirm(selectedColumnData[selectedColumnPage - 1])
            }
            buttonStyles={styles.select_column_btn}
          />
        </div>
      </div>
      <div className={styles.cancel_btn}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onBack}
        />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ChangeImportedColumnModal;
