import { useState, useEffect } from "react";
import { CreateTeamGroupModel } from "../../models/TeamGroupModel";
import { RootState } from "typedefs";
import { useActions, useSelector, useStoreActions } from "app/utils/hooks";
import { createTeamGroup } from "app/actions/team-group";
import { fetchSpacesTemplates } from "app/actions/spaceTemplate";
import { DefaultOption } from "../inputs/SelectBox/Base";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";

interface Props {
  onClose?: () => void;
}

export const useCreateNewChannelModel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const groupId = useSelector(
    (state: RootState) => state.groups.selectedGroup.id,
    shallowEqual,
  );
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const ChannelSettingsData = [
    {
      label: t("Private"),
      value: "private",
      description: t(
        "Only visible to Group Members. Only coaches can add Group Members.",
      ),
    },
    {
      label: t("Joinable"),
      value: "joinable",
      description: t(
        "Visible to all Group Members. Any Group Member can join.",
      ),
    },
    {
      label: t("Auto-Join"),
      value: "auto_join",
      description: t(
        "All current and new Group members will be automatically added to the Group.",
      ),
    },
  ];

  const [newChannelData, setNewChannelData] = useState<CreateTeamGroupModel>({
    group_type: "private",
    name: null,
    space_template_id: null,
    team_id: groupId,
    description: null,
  });

  const { spaceTemplates } = useStoreActions({
    spaceTemplates: fetchSpacesTemplates,
  });

  const teamGroupActions = useActions({ createTeamGroup });

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, name: value });
  };

  const groupTypeHandler: React.MouseEventHandler<HTMLDivElement> = (value) => {
    setNewChannelData({
      ...newChannelData,
      group_type: value as any,
    });
  };

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, description: value });
  };
  const [templpateOptions, setTemplateOptions] = useState([]);

  const createTemplateOption = (val: {
    name?: string;
    id?: string;
  }): DefaultOption => {
    return { value: val.name, label: val.name, id: val.id };
  };

  useEffect(() => {
    if (spaceTemplates && spaceTemplates.data != null) {
      const namesArray = spaceTemplates.data.map(
        (obj: { name: string; id: string }) => obj,
      );
      setTemplateOptions(namesArray.map(createTemplateOption));
    }
  }, [spaceTemplates]);

  const handleTemplateSelect = (value: any) => {
    if (value) {
      setNewChannelData({ ...newChannelData, space_template_id: value.id });
    }
  };

  const createHandler = () => {
    const trimmedName = newChannelData.name.trim();
    if (trimmedName.length !== 0) {
      teamGroupActions.createTeamGroup({ ...newChannelData }).then((res) => {
        if (res) {
          if (onClose) {
            onClose();
          }
          route(`/groups/${groupId}/channels/${res.id}`);
        }
      });
    }
  };

  return {
    ChannelSettingsData,
    setNewChannelData,
    newChannelData,
    handleNameChange,
    groupTypeHandler,
    handleDescriptionChange,
    handleTemplateSelect,
    templpateOptions,
    createHandler,
  };
};
