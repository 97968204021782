import { clearGroup, fetchGroupById } from "app/actions/groups";
import {
  fetchTeamGroups,
  fetchTeamGroupData,
  clearTeamGroup,
} from "app/actions/team-group";
import { fetchJoinableTeam } from "app/actions/team-group-member-import";
import { useActions, useSelector } from "app/utils/hooks";
import { useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";

export const GroupPageLayoutModel = () => {
  const groupActions = useActions({
    fetchGroupById,
    fetchTeamGroupData,
    fetchJoinableTeam,
    fetchTeamGroups,
    clearGroup,
    clearTeamGroup,
  });
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
  ) as any;
  const { data: channels } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const [joinableList, setJoinableList] = useState([]);

  const url = window.location.pathname;
  const parts = url.split("/");
  const groupId = parts[parts.indexOf("groups") + 1];
  const teamId = parts[parts.indexOf("channels") + 1];

  useMemo(() => {
    if (teamId && channels) {
      groupActions.fetchTeamGroupData(teamId);
    }
  }, [channels]);

  useMemo(() => {
    Promise.all([
      groupActions.fetchGroupById(groupId),
      groupActions.fetchTeamGroups(groupId),
      groupActions.fetchJoinableTeam(groupId).then((res) => {
        setJoinableList(res);
      }),
    ]);

    return () => {
      groupActions.clearGroup();
      groupActions.clearTeamGroup();
    };
  }, [groupId, teamId]);

  return { selectedGroup, channels, joinableList };
};
