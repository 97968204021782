import React from "react";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import CheckBox from "../inputs/CheckBox";
import { useTranslation } from 'react-i18next';

interface Props {
  openMoveModal: boolean;
  setOpenMoveModal: (arg: boolean) => void;
  movePostReplies: boolean;
  setMovePostReplies: (arg: boolean) => void;
  sendNotification: boolean;
  setSendNotification: (arg: boolean) => void;
  createMovePost: () => void;
}

const MoveModal = ({
  openMoveModal,
  setOpenMoveModal,
  movePostReplies,
  setMovePostReplies,
  sendNotification,
  setSendNotification,
  createMovePost,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalLayout isOpen={openMoveModal} onClose={() => setOpenMoveModal(false)}>
      <div className={styles.move_modal_container}>
        <h1>{t('Move this post?')}</h1>
        <CheckBox
          checked={movePostReplies}
          label={t('Move Post replies')}
          onChange={setMovePostReplies}
        />
        <CheckBox
          checked={sendNotification}
          label={t('Send notifications about new post')}
          onChange={setSendNotification}
        />
        <div className={styles.move_actions}>
          <button
            className={styles.cancel_btn}
            onClick={() => setOpenMoveModal(false)}
          >
            <div>{t('Cancel')}</div>
          </button>
          <button
            type="button"
            className={styles.save_btn}
            onClick={createMovePost}
          >
            <div>{t('Move')}</div>
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default MoveModal;
