import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import AudioPlayer from "app/components/AudioPlayer";
import Datafile from "app/components/Datafile";
import useVideoDuration from "app/utils/hooks/usVideoDuration";
import { useActions } from "app/utils/hooks";
import { fetchMediaById } from "app/actions/media-library-data";
import { Loading } from "app/components/Wrappers";

interface Props {
  mediaData: any;
  onClick: (data?: any) => void;
}

export const ReplyAttachmentPreview = ({ mediaData, onClick }: Props) => {
  const mediaActions = useActions({ fetchMediaById });
  const [selectedMedia, setSelectedMedia] = useState(mediaData);

  useEffect(() => {
    const fetchMedia = () => {
      if (selectedMedia?.id && !selectedMedia?.processed) {
        mediaActions.fetchMediaById(selectedMedia?.id).then((res) => {
          if (res?.processed) {
            setSelectedMedia(res);
          }
        });
      }
    };

    let pollingInterval: any;

    pollingInterval = setInterval(() => {
      fetchMedia();

      if (selectedMedia?.processed) {
        clearInterval(pollingInterval);
      }
    }, 5000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [selectedMedia]);

  const duration = useVideoDuration({
    attachment: mediaData,
    isDemo: selectedMedia?.type === "video",
  });

  if (selectedMedia?.type === "image") {
    return (
      <div className={styles.preview_container}>
        <img
          className={styles.thumbnail}
          src={selectedMedia?.originalUrl}
          alt="media"
          onClick={() => onClick(mediaData)}
        />
      </div>
    );
  }

  if (selectedMedia?.type === "video") {
    if (!selectedMedia?.processed) {
      return (
        <div className={styles.loading_preview_container}>
          <Loading isLoading loadType="spinner" />
        </div>
      );
    } else {
      return (
        <div className={styles.preview_container}>
          <div
            className={styles.video_container}
            onClick={() => onClick(mediaData)}
          >
            <span className={styles.mediaOverlay}>{duration}</span>
            <img
              className={styles.thumbnail}
              src={selectedMedia?.thumbnails?.default}
              alt="media"
            />
          </div>
        </div>
      );
    }
  }

  if (selectedMedia?.type === "datafile") {
    return (
      <a
        className={cls(styles.datafile_container)}
        href={selectedMedia?.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Datafile
          title={selectedMedia?.filename}
          type={selectedMedia?.extension}
        />
      </a>
    );
  }

  if (selectedMedia?.type === "audio") {
    return <AudioPlayer data={mediaData} />;
  }

  return null;
};
