import React, { useEffect } from "react";
import ReturnBar from "./Navbar/ReturnBar";
import PagePanel from "app/components/PagePanel";
import styles from "./styles.module.scss";
import CopyField from "./CopyField";
import { RootState } from "typedefs";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchStudentProfile } from "../../actions/scheduling/studentProfile";
import { useTranslation } from 'react-i18next';

export default function SubscribeCalendar() {
  const { t } = useTranslation();

  const studentProfile = useSelector((state: RootState) => state.studentProfile);

  const userId: any = useSelector((state: RootState) => state.user.self.id);

  const studentProfileActions = useActions({
    fetchStudentProfile,
  });

  useEffect(() => {
    studentProfileActions.fetchStudentProfile(userId);
  }, []);

  return (
    <>
      <ReturnBar returnTo="/sessions" />
      <PagePanel title={t('Calendar Integrations')}>
        <form>
          <div className={styles.inputContainer}>
            <label htmlFor="calendarUrl" className={styles.labelStyle}>
              {t('Subscribe to your Coach Now Calendar')}
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                {t('Copy the URL so you can paste it in your preferred calendar app.')}
              </div>
              <div className={styles.customText}>
                <strong className={styles.boldText}>{t('Sessions')}</strong>{" "}
                {t('will be synced with your preferred calendar.')}
              </div>
            </div>
            <CopyField value={studentProfile.data ? studentProfile.data.calendarSubscriptionUrl : "https://example.com/calendar.ics"} />
          </div>
        </form>
      </PagePanel>
    </>
  );
}
