import { request } from "app/utils/request";
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import config from "config/environment";

const pending = () => action("@timezone.fetch.pending");
const error = (err: string) => action("@timezone.fetch.error", err);
const success = (post: any) => action("@timezone.fetch.success", post);

export const clear = () => action("@timezone.clear");

const thisActions = {
  pending,
  error,
  success,
  clear,
};

export type TimezoneAction = ActionType<typeof thisActions>;

export function fetchTimezone() {
  return (dispatch: Dispatch<TimezoneAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v1/timezones`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}
