/**
 * @module Components.Buttons
 *
 */
import React, { FormEvent } from "react";
import { readFilesFromInput, PreviewFile } from "app/utils/file";
import { isEmpty, cls } from "app/utils";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  onChange: (f: PreviewFile[]) => void;
  file?: PreviewFile;
  disabled?: boolean;
};

/**
 * @class UploadFile
 *
 */
export default function UploadFile(props: PropsDef) {
  const { t } = useTranslation();
  const file = props.file;
  const text =
    file && !isEmpty(file.url)
      ? t("Picture (recommended)")
      : t("Add your photo");

  const onFileUpload = (e: FormEvent<HTMLInputElement>) => {
    readFilesFromInput(e).then(props.onChange);
  };

  const showImage = (f: PreviewFile | null) => {
    if (f && !isEmpty(f.url)) {
      return (
        <div
          className={styles.previewImage}
          style={{ backgroundImage: `url(${f.url})` }}
        />
      );
    }
    return <i className={cls(styles.previewIcon, "fas fa-camera")}></i>;
  };

  return (
    <div className={cls(styles.main, props.disabled ? styles.disabled : "")}>
      <input
        accept="image/*"
        type="file"
        disabled={props.disabled}
        onChange={onFileUpload}
      />

      <div className={styles.uploadInput}>
        <div className={styles.preview}>
          <span className={styles.previewContainer}>{showImage(file)}</span>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </div>
  );
}
