/**
 * @module Components.Selectbox
 *
 */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import Selectbox, {
  DefaultOption,
  OptionsType,
} from "app/components/inputs/SelectBox/Base";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  className?: string;
  maxDate?: number | string;
  minDate?: number | string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

const normailzeValue = (
  value?: string | null,
  defaultLabel?: string
): DefaultOption | null => {
  if (!value) {
    return { value: "", label: defaultLabel };
  }
  return { value, label: value };
};

/**
 * @class MonthSelect
 *
 */
export default function MonthSelect(props: PropsDef) {
  const { t } = useTranslation();
  const {
    className,
    minDate,
    maxDate,
    onChange,
    value,
    // defaultValue,
    ...rest
  } = props;
  const [__options, setOptions] = useState<OptionsType<DefaultOption>>(null);
  const [selectedMonth, setSelectedMonth] = useState(
    normailzeValue(value, t("Month"))
  );
  const [prevMonthIndex, setPrevMonthIndex] = useState<null | number>(null);

  useEffect(
    // @ts-ignore
    () => setOptions(generateMonths(minDate, maxDate)),
    [minDate, maxDate]
  );

  useEffect(() => {
    setSelectedMonth(normailzeValue(value, t("Month")));
  }, [value]);

  useMemo(() => {
    if (__options) {
      __options.filter((element) => {
        if (selectedMonth === element) {
          const index = __options.findIndex(
            (element) => element === selectedMonth
          );
          setPrevMonthIndex(index);
        }
      });

      if (__options.length < prevMonthIndex) {
        setSelectedMonth(__options[__options.length - 1]);
      } else {
        return;
      }
    }
  }, [__options, selectedMonth]);

  const onChangeFunc = (val: any) => {
    if (onChange) {
      setSelectedMonth(val);
      onChange(val);
    }
  };

  return (
    <Selectbox
      isCreatable={false}
      isClearable={false}
      isMulti={false}
      className={cls(styles.select, className, "c-inputs-month-select")}
      options={__options}
      value={selectedMonth}
      // defaultValue={normailzeValue(defaultValue)}
      onChange={onChangeFunc}
      {...rest}
    />
  );
}

function generateMonths(
  minDate: number,
  maxDate: number
): OptionsType<DefaultOption> {
  const minMonth = minDate != null ? moment(minDate).month() : 0;
  const maxMonth = maxDate != null ? moment(maxDate).month() : 11;
  const length = maxMonth - minMonth + 1;

  let monthIndex: number = minMonth;
  const increment = () => (monthIndex = monthIndex < 11 ? monthIndex + 1 : 0);

  return Array.apply(null, Array(length)).map(() => {
    const month = moment().month(monthIndex).format("MMM");
    increment();
    return { value: month, label: month };
  });
}
