import React from 'react';
import styles from './DateTime.module.scss';

const TimeSlot = ({ time, isSelected, onSlotSelect, onCurrentDay, multiCoach = false }) => {

  let currentBgColor;
  if (multiCoach) {
    currentBgColor = '#f4f4f4';
  } else {
    currentBgColor = onCurrentDay ? '#fff' : '#F4F4F4';
  }

  return (
    <div
      className={styles.time_slot_container}
      style={{
        backgroundColor: isSelected ? '#039BE5' : currentBgColor ,
      }}
      onClick={() => {
        onSlotSelect(time);
      }}>
      <div
        className={styles.time_slot_text}
        style={{ color: isSelected ? 'white' : '' }}>
        {`${time.startHour.toFormat('h:mm a')} - ${time.endHour.toFormat(
          'h:mm a'
        )}`}
      </div>
    </div>
  );
};

export default TimeSlot;
