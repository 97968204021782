import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Switch from "react-switch";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

interface SwitchToggleProps {
  defaultChecked: boolean;
  title: string;
  register: UseFormRegister<any>;
  name: string;
  customText?: string;
  disabled?: boolean;
  setValue: UseFormSetValue<any>;
  defaultValue?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
  forcedState?: boolean;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({
  defaultChecked,
  title,
  name,
  customText = "",
  disabled = false,
  setValue,
  label = "",
  onChange,
  forcedState,
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (forcedState !== undefined) {
      setChecked(forcedState);
      setValue(name, forcedState);
    }
  }, [forcedState, name, setValue]);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (newChecked: boolean) => {
    if (forcedState === undefined) {
      setChecked(newChecked);
      setValue(name, newChecked);
      if (onChange) {
        onChange(newChecked);
      }
    }
  };

  return (
    <div className={styles.containerToggle}>
      {label !== "" && <span className={styles.labelStyle}>{label}</span>}
      {customText !== "" && (
        <span className={styles.custom_text}>{customText}</span>
      )}
      <div className={styles.row}>
        <Switch
          checked={checked}
          onChange={handleChange}
          offColor="#C2C8D8"
          onColor="#27A4FF"
          onHandleColor="#FFFFFF"
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          height={30}
          width={50}
          className="react-switch"
          disabled={disabled || forcedState !== undefined}
          data-testid={`${title} Toggle`}
        />
        <span className={styles.tag}>{title}</span>
      </div>
    </div>
  );
};

export default SwitchToggle;
