import { defineMessages } from "react-intl";
import i18n from "app/i18n";

export default defineMessages({
  status: { id: "app.title.status", defaultMessage: i18n.t("Status") },
  status_plan: { id: "app.title.plan", defaultMessage: i18n.t("Plan") },
  status_plan_name: {
    id: "app.subscription.plan-monthly",
    defaultMessage: i18n.t("CoachNow+ Monthly"),
  },
  status_active_message: {
    id: "subscription.active-member-status",
    defaultMessage: i18n.t("You're a {planName} Member"),
  },

  cant_upgrade: {
    id: "subscription.cant-upgrade",
    defaultMessage: i18n.t("Please contact support to upgrade to CoachNow PRO"),
  },
  contact_support: {
    id: "subscription.contact-support",
    defaultMessage: i18n.t("Contact Support"),
  },

  upgrade_to_pro_title: {
    id: "subscription.status.upgrade_to_pro_title",
    defaultMessage: i18n.t("Upgrade to PRO"),
  },
  upgrade_to_pro_subtitle: {
    id: "subscription.status.upgrade_to_pro_subtitle",
    defaultMessage: i18n.t(
      'CoachNow Pro unlocks all of our powerful features. For more information on the PRO feature set, <a href="https://coachnow.io/pro">click here</a>.'
    ),
  },

  normal_price: {
    id: "subscription.status.normal_price",
    defaultMessage: i18n.t("Normal price"),
  },
  your_discount: {
    id: "subscription.status.your_discount",
    defaultMessage: i18n.t("Your discount"),
  },
  your_price: {
    id: "subscription.status.your_price",
    defaultMessage: i18n.t("Your price"),
  },
  coupon_not_allowed: {
    id: "subscription.status.coupon_not_allowed",
    defaultMessage: i18n.t("Discount can not be applied to the selected plan."),
  },

  upgrade_button: {
    id: "subscription.status.upgrade_button",
    defaultMessage: i18n.t("Upgrade Now"),
  },
  back_button: {
    id: "subscription.status.back_button",
    defaultMessage: i18n.t("Go to Dashboard"),
  },
});