import React from "react";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { useInviteWithEmailModel } from "./indexModel";
import CsvFileUpload from "app/components/CsvFileUpload";
import SelectMemberRoleModal from "../SelectMemberRoleModal";
import ImportedUsersModal from "../ImportedUsersModal";
import ConfirmInvitesModal from "../ConfirmInvitesModal";
import PendingInviteModal from "../PendingInviteModal";
import SuccessImportModal from "../SuccessImportModal";
import AddMembersToChannels from "app/components/modals/AddMembersToChannels";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const InviteWithCsv: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    selectedTeams,
    membersListToInvite,
    selectRoleModalOpen,
    importedUsersModalOpen,
    csvModalOpen,
    handleGetFile,
    onNextSelectRoleModal,
    onNextImportedUsersModal,
    confirmImportsModalOpen,
    importPending,
    importSuccess,
    onBackConfirmImportsModal,
    onNextAddMembersToChannelIsOpen,
    onBackAddMembersToChannelIsOpen,
    addMembersToChannelIsOpen,
    importMembersHandler,
  } = useInviteWithEmailModel();

  return (
    <>
      {csvModalOpen && (
        <ModalLayoutWithTitle isOpen={isOpen} onClose={onClose} hideHeader>
          <CsvFileUpload
            onClose={onClose}
            getFileOnUpload={handleGetFile}
            isOpen={csvModalOpen}
            onDataParsed={(data) => {
              console.log("data parsed", data);
            }}
          />
        </ModalLayoutWithTitle>
      )}

      {selectRoleModalOpen && (
        <SelectMemberRoleModal
          isOpen={selectRoleModalOpen}
          onClose={onClose}
          onNext={onNextSelectRoleModal}
        />
      )}

      {importedUsersModalOpen && (
        <ImportedUsersModal
          isOpen={importedUsersModalOpen}
          onClose={onClose}
          onNext={onNextImportedUsersModal}
        />
      )}

      {addMembersToChannelIsOpen && (
        <AddMembersToChannels
          show={addMembersToChannelIsOpen}
          closeText={t("Back")}
          doneText={t("Add To Selected")}
          onClose={onClose}
          onBack={onBackAddMembersToChannelIsOpen}
          onDone={onNextAddMembersToChannelIsOpen}
        />
      )}

      {confirmImportsModalOpen && (
        <ConfirmInvitesModal
          isOpen={confirmImportsModalOpen}
          onClose={onClose}
          onBack={onBackConfirmImportsModal}
          onConfirm={importMembersHandler}
          pending={importPending}
          selectedMembers={membersListToInvite}
          selectedTeams={selectedTeams}
        />
      )}

      {importPending && !confirmImportsModalOpen && (
        <PendingInviteModal isOpen={importPending} />
      )}

      {importSuccess && (
        <SuccessImportModal
          isOpen={importSuccess}
          onClose={onClose}
          selectedMembers={membersListToInvite}
        />
      )}
    </>
  );
};

export default InviteWithCsv;
