import { fetchGet } from "app/utils/request";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { SearchAllModel } from "app/models/SearchAllModel";

const pendingSearch = () =>
  action("@searchAll.create.pending");
const errorSearch = (err: string) =>
  action("@searchAll.create.error", err);
const successSearch = (value: SearchAllModel[]) =>
  action("@searchAll.create.success", value);

export function searchAll(q: string, search_replies: boolean = false, page: number = 1, per_page: number = 10) {
  return (dispatch: Dispatch<SearchAllAction>) => {
    dispatch(pendingSearch());

    const promise = fetchGet(`/search`, { q, search_replies, page, per_page }, { version: 1 });
    promise.then((res) => dispatch(successSearch(res)));
    promise.catch((err) => dispatch(errorSearch(err)));

    return promise;
  };
}

const thisActions = {
  pendingSearch,
  errorSearch,
  successSearch,
};

export type SearchAllAction = ActionType<typeof thisActions>;
