import { mediaAction } from "app/actions/media-library-data";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { RequestError } from "typedefs";

export type LibraryState = {
  data: MediaTypes[] | null;
  libraryData: MediaTypes[] | null;
  error: RequestError | null;
  pending: boolean;
  hasMore: boolean;
  selectedMedia: MediaTypes;
  attachment: MediaTypes[] | MediaTypes | null;
};

const initialState: LibraryState = {
  data: null,
  libraryData: null,
  error: null,
  pending: false,
  hasMore: true,
  selectedMedia: null,
  attachment: null,
};

export default function mediaReducer(
  state = initialState,
  action: mediaAction
) {
  switch (action.type) {
    case "@media.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@media.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@media.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@media.fetch.mediaLibraryData":
      return { ...state, libraryData: action.payload, pending: false };

    case "@media.fetch.selectedMedia":
      return { ...state, selectedMedia: action.payload, pending: false };
    case "@media.fetch.attachment":
      return { ...state, attachment: action.payload, pending: false };

    case "@media.fetch.hasMore":
      return { ...state, hasMore: action.payload, pending: false };
    case "@media.clear":
      return {
        ...state,
        data: null,
        pending: false,
        hasMore: true,
        selectedMedia: null,
        libraryData: null,
        attachment: null,
      };
    default:
      return state;
  }
}
