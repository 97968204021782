import React from "react";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import {useForm, SubmitHandler, FormProvider} from "react-hook-form";
import AvailabilityWithDates from "app/routes/Scheduling/Availability/AvailabilityWithDates";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../public/images/close.svg";

interface DailyTimeTable {
  day: number;
  active: boolean;
  startTime: string;
  endTime: string;
  value: number;
  label: string;
  short: string;
}

interface TimeTable {
  id?: string;
  name: string;
  dailyTimeTables: DailyTimeTable[];
  customPeriod: boolean;
  startDate: string | Date;
  endDate: string | Date;
}

interface AddPricingProps {
  isOpen: boolean;
  onClose: () => void;
  onAddAvailability: (newAvailability: TimeTable) => void;
  schedule: TimeTable;
  setSchedule: (schedule: TimeTable) => void;
}

export default function AddAvailability({ isOpen, onClose, onAddAvailability, schedule, setSchedule }: AddPricingProps) {
  const { t } = useTranslation();
  const {  formState: { errors } } = useForm<TimeTable>();

  const setScheduleData = (key: string, value: any) => {
    const updatedSchedule = {
      ...schedule,
      [key]: value,
    };

    setSchedule(updatedSchedule);
  };

  const onSubmit: SubmitHandler<TimeTable> = (data: TimeTable) => {
    onAddAvailability(data);
    onClose();
  };

  const methods = useForm({
    defaultValues: schedule,
    mode: 'onChange',
  });

  const {
    handleSubmit,
  } = methods;


  const cancelButton = (
    <button
      data-testid="cancel-edit"
      className={`${styles.button} ${styles.cancel_button}`}
      onClick={onClose}
    >
      {t("Cancel")}
    </button>
  );

  const confirmButton = (
    <button
      className={`${styles.button} ${styles.confirm_button}`}
      type="submit">
      {t("Create Schedule")}
    </button>
  );

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <h2>{t("Add Schedule")}</h2>
        <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onClose} />
      </div>
        <div className={styles.modal_content}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(() => onSubmit(schedule))}>
              <div className={styles.form_row}>
                <label>{t("Schedule Name *")}</label>
              </div>
              <div className={styles.form_row}>
                <input
                  id="name"
                  required={true}
                  onChange={(e) => setScheduleData('name', e.target.value)}
                />
                {errors.name && <span>{t("This field is required")}</span>}
              </div>
              <AvailabilityWithDates
                schedule={schedule}
                setScheduleData={setScheduleData}
              />
              <div className={styles.footer}>
                <div className={styles.buttons_container}>
                  {cancelButton} {confirmButton}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
    </ModalLayout>
  );
}
