import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import InvitationsList from "../../InvitationsList";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Invitations = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const route = (path: string) => {
    history.push(path);
  };

  return (
    <div className={styles.container}>
      <InvitationsList nullResultClassname={styles.null_result_wrapper} />
      <div className={styles.footer}>
        <Button
          title={t("View All")}
          buttonStyles={styles.read_all_btn}
          buttonType="secondary-outline"
          onClickFunc={() => route("/invitations")}
        />
      </div>
    </div>
  );
};

export default Invitations;
