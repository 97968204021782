import { SchedulePostAction } from "app/actions/schedule";
import PostModel from "app/models/PostModel";
import { RequestError } from "typedefs";

export interface ScheduledPostsModel {
  posts: PostModel[] | null;
  pending?: boolean;
  error?: RequestError | null;
}

export type SchedulePostState = {
  scheduleIn: {
    pickedAt: number | null;
    pickedIn: string;
  };
  // date: { day: number; in: number } | null;
  error: RequestError | null;
  pending: boolean;

  pendingSchedulePost: boolean;
  errorSchedulePost: RequestError | null;

  scheduledPostsSuccess: ScheduledPostsModel;
};

const initialState: SchedulePostState = {
  scheduleIn: {
    pickedAt: null,
    pickedIn: "Days",
  },
  error: null,
  pending: false,
  errorSchedulePost: null,
  pendingSchedulePost: false,
  scheduledPostsSuccess: {
    posts: null,
    pending: false,
    error: null,
  },
};

export default function schedulePostReducer(
  state = initialState,
  action: SchedulePostAction
) {
  switch (action.type) {
    case "@schedulePost.fetch.scheduledPostsSuccess":
      return {
        ...state,
        scheduledPostsSuccess: action.payload,
      };
    case "@schedulePost.fetch.pending":
      return { ...state, pending: true, scheduleIn: null };
    case "@schedulePost.fetch.error":
      return {
        ...state,
        pending: false,
        scheduleIn: null,
        error: action.payload,
      };
    case "@schedulePost.fetch.scheduleIn":
      return {
        ...state,
        pending: false,
        scheduleIn: action.payload,
        error: null,
      };
    case "@schedulePost.create.error":
      return {
        ...state,
        scheduleIn: action.payload,
        pendingSchedulePost: false,
        errorSchedulePost: action.payload,
      };
    case "@schedulePost.clear":
      return {
        ...state,
        scheduleIn: null,
        errorSchedulePost: null,
        pendingSchedulePost: null,
      };
    default:
      return state;
  }
}
