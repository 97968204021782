// import { fetchScheduledPosts } from "app/actions/schedule";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

const useGroupsPostsContainerModel = () => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  return {
    self,
  };
};

export default useGroupsPostsContainerModel;
