import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TemplatePageLayout from "app/components/Layouts/TemplatePageLayout";
import ReorderPosts from "./ReorderPosts";
import { useHistory, useParams } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import { fetchPosts } from "app/actions/posts";
import { clearPost } from "app/actions/postingForm";
import PostModel from "app/models/PostModel";
import useGroupAndSortPosts from "app/utils/hooks/useGroupAndSortPosts";
import Button from "app/components/Button/ButtonVariant";
import { sortSpaceTemplate } from "app/actions/spaceTemplate";

const TemplateReorder = () => {
  const history = useHistory();
  const { id: templateId } = useParams<{ id: string }>();
  const feedTimelineActions = useActions({
    sortSpaceTemplate,
    fetchPosts,
    clearPost,
  });

  const [postsParams] = useState({
    spaceTemplateId: templateId,
    orderBy: "scheduled_in",
    order: "asc",
  });

  const [groupedPosts, setGroupedPosts] =
    useState<Record<string, PostModel[]>>(null);
  const [changedOrder, setChangedOrder] = useState({});
  const [posts, setPosts] = useState<PostModel[]>([]);

  useEffect(() => {
    feedTimelineActions.fetchPosts(postsParams).then((res: PostModel[]) => {
      setPosts(res);
      setGroupedPosts((prevGroupedPosts) =>
        useGroupAndSortPosts(res, prevGroupedPosts)
      );
    });

    return () => {
      feedTimelineActions.clearPost();
      setGroupedPosts(null);
    };
  }, []);

  function handleOnDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const updatedGroupedPosts = { ...groupedPosts };
    const items = updatedGroupedPosts[0] ? [...updatedGroupedPosts[0]] : [];

    // remove the item from its source position
    const [reorderedItem] = items.splice(sourceIndex, 1);

    // insert the item into its destination position
    items.splice(destinationIndex, 0, reorderedItem);

    // update the grouped posts
    updatedGroupedPosts[0] = items;
    setGroupedPosts(updatedGroupedPosts);

    // create the changed order object
    const newOrder = items.reduce((acc, post, index) => {
      acc[post.id] = index;
      return acc;
    }, {});

    // update the changedOrder state
    setChangedOrder(newOrder);
  }

  const route = (path: string) => {
    history.push(path);
  };

  const updateHandler = useCallback(async () => {
    await feedTimelineActions.sortSpaceTemplate({
      id: templateId,
      posts: changedOrder,
    });
    route(`/space_templates/${templateId}/posts`);
  }, [changedOrder]);

  return (
    <TemplatePageLayout hideHeaderMenu hideTabs>
      {posts && (
        <div className={styles.wrapper}>
          <ReorderPosts
            handleOnDragEnd={handleOnDragEnd}
            posts={groupedPosts}
          />
          <Button
            title="Update"
            buttonType="primary"
            onClickFunc={updateHandler}
            buttonStyles={styles.update_btn}
          />
        </div>
      )}
    </TemplatePageLayout>
  );
};

export default TemplateReorder;
