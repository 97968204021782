import React, { useState } from "react";
import styles from "./styles.module.scss";
import CopyIcon from "../../../../public/images/copy-icon.svg";
import ToolTip from "../ToolTip";
import { useTranslation } from 'react-i18next';

interface CopyFieldProps {
  value: string;
  className?: string;
  buttonClassName?: string;
}

const CopyField: React.FC<CopyFieldProps> = ({ value, className = "", buttonClassName = "" }) => {
  const { t } = useTranslation();
  const [textTooltip, setTextTooltip] = useState(t('Copy URL'));

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setTextTooltip(t('URL copied to clipboard'));
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <input className={styles.input} value={value} readOnly />
      <button
        type="button"
        className={`${styles.button} ${buttonClassName}`}
        onClick={handleCopy}
        onMouseLeave={() => setTextTooltip(t('Copy URL'))}
      >
        <ToolTip text={textTooltip} showIcon={false}>
          <img src={CopyIcon} alt="Copy" className={styles.copyIcon} />
        </ToolTip>
      </button>
    </div>
  );
};

export default CopyField;
