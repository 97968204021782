import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GuideCard from "app/components/GuideCard";
import StepCard from "app/components/Layouts/StepCard";
import { cls } from "app/utils";
import ArrowRight from "../../../public/images/arrow-right.svg";
import AndroidAppStoreBadge from "../../../public/images/android-app-store-badge.svg";
import IphoneAppStoreBadge from "../../../public/images/iphone-app-store-badge.svg";
import MobileAppDesign from "../../../public/images/mobile-app-design.svg";
import SpacesModal from "app/components/GettingStarted/SpacesModal";
import GroupsModal from "app/components/GettingStarted/GroupsModal";
import InspirationSpaceModal from "app/components/GettingStarted/InspirationSpacesModal";
import useGettingStartedModel from "./indexModel";
import BulbIcon from "../../../public/images/blue-bulb.svg";
import SpacesIcon from "../../../public/images/blue-spaces.svg";
import GroupIcon from "../../../public/images/blue-group.svg";
import ProgressBar from "app/components/Layouts/ProgressBar";
import VideoAnalysisModal from "app/components/GettingStarted/VideoAnalysisModal";
import { useTranslation } from 'react-i18next';

const GettingStarted = () => {
  const { t } = useTranslation();
  const {
    onboarding,
    handleInspirationModal,
    isInspirationModalOpen,
    hyperLinkUrl,
  } = useGettingStartedModel();

  const SpacesCard = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => {
      setIsOpen((prev) => !prev);
    };

    return (
      <>
        <GuideCard
          icon={SpacesIcon}
          title={t("Spaces")}
          description={t("Invite your athletes into their personal space to communicate")}
          accomplishedText={t("Space created!")}
          stepDone={onboarding.data.createdSpacesCount > 0}
          onClickFunction={handleModal}
        />
        <SpacesModal url="/spaces/new" isOpen={isOpen} onClose={handleModal} />
      </>
    );
  };

  const GroupsCard = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => {
      setIsOpen((prev) => !prev);
    };
    return (
      <>
        <GuideCard
          icon={GroupIcon}
          title={t("Groups")}
          description={t("Perfect for group coaching, camps, clinics, or even a paid community")}
          accomplishedText={t("Group created!")}
          stepDone={onboarding.data.createdGroupsCount > 0}
          onClickFunction={handleModal}
        />
        <GroupsModal url="/groups" isOpen={isOpen} onClose={handleModal} />
      </>
    );
  };

  const StepCardTwo = () => {
    const { t } = useTranslation();
    return (
      <>
        <StepCard
          stepNumber={2}
          isDone={onboarding.data.createdSpacesCount > 0}
        >
          <a
            href="/spaces/new"
            className={cls(
              styles.step_card_child,
              styles.step_card_text,
              onboarding.data.createdSpacesCount > 0 &&
                styles.checked_step_card_text
            )}
          >
            <p
              className={cls(
                onboarding.data.createdSpacesCount > 0 &&
                  styles.checked_step_card_text
              )}
            >
              {t("Add an athlete")}
            </p>{" "}
            <img src={ArrowRight} alt="arrow" />
          </a>
        </StepCard>
      </>
    );
  };

  const StepCardThree = () => {
    const { t } = useTranslation();
    return (
      <>
        <StepCard stepNumber={3} isDone={onboarding.data.createdPost}>
          <a
            href="/"
            className={cls(
              styles.step_card_child,
              styles.step_card_text,
              onboarding.data.createdPost && styles.checked_step_card_text
            )}
          >
            <p
              className={cls(
                onboarding.data.createdPost > 0 && styles.checked_step_card_text
              )}
            >
              {t("Create first Post")}
            </p>{" "}
            <img src={ArrowRight} alt="arrow" />
          </a>
        </StepCard>
      </>
    );
  };

  const StepCardFour = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsOpen(false);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleModal = () => {
      if (window.innerWidth < 820) {
        setIsOpen((prev) => !prev);
      }
    };

    return (
      <>
        <StepCard stepNumber={4} isDone={onboarding.data.mobileAppInstalled}>
          <div className={styles.download} onClick={handleModal}>
            <div className={styles.download_info}>
              <div className={styles.step_title}>
                <p
                  className={cls(
                    onboarding.data.mobileAppInstalled &&
                      styles.checked_step_card_text
                  )}
                >
                  {t("Get started with Video Analysis Suite")}
                </p>
                <img src={ArrowRight} alt="arrow" />
              </div>
              <p>
                {t("Access the Video Analysis Tools directly in an athlete Space or Group on iOS or Android. When creating a post, you can shoot a new video, re-use a video from your Personal CoachNow library, or upload one from your camera roll.")}{" "}
                <a
                  href={hyperLinkUrl.learnMore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Learn more about special features")}
                </a>
              </p>
              <div className={styles.play_store_badges_container}>
                <a
                  href={hyperLinkUrl.playStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={AndroidAppStoreBadge} alt="play_store" />
                </a>
                <a
                  href={hyperLinkUrl.appStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IphoneAppStoreBadge} alt="app_store" />
                </a>
              </div>
            </div>
            <img src={MobileAppDesign} className={styles.mobile_app_design} />
          </div>
        </StepCard>
        <VideoAnalysisModal
          url={hyperLinkUrl}
          isOpen={isOpen}
          onClose={handleModal}
        />
      </>
    );
  };

  return (
    onboarding.data && (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.tour_icon}>{t("Basics")}</div>
          <div className={styles.header_title}>{t("Welcome to Coachnow")}</div>
          <div className={styles.question_url}>
            {t("Have a questions?")}{" "}
            <a
              href={hyperLinkUrl.book_a_demo_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Book Demo")}
            </a>
          </div>
        </div>
        <div className={styles.cards_container}>
          <>
            <GuideCard
              icon={BulbIcon}
              title={t("Inspiration")}
              description={t("Check out these sample Spaces from coaches just like you.")}
              accomplishedText={t("Acknowledged!")}
              stepDone={onboarding.data.inspirationSpacesAcknowledged}
              onClickFunction={handleInspirationModal}
            />
            <InspirationSpaceModal
              isOpen={isInspirationModalOpen}
              onClose={handleInspirationModal}
            />
          </>
          <SpacesCard />
          <GroupsCard />
        </div>
        <div className={styles.getting_stareted_container}>
          <div className={styles.title_slider_wrapper}>
            <div className={styles.getting_started}>{t("Getting started")}</div>
            <div className={styles.percentage}>
              <ProgressBar percentage={onboarding.data.percentsCompleted} />
            </div>
          </div>
          <div className={styles.steps_container}>
            <div className={styles.steps_wrapper}>
              <StepCard stepNumber={1} isDone>
                <div
                  className={cls(
                    styles.step_card_child,
                    styles.step_card_text,
                    styles.checked_step_card_text
                  )}
                >
                  {t("Create your profile")}
                </div>
              </StepCard>
              <StepCardTwo />
              <StepCardThree />
            </div>
            <div className={styles.download_app_container}>
              <StepCardFour />
            </div>
          </div>
        </div>
        {/* <TrialModal isOpen={isOpen} onClose={handleModal} /> */}
      </div>
    )
  );
};

export default GettingStarted;
