/**
 * @module Components
 *
 */
import React, { useCallback } from "react";
import { isEmpty, cls } from "app/utils";
import default_profile_img from "app/images/fallback/profiles/square_default.png";

/***/
type PropsDef = {
  className?: string;
  srcName?: string;
  altName?: string;
  sizeWidth?: number;
  sizeHeight?: number;
  kind?: string;
  userName?: string;
};

/**
 * Corrects the url for the default user avatar when the user
 * has not set an avatar yet.
 *
 * @method UserAvatar
 */
export default function UserAvatar(props: PropsDef) {
  const kind = props.kind != null ? props.kind : "";
  let srcName = props.srcName;

  const getNameInitials = useCallback(() => {
    if (props.userName) {
      const separateNames = props.userName.split(" ");
      const prepareInitials =
        (separateNames[0][0] || "") +
        (separateNames[separateNames.length - 1][0] || "");
      return prepareInitials.toUpperCase();
    }
    return null;
  }, []);

  if (srcName && srcName.includes("default")) {
    return <div className={cls('user-avatar avatar', props.className)}>{getNameInitials()}</div>;
  }

  if (
    isEmpty(srcName) ||
    srcName.indexOf("/assets/fallback/profiles/square_default") !== -1
  ) {
    srcName = default_profile_img;
  }

  return (
    <img
      src={srcName}
      alt={props.altName}
      width={props.sizeWidth}
      height={props.sizeHeight}
      className={cls(props.className, kind)}
    />
  );
}
