import React, { memo, useCallback, useState } from "react";
import styles from "./styles.module.scss";
import Sidebar from "app/components/Sidebar";
import SiteNavbar from "app/components/SiteNavbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import AlertContainer from "app/components/AlertContainer";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const SiteWrapper: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const { alerts } = useSelector(
    (state: RootState) => state.alerts,
    shallowEqual
  );
  const location = useLocation();

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(true);

  const toggleSidebar = useCallback(() => {
    setSidebarExpanded(!sidebarExpanded);
  }, [sidebarExpanded]);

  const onExpireAlarm = useCallback((id: string) => {
    dispatch({ type: "@alert.expire", payload: { id } });
  }, []);

  if (!user) {
    return null;
  }

  if (location.pathname === "/calendar") {
    return (
      <div>
        <div className={styles.isWeb}>
          <div className={styles.container}>
            <Sidebar />
            <div
              className={cls(styles.main, {
                [styles.expanded]: sidebarExpanded,
              })}
            >
              {children}
            </div>
            <AlertContainer alerts={alerts} setExpired={onExpireAlarm} />
          </div>
        </div>
        <div className={styles.isMobile}>
          <div className={styles.container}>
            <Sidebar />
            <div
              className={cls(styles.main, {
                [styles.expanded]: sidebarExpanded,
              })}
            >
              <SiteNavbar
                sidebarExpanded={sidebarExpanded}
                toggleSidebar={toggleSidebar}
              />
              <div className={styles.child_container_no_padding}>
                <div className={cls(styles.child_wrapper)}>{children}</div>
              </div>
            </div>
            <AlertContainer alerts={alerts} setExpired={onExpireAlarm} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={cls(styles.main, { [styles.expanded]: sidebarExpanded })}>
        <SiteNavbar
          sidebarExpanded={sidebarExpanded}
          toggleSidebar={toggleSidebar}
        />
        <div className={styles.child_container}>
          <div className={cls(styles.child_wrapper)}>{children}</div>
        </div>
      </div>
      <AlertContainer alerts={alerts} setExpired={onExpireAlarm} />
    </div>
  );
};

export default memo(SiteWrapper);
