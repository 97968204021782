import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { computePosition, autoUpdate, shift, flip, hide } from '@floating-ui/dom';

import { EventModel } from 'app/models/EventModel';
import { deselectEvent } from 'app/actions/events';

import styles from './styles.module.scss';

type PropsDef = {
  event?: EventModel;
  coords?: {
    x: number;
    y: number;
  };
  referenceElement: any;
  onDelete: () => void;
  onEdit: () => void;
};

export default function Event(props: PropsDef) {
  const { event, referenceElement, onDelete, onEdit } = props;
  const dispatch = useDispatch();
  const currentElement = useRef(null);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if(!referenceElement || !currentElement.current) {
      // @ts-ignore: next-line
      return;
    }

    const cleanup = autoUpdate(referenceElement, currentElement.current, () => {
      computePosition(referenceElement, currentElement.current, {
        middleware: [shift(), flip(), hide()]
      }).then(({ x, y, middlewareData }) => {
        setHidden(middlewareData.hide.referenceHidden);
        setPosition({ left: x, top: y });
      });
    });

    return () => {
      cleanup();
    };
  }, [event?.id, referenceElement]);

  if (!props.event?.id) {
    return null;
  }

  return (
    <div className={styles.popup} ref={currentElement} style={{ left: position.left, top: position.top, visibility: hidden ? 'hidden' : 'visible', zIndex: 2 }}>
      <div className={styles.header}>
        <div className={styles.event_title}>
          <p>{props.event.title}</p>
          <div className={styles.repeat}>{event.repeatLabel}</div>
        </div>
        <div className={styles.header_actions}>
          <a onClick={onEdit}>
            <i className="ico ico-edit-outline" />
          </a>
          <a onClick={onDelete}>
            <i className="ico ico-trash-outline" />
          </a>
          <a onClick={() => dispatch(deselectEvent())}>
            <i className="ico ico-x" />
          </a>
        </div>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-calendar"></i>
        <span>{props.event.dateString}</span>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-clock"></i>
        <span>{props.event.intervalTimeString}</span>
      </div>
      <div className={styles.event_notes}>
        {props.event.notes}
      </div>
    </div>
  );
}
