/**
 * @module Components.Selectbox
 *
 */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import Selectbox, {
  DefaultOption,
  OptionsType,
} from "app/components/inputs/SelectBox/Base";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  className?: string;
  maxDate?: number;
  minDate?: number;
  year: string;
  month: string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

const normailzeValue = (value?: string | null, defaultLabel?: string): DefaultOption | null => {
  if (!value) {
    return { value: "", label: defaultLabel };
  }

  return {
    value,
    label: value,
  };
};

/**
 * @class DaySelect
 *
 */
export default function DaySelect(props: PropsDef) {
  const { t } = useTranslation();
  const { className, onChange, value, year, month, ...rest } = props;
  const [__options, setOptions] = useState<OptionsType<DefaultOption>>(null);
  const [selectedDay, setSelectedDay] = useState<DefaultOption | null>(
    normailzeValue(value, t("Day"))
  );

  useEffect(() => {
    setOptions(generateDays(year, month));
  }, [year, month]);

  useEffect(() => {
    setSelectedDay(normailzeValue(value, t("Day")));
  }, [value]);

  useMemo(() => {
    if (__options) {
      if (parseInt(selectedDay.value, 10) > __options.length) {
        setSelectedDay(__options[__options.length - 1]);
      }
    } else {
      return;
    }
  }, [__options, selectedDay]);

  const onChangeFunc = (val: any) => {
    if (onChange) {
      setSelectedDay(val);
      onChange(val);
    }
  };

  return (
    __options && (
      <Selectbox
        isCreatable={false}
        isClearable={false}
        isMulti={false}
        className={cls(styles.select, className, "c-inputs-day-select")}
        options={__options}
        value={selectedDay}
        onChange={onChangeFunc}
        {...rest}
      />
    )
  );
}

function generateDays(year: string, month: string): OptionsType<DefaultOption> {
  const selectedDate = moment(`${year}-${month}`, "YYYY-MMM");

  const daysInMonth = selectedDate.daysInMonth();
  const minDay = 1;
  const maxDay = daysInMonth ? daysInMonth : 31;
  const length = maxDay - minDay + 1;

  let dayIndex: number = minDay;
  const increment = () => {
    dayIndex++;
  };

  return Array.from({ length }, () => {
    const day = moment().date(dayIndex).format("DD");
    increment();
    return { value: day, label: day };
  });
}
