import Segments from "./routes/Segments";
import SegmentSpaces from "./routes/Segments/SegmentSpaces";
import SegmentSpacesEdit from "./routes/Segments/ManageSpaces";
import Calendar from "./routes/Calendar";
import FeedTimeline from "./routes/Feed/FeedTimeline";
import Resources from "./routes/Resources";
import CreateNewResource from "./routes/Resources/CreateNewResource";
import EditResource from "./routes/Resources/EditResource";
import Groups from "./routes/Groups";
import GroupTimeline from "./routes/Groups/Group/GroupTimeline";
import GroupMembers from "./routes/Groups/Group/GroupMembers";
import TeamsTimeline from "./routes/Teams/TeamsTimeline";
import TeamsMembers from "./routes/Teams/TeamsMembers";
import InviteToSegment from "./routes/Segments/InviteToSegment";
import Spaces from "./routes/Spaces";
import CreateNewSpace from "./routes/Spaces/CreateNewSpace";
import CreateNewMultipleSpaces from "./routes/Spaces/CreateNewMultipleSpaces";
import SpacesTimeline from "./routes/Spaces/SpacesTimeline";
import SpaceMembers from "./routes/Spaces/SpaceMembers";
import SpacesScheduledTimeline from "./routes/Spaces/SpacesScheduledTimeline";
import EditSpaceInvitation from "./routes/EditSpaceInvitation";
import Tags from "./routes/Tags";
import AddTag from "./routes/Tags/AddTag";
import EditTag from "./routes/Tags/EditTag";
import DeleteTag from "./routes/Tags/DeleteTag";
import NotificationsEdit from "./routes/Account/NotificationsEdit";
import ProfileEdit from "./routes/Account/ProfileEdit";
import PasswordEdit from "./routes/Account/PasswordEdit";
import Benefits from "./routes/Benefits";
import CopyMovePost from "./components/CopyMovePost";
import GettingStarted from "./routes/GettingStarted";
import UpgradePlus from "./routes/UpgradePlus";
import InternalServerErrorPage from "./routes/500";
import ForbiddenPage from "./routes/403";
import PageNotFound from "./routes/404";
import EditSegment from "./routes/Segments/EditSegment";
import SpaceTemplate from "./routes/SpaceTemplates";
import TemplateSpaceEdit from "./routes/SpaceTemplates/TemplateSpaceEdit";
import BillingInfo from "./routes/Account/BillingInfo";
import BillingInfoEdit from "./routes/Account/BillingInfoEdit";
import SpaceTemplateMembers from "./routes/SpaceTemplates/SpaceTemplateMembers";
import Schedule from "./routes/Schedule";
import Subscriptions from "./routes/Account/Subscriptions";
import Sessions from "./routes/Sessions";
import NewSession from "./routes/Sessions/NewSession";
import EditSession from "./routes/Sessions/EditSession";
import NewConfirmation from "./routes/Sessions/SessionConfirmation/NewConfirmation";
import EditConfirmation from "./routes/Sessions/SessionConfirmation/EditConfirmation";
import Availability from "./routes/Scheduling/Availability";
import Pricing from "./routes/Scheduling/Pricing";
import CalendarSettings from "./routes/Scheduling/CalendarSettings";
import Settings from "./routes/Scheduling/Settings";
import SubscribeCalendar from "./routes/Scheduling/SubscribeCalendar";
import ActivityNotifications from "./routes/Activity/ActivityNotifications";
import ActivityInvitations from "./routes/Activity/ActivityInvitations";
import { Search } from "./routes/Search";
import { Organization } from "./routes/Organization";
import Post from "./routes/Post";
import PostToSmartList from "./routes/PostToSmartList";
import PostToSegment from "./routes/PostToSegment";
import DemoSpaceTimeline from "./routes/DemoSpace/DemoSpaceTimeline";
import DemoSpaceMembers from "./routes/DemoSpace/DemoSpaceMembers";
import TemplateTimeline from "./routes/SpaceTemplates/TemplateTimeline";
import TemplateHistory from "./routes/SpaceTemplates/TemplateHistory/TemplateHistory";
import TemplateReorder from "./routes/SpaceTemplates/TemplateReorder";
import CreatePostWithMedia from "./routes/Resources/CreatePostWithMedia";
import CoachProfile from "./routes/Account/CoachProfile";

export const routerData = [
  { path: "/", exact: true, main: FeedTimeline },
  {
    path: "/feed",
    exact: true,
    redirectTo: "/",
    main: FeedTimeline,
  },

  {
    path: "/spaces",
    exact: true,
    main: Spaces,
  },
  {
    path: "/calendar",
    exact: true,
    main: Calendar,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/sessions/new/confirmation",
    exact: true,
    main: NewConfirmation,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/sessions/edit/confirmation",
    exact: true,
    main: EditConfirmation,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/sessions/edit",
    exact: true,
    main: EditSession,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/sessions/new",
    exact: true,
    main: NewSession,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/sessions",
    exact: true,
    main: Sessions,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/calendar/availability",
    exact: true,
    main: Availability,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/spaces/all/:listId/:id",
    exact: true,
    main: Spaces,
  },
  {
    path: "/spaces/smartList/:listId/:id",
    exact: true,
    main: Spaces,
  },
  {
    path: "/spaces/list/:listId/:id",
    exact: true,
    main: Spaces,
  },
  {
    path: "/spaces/smartList/:listId/post_to_smart_list/:id",
    exact: true,
    main: PostToSmartList,
  },
  {
    path: "/spaces/new",
    exact: true,
    main: CreateNewSpace,
  },
  {
    path: "/spaces/new/multiple",
    exact: false,
    main: CreateNewMultipleSpaces,
  },
  {
    path: "/spaces/:id",
    exact: true,
    main: SpacesTimeline,
  },
  {
    path: "/spaces/demo/:id",
    exact: true,
    main: DemoSpaceTimeline,
  },
  {
    path: "/spaces/demo/:id/members",
    exact: true,
    main: DemoSpaceMembers,
  },
  {
    path: "/organizations",
    exact: true,
    main: Organization,
  },
  {
    path: "/spaces/:id/members",
    exact: true,
    main: SpaceMembers,
  },
  {
    path: "/spaces/:id/schedule",
    exact: true,
    main: SpacesScheduledTimeline,
  },
  {
    path: "/spaces/:spaceId/invitations/:invitationId/edit",
    exact: true,
    main: EditSpaceInvitation,
  },
  {
    path: "/search",
    exact: true,
    main: Search,
  },
  {
    path: "/space_templates",
    exact: true,
    main: SpaceTemplate,
  },
  {
    path: "/space_templates/:id/posts",
    exact: true,
    main: TemplateTimeline,
  },
  {
    path: "/space_templates/:id/posts/reorder",
    exact: true,
    main: TemplateReorder,
  },
  {
    path: "/space_templates/:id/history",
    exact: true,
    main: TemplateHistory,
  },
  {
    path: "/space_templates/:id/edit",
    exact: true,
    main: TemplateSpaceEdit,
  },
  {
    path: "/space_templates/:id/members",
    exact: true,
    main: SpaceTemplateMembers,
  },

  {
    path: "/schedule",
    exact: true,
    main: Schedule,
  },

  {
    path: "/resources",
    exact: true,
    main: Resources,
  },
  {
    path: "/resources/new",
    exact: true,
    main: CreateNewResource,
  },
  {
    path: "/resources/:id",
    exact: true,
    main: EditResource,
  },
  {
    path: "/resources/:resourceId/post",
    exact: true,
    main: CreatePostWithMedia,
  },
  {
    path: "/groups",
    exact: true,
    main: Groups,
  },
  {
    path: "/groups/:id",
    exact: true,
    main: GroupTimeline,
  },
  {
    path: "/groups/:id/members",
    exact: true,
    main: GroupMembers,
  },
  {
    path: "/groups/:id/channels/:teamId",
    exact: true,
    main: TeamsTimeline,
  },
  {
    path: "/groups/:id/channels/:teamId/members",
    exact: true,
    main: TeamsMembers,
  },

  {
    path: "/lists",
    exact: true,
    main: Segments,
  },
  {
    path: "/lists/:id/spaces",
    exact: true,
    main: SegmentSpaces,
  },
  {
    path: "/lists/:id/spaces/edit",
    exact: true,
    main: SegmentSpacesEdit,
  },
  {
    path: "/lists/:segmentId/edit",
    exact: true,
    main: EditSegment,
  },

  {
    path: "/lists/:segmentId/add_members",
    exact: true,
    main: InviteToSegment,
  },

  {
    path: "/lists/:segmentId/spaces/post",
    exact: true,
    main: PostToSegment,
  },

  {
    path: "/notifications",
    exact: true,
    main: ActivityNotifications,
  },
  {
    path: "/invitations",
    exact: true,
    main: ActivityInvitations,
  },

  {
    path: "/account/settings",
    exact: true,
    main: NotificationsEdit,
  },
  // todo: private route only for coaches
  {
    path: "/account/coach-profile",
    exact: true,
    main: CoachProfile,
  },
  {
    path: "/account/edit",
    exact: true,
    main: ProfileEdit,
  },

  {
    path: "/account/tags",
    exact: true,
    main: Tags,
  },
  {
    path: "/account/tags/new",
    exact: true,
    main: AddTag,
  },
  {
    path: "/account/tags/:id/edit",
    exact: true,
    main: EditTag,
  },
  {
    path: "/account/tags/:id/delete",
    exact: true,
    main: DeleteTag,
  },

  {
    path: "/account/paid_subscriptions",
    exact: true,
    redirectTo: "/account/subscriptions",
    main: Subscriptions,
  },
  {
    path: "/account/subscriptions",
    exact: true,
    main: Subscriptions,
  },
  {
    path: "/account/billing_info",
    exact: true,
    main: BillingInfo,
  },
  {
    path: "/account/billing_info/edit",
    exact: true,
    main: BillingInfoEdit,
  },
  {
    path: "/account/password",
    exact: true,
    main: PasswordEdit,
  },

  {
    path: "/account/benefits",
    exact: true,
    main: Benefits,
  },

  {
    path: "/copy/:id",
    exact: true,
    main: CopyMovePost,
  },
  {
    path: "/move/:id",
    exact: true,
    main: CopyMovePost,
  },

  {
    path: "/getting-started",
    exact: true,
    main: GettingStarted,
  },
  {
    path: "/upgrade-plus",
    exact: true,
    main: UpgradePlus,
  },
  {
    path: "/posts/:id",
    exact: true,
    main: Post,
  },
  {
    path: "/500",
    exact: true,
    main: InternalServerErrorPage,
  },
  {
    path: "/403",
    exact: true,
    main: ForbiddenPage,
  },
  {
    path: "/calendar/pricing",
    exact: true,
    main: Pricing,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/calendar/scheduling",
    exact: true,
    main: Settings,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/calendar/settings",
    exact: true,
    main: CalendarSettings,
    permission: "scheduling",
    permissionObject: "feature",
  },
  {
    path: "/calendar/integration",
    exact: true,
    main: SubscribeCalendar,
    permission: "scheduling",
    permissionObject: "feature",
  },
  // todo: dont add components below this line
  {
    path: "*",
    exact: true,
    main: PageNotFound,
  },
];
