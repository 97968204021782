import React from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import useCopyToClipboard from "app/utils/hooks/useCopyToClipboard";
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  url?: string;
}

const SpaceMemberInviteModal: React.FC<Props> = ({
  isOpen,
  onClose,
  url,
}: Props) => {
  const { t } = useTranslation();
  const { copied, copyToClipboard } = useCopyToClipboard();

  const onCopyHandler = (value: string) => {
    copyToClipboard(value);
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Copy invitation link")}
    >
      <div className={styles.container}>
        {url && (
          <div className={styles.copy_btn_wrapper}>
            <p onClick={() => !copied && onCopyHandler(url)}>{url}</p>

            <Button
              disabled={copied}
              buttonType="primary"
              title={!copied ? t("Copy Link") : t("Copied...")}
              icon={<i className="ico ico-copy" />}
              onClickFunc={() => onCopyHandler(url)}
              buttonStyles={styles.copy_btn}
            />
          </div>
        )}
        <div className={styles.done_btn}>
          <Button
            buttonType="secondary-outline"
            title={t("Done")}
            onClickFunc={onClose}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default SpaceMemberInviteModal;
