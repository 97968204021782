import React from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { cls } from "app/utils";
import AddMembersToChannels from "app/components/modals/AddMembersToChannels";
import ConfirmInvitesModal from "../ConfirmInvitesModal";
import { useInviteWithEmailModel } from "./indexModel";
import UserModel from "app/models/UserModel";
import SuccessInviteModal from "../SuccessInviteModal";
import PendingInviteModal from "../PendingInviteModal";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const InviteWithEmailModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    addMembersToChannelIsOpen,
    setAddMembersToChannelIsOpen,
    setConfirmInvitesModalOpen,
    confirmInvitesModalOpen,
    inviteMembersHandler,
    membersListToInvite,
    selectedTeams,
    members,
    handleEmailChange,
    handleNameChange,
    emailErrors,
    nameErrors,
    addMember,
    handleRoleChange,
    onSubmitHandler,
    invitationPending,
    invitationSuccess,
  } = useInviteWithEmailModel();

  if (addMembersToChannelIsOpen) {
    return (
      <AddMembersToChannels
        show={addMembersToChannelIsOpen}
        closeText={t("Back")}
        doneText={t("Add To Selected")}
        onClose={onClose}
        onBack={() => {
          setAddMembersToChannelIsOpen(false);
        }}
        onDone={() => {
          setAddMembersToChannelIsOpen(false);
          setConfirmInvitesModalOpen(true);
        }}
      />
    );
  }

  if (confirmInvitesModalOpen) {
    return (
      <ConfirmInvitesModal
        isOpen={confirmInvitesModalOpen}
        onClose={onClose}
        onBack={() => {
          setConfirmInvitesModalOpen(false);
          setAddMembersToChannelIsOpen(true);
        }}
        onConfirm={inviteMembersHandler}
        selectedMembers={membersListToInvite}
        selectedTeams={selectedTeams}
      />
    );
  }

  if (invitationPending) {
    return <PendingInviteModal isOpen={invitationPending} />;
  }

  if (invitationSuccess) {
    return (
      <SuccessInviteModal
        isOpen={invitationSuccess}
        onClose={onClose}
        selectedMembers={membersListToInvite}
      />
    );
  }

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Invite With Email")}
    >
      <div className={styles.content_container}>
        <table className={styles.member_invite_roster}>
          <thead className={styles.member_invite_roster_header}>
            <tr>
              <th className={styles.member_invite_roster_label}>
                {t("Email (Required)")}
              </th>
              <th className={styles.member_invite_roster_label}>{t("Name")}</th>
              <th className={styles.member_invite_roster_label}>{t("Role")}</th>
            </tr>
          </thead>

          <tbody className={styles.member_invite_roster_list}>
            {members.map((member: UserModel, index: number) => (
              <tr
                key={index}
                className={cls(
                  styles.member_invite_roster_control_group,
                  styles.fade_in,
                )}
              >
                <td className={styles.member_invite_roster_control}>
                  <input
                    className={styles.validate_email}
                    name="email[]"
                    value={member.email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    type="email"
                  />
                  {emailErrors[index] && (
                    <div className={styles.error_message}>
                      {emailErrors[index]}
                    </div>
                  )}
                </td>

                <td className={styles.member_invite_roster_control}>
                  <input
                    name="name[]"
                    value={member.name}
                    onChange={(e) => handleNameChange(index, e.target.value)}
                    type="text"
                  />
                  {nameErrors[index] && (
                    <div className={styles.error_message}>
                      {nameErrors[index]}
                    </div>
                  )}
                </td>

                <td className={styles.member_invite_roster_control}>
                  <select
                    name="role[]"
                    value={member.role}
                    onChange={(e) => handleRoleChange(index, e.target.value)}
                  >
                    <option value="coach">{t("Coach/Admin")}</option>
                    <option value="player">{t("Member")}</option>
                    <option value="viewer">{t("Viewer")}</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <span className={styles.add_member_btn} onClick={addMember}>
          {t("+ Add More Members")}
        </span>
      </div>

      <div className={styles.btn_wrapper}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onClose}
        />
        <Button
          buttonType="primary"
          title={t("Next")}
          onClickFunc={onSubmitHandler}
        />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default InviteWithEmailModal;
