/* eslint-disable no-console */
import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import Button from "app/components/Button/ButtonVariant";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { useHistory } from "react-router-dom";
import TemplateSpaces from "./TemplatesList";
import { createSpaceTemplate } from "app/actions/spaceTemplate";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import TemplatesPaywall from "app/components/paywalls/Templates";
import { UpgradeToPro } from "app/components/paywalls";

const SpaceTemplates = () => {
  const history = useHistory();
  const { self: user, pending } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const templateActions = useActions({ createSpaceTemplate });
  const [newTemplateModalOpen, setNewTemplateModalOpen] =
    useState<boolean>(false);
  const [newTemplateName, setNewTemplateName] = useState<string | null>(null);
  const [newTemplateError, setNewTemplateError] = useState<boolean>(false);
  const [showModal, setPaywallModal] = useState(false);
  const [showProPaywall, setShowProPaywall] = useState(false);

  const [canCreateTemplate] = useState(() => {
    if (!user || !user.planPermissions) {
      return null;
    }
    return user.planPermissions.includes("create_templates");
  });
  const { t } = useTranslation();

  const modalHandler = () => {
    setNewTemplateModalOpen((prev) => !prev);
  };

  const onNewTemplateClick = () => {
    if (canCreateTemplate) {
      modalHandler();
    } else {
      openPaywall();
    }
  };

  const openPaywall = useCallback(() => {
    setPaywallModal(true);
  }, []);

  const closePaywall = useCallback(() => {
    setPaywallModal(false);
  }, []);

  const paywallModalPro = useCallback(() => {
    setShowProPaywall(true);
  }, []);

  const closeProPaywall = useCallback(() => {
    setShowProPaywall(false);
  }, []);

  const onNewTemplateNameChange = (e: any) => {
    const name = e.target.value;
    setNewTemplateName(name);
    if (name && name.length > 0) {
      setNewTemplateError(false);
    } else {
      setNewTemplateError(true);
    }
  };

  const onSubmit = async () => {
    if (canCreateTemplate) {
      if (!newTemplateName || newTemplateName.trim().length === 0) {
        setNewTemplateError(true);
        return;
      }

      try {
        const res = await templateActions.createSpaceTemplate({
          name: newTemplateName.trim(),
        });

        if (res.id) {
          history.push(`/space_templates/${res.id}/posts`);
          // history.goBack();
        } else {
          console.error("space template creation response is missing 'id'.");
        }
      } catch (error) {
        console.error("Error creating space template:", error);
      }
    } else {
      paywallModalPro();
    }
    modalHandler();
  };

  return (
    <SegmentsLayout isLoading={pending}>
      <div className={styles.container}>
        <PagePanel
          title={t("Templates")}
          buttonTitle={t("+ New Template")}
          buttonStyles={cls({
            ["btn-primary"]: canCreateTemplate,
            ["btn-upgrade"]: !canCreateTemplate,
          })}
          onButtonClick={onNewTemplateClick}
        />
        <TemplateSpaces />
      </div>
      <TemplatesPaywall show={showModal} onClose={closePaywall} />
      <UpgradeToPro show={showProPaywall} onClose={closeProPaywall} />
      <ModalLayout
        classNames={styles.modal_container}
        isOpen={newTemplateModalOpen}
        onClose={modalHandler}
      >
        <div className={styles.modal_content_wrapper}>
          <p>{t("New Template")}</p>
          <span className={styles.input_wrapper}>
            <label htmlFor="new-template">{t("Template Name")}</label>
            <input
              id="new-template"
              name="new-template"
              onChange={(e) => onNewTemplateNameChange(e)}
              className={cls({
                [styles.error]: newTemplateError,
              })}
            />
            {newTemplateError && (
              <p
                className={cls({
                  [styles.error_text]: newTemplateError,
                })}
              >
                {t("Name Required")}
              </p>
            )}
          </span>
          <span className={styles.buttons_container}>
            <Button
              title={t("Cancel")}
              buttonType="secondary-outline"
              onClickFunc={modalHandler}
            />
            <Button
              title={t("Create Template")}
              buttonType="primary"
              onClickFunc={onSubmit}
              disabled={newTemplateName && newTemplateName.length <= 0}
            />
          </span>
        </div>
      </ModalLayout>
    </SegmentsLayout>
  );
};

export default SpaceTemplates;
