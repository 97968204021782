import React from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { PostMedia } from "app/components/paywalls";
import { useResourcesModel } from "./indexModel";
import ResourcesContainer from "./ResourcesContainer";

const Resources = () => {
  const {
    user,
    t,
    showPaywallModal,
    canAddMedia,
    onUpgrade,
    closePaywall,
    onSelectMedia,
  } = useResourcesModel();

  return (
    <SegmentsLayout displayNoResult={!user}>
      <div className={styles.container}>
        <PagePanel
          title={t("Library")}
          titleStyles={styles.title}
          buttonTitle={t("+ Add Media")}
          buttonStyles={canAddMedia ? "btn-primary" : "btn-upgrade"}
          onButtonClick={() => onUpgrade()}
          url={canAddMedia && "/resources/new"}
        />
        <ResourcesContainer onSelectMedia={onSelectMedia} />
      </div>
      <PostMedia show={showPaywallModal} onClose={closePaywall} />
    </SegmentsLayout>
  );
};

export default Resources;
