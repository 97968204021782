/** @module components/paywalls */
import React, { useState } from "react";
import { cls } from "app/utils";
import { openIntercom, closeIntercom } from "app/utils/intercom";
import { chatSelected, demoSelected } from "app/utils/analytics-helpers";
import CalendlyModal from "app/components/modals/CalendlyModal";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  className?: string;
  disabled?: boolean;
  feature?: string;
  onChat?: (isOpen: boolean) => void;
  onSchedule?: (isOpen: boolean) => void;
};

/**
 * @class Support
 *
 */
export default function Support(props: PropsDef) {
  const { t } = useTranslation();
  const [showCalendly, setShowCalendly] = useState<boolean>(false);
  const [intercomIsOpen, setIntercomState] = useState<boolean>(false);

  const handleChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (intercomIsOpen) {
      closeIntercom();
    } else {
      openIntercom();
      chatSelected(props.feature || "Unknown");
    }

    if (props.onChat) {
      props.onChat(!intercomIsOpen);
    }
    setIntercomState(!intercomIsOpen);
  };

  const handleSchedule = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowCalendly(true);
    demoSelected(props.feature || "Unknown");
    if (props.onSchedule) {
      props.onSchedule(true);
    }
  };

  const closeCalendly = () => {
    setShowCalendly(false);
    if (props.onSchedule) {
      props.onSchedule(false);
    }
  };

  return (
    <div className={cls(styles.support, props.className)}>
      <strong className="text-secondary">{t("Have Questions?")}</strong>
      <div className={styles.description}>
        {t(
          "Want to see how it works, extend your trial time, or have another question?"
        )}
      </div>
      <button
        className={cls(styles.button, "btn btn-outline-secondary")}
        onClick={handleChat}
        disabled={props.disabled}
      >
        {t("Start Chat")}
      </button>
      &nbsp;&nbsp;
      <button
        className={cls(styles.button, "btn btn-outline-secondary")}
        onClick={handleSchedule}
        disabled={props.disabled}
      >
        {t("Schedule Call")}
      </button>
      <CalendlyModal isOpen={showCalendly} onClose={closeCalendly} />
    </div>
  );
}
