import React from "react";
import styles from './styles.module.scss';

interface OptProps {
  day: number;
  active: boolean;
  startTime: string | number;
  endTime: string | number;
  value: number;
  label: string;
  short: string;
}

interface OptionButtonProps {
  option: OptProps;
  selected: OptProps[];
  setSelected: any;
  shortButtons: boolean;
}

const OptionButton = ({ option, setSelected, shortButtons }: OptionButtonProps) => {

  return (
    <button
      type="button"
      onClick={() => {
        setSelected(option.value);
      }}
      className={`${styles.button} ${
        option.active ? styles.selected_btn : styles.default_btn
      } ${shortButtons ? styles.short_buttons : styles.long_buttons}`}>
      <span className={styles.button_text}>{option.short || option.label}</span>
    </button>
  );
};

export default OptionButton;
