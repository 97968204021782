/**
 * @module Components.Filter
 *
 */
import React from 'react';
import { cls } from 'app/utils';
import SearchBar from 'app/components/inputs/SearchBar';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = {
  clearSearchInput: boolean;
  onSearchSubmit: (value: any) => void;
};

export default function Search(props: PropsDef) {
  const { t } = useTranslation();

  const onSearchSubmit = (query: string) => {
    props.onSearchSubmit(query);
  };

  return (
    <div className={cls(styles.searchContainer, "pull-right")}>
      <SearchBar
        className={styles.search}
        placeholder={t('Search')}
        clearInput={props.clearSearchInput}
        onClick={onSearchSubmit}
      />
    </div>
  );
}
