/**
 * @module Reducers.LessonTypes
 *
 */
import { RequestError } from "typedefs";
import { LessonTypeAction } from "app/actions/lessonTypes";
import { LessonTypeModel } from "app/models/scheduling/LessonTypeModel";

export type LessonTypesState = {
  data: LessonTypeModel[] | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: LessonTypesState = {
  data: null,
  error: null,
  pending: true,
};

export default function listReducer(
  state = initialState,
  action: LessonTypeAction
): LessonTypesState {
  switch (action.type) {
    case "@lessonTypes.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@lessonTypes.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@lessonTypes.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@lessonTypes.clear":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
