/**
 * @module Actions.Event
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { StudentProfileModel } from "app/models/scheduling/StudentProfileModel";

const fetchPending = () => action("@studentProfile.fetch.pending");

const fetchError = (err: string) => action("@studentProfile.fetch.error", err);

const clearStudentProfile = () => action("@studentProfile.clear");
const fetchSuccess = (studentProfile: StudentProfileModel) =>
  action("@studentProfile.fetch.success", studentProfile);

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  clearStudentProfile,
};

export type StudentProfileAction = ActionType<typeof thisActions>;

export function fetchStudentProfile(studentProfileId: number) {
  return (dispatch: Dispatch<StudentProfileAction>) => {
    dispatch(fetchPending());
    return schedulingFetchGet<StudentProfileModel>(`student_profiles/${studentProfileId}`)
      .then((studentProfile: StudentProfileModel) => {
        const model = new StudentProfileModel(studentProfile);
        dispatch(fetchSuccess(model));
      })
      .catch((err) =>  {
        console.log('fetchErr', err);
        dispatch(fetchError(err));
      });
  };
}
