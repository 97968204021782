/**
 * @module Components.Subscription
 *
 */
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import { Token } from "app/utils/stripe";
import { RootState } from "app/reducers";
import { fetchSelf } from "app/actions/user";
import { updatePaymentInfo } from "app/actions/payment-info";
import StripeForm from "app/components/StripeForm";
import { ErrorMessage } from "app/components/helpers";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BillingInfoEdit() {
  const { t } = useTranslation();
  const history = useHistory();
  const paymentInfo = useSelector((state: RootState) => state.paymentInfo, shallowEqual);
  const actions = useActions({ fetchSelf, updatePaymentInfo });

  useEffect(() => (actions.fetchSelf(), undefined), [actions.fetchSelf]);

  const _updatePaymentInfo = (token: Token): void => {
    actions.updatePaymentInfo(token.id).then(() => leaveEdit());
  };

  const leaveEdit = () => {
    // route("/account/billing_info");
    history.goBack();
  };

  return (
    <div className={styles.billingInfoEdit}>
      <StripeForm
        disabled={paymentInfo.pending}
        submitTitle={t("Save Changes")}
        onToken={_updatePaymentInfo}
        onCancel={leaveEdit}
      />

      <ErrorMessage message={paymentInfo.error} />
    </div>
  );
}

export default BillingInfoEdit;
