import React from "react";
import ErrorPageLayout from "../../components/Layouts/ErrorPageLayout";
import { useTranslation } from 'react-i18next';

const ForbiddenPage = () => {
  const { t } = useTranslation();
  return (
    <ErrorPageLayout title={t("403 - Forbidden")}>
      <p>{t("You don't have enough permissions to perform this action.")}</p>
    </ErrorPageLayout>
  );
};

export default ForbiddenPage;
