import React from "react";
import Switch from "react-switch";
import styles from "./styles.module.scss";

interface SwitchToggleProps {
  checked: boolean;
  onChange: any;
  title: string;
  customText?: string;
  disabled?: boolean;
}

const SwitchToggle = ({
                        checked,
                        onChange,
                        title,
                        customText = '',
                        disabled = false,
                      }: SwitchToggleProps) => {
  return (
    <div className={styles.switch_toggle}>
      <div className={styles.container}>
        <Switch
          checked={checked}
          onChange={onChange}
          offColor="#CECECE"
          onColor="#27A4FF"
          onHandleColor="#FFFFFF"
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          height={30}
          width={50}
          className="react-switch"
          disabled={disabled}
          data-testid={`${title} Toggle`}
        />
        <span className={styles.tag}>{title}</span>
        {customText !== '' && (
          <span className={styles.custom_text}>{customText}</span>
        )}
      </div>
    </div>
  );
};
export default SwitchToggle;
