import React, { LegacyRef } from 'react';
import Select from 'react-select';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { SelectStyles } from './constants';

type PropsDef = {
  control: any;
  name: string;
  options: LessonRateModel[];
};

const SessionTypeSelector = React.forwardRef((props: PropsDef, _ref: LegacyRef<HTMLInputElement>) => {
  const { control, name, options } = props;
  const { field } = useController({ name, control });
  const { t } = useTranslation();

  const customOption = {
    title: t('Custom'),
    id: null,
  };

  const allOptions = [customOption, ...options];

  return (
    <Select
      styles={SelectStyles}
      placeholderText={t('Select')}
      getOptionLabel={opt => opt.title}
      getOptionValue={opt => opt.id}
      options={allOptions}
      isSearchable={false}
      value={allOptions.find((option) => {
        return option.id === field.value;
      })}
      onChange={field.onChange}
      selected={field.value}
    />
  );
});

export default SessionTypeSelector;
