import PostModel from "app/models/PostModel";
import { localGet, localSet } from "../LocalStorage";
import moment from "moment";
import { MutableRefObject, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "app/reducers";
import { sendContentViews, sendPostViews } from "app/actions/posts";
import useActions from "./useActions";

export interface IPostView {
  post_id: string;
  seen_at: string;
  viewed_at?: string;
}

export function usePostViewsTracking() {
  let cache: Array<IPostView> = localGet("postViews", []);
  // let intervalId = 0;
  const models: Array<PostModel> = [];
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const viewsActions = useActions({
    sendPostViews,
    sendContentViews,
  });

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        entries.forEach((entree) => {
          if (entree.isIntersecting) {
            const target = entree.target as HTMLElement;
            observer.unobserve(target);
            const viewsModel = prepareViewsModel(
              models.find((model) => model.id === target.dataset.id)
            );
            cache = localGet("postViews", []);
            cache.push(viewsModel);
            localSet("postViews", cache);
          }
        });
      }, {}),
    []
  );

  function prepareViewsModel(post: PostModel): IPostView {
    if (post) {
      if (post.type === "note") {
        return {
          post_id: post.id,
          seen_at: moment().toISOString(),
          viewed_at: moment().toISOString(),
        };
      }
    }

    return {
      post_id: post.id,
      seen_at: moment().toISOString(),
    };
  }

  function createObserver(elem: MutableRefObject<Element>, post: PostModel) {
    models.push(post);

    const isInCache = cache && cache.find((view) => view.post_id === post.id);

    if (!isInCache && !post.seenAt && post.userId !== self.id) {
      observer.observe(elem.current);
    }
  }

  function sendContentView(post: PostModel) {
    const model = {
      post_id: post.id,
      viewed_at: moment().toISOString(),
    };

    if (!post.viewedAt) {
      post.viewedAt = moment().toISOString();
      viewsActions.sendContentViews(model);
    }
  }

  function sendAndClearPostViews() {
    if (cache && cache.length > 0) {
      viewsActions.sendPostViews(cache);
      localSet("postViews", []);
    }
  }

  return {
    createObserver,
    sendContentView,
    sendAndClearPostViews,
  };
}
