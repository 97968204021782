import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchOrganization } from "app/actions/organizations";
import { RootState } from "typedefs";
import { OrganizationModel } from "app/models/OrganizationModel";

const Organization = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.organizations.data, shallowEqual);

  useEffect(() => {
    dispatch(fetchOrganization());
  }, []);

  return (
    <div className={styles.org_container}>
      <h1>Organization</h1>
      <table className={styles.table}>
        <tbody>
          <tr>
            {data &&
              data.map((org: OrganizationModel) => {
                return <td key={org.id}>{org.name}</td>;
              })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { Organization };
