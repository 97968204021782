import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import SelectSpaceSport from "./StepsComponents/SelectSpaceSport";
import InvitationToSpace from "./StepsComponents/InvitationToSpace";
import SelectSpaceList from "./StepsComponents/SelectSpaceList";
import { useActions, useSelector } from "app/utils/hooks";
import { clearAppliedTemplates } from "app/actions/spaceCreation";
// import { fetchSelf } from "app/actions/user";
import { RootState } from "typedefs";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { clearList } from "app/actions/list";

const CreateNewSpace = () => {
  const { t } = useTranslation();
  // const useractions = useActions({ user: fetchSelf });
  const newSpaceActions = useActions({ clearAppliedTemplates, clearList });
  const { step } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual
  );

  useEffect(() => {
    // useractions.user();
    // clear all states on unmount
    return () => {
      newSpaceActions.clearAppliedTemplates();
      newSpaceActions.clearList();
    };
  }, []);

  const renderContent = () => {
    if (step === 2) {
      return <InvitationToSpace />;
    }

    if (step === 3) {
      return <SelectSpaceList />;
    }

    return <SelectSpaceSport />;
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel
          title={t("Create New Space")}
          titleStyles={styles.header_title}
        />
        <div className={styles.panel}>{renderContent()}</div>
      </div>
    </>
  );
};

export default CreateNewSpace;
