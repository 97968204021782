import React, { useCallback, useMemo, useState } from "react";
import { cls } from "app/utils";
import { SelectBtn, SelectBtnOption } from "app/components/Button";
import { Loading } from "app/components/Wrappers";
import SpaceListContainer from "./SpaceListContainer";
import { SpacesLimit } from "app/components/paywalls";
import Styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import SpaceTransferSuccessModal from "app/components/SpaceTabsContainer/SpaceTransferSuccessModal";
import { RootState } from "typedefs";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PagePanel from "app/components/PagePanel";
import useQuery from "app/utils/hooks/useQuery";
import { selectedListValue } from "app/actions/smartList";
import UserModel from "app/models/UserModel";

export default function Spaces() {
  const [showUpgrade, setUpgradeState] = useState(false);
  const { self: user, pending } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const { isModalOpen } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openUpgrade = () => {
    setUpgradeState(true);
  };

  const closeUpgrade = () => {
    setUpgradeState(false);
  };

  const searchParams = useQuery();
  const queryObject = searchParams.get("object");
  const queryName = searchParams.get("name");
  const queryId = searchParams.get("id");

  const transformQueryName = useCallback(
    (name: string) => {
      return (
        name.replace(/_/g, " ").charAt(0).toUpperCase() +
        (queryName ?? "").replace(/_/g, " ").slice(1)
      );
    },
    [queryName]
  );

  useMemo(() => {
    if (queryObject || queryName) {
      dispatch(
        selectedListValue({
          name:
            (queryObject === "segment"
              ? queryName
              : transformQueryName(queryName)) ?? "",
          object: queryObject ?? "",
          id: queryId,
        })
      );
    }
  }, [queryId, queryName, queryObject, dispatch]);

  const pagePanelHeaderChildren = (
    <div className={Styles.space_list_action_wrapper}>
      <SpaceListAction
        user={user}
        onUpgrade={openUpgrade}
        jacksjourney={false}
      />
    </div>
  );

  return (
    <Loading className={cls(Styles.spaces)} isLoading={pending}>
      <div
        className={cls("non-scrollable", {
          ["spaces-container"]: user?.kind === "coach",
        })}
      >
        <GettingStartedProgress />
        <div className={Styles.page_container}>
          <PagePanel
            title={t("Spaces")}
            headerWrapperClassName={Styles.header_wrapper}
            headerChildren={pagePanelHeaderChildren}
          />
          <SpaceListContainer />
          {isModalOpen && <SpaceTransferSuccessModal />}
        </div>
        <SpacesLimit show={showUpgrade} onClose={closeUpgrade} />
      </div>
    </Loading>
  );
}

type SpaceListActionProps = {
  user: UserModel;
  jacksjourney: boolean;
  onUpgrade: () => void;
};

function SpaceListAction(props: SpaceListActionProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const newSingle = () => {
    if (props.jacksjourney) {
      history.push("/spaces/new?jacksjourney=true");
    } else {
      history.push("/spaces/new");
    }
  };

  const newMulti = () => {
    if (!props.user?.planPermissions?.includes("create_spaces")) {
      return props.onUpgrade();
    }

    return history.push("/spaces/new/multiple");
  };

  if (!props.user?.planPermissions?.includes("import_spaces")) {
    return (
      <button className="btn btn-upgrade" onClick={props.onUpgrade}>
        {t("+ New Space")}
      </button>
    );
  }

  return (
    <SelectBtn title={t("+ New Space")}>
      <SelectBtnOption onClick={newSingle}>
        <i className="ico ico-tspace mr-1" />
        {t("Create one Space")}
      </SelectBtnOption>

      <SelectBtnOption onClick={newMulti}>
        <i className="ico ico-groups mr-1" />
        {t("Create multiple Spaces")}
      </SelectBtnOption>
    </SelectBtn>
  );
}
