import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  clearSpaceTemplate,
  deleteSpaceTemplate,
  fetchSpacesTemplates,
} from "app/actions/spaceTemplate";
import { useActions } from "app/utils/hooks";
import TemplateCard from "./TemplateCard";
import NoPosts from "app/components/NoResults/NoPosts";
import { useTranslation } from "react-i18next";

const TemplatesList = () => {
  const { t } = useTranslation();
  const templateActions = useActions({
    fetchSpacesTemplates,
    deleteSpaceTemplate,
    clearSpaceTemplate,
  });

  const [templates, setTemplates] = useState<any>([]);

  useEffect(() => {
    templateActions.fetchSpacesTemplates().then((res: any) => {
      res.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });

      setTemplates(res);
    });

    return () => {
      setTemplates([]);
      templateActions.clearSpaceTemplate();
    };
  }, []);

  const onDelete = (id: string) => {
    templateActions.deleteSpaceTemplate(id).then(() => {
      setTemplates((prev: any) => {
        return prev.filter((template: any) => template.id !== id);
      });
    });
  };

  if (templates?.length === 0) {
    return (
      <NoPosts
        title={t("No Templates Yet")}
        description={t(
          "Templates make it easy to create the same content for any number of Spaces or Teams."
        )}
      />
    );
  }

  return (
    <div className={styles.container}>
      {templates &&
        templates.map((template: any) => {
          return (
            <TemplateCard
              template={template}
              key={template.id}
              onDelete={onDelete}
            />
          );
        })}
    </div>
  );
};

export default TemplatesList;
