import { useCallback, useEffect, useMemo, useState } from "react";
import Fuse from "../../../vendor/fuse";
import { useActions, useSelector } from "app/utils/hooks";
import {
  clearGroup,
  clearMyInformationData,
  clearSelectedGroupData,
  fetchGroups,
} from "../../actions/groups";
import { RootState } from "typedefs";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";
import SpaceModel from "app/models/SpaceModel";

export const useGroupsModel = () => {
  const [searchTerm, setSearch] = useState<string>("");
  const history = useHistory();
  const groupActions = useActions({
    fetchGroups,
    clearGroup,
    clearSelectedGroupData,
    clearMyInformationData,
  });
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false);

  useEffect(() => {
    groupActions.fetchGroups();

    return () => {
      groupActions.clearSelectedGroupData();
      groupActions.clearMyInformationData();
      groupActions.clearGroup();
    };
  }, []);

  const groups = useSelector((state: RootState) => state.groups, shallowEqual);
  const user = useSelector((state: RootState) => state.user.self, shallowEqual);

  const canCreateGroup = user && user.planPermissions.includes("create_teams");

  const fuseOptions = {
    keys: ["name", "sport"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.5,
  };

  const fuse = useMemo(() => {
    if (groups && groups.data) {
      const items = groups && groups.data && groups.data.map((space: SpaceModel) => space);
      return new (Fuse as any)(items, fuseOptions);
    }
  }, [groups, fuseOptions]);

  const results = useMemo(() => {
    if (groups && groups.data) {
      if (searchTerm !== "") {
        return fuse.search(searchTerm).map((result: any) => result.item);
      } else {
        return groups.data;
      }
    } else {
      return null;
    }
  }, [searchTerm, groups, fuse]);

  const onSearchGroup = useCallback(
    (value: string) => {
      if (value != null && value.length > 0) {
        setSearch(value);
      } else {
        setSearch("");
      }
    },
    [searchTerm]
  );

  const onGroupClickHandler = (group_id: string) => {
    history.push(`/groups/${group_id}`);
  };

  const createGroupModalHandler = () => {
    setIsOpenCreateGroupModal((prev) => !prev);
  };

  return {
    groups,
    searchTerm,
    onSearchGroup,
    canCreateGroup,
    results,
    onGroupClickHandler,
    createGroupModalHandler,
    isOpenCreateGroupModal,
  };
};
