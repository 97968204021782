/** @module components/paywalls */
import React from "react";
import { cls } from "app/utils";
import ModalWrapper from "app/components/modals/ModalWrapper";
import Support from "app/components/paywalls/Support";
import spacelimit_icon_png from "../../../../public/images/paywall-body-icon-templates.png";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { startTrial } from "app/actions/subscription";
import { useActions } from "app/utils/hooks";

type PropsDef = {
  show?: boolean;
  onClose?: () => void;
};

export default function TemplatesPaywall(props: PropsDef) {
  const { t } = useTranslation();
  const trialAction = useActions({ startTrial });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );

  const handleClick = async () => {
    await trialAction.startTrial();
    (window as any).location = "/upgrade?return_url=/feed";
  };

  const handleTrialClicked = async () => {
    await trialAction.startTrial();
    (window as any).location = "/space_templates?trialStarted=true";
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  function canStartTrial() {
    const noStatus =
      user?.subscription != null && user?.subscription?.status === "none";
    const hasTrialStarted = !(user?.plan && user?.plan?.trialStartedAt != null);
    return !user?.hasTrialSubscription && noStatus && hasTrialStarted;
  }

  return (
    <ModalWrapper
      isOpen={props.show}
      onRequestClose={handleClose}
      title={t("Upgrade to Create a Template")}
    >
      <div className={cls(styles.spacesLimitModal)}>
        <div className={styles.upper}>
          <div className={styles.image}>
            <img src={spacelimit_icon_png} alt="Space Limit Icon" />
          </div>

          <h5>{t("Templates Not Enabled")}</h5>

          <p className={styles.description}>
            {t("Upgrade to")}{" "}
            <a href="https://coachnow.io/pro" className={styles.highlight}>
              CoachNow+
            </a>{" "}
            {t("to create reusable series of posts for easy Space setup!")}
          </p>

          {/* <p className="text-muted">
            <Trans
              i18nKey="upgrade_to_coachnow_plus"
              components={{ a: <a href="https://coachnow.io/pricing" target="_blank" /> }}
             />
          </p> */}
        </div>

        <div className={styles.lower}>
          <div className={styles.section}>
            <h3>{t("Why Upgrade?")}</h3>

            <p className={styles.description}>
              {t(
                "Transform your coaching with unlimited Spaces, media posting, Library storage, Lists, and Templates."
              )}
            </p>

            <button className="btn btn-primary" onClick={handleClick}>
              {t("Upgrade Now")}
            </button>
          </div>

          {canStartTrial() && (
            <div className={styles.section}>
              <h3 className={styles.highlight_green}>{t("Try It Out!")}</h3>

              <p className={styles.description}>
                {t(
                  "Check out all the features CoachNow+ has to offer, with a free 7-day trial."
                )}
              </p>

              <button className="btn btn-success" onClick={handleTrialClicked}>
                {t("Start Trial")}
              </button>
            </div>
          )}

          <div className={styles.section}>
            <Support className={styles.lower} feature={t("Space Limit")} />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
