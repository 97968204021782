import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'typedefs';

import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { TimeZoneState } from 'app/reducers/scheduling/timeZone';
import { TimeZoneModel } from "app/models/scheduling/TimeZoneModel";

import styles from './CoachInfo.module.scss';

type PropsDef = {
  coach: CoachProfileModel,
  singleCoach: boolean,
};

const CoachInfo = ({ coach, singleCoach }: PropsDef) => {
  const timeZones = useSelector((state: RootState): TimeZoneState => state.schedulingTimeZones);
  const timezone = timeZones.data
    .find((item: TimeZoneModel) => item.value === coach.timeZone)?.display || '';

  const [timeZoneOffset, ...timeZoneName] = timezone.split(' ');
  const parentClass = singleCoach ? styles.parent_single_coach : styles.parent_multi_coach;

  return (
    <div className={parentClass}>
      <div className={styles.container}>
        <div className={styles.info_text}>
          <div className={styles.coach_name}>{coach.fullName}</div>
          <div className={styles.timezone}>
            <i className={`${styles.tz_ico} ico ico-globe`} />
            <span>{timeZoneOffset}</span>
            <span>{timeZoneName.join(' ')}</span>
          </div>
        </div>

        <img className={styles.avatar} src={coach.profilePicture} />
      </div>
    </div>
  );
};

export default CoachInfo;
