import React from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserAvatar from "app/components/UserAvatar";
import InspirationalModal from "../../routes/DemoSpace/Modals/InspirationalModal";

interface Props {
  label?: string;
}

const DemoSpaceFeedPostingForm = ({ label = "Post to Space" }: Props) => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className={styles.container}>
        <UserAvatar
          altName="user"
          srcName={self.avatar as string}
          sizeHeight={44}
          sizeWidth={44}
          userName={self.displayName}
          className={styles.avatar}
        />
        <div className={styles.input} onClick={handleModal}>
          {label}
        </div>
      </div>
      <InspirationalModal isOpen={isModalOpen} onClose={handleModal} />
    </>
  );
};

export default DemoSpaceFeedPostingForm;
