import { useCallback, useEffect, useRef, useState } from "react";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { membersPage as memberPage } from "app/actions/groups";
import {
  updateTeamGroupMember,
  clearTeamGroupMembers,
  deleteTeamGroupMember,
  fetchTeamGroupMembers,
} from "app/actions/team-group";
import useDebounce from "app/utils/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type FilterName = "coach" | "player" | "viewer";

export const useTeamsGroupMembersModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const memberActions = useActions({
    fetchTeamGroupMembers,
    clearTeamGroupMembers,
    deleteTeamGroupMember,
    updateTeamGroupMember,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );

  // const { selectedTeam, membersData, membersPage, hasMoreMembers } =
  //   useSelector((state: RootState) => state.groups);
  const { selectedTeam, membersList, membersPage, hasMoreMembers } =
    useSelector((state: RootState) => state.teamGroup, shallowEqual);
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
  const [canManageMembers, setCanManageMembers] = useState(false);
  const [members, setMembers] = useState<UserModel[]>(null);
  const [memberFilterParams, setMemberFilter] = useState({
    coach: true,
    player: true,
    viewer: true,
  });
  const [searchTerm, setSearchTerm] = useState(null);

  const membersFilterHandler = (e: { [key in FilterName]: boolean }) => {
    setMemberFilter(e);
  };

  const memberSearchHandler = (val: string) => {
    setSearchTerm(val);
  };

  const inviteMembersModalHandler = () => {
    setInviteModalIsOpen((prev) => !prev);
  };

  const debouncedSearch = useDebounce(searchTerm, 300);

  const memberSearchParams = {
    userName: debouncedSearch,
  };

  useEffect(() => {
    dispatch(memberPage(1));
    if (selectedTeam) {
      memberActions
        .fetchTeamGroupMembers(selectedTeam.id, {
          page: 1,
          per_page: 20,
          ...memberSearchParams,
        })
        .then((res: UserModel[]) => {
          setMembers(null);
          setMembers(res);
        });

      if (
        selectedTeam.permissions.includes("manage_members") &&
        selectedTeam.planPermissions.includes("manage_members")
      ) {
        setCanManageMembers(true);
      } else {
        setCanManageMembers(false);
      }
    }

    return () => {
      setCanManageMembers(false);
      // setMembers(null);
      memberActions.clearTeamGroupMembers();
    };
  }, [selectedTeam, memberFilterParams, debouncedSearch]);

  const fetchPaginatedGroupMembers = async (): Promise<UserModel[]> => {
    try {
      const res: [] = await memberActions.fetchTeamGroupMembers(
        selectedTeam.id,
        {
          page: membersPage,
          per_page: 20,
          ...memberSearchParams,
        }
      );

      if (res && Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      // console.error(error);
      return [];
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      if (hasMoreMembers && selectedTeam && members) {
        const entry = entries[0];
        if (entry.isIntersecting) {
          fetchPaginatedGroupMembers().then((res: []) => {
            setMembers((prev: []) => [...prev, ...res]);
          });
        }
      }
    };

    if (containerRef.current) {
      observer = new IntersectionObserver(handleObserver, {
        threshold: 0.1,
        rootMargin: "100px",
      });
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
        observer.disconnect();
      }
    };
  }, [selectedTeam, containerRef, membersPage, hasMoreMembers, membersList]);

  const onDeleteMember = (memberId: string, userName: string) => {
    const confirmation = window.confirm(
      t(`Are you sure you want to remove {{userName}} from this channel?`, {
        userName,
      })
    );
    if (confirmation) {
      memberActions
        .deleteTeamGroupMember(selectedTeam.id, memberId)
        .then(() => {
          if (memberId === self.id) {
            route("/groups");
          } else {
            setMembers((prevPosts) =>
              prevPosts.filter((member) => member.id !== memberId)
            );
          }
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onMemberInvite = useCallback((members) => {
    setMembers((prevMembers) => {
      return [...prevMembers, ...members];
    });
  }, []);

  const onMemberUpdate = (updatedMember: UserModel) => {
    memberActions
      .updateTeamGroupMember(selectedTeam.id, updatedMember)
      .then(() => {
        setMembers((prevMembers) => {
          // Find the index of the updated member in the array
          const updatedIndex = prevMembers.findIndex(
            (member) => member.id === updatedMember.id
          );

          // If the member is found, update it
          if (updatedIndex !== -1) {
            const updatedMembers = [...prevMembers];
            updatedMembers[updatedIndex] = updatedMember;
            return updatedMembers;
          }

          // If the member is not found, return the previous array as is
          return prevMembers;
        });
      });
  };

  return {
    selectedTeam,
    canManageMembers,
    membersFilterHandler,
    members,
    containerRef,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler,
    inviteMembersModalHandler,
    inviteModalIsOpen,
    onMemberInvite,
    modalInviteIsOpen,
  };
};
