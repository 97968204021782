import React, {useEffect, useState} from 'react';
import style from '../styles.module.scss';
import DeleteIcon from '../../../../public/images/delete-icon.svg';
import UpArrow from '../../../../public/images/up-arrow.svg';
import DownArrow from '../../../../public/images/down-arrow.svg';
interface ScheduleDropdownProps {
  key: string;
  schedule: any;
  index: number;
  noSchedules: number;
  deleteSchedule: (id: number) => void;
  children: React.ReactNode;
}

const ScheduleDropdown = ({key, schedule, index, noSchedules, deleteSchedule, children}: ScheduleDropdownProps) => {

  const [toggled, setToggled] = useState(false);

  const handleClick = () => {
    setToggled(!toggled);
  };

  useEffect(() => {
    if (index === 0) {
      setToggled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrow = toggled ? (
    <img style={{width: '20px', height: '20px', display: 'block', margin: '0 auto'}} src={UpArrow}
         alt="info" onClick={handleClick}/>
  ) :
    (
      <img style={{width: '20px', height: '20px', display: 'block', margin: '0 auto'}} src={DownArrow}
           alt="info" onClick={handleClick}/>
    );

  return (
    <div key={key} className={style.schedule_dropdown}>
      <div className={style.sub_container}>
        <div className={style.dropdown_container}>
          <div className={style.title_container}>
            <div className={style.label}>{schedule.name}</div>
            <div className={style.left_side_header}>
              {noSchedules > 1 && (
                <>
                  <img style={{width: '20px', height: '20px', display: 'block', margin: '0 auto'}} src={DeleteIcon}
                       alt="info" onClick={() => deleteSchedule(schedule)}/>
                  <div className={style.vertical_line}/>
                </>
              )}
              <span
                className={style.arrow}
                onClick={null}>
                {arrow}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${style.list} ${
            !toggled && style.hide
          }`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDropdown;
