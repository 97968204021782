/** @module components/Selectbox */
import React, { useEffect, useState } from "react";
import SelectBox, {
  SelectProps,
  OptionsType,
  DefaultOption,
} from "app/components/inputs/SelectBox/Base";
import {  useStoreActions } from "app/utils/hooks";
import { cls } from "app/utils";
import { fetchSports } from "app/actions/sports";
import { useTranslation } from "react-i18next";

type PropsDef = SelectProps & {
  value?: string;
  defaultValue?: string;
};

/**
 * Renders a select drop down prefilled with sports to select from
 *
 * @name SportSelect
 * @param {Properties} props
 * @return {Element}
 */
export default function SportSelect(props: PropsDef) {
  const { t } = useTranslation();
  const { className, isCreatable, isClearable, value, defaultValue, ...rest } =
    props;

  // fetch sports list from api
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });
  const [__options, setOptions] = useState<OptionsType>(null);

  const createOption = (val: string): DefaultOption => {
    return { value: val, label: val };
  };

  useEffect(() => {
    if (sports.data != null) {
      setOptions(sports.data.map(createOption));
    }
  }, [sports.data]);

  const __value = value != null && value.length ? createOption(value) : null;
  const __defaultValue =
    defaultValue != null && defaultValue.length
      ? createOption(defaultValue)
      : null;

  return (
    <SelectBox
      className={cls("sport-select", className)}
      placeholder={t("Select Sport / Subject")}
      isCreatable={isCreatable}
      isClearable={isClearable || isCreatable}
      options={__options}
      defaultValue={__defaultValue}
      value={__value}
      {...rest}
    />
  );
}
