import React from "react";
import AccountWrap from "..";
import PagePanel from "app/components/PagePanel";
import BillingEdit from "app/components/subscription/BillingInfo/Edit";
import { useTranslation } from "react-i18next";

const BillingInfoEdit = () => {
  const { t } = useTranslation();

  return (
    <AccountWrap>
      <PagePanel title={t("Billing")}>
        <BillingEdit />
      </PagePanel>
    </AccountWrap>
  );
};

export default BillingInfoEdit;
