import React from "react";
import CheckBox, { PropsDef } from "app/components/inputs/CheckBox/Base";
import PrivacyPolicy from "app/components/links/PrivacyPolicy";
import TermsOfService from "app/components/links/TermsOfService";
import Cookies from "app/components/links/Cookies";
import { Trans } from "react-i18next";

export default function TermsOfServiceCheckbox(props: PropsDef) {
  const checkboxLabel = (
    <Trans i18nKey="tosConsentLabel">
      I agree to CoachNow's <PrivacyPolicy />, <TermsOfService />, and{" "}
      <Cookies />.
    </Trans>
  );

  return <CheckBox {...props} name="tosConsent" label={checkboxLabel} />;
}
