/**
 * @module Components.Selectbox
 *
 */
import React, { useRef, useEffect, useState } from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { NoticeProps } from "react-select/src/components/Menu";
import { useStoreActions } from "app/utils/hooks";
import { AuthService } from "app/services";
import SelectBox, {
  StateManager,
  SelectProps,
  OptionsType,
  DefaultOption,
} from "app/components/inputs/SelectBox/Base";
import { uuid } from "app/utils/uuid";
import { cls } from "app/utils";
import { UserModel } from "app/models";
import { fetchConnections } from "app/actions/connection";
import { CreateNewForm } from "./helpers";
import UserAvatar from "app/components/UserAvatar";
import { findJack, createMemberJack } from "app/utils/appcues";
import Styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

/***/
const Auth = AuthService as any;

export interface UserOption extends DefaultOption {
  model: UserModel;
}

interface ComponentOpts {
  isCreatable: boolean;
  selectState: React.MutableRefObject<StateManager<UserOption>>;
  noOptionsMessage: (value: { inputValue: string }) => string;
  onSaveNewOption: (value: UserOption) => void;
}

interface PropsDef extends SelectProps<UserOption> {
  value?: any; // UserModel[];
  defaultValue?: any; // UserModel[];
  excludeOptions?: UserModel[];
  hideSelected?: boolean;
  includeJack?: boolean;
}

const normailzeValue = (val?: UserModel[] | null) => {
  if (val == null) {
    return null;
  }

  if (Array.isArray(val)) {
    return val.map(createUserOption);
  } else {
    return createUserOption(val);
  }
};

/**
 * @class UserSelect
 *
 */
export default function UserSelect(props: PropsDef) {
  const { t } = useTranslation();
  const {
    className,
    value,
    defaultValue,
    isCreatable,
    excludeOptions,
    noOptionsMessage,
    hideSelected,
    includeJack,
    ...rest
  } = props;

  const selectState = useRef(null);
  const [__options, setOptions] = useState<OptionsType<UserOption>>(undefined);

  // load connections from api
  const { connection } = useStoreActions({ connection: fetchConnections });

  const buildOptions = (): void => {
    if (connection.data != null) {
      const exclude = excludeOptions || [];

      let users: UserModel[] = connection.data;
      if (exclude.length) {
        users = users.filter((user: UserModel) => {
          return exclude.find((ex: UserModel) => ex.id === user.id) == null;
        });
      }

      if (includeJack && Auth.user && Auth.user.kind === "coach") {
        if (findJack(users, "email") == null) {
          users.unshift(createMemberJack());
        }
      }

      setOptions(users.map(createUserOption));
    }
  };

  useEffect(() => buildOptions(), [connection.data, excludeOptions, Auth.user]);

  const onSaveNewOption = (value: UserOption) => {
    if (selectState.current != null) {
      selectState.current.createOption([value]);
    }
  };

  const [showForm, setShowForm] = useState(false);
  const handleClose = () => {
    setShowForm(false);
  };

  const comps = createComponents(
    {
      selectState,
      isCreatable,
      onSaveNewOption,
      noOptionsMessage,
    },
    showForm,
    setShowForm
  );

  return (
    <SelectBox
      ref={selectState}
      className={cls("user-select", className)}
      placeholder={t("Search by name or email")}
      controlShouldRenderValue={!hideSelected}
      isClearable={true}
      isCreatable={false}
      isMulti={true}
      onMenuClose={handleClose}
      options={__options}
      value={normailzeValue(value)}
      defaultValue={normailzeValue(defaultValue)}
      components={comps}
      {...rest}
    />
  );
}

function createUserOption(user: UserModel): UserOption {
  return {
    value: user.id || uuid(),
    label: `${user.displayName} ${user.email}`,
    model: user,
  };
}

interface AddNewProps {
  label: string;
  showTitle?: boolean;
  onClick: (event: React.FormEvent<HTMLButtonElement>) => void;
}

export function AddNew(props: AddNewProps) {
  const { t } = useTranslation();
  const title = <h3 className="needsclick">{t("Add Option")}</h3>;
  return (
    <div
      className={cls("add-new-handle", Styles.noOptionsButton, "needsclick")}
      contentEditable={false}
    >
      {props.showTitle ? title : ""}
      <button className="btn btn-primary needsclick" onClick={props.onClick}>
        {props.label}
      </button>
    </div>
  );
}

function createComponents(
  {
    isCreatable,
    selectState,
    onSaveNewOption,
    noOptionsMessage,
  }: ComponentOpts,
  showForm: boolean,
  setShowForm: (v: boolean) => void
) {
  const { t } = useTranslation();
  function NoOptionsMessage(props: NoticeProps<UserOption>) {
    const inputValue =
      selectState.current && selectState.current.getInputValue();

    const createNewOption = (event: React.FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowForm(true);
      return false;
    };

    const formatCreateLabel = () => {
      if (noOptionsMessage != null) {
        return noOptionsMessage({ inputValue });
      }
      return t("Add Option");
    };

    const saveNewOption = (user: UserModel) => {
      if (onSaveNewOption != null) {
        onSaveNewOption(createUserOption(user));
      }
      setShowForm(false);
    };

    if (isCreatable) {
      const rest = { ...props };
      delete rest.children;

      if (showForm) {
        return (
          <components.NoOptionsMessage {...rest}>
            <CreateNewForm
              getLabel={formatCreateLabel}
              onDone={saveNewOption}
              onCancel={() => setShowForm(false)}
              inputValue={inputValue}
            />
          </components.NoOptionsMessage>
        );
      }
      return (
        <components.NoOptionsMessage {...rest}>
          <AddNew
            showTitle={true}
            label={formatCreateLabel()}
            onClick={createNewOption}
          />
        </components.NoOptionsMessage>
      );
    }
    return <components.NoOptionsMessage {...props} />;
  }

  function Option(props: OptionProps<UserOption>) {
    const rest = { ...props };
    delete rest.children;

    const user = props.data.model;
    if (user == null) {
      return <components.Option {...rest}>{props.children}</components.Option>;
    }

    return (
      <components.Option {...rest}>
        <div className="menu-item needsclick" id={user.id}>
          <div className="profile-block user-select-user profile-block-default needsclick">
            <UserAvatar
              className="avatar needsclick"
              userName={user.name}
              srcName={user.avatar as string}
            />
            <div className="profile-block-details needsclick">
              <div className="profile-block-title needsclick">
                {user.displayName}
              </div>

              <div className="profile-block-summary needsclick">
                {user.email || user.phoneNormalized}
              </div>
            </div>
          </div>
        </div>
      </components.Option>
    );
  }

  function MultiValueLabel(props: MultiValueProps<UserOption>) {
    const rest = { ...props };
    delete rest.children;

    const user = props.data.model;
    return (
      <div className={cls(Styles.selectedUser, "needsclick")} key={user.id}>
        <components.MultiValueLabel {...rest}>
          <UserAvatar
            className="avatar needsclick"
            srcName={user.avatar as string}
          />
          <span className="needsclick">{user.displayName}</span>
        </components.MultiValueLabel>
      </div>
    );
  }

  function Menu(props: any) {
    const { onMouseDown } = props.innerProps;
    props.innerProps.onMouseDown = (evt: React.MouseEvent<HTMLDivElement>) => {
      if (evt.button !== 0) {
        return evt;
      }

      let isButtonClick: boolean = false;
      if (evt.relatedTarget != null) {
        isButtonClick = /btn/.test(
          (evt.relatedTarget as HTMLElement).className
        );
      }

      if (
        evt.currentTarget.contains(evt.target as HTMLElement) &&
        (showForm || isButtonClick)
      ) {
        return evt;
      }

      return onMouseDown(evt);
    };

    props.innerProps.contentEditable = false;

    return <components.Menu {...props} />;
  }

  function Input({ onBlur, ...props }: any) {
    props.onBlur = (evt: React.MouseEvent<HTMLDivElement>) => {
      const inputValue =
        selectState.current && selectState.current.getInputValue();

      let isButtonClick: boolean = false;
      if (evt.relatedTarget != null) {
        isButtonClick = /btn/.test(
          (evt.relatedTarget as HTMLElement).className
        );
      }

      if (
        inputValue != null &&
        inputValue.length &&
        (showForm || isButtonClick)
      ) {
        evt.preventDefault();
        evt.stopPropagation();
        return false;
      }

      return onBlur(evt);
    };
    return <components.Input {...props} />;
  }

  return { Option, NoOptionsMessage, MultiValueLabel, Input, Menu };
}
