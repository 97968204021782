import PostingForm from "app/components/PostingForm";
import React from "react";
import { SpacePostingFormModel } from "./indexModel";
import { useTranslation } from "react-i18next";

interface Props {
  onPost?: (post: any) => void;
}

const SpacePostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { user, canUploadMedia, onSubmit } = SpacePostingFormModel({
    onPost,
  });

  return (
    <PostingForm
      textAreaPlaceholder={t("Post to Space")}
      canSchedule={
        user && user.planPermissions.includes("access_scheduled_posts")
      }
      profilePicture={user && (user.avatar as string)}
      userName={user && user.displayName}
      canUploadMedia={canUploadMedia()}
      hasLocationSelect={false}
      postButtonLabel={t("Post")}
      postTextIsRequired
      onPost={onSubmit}
    />
  );
};

export default SpacePostingForm;
