import UserModel from "app/models/UserModel";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

interface Props {
  members: UserModel[];
  teamGroups: any;
}

const ExpandedListContainer: React.FC<Props> = ({
  members,
  teamGroups,
}: Props) => {
  const { t } = useTranslation();
  const [showMembers, setShowMembers] = useState(true);
  const [showGroups, setShowGroups] = useState(true);

  return (
    <div className="expanded-list-container">
      <div
        className={`expanded-list ${showMembers ? "active" : ""}`}
        onClick={() => setShowMembers(!showMembers)}
      >
        <label>
          <i></i>
          {t("Members")}
        </label>
        <table>
          <tbody>
            {members &&
              members.map((member, index) => (
                <tr key={index}>
                  {/* @ts-ignore */}
                  <td width="1" />
                  <td>{member.email}</td>
                  <td>{member.name}</td>
                  <td>{member.position}</td>
                  <td>{member.jersey}</td>
                  <td>{member.number}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {teamGroups && teamGroups.length > 0 && (
        <div
          className={`expanded-list ${showGroups ? "active" : ""}`}
          onClick={() => setShowGroups(!showGroups)}
        >
          <label>
            <i></i>
            {t("Channels")}
          </label>
          <table>
            <tbody>
              {teamGroups.map((teamGroup: any, index: number) => (
                <tr key={index}>
                  {/* @ts-ignore */}
                  <td width="1" />
                  <td>{teamGroup.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExpandedListContainer;
