import React, { useState } from "react";
import styles from "./styles.module.scss";
import InspirationalModal from "../../Modals/InspirationalModal";

const DemoFeedFilter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className={styles.container}>
        <hr />
        <span className={styles.filter_btn_wrapper} onClick={handleModal}>
          <i className="ico ico-funnel" />
          <p>Filter</p>
        </span>
      </div>
      <InspirationalModal isOpen={isModalOpen} onClose={handleModal} />
    </>
  );
};

export default DemoFeedFilter;
