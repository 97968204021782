import { useCallback, useEffect, useMemo, useState } from "react";
import { useActions } from "app/utils/hooks";
import Fuse from "../../../../vendor/fuse";
import { GroupSelectProps } from "./index.types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  locations,
  groupObj,
  applyTemplateToGroup,
} from "app/actions/applyTemplate";
import { RootState } from "typedefs";
import TeamGroupModel from "app/models/TeamGroupModel";

const initGroups: TeamGroupModel = [];

const useTeamSelectModal = ({ onClose, templateId }: GroupSelectProps) => {
  const dispatch = useDispatch();

  const team = useSelector((state: RootState) => state.team, shallowEqual);

  const teamIds = useSelector(
    (state: RootState) => state.applyTemplate.locations, shallowEqual
  );
  const teamsArr = useSelector(
    (state: RootState) => state.applyTemplate.groupObj, shallowEqual
  );

  const applyTemplateActions = useActions({
    applyTemplateToGroup,
  });
  const [searchTerm, setSearch] = useState<string>("");
  const [teams, setTeams] = useState(initGroups);
  const [selectedTeamsId, setSelectedTeamsId] = useState<string[]>(teamIds);
  const [selectedTeam, setSelectedTeam] = useState<TeamGroupModel[]>(teamsArr);

  const fuseOptions = {
    keys: ["athleteName", "name"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.5,
  };

  useEffect(() => {
    if (team.data != null) {
      setTeams(team.data || []);
    }
  }, [team.data]);

  const fuse = useMemo(() => {
    const items = teams.map((team: TeamGroupModel) => team);
    return new (Fuse as any)(items, fuseOptions);
  }, [teams, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "") {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return teams;
    }
  }, [searchTerm, team, fuse]);

  const onSearchTeam = useCallback(
    (value: string) => {
      if (value != null && value.length > 0) {
        setSearch(value);
      } else {
        setSearch("");
      }
    },
    [searchTerm]
  );

  const teamSelectHandler = useCallback(
    (value: string, groupObj: TeamGroupModel) => {
      setSelectedTeamsId((prevSelectedTeamsId) => {
        if (prevSelectedTeamsId.includes(value)) {
          return prevSelectedTeamsId.filter((val) => val !== value);
        } else {
          return [...prevSelectedTeamsId, value];
        }
      });
      setSelectedTeam((prevSelectedTeam) => {
        if (prevSelectedTeam.includes(groupObj)) {
          return prevSelectedTeam.filter((val) => val !== groupObj);
        } else {
          return [...prevSelectedTeam, groupObj];
        }
      });
    },
    [selectedTeamsId, selectedTeam]
  );

  useEffect(() => {
    dispatch(locations(selectedTeamsId));
    dispatch(groupObj(selectedTeam));
  }, [selectedTeamsId, selectedTeam]);

  const cancelHandler = () => {
    setSelectedTeamsId([]);
    setSelectedTeam([]);
    setSearch("");
    onClose();
  };

  const applyHandler = () => {
    applyTemplateActions.applyTemplateToGroup(selectedTeamsId, templateId);
    setSearch("");
    onClose();
  };

  return {
    teamSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchTeam,
    team,
    searchTerm,
    results,
    selectedTeamsId,
  };
};

export default useTeamSelectModal;
