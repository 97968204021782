import PanelWrapper from "app/components/PanelWrapper";
import React from "react";
import styles from "./styles.module.scss";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const ErrorPageLayout = ({ title, children }: Props) => {
  const { t } = useTranslation();
  const homePageUrl = "/";
  const history = useHistory();

  return (
    <PanelWrapper>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.description}>{children}</div>
      <p className={styles.description}>
        {t("You could go back to")}{" "}
        <span className={styles.link} onClick={() => history.goBack()}>
          {t("where you were")}
        </span>{" "}
        {t("or head straight to our")} <Link to={homePageUrl}>{t("home page")}</Link>
      </p>
    </PanelWrapper>
  );
};

export default ErrorPageLayout;
