import PostModel from "app/models/PostModel";
import { fetchGet } from "app/utils/request";
import { Dispatch } from "react";
import { RequestError } from "typedefs";
import { action, ActionType } from "typesafe-actions";

export interface scheduleDateProps {
  pickedAt: number | null;
  pickedIn: string;
}

export const scheduledPosts = (scheduledPosts: {
  posts: PostModel[];
  error?: RequestError | null;
  pending?: boolean;
}) => action("@schedulePost.fetch.scheduledPostsSuccess", scheduledPosts);

const pending = () => action("@schedulePost.fetch.pending");
const error = (err: string) => action("@schedulePost.fetch.error", err);
export const scheduleIn = (scheduleIn: scheduleDateProps) =>
  action("@schedulePost.fetch.scheduleIn", scheduleIn);

const pendingSchedulePost = () => action("@schedulePost.create.pending");
const errorSchedulePost = (err: string) =>
  action("@schedulePost.create.error", err);

export const clearSchedulePost = () => action("@schedulePost.clear");

const thisActions = {
  scheduledPosts,
  pending,
  error,
  scheduleIn,
  pendingSchedulePost,
  errorSchedulePost,
  clearSchedulePost,
};

export function fetchScheduledPosts(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<any>) => {
    // dispatch(pending());

    return fetchGet<PostModel[]>(
      `posts/scheduled`,
      params || {},
      {},
      signal
    ).then((res: PostModel[]) => {
      if (Array.isArray(res)) {
        dispatch(scheduledPosts({ posts: res, error: null, pending: false }));
      }

      return res;
    });
  };
}

export type SchedulePostAction = ActionType<typeof thisActions>;
