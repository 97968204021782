import React, { useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import Button from "app/components/Button/ButtonVariant";
import SubscriptionModel from "app/models/SubscriptionModel";
import { useTranslation } from 'react-i18next';

interface SubscriptionCancelModalProps {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}

const SubscriptionCancelModal = ({
  isOpen,
  onClose,
  subscription,
}: SubscriptionCancelModalProps) => {
  const { t } = useTranslation();
  const [basePlanName] = useState(subscription.planName);

  const infoText = () => {
    switch (subscription.source) {
      case "AppStore":
        return {
          summary: (
            <>
              {t("Your")} {basePlanName} {t("subscription is managed by the")}{" "}
              <b>{t("App Store")}</b>. {t("Learn more about how to manage your subscriptions")}{" "}
              <a
                href="https://support.apple.com/en-us/HT202039"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("here")}
              </a>
              .
            </>
          ),
          showSupportContact: true,
        };
      case "PlayStore":
        return {
          summary: (
            <>
              {t("Your")} {basePlanName} {t("subscription is managed by the")}{" "}
              <b>{t("Play Store")}</b>. {t("Learn more about how to manage your subscriptions")}{" "}
              <a
                href="https://support.google.com/googleplay/answer/7018481"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("here")}
              </a>
              .
            </>
          ),
          showSupportContact: true,
        };
      default:
        return {
          summary: (
            <>
              {t("To cancel your subscription please")}{" "}
              <a
                href="mailto:support@coachnow.io"
                target="_blank"
                rel="noopener noreferrer"
                className="modal_link"
              >
                {t("contact support")}
              </a>{" "}
              {t("or email us at")}
              <br />
              <a
                href="mailto:support@coachnow.io"
                target="_blank"
                rel="noopener noreferrer"
                className="modal_link"
              >
                support@coachnow.io
              </a>
              .
            </>
          ),
          showSupportContact: false,
        };
    }
  };

  const { summary, showSupportContact } = infoText();

  const supportEmail = () => {
    return (
      <a
        href="mailto:support@coachnow.io"
        target="_blank"
        rel="noopener noreferrer"
        className="modal_link"
      >
        support@coachnow.io
      </a>
    );
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>{basePlanName} {t("Subscription")}</div>
        <div className={styles.info_wrapper}>
          <p className="modal_info">{summary}</p>
          {showSupportContact && <p className="modal_info">{supportEmail}</p>}
        </div>
        <Button
          title={t("Cancel")}
          buttonType="secondary-outline"
          onClickFunc={onClose}
          buttonStyles={styles.cancel_button}
        />
      </div>
    </ModalLayout>
  );
};

export default SubscriptionCancelModal;
