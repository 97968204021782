/**
 * @module Utils
 *
 */
import { Promise } from "rsvp";
import { buildSchedulingUrl, QueryDef, getQuerySymbol } from "../url";
import createRequest from "../createRequest";
import responseHandler, { ResponseType } from "../responseHandler";
import { getCacheKey } from "app/utils/auth";
import { digest } from "app/utils/runloop";
import config from "config/environment";
import { store } from 'app/Store';

export interface RequestOpts {
  method?: string;
  headers?: Headers;
  body?:
    | Blob
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string;
  mode?: RequestMode;
  credentials?: RequestCredentials;
  cache?: RequestCache;
  redirect?: RequestRedirect;
  referrer?: string | "no-referrer" | "client";
  integrity?: string;
  window?: any;
}

export type RInfo = RequestInfo & OptionsDef;
export type RInit = RequestInit & OptionsDef;

export interface OptionsDef extends RequestOpts {
  version?: number;
  debug_key?: any;
  noAuth?: boolean;
  noCache?: boolean;
}

export function schedulingFetchGet<T = any>(
  type: string,
  query: QueryDef = {},
  opts: OptionsDef = {},
  signal?: AbortSignal, // Add signal parameter
): Promise<T | T[]> {
  const url = buildSchedulingUrl(type, query, opts.version || 2);

  return request(url, "GET", null, opts, signal) as Promise<T | T[]>;
}

export function schedulingFetchPost<T = any>(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<T> {
  const url = buildSchedulingUrl(type, {}, opts.version || 2);
  return request(url, "POST", data, opts) as Promise<T>;
}

export function schedulingFetchPatch<T = any>(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<T> {
  const url = buildSchedulingUrl(type, {}, opts.version || 2);
  return request(url, "PATCH", data, opts) as Promise<T>;
}

export function schedulingFetchDelete(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<null> {
  const url = buildSchedulingUrl(type, {}, opts.version || 2);
  return request(url, "DELETE", data, opts) as Promise<null>;
}

// export function fetchApi(input: RInfo, init?: RInit) {
//   let url = input as string;
//   let options = Object.assign({}, init);

//   if (input && (input as Request).url) {
//     url = (input as Request).url;
//     options = Object.assign({}, options, input, { url: null });
//   }

//   const version = options.version || 2;
//   if (options.version) {
//     delete options.version;
//   }

//   const method = options.method || "GET";
//   if (options.method) {
//     delete options.method;
//   }

//   let data: QueryDef | BodyInit | null = options.body || {};
//   if (options.body) {
//     delete options.body;
//   }

//   if (method === "GET") {
//     url = buildUrl(url, data as QueryDef, version);
//     data = null;
//   } else {
//     url = buildUrl(url, {}, version);
//   }

//   // create request opts
//   const req = createRequest(method, options, data);

//   return new Promise((resolve, reject) => {
//     (window as any).fetch(url, req).then(resolve).catch(reject);
//   });
// }

/**
 * request an api from a url
 *
 * @private
 * @method request
 */
export function request(
  url: string,
  method: string,
  data: QueryDef | null,
  options: OptionsDef,
  signal?: AbortSignal, // Added signal parameter
): Promise<any> {
  data = data || {};

  if (config.INCLUDE_SERVICE_WORKER) {
    if (method === "GET") {
      if (!options.noCache) {
        url += `${getQuerySymbol(url)}_ca=${getCacheKey()}`;
      }
    }
  }

  const jwtToken = store.getState().user.data.jwt;

  // create request opts
  const head = { Authorization: jwtToken };
  const req = createRequest(method, { ...options, headers: head }, data);

  function handleResults(res: ResponseType): any {
    digest();
    if (res.success) {
      return res.data;
    } else {
      return Promise.reject(res);
    }
  }

  // Create an AbortController
  const controller = new AbortController();
  const { signal: controllerSignal } = controller;

  // Use the passed signal or default to controller's signal
  const fetchSignal = signal || controllerSignal;

  // fetch request with signal
  return new Promise((resolve, reject) => {
    fetch(url, { ...req, signal: fetchSignal })
      .catch(schedulingResponseHandler)
      .then(schedulingResponseHandler)
      .then(responseHandler)
      .then(handleResults)
      .then(resolve)
      .catch((xhr) => {
        return xhr.json().then(({ errors }) => {
          return reject({ errors, status: xhr.status });
        });
      });
  });
}

function schedulingResponseHandler(xhr) {
  const  { ok } = xhr;
  if (!ok) {
    return Promise.reject(xhr);
  }

  return xhr;
}
