/**
 * @module Model.LessonRate
 *
 */
import { Model } from "typedefs";
import { LessonTypeResponse, LessonTypeModel } from 'app/models/scheduling/LessonTypeModel';

export interface LessonRateResponse {
  id: number;
  coachProfileId: string;
  defaultLessonTypeId: number;
  defaultRate: boolean;
  duration: string;
  durationInMinutes: number;
  location: string;
  rate: number;
  title: string;
  lessonTypes: LessonTypeResponse[];
}

export class LessonRateModel implements Model {
  id: number;
  coachProfileId: string;
  defaultLessonTypeId: number;
  defaultRate: boolean;
  duration: string;
  durationInMinutes: number;
  location: string;
  rate: number;
  title: string;
  lessonTypes: LessonTypeModel[];

  constructor(lessonRate: LessonRateResponse) {
    this.id = lessonRate.id;
    this.coachProfileId = lessonRate.coachProfileId;
    this.defaultLessonTypeId = lessonRate.defaultLessonTypeId;
    this.defaultRate = lessonRate.defaultRate;
    this.duration = lessonRate.duration;
    this.durationInMinutes = lessonRate.durationInMinutes;
    this.location = lessonRate.location;
    this.rate = lessonRate.rate;
    this.title = lessonRate.title;

    this.lessonTypes = [];

    if (lessonRate.lessonTypes && lessonRate.lessonTypes.length > 0) {
      this.lessonTypes = lessonRate.lessonTypes.map((lessonType: LessonTypeResponse) => {
        return new LessonTypeModel(lessonType);
      });
    }
  }
}
