import React from 'react';
import Select from 'react-select';
import { DateTime } from 'luxon';

import { useFormContext, Controller } from 'react-hook-form';

import FormItem from "app/components/inputs/FormItem";
import DateSelector from "./DateSelector";

import { errorMessageFor } from './helpers';
import { useCalendarUtils } from 'app/routes/Calendar/hooks/useCalendarUtils';

import { SelectStyles } from './constants';
import { REPEAT_OPTIONS } from 'app/models/EventModel';
import styles from './styles.module.scss';

export default function RecurrenceSelector() {
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { getRecurrenceLabel } = useCalendarUtils();

  const watchRepeat = watch('repeat');
  const watchId = watch('id');
  const startDate = DateTime.fromJSDate(watch('date'));

  const onChangeRepeat = (val) => {
    setValue('repeat', val.value);

    if(val) {
      if(startDate.isValid) {
        setValue('untilDate', startDate.plus({ months: 1 }).toJSDate());
      } else {
        setValue('untilDate', DateTime.now().plus({ months: 1 }).toJSDate());
      }
    }
  };

  let maxUntilDate = startDate.plus({ years: 1 });
  if (watchRepeat === 'daily') {
    maxUntilDate = startDate.plus({ months: 1 });
  }

  return (
    <>
      <FormItem className={styles.input_field}>
        <label>Repeat</label>
        <Controller
          control={control}
          name="repeat"
          render={({ field: { value, ref }}) => (
            <Select
              styles={SelectStyles}
              isSearchable={false}
              inputRef={ref}
              options={REPEAT_OPTIONS.map((o) => getRecurrenceLabel(startDate, o))}
              value={getRecurrenceLabel(startDate, REPEAT_OPTIONS.find(c => c.value === value))}
              onChange={onChangeRepeat}
              isDisabled={!!watchId}
            />
          )}
        />
      </FormItem>

      {watchRepeat && (
        <FormItem className={styles.input_field} error={errorMessageFor(errors, 'untilDate')} >
          <label>Until Date</label>
          <DateSelector {...register('untilDate')} control={control} disabled={!!watchId} maxDate={maxUntilDate.toJSDate()} />
        </FormItem>
      )}
    </>
  );
}
