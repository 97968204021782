import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "app/utils/classNames";
import Button from "../Button/ButtonVariant";
import NoSubscriptionImg from "../../../public/images/no-subscription-image.png";
import CloseIcon from "../../../public/images/close-icon.svg";
import { useTranslation } from "react-i18next";
import { UpgradeToPro } from "../paywalls";

interface PostNoSubscriptionModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const PostNoSubscriptionModal = (props: PostNoSubscriptionModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose } = props;
  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const onUpgrade = () => {
    onClose();
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  const onLearnMode = () => {
    window.location.replace("https://coachnow.io/pro");
  };

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <div className={classNames(styles.content_wrapper)}>
          <button className={classNames(styles.close_btn)} onClick={onClose}>
            <img src={CloseIcon} alt="icon" />
          </button>
          <div className={classNames(styles.info_block)}>
            <h2>{t("Post Views - Available for CoachNow PRO")}</h2>
            <p>
              {t(
                "Views Tracking gives you insight into the engagement within your POSTS."
              )}
            </p>
            <p>
              {t(
                "Views Tracking will help you understand WHO has viewed your post and WHEN they viewed it"
              )}
            </p>
            <div className={classNames(styles.info_block_actions)}>
              <Button title={t("Learn more")} onClickFunc={onLearnMode} />
              <Button
                title={t("Upgrade Now")}
                buttonType="primary"
                onClickFunc={onUpgrade}
              />
            </div>
          </div>
          <div className={classNames(styles.no_sub_image)}>
            <img src={NoSubscriptionImg} alt="img" />
          </div>
        </div>
      </ModalLayout>
      <UpgradeToPro show={showPaywallModal} onClose={closePaywall} />
    </>
  );
};

export default PostNoSubscriptionModal;
