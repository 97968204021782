/**
 * @module Components.Filter
 *
 */
import React, { useEffect, useState, useRef } from "react";
import { useStoreActions } from "app/utils/hooks";
import { cls } from "app/utils";
import { fetchLists } from "app/actions/list";
import { ListModel } from "app/models";
import CheckBox from "app/components/inputs/CheckBox/Base";
import SearchBar from "app/components/inputs/SearchBar";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  selectedLists: any;
  onSubmit: (value: any, objectTypes?: any) => void;
};

const initLists: ListModel[] = [];
const OBJECT_TYPES = ["segment"];

/**
 * Renders a select drop down prefilled with lists to select from
 *
 * @name ListsSelect
 * @param {Properties} props
 * @return {Element}
 */
export default function ListsSelect(props: PropsDef) {
  const { t } = useTranslation();
  // fetch lists from api
  const { list } = useStoreActions({ list: () => fetchLists({}) });
  const [lists, setLists] = useState(initLists);
  const [search, setSearch] = useState("");
  const [selectedLists, setSelectedLists] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (list.data != null) {
      setLists(list.data);
    }

    if (props.selectedLists) {
      setSelectedLists(
        props.selectedLists.filter((target: ListModel) =>
          OBJECT_TYPES.includes(target.object),
        ),
      );
    }
  }, [list.data, props.selectedLists]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (search && search.length) {
      const filter = list.data.filter((item: ListModel) => {
        const regex = new RegExp(search, "i");
        if (regex.test(item.name)) {
          return true;
        }
        return false;
      });
      setLists(filter);
    } else {
      setLists(list.data || []);
    }
  }, [search]);

  const updateSearch = (value: string) => {
    value !== null && value.length > 0 ? setSearch(value) : setSearch("");
  };

  const handleClickOutside = (e: any) => {
    if (
      dropdownOpened &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setDropdownOpened(false);
      setSearch("");
    }
  };

  const toggleDropdown = () => {
    setDropdownOpened(!dropdownOpened);

    if (!dropdownOpened) {
      setSearch("");
    }
  };

  const onSubmit = () => {
    toggleDropdown();
    props.onSubmit(selectedLists, OBJECT_TYPES);
  };

  const toggleListClick = (list: ListModel) => {
    if (selectedLists.includes(list)) {
      setSelectedLists((selectedLists) =>
        selectedLists.filter((_list) => _list.id !== list.id),
      );
    } else {
      setSelectedLists([...selectedLists, list]);
    }
  };

  return (
    <div ref={dropdownRef} className={cls(styles.dropdown)}>
      <button
        onClick={toggleDropdown}
        className={cls(
          styles.filterButton,
          dropdownOpened || selectedLists.length > 0 ? styles.active : "",
        )}
      >
        <i className={cls("ico", "ico-groups", styles.icon)}></i>
        <span className={styles.buttonCaption}>
          {t("count_list", { count: selectedLists.length })}
        </span>
        <i className={cls("ico", "ico-chevron", styles.chevron)}></i>
      </button>

      {dropdownOpened && (
        <div className={styles.dropdownBody}>
          <SearchBar
            className={styles.search}
            placeholder={t("Search Lists")}
            onChange={updateSearch}
          />

          <div className={styles.dropdownOptions}>
            {lists?.map((list) => (
              <div key={list.id}>
                <div
                  className={styles.dropdownItem}
                  onClick={() => toggleListClick(list)}
                >
                  <div className={styles.contentCardAvatar}>
                    <div className={styles.contentCardAvatarShape}>
                      <i className="ico ico-groups" />
                    </div>
                  </div>
                  <div className={styles.name}>
                    {list?.name}
                    <div className={styles.sport}>
                      {t("List")}&nbsp;
                      {list?.spaceIds && list?.spaceIds?.length > 0
                        ? t("count_space", { count: list?.spaceIds?.length })
                        : ""}
                    </div>
                  </div>

                  <CheckBox
                    className={styles.checkbox}
                    disabled={true}
                    checked={selectedLists.indexOf(list) > -1}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.buttonsContainer}>
            <button
              onClick={toggleDropdown}
              className={cls("btn", "btn-outline-secondary")}
            >
              {t("Cancel")}
            </button>

            <button onClick={onSubmit} className={cls("btn", "btn-primary")}>
              {t("Filter")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
