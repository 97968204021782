import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import ChangeImportedColumnModal from "../ChangeImportedColumnModal";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onNext?: () => void;
}

const ImportedUsersModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onNext,
}: Props) => {
  const { t } = useTranslation();
  const { membersListToInvite } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );
  const [emails, setEmails] = useState<string[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [postitions, setPositions] = useState<string[]>([]);
  const [jerseyNumbers, setJerseyNumbers] = useState<string[]>([]);

  const [columnChangeModalOpen, setColumnChangeModalOpen] = useState(false);
  const [columnToChange, setColumnToChange] = useState("");

  useEffect(() => {
    if (membersListToInvite && membersListToInvite.length !== 0) {
      const allEmails = membersListToInvite
        .map((member: UserModel) => member.email)
        .filter((email: { email?: string }) => email);
      const allNames = membersListToInvite
        .map((member: UserModel) => member.name)
        .filter((name: { name?: string }) => name);
      const allPositions = membersListToInvite
        .map((member: UserModel) => member.position)
        .filter((position: { position?: string }) => position);
      const allJerseyNumbers = membersListToInvite
        .map((member: UserModel) => member.jerseyNumber)
        .filter((jerseyNumber?: string) => jerseyNumber);

      setEmails(allEmails);
      setNames(allNames);
      setPositions(allPositions);
      setJerseyNumbers(allJerseyNumbers);
    }
  }, [membersListToInvite]);

  const handleColumnChange = (colName: string) => {
    setColumnToChange(colName);
    setColumnChangeModalOpen((prev) => !prev);
  };

  const onColumnChangeConfirm = (value: string, data: string[]) => {
    if (value === "emails") {
      setEmails(data);
    } else if (value === "names") {
      setNames(data);
    } else if (value === "positions") {
      setPositions(data);
    } else if (value === "jerseyNumbers") {
      setJerseyNumbers(data);
    }
    setColumnChangeModalOpen(false);
  };

  if (
    columnChangeModalOpen &&
    membersListToInvite &&
    membersListToInvite.length !== 0
  ) {
    return (
      <ChangeImportedColumnModal
        isOpen={columnChangeModalOpen}
        onBack={handleColumnChange}
        onConfirm={onColumnChangeConfirm}
        changeFor={columnToChange}
      />
    );
  }

  const dataList = [
    {
      value: emails,
      for: "emails",
      label: t("Email (Required)"),
      htmlFor: "email_list",
      onClickFunc: () => {},
      onDelete: () => setEmails([]),
    },
    {
      value: names,
      for: "names",
      label: t("Name"),
      htmlFor: "name_list",
      onClickFunc: () => {},
      onDelete: () => setNames([]),
    },
    {
      value: postitions,
      for: "positions",
      label: t("Position"),
      htmlFor: "position_list",
      onClickFunc: () => {},
      onDelete: () => setPositions([]),
    },
    {
      value: jerseyNumbers,
      for: "jerseyNumbers",
      label: t("Jersey #"),
      htmlFor: "jersey_number_list",
      onClickFunc: () => {},
      onDelete: () => setJerseyNumbers([]),
    },
  ];

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Assign Attributes")}
    >
      <div className={styles.header_text_wrapper}>
        <p>
          <b>{t("Assign Attributes to Columns")}</b>
        </p>
        <p>
          {t("Select columns from your file to be imported as the following")}
        </p>
        <p>
          {t(
            "An invitation will be sent to each email address and its associated row of attributes."
          )}
        </p>
      </div>
      <div className={styles.list_wrapper}>
        <div className={styles.inputs_container}>
          {dataList.map((data, index) => (
            <div key={index}>
              <label htmlFor={data.htmlFor}>{data.label}</label>
              <div className={styles.input_wrapper}>
                <span className={styles.input_list_wrapper}>
                  <input
                    value={
                      data.value && data.value.length !== 0
                        ? data.value.join(", ")
                        : ""
                    }
                    readOnly={data.value && data.value.length !== 0}
                    type="text"
                    onClick={() => handleColumnChange(data.for)}
                    className={styles.input}
                  />
                  <Button
                    buttonType="secondary-outline-danger"
                    icon={<i className="ico ico-x" />}
                    title={""}
                    onClickFunc={data.onDelete}
                    buttonStyles={styles.delete_btn}
                  />
                </span>
                <Button
                  title={t("Change Column")}
                  buttonType="secondary-outline"
                  buttonStyles={styles.change_col_btn}
                  onClickFunc={handleColumnChange}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.cancel_btn}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onClose}
        />
        <Button buttonType="primary" title={t("Next")} onClickFunc={onNext} />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ImportedUsersModal;
