import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import closeIcon from "../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import { putSpaceNotifications, setSelectedSpace } from "app/actions/space";
import { useTranslation } from 'react-i18next';

interface Props {
  isModalOpen: boolean;
  modalHandler: () => void;
}

const SpaceNotificationsModal = ({ isModalOpen, modalHandler }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationActions = useActions({ putSpaceNotifications });
  const { selectedSpace } = useSelector((state: RootState) => state.space, shallowEqual);
  const [settings, setSettings] = useState(
    selectedSpace
      ? selectedSpace.userSettings
      : {
          emailNotificationsEnabled: false,
          pushNotificationsEnabled: false,
        }
  );

  const selectHandler = (key: string, value: boolean) => {
    setSettings({ ...settings, [key]: value });
  };

  const ListData = [
    {
      label: t("Email"),
      description: t("Get an email for every Space notification."),
      value: "emailNotificationsEnabled",
      isActive: (settings && settings.emailNotificationsEnabled) || false,
    },
    {
      label: t("Push Notifications"),
      description: t("Get a push for every Space notification."),
      value: "pushNotificationsEnabled",
      isActive: (settings && settings.pushNotificationsEnabled) || false,
    },
  ];

  const saveHandler = () => {
    notificationActions
      .putSpaceNotifications(selectedSpace.id, settings)
      .then(() => {
        dispatch(
          setSelectedSpace({
            ...selectedSpace,
            userSettings: { ...selectedSpace.userSettings, ...settings },
          })
        );
      });
    modalHandler();
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Your Notifications")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>
        <ul className={cls("stack-list-items")}>
          {ListData.map((setting) => (
            <li
              key={setting.label}
              className={cls("stack-list-item selectable-item")}
            >
              <label
                className="selectable-item-trigger"
                htmlFor={setting.label}
              ></label>
              <div className="selectable-item-control">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    name="spaceType"
                    id={setting.label}
                    checked={setting.isActive}
                    onChange={(event) =>
                      selectHandler(setting.value, event.target.checked)
                    }
                  />
                  <label className="opaque" htmlFor={setting.label}></label>
                </div>
              </div>

              <div className="selectable-item-details">
                <div className="selectable-item-label ng-binding">
                  {setting.label}
                </div>
                <div className="selectable-item-summary ng-binding">
                  {setting.description}
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button buttonType="primary" title={t("Save")} onClickFunc={saveHandler} />
        </div>
      </div>
    </ModalLayout>
  );
};

export default SpaceNotificationsModal;
