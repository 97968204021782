import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  location: string;
}

const Navbar: React.FC<NavbarProps> = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleTabClick = (path: string) => {
    history.push(path);
  };

  return (
    <nav className={styles.navbar}>
      <ul className={styles.navList}>
        <li
          className={`${styles.navItem} ${location === 'pricing' ? styles.active : ''}`}
          onClick={() => handleTabClick("/calendar/pricing")}
        >
          {t("Booking Options")}
        </li>
        <li
          className={`${styles.navItem} ${location === 'availability' ? styles.active : ''}`}
          onClick={() => handleTabClick("/calendar/availability")}
        >
          {t("Availability")}
        </li>
        <li
          className={`${styles.navItem} ${location === 'settings' ? styles.active : ''}`}
          onClick={() => handleTabClick("/calendar/scheduling")}
        >
          {t("Schedule Options")}
        </li>
        <li
          className={`${styles.navItem} ${location === 'calendar' ? styles.active : ''}`}
          onClick={() => handleTabClick("/calendar/settings")}
        >
          {t("Calendar Integrations")}
        </li>
      </ul>
      <div className={styles.horizontalLine} />
    </nav>
  );
};

export default Navbar;
