/**
 * @module Actions.User
 *
 */
import { Promise } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { request, fetchGet } from "app/utils/request";
import { ErrorResponse } from "app/utils/request/errorHandler";
import { UserModel } from "app/models";
import config from "config/environment";
import { UserState } from "app/reducers/user";

export const asyncUserGet = (id?: string): Promise<UserModel> => {
  const query = id != null ? `/${id}` : "";
  return fetchGet<UserModel>(`account${query}`, {}, { version: 1 }).then(
    (user: UserModel) => user,
  );
};

const pending = () => action("@user.fetch.pending");
const error = (err: string) => action("@user.fetch.error", err);
const success = (user: UserModel) => action("@user.fetch.success", user);
const resetPasswordSuccess = () => action("@user.put.reset-password.success");

const thisActions = { pending, error, success, resetPasswordSuccess };

export type UserAction = ActionType<typeof thisActions>;

export function fetchSelf() {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(pending());

    const promise = asyncUserGet();
    promise.then((user) => dispatch(success(user)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function resetPasswordWithToken(
  resetPasswordToken: string,
  password: string,
  passwordConfirmation: string,
) {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + "/password";
    const user = { resetPasswordToken, password, passwordConfirmation };
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.then(() => dispatch(resetPasswordSuccess()));
    promise.catch((e: ErrorResponse) => dispatch(error(e.errors[0])));

    return promise;
  };
}

export function editProfileSettings(user: { [key: string]: any }) {
  return (dispatch: Dispatch<UserAction>) => {
    const url = config.API_SERVER + `/api/v1/account`;
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function editPhone({
  verification_token,
  phoneInfo,
}: {
  [key: string]: any;
}) {
  return (dispatch: Dispatch<UserAction>, getState: () => UserState) => {
    // @ts-ignore
    const state = getState().user;
    const url = config.API_SERVER + `/api/v1/account/phone`;
    const promise = request(url, "PUT", { verification_token }, { version: 1 });
    dispatch(success({ ...state?.data, phoneVerified: true, ...phoneInfo }));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function changePassword(
  currentPassword: string,
  password: string,
  passwordConfirmation: string,
) {
  return (dispatch: Dispatch<UserAction>) => {
    const user = { currentPassword, password, passwordConfirmation };
    const url = config.API_SERVER + `/api/v1/account/password`;
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const actions = {
  fetchSelf,
  resetPasswordWithToken,
  changePassword,
  editProfileSettings,
};
