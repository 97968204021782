import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PostModel from "app/models/PostModel";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import TagModel from "app/models/TagModel";
import TagsSelect from "app/components/inputs/SelectBox/TagsSelect";
import { useTranslation } from "react-i18next";
import ScheduleBtn from "app/components/Button/ScheduleBtn";
import ScheduledAtPicker from "app/components/ScheduledPosts/ScheduledAtPicker";
import { toInteger } from "lodash";
import { useLocation } from "react-router-dom";

interface EditPostProps {
  classNames: string;
  post: PostModel;
  onUpdate: (post: PostModel) => void;
  onCancel: () => void;
  editTags?: boolean;
  hideSelectTags?: boolean;
}

const EditPost = ({
  classNames,
  post,
  onUpdate,
  onCancel,
  editTags,
  hideSelectTags,
}: EditPostProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isTemplatePage] = useState(
    location.pathname.includes("space_templates"),
  );
  const [editedPost, setEditedPost] = useState<PostModel | null>(
    (post ??= null),
  );

  const [scheduledTime, setScheduledTime] = useState<string>("");
  const [scheduledValue, setScheduledValue] = useState<number>(
    editedPost?.scheduledIn ?? 0,
  );

  useEffect(() => {
    if (editedPost?.scheduledIn) {
      const value = editedPost.scheduledIn;
      let timeUnit = "";

      if (value < 24) {
        timeUnit = "Hour";
        setScheduledValue(value);
      } else if (value >= 24 && value < 168) {
        timeUnit = "Day";
        setScheduledValue(value / 24);
      } else if (value >= 168) {
        timeUnit = "Week";
        setScheduledValue(value / (7 * 24));
      }

      setScheduledTime(timeUnit);
    }
  }, [post]);

  const onTagsChange = useCallback((tags: TagModel[]) => {
    setEditedPost((prev) => ({
      ...prev,
      tags: tags.map((t) => t.name),
    }));
  }, []);

  const onDetailsChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setEditedPost((prevData) => ({
        ...prevData,
        details: value,
      }));
    },
    [],
  );

  const onScheduleChange = useCallback(
    (pickedAt: number, pickedTime?: "Days" | "Hours" | "Weeks") => {
      if (pickedAt) {
        if (isTemplatePage) {
          let calculatedTime = toInteger(pickedAt);
          if (pickedTime === "Days") {
            calculatedTime = toInteger(pickedAt) * 24;
          } else if (pickedTime === "Weeks") {
            calculatedTime = toInteger(pickedAt) * 7 * 24;
          } else {
            calculatedTime = toInteger(pickedAt);
          }
          setScheduledValue(pickedAt);
          setScheduledTime(pickedTime.slice(0, -1));
          setEditedPost((prevData) => ({
            ...prevData,
            scheduled_in: calculatedTime,
          }));
        } else {
          setEditedPost((prevData) => ({
            ...prevData,
            scheduled_at: new Date(pickedAt).toISOString(),
          }));
        }
      }
    },
    [],
  );

  const handleUpdate = useCallback(() => {
    if (editedPost) {
      onUpdate(editedPost);
      onCancel();
    }
  }, [editedPost, onUpdate, onCancel]);

  return (
    <div className={cls(styles.container, classNames)}>
      {!editTags && (
        <textarea
          placeholder={t("Say something...")}
          value={editedPost?.details}
          onChange={onDetailsChange}
          rows={4}
        />
      )}
      {!hideSelectTags && (
        <TagsSelect
          isCreatable
          isClearable
          isMulti
          onChange={onTagsChange}
          value={editedPost?.tags}
          tagValContainer={styles.tag_container}
        />
      )}
      <div
        className={cls({
          [styles.justify_between]: isTemplatePage || editedPost?.scheduledAt,
        })}
      >
        {isTemplatePage && (
          <ScheduleBtn
            onScheduleChange={onScheduleChange}
            scheduledAt={scheduledValue}
            scheduledTime={scheduledTime}
          />
        )}
        {new Date(editedPost?.scheduledAt) > new Date() && (
          <ScheduledAtPicker
            value={editedPost?.scheduledAt}
            onChange={onScheduleChange}
          />
        )}
        <div className={styles.buttons}>
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            onClickFunc={onCancel}
          />
          <Button
            title={t("Update")}
            buttonType="primary"
            onClickFunc={handleUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPost;
