/** @module components/paywalls */
import React from "react";
import { cls } from "app/utils";
import ModalWrapper from "app/components/modals/ModalWrapper";
import Support from "app/components/paywalls/Support";
import spacelimit_icon_png from "../../../../public/images/paywall-body-icon-pro.png";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  show?: boolean;
  onClose?: () => void;
};

export default function SpacesLimit(props: PropsDef) {
  const { t } = useTranslation();

  const handleClick = () => {
    (window as any).location = "/upgrade?return_url=/spaces";
  };

  const handleTrialClicked = () => {
    (window as any).location = "/spaces?trialStarted=true";
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ModalWrapper
      isOpen={props.show}
      onRequestClose={handleClose}
      title={t("Upgrade to CoachNow PRO")}
    >
      <div className={cls(styles.spacesLimitModal)}>
        <div className={styles.upper}>
          <div className={styles.image}>
            <img src={spacelimit_icon_png} alt="Space Limit Icon" />
          </div>

          <p className={styles.description}>
            {t("Upgrade to ")}
            <a href="https://coachnow.io/pro" className={styles.highlight}>
              CoachNow PRO
            </a>
            {t(
              " to access all new features, including Post Scheduling, Advanced Group Controls, and access to the ConnectedCoach Blueprint."
            )}
          </p>

          {/* <p className="text-muted">
            <Trans
              i18nKey="upgrade_to_coachnow_plus"
              components={{ a: <a href="https://coachnow.io/pricing" target="_blank" /> }}
             />
          </p> */}
        </div>

        <div className={styles.lower}>
          <div className={styles.section}>
            <h3>{t("Why Upgrade?")}</h3>

            <p className={styles.description}>
              {t(
                "Transform your coaching with unlimited Spaces, media posting, Library storage, Lists, and Templates."
              )}
            </p>

            <button className="btn btn-primary" onClick={handleClick}>
              {t("Upgrade Now")}
            </button>
          </div>

          <div className={styles.section}>
            <h3 className={styles.highlight_green}>{t("Try It Out!")}</h3>

            <p className={styles.description}>
              {t(
                "Check out all the features CoachNow+ has to offer, with a free 7-day trial."
              )}
            </p>

            <button className="btn btn-success" onClick={handleTrialClicked}>
              {t("Start Trial")}
            </button>
          </div>

          <div className={styles.section}>
            <Support className={styles.lower} feature={t("Space Limit")} />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
