/** @module Main */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ProvideWrapper from "app/components/ProvideWrapper";
import "./i18n";
import "./styles/main.scss";
import App from "./App";
import { Loading } from "./components/Wrappers";

ReactDOM.render(
  (
    <Suspense fallback={<Loading isLoading loadType="spinner" />}>
      <ProvideWrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ProvideWrapper>
    </Suspense>
  ),

  document.getElementById("root")
);
