import React from "react";
import styles from "./styles.module.scss";
import pinIcon from "../../../../../public/images/ico-pin.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface PinnedPostBadgeProps {
  pinnedByName: string;
  pinnedTill?: string;
}

const PinedPostBadge = ({ pinnedByName, pinnedTill }: PinnedPostBadgeProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.pinned_post_badge}>
        <img src={pinIcon} alt="pin" className={styles.pin_icon} />
        <span className={styles.pinned_by}>
          {pinnedByName && t("Pinned by {{name}}", { name: pinnedByName })}
          {pinnedTill &&
            t("till {{date}}", {
              date: moment(pinnedTill).format("MMM DD"),
            })}
          {/* check pinnedTill year and if its more than one year than need to display it too */}{" "}
          {pinnedTill &&
            moment(pinnedTill).year() !== moment().year() &&
            moment(pinnedTill).format("YYYY")}
        </span>
      </div>
    </div>
  );
};

export default PinedPostBadge;
