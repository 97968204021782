import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  description?: string;
}

const NoScheduledPosts: React.FC<Props> = ({
  title,
  description,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <i className={cls("ico ico-time", styles.time_icon)} />
      <h3>{title ?? t("No scheduled posts yet")}</h3>
      <p>{description ?? t("Automate your business and keep your athletes engaged with scheduled posts. On this page you can create, manage, and schedule posts to publish automatically in any Space or Group.")}</p>
    </div>
  );
};

export default NoScheduledPosts;
