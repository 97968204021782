/**
 * @module Components.PhoneVerification
 *
 */
import React, { useState, useEffect } from 'react';
import { PhoneVerificationService } from 'app/services';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = {
  phone?: string;
  phoneCountry?: string;
};

/**
 * @class ResendButton
 *
 */
const ResendButton = (props: PropsDef) => {
  const { t } = useTranslation();
  const { phone, phoneCountry } = props;
  const [counter, setCounter] = useState(60);
  const [error, setError] = useState(null);


  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onResendClick = (event: any) => {
    event.preventDefault();
    setError(null);

    (PhoneVerificationService as any).verify(phone, phoneCountry)
      .then(() => {
        setCounter(60);
      })
      .catch((err: 'phone_verification_unprocessable_entity' | 'phone_verification_unknown') => {
        setCounter(60);
        setError(err);
      });
  };

  return (
    <div className={styles.resendCodeButton}>
      {t("Didn't get the code?")}&nbsp;
      {counter === 0 ? <a onClick={onResendClick}>{t("Resend")}</a> : <span>{t("Retry in {{counter}} seconds.", { counter })}</span>}
      <br/>
      {error !== null ? <span className={styles.error}>{t("Something went wrong.")}</span> : ''}
    </div>
  );
};

export default ResendButton;
