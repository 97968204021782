/**
 * main react app loader
 *
 */
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { useLocation } from "react-router-dom";
import styles from "./styles/app.module.scss";
import Router from "./Router";
import { AuthService } from "app/services";
import { useStoreActions } from "./utils/hooks";
import { fetchSelf } from "./actions/user";
import { usePostViewsTracking } from "./utils/hooks/usePostViewsTracking";

/***/
const Auth: any = AuthService;
const POST_VIEWS_POLL_INTERVAL = 5000; // 5 seconds


/**
 * Application Class
 */
function App() {
  const location = useLocation();
  const { sendAndClearPostViews } = usePostViewsTracking();

  // This identifies user for analytics.
  useEffect(() => {
    if (location.pathname !== "/signin") {
      Auth.loadAuthUser();
    }
  }, [Auth.isAuthenticated]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      sendAndClearPostViews();
    }, POST_VIEWS_POLL_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useStoreActions({ user: fetchSelf });

  return (
    <div className={styles.app}>
      <Router />
    </div>
  );
}

export default hot(App);
