/**
 * @module Routes
 *
 */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { AnalyticsService } from 'app/services';
import { ProRedirect } from 'app/components/Wrappers';
import { Loc } from 'app/components/helpers';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';
import coachnow_plus_img from 'app/images/coachnow-plus/image.png';
import expert_voice_img from 'app/images/expert-voice/logo-2x.png';
import golf_shot_img from 'app/images/golf-shot/course-2x.png';
import { useTranslation } from 'react-i18next';

const EXPERT_VOICE_LINK =  'https://www.expertvoice.com/coachnow/?utm_source=coachnow.com&utm_medium=referral&utm_campaign=landing_page';
const GOLF_SHOT_LINK = 'https://play.golfshot.com/buy?coupon=Coachnow10';

/**
 * @class Benefits
 *
 */
export default function Benefits() {
  const { t } = useTranslation();
  const [ homeLink, setHome ] = useState(false);
  if (homeLink) {
    return <Redirect to="/" />;
  }

  const trackEvent = (name: string) => {
    AnalyticsService.track(`Benefit Accessed`, { "Benefit Name": name });
  };

  const Locales = defineMessages({
    sublogo: { id: 'benefits.header.sub-logo', defaultMessage: t('Exclusive Benefits and Perks for being a Member') },
    ev_title: { id: 'benefits.ev.title', defaultMessage: t('Take Advantage of Brand Discounts') },
    ev_subtitle: { id: 'benefits.ev.subtitle', defaultMessage: t('Enjoy exclusive deals through ExpertVoice') },
    ev_button: { id: 'benefits.ev.button', defaultMessage: t('JOIN NOW') },
    ev_message: {
      id: 'benefits.ev.message',
      defaultMessage: t('Take advantage of your CoachNow+ membership with exclusive access to brand content and discounts. With ExpertVoice, you can grab the gear you need from the brands you love at exclusive expert prices.')
    },

    gs_title: { id: 'benefits.gs.title', defaultMessage: t('Get the #1 Golf GPS App Golfshot Pro') },
    gs_subtitle: { id: 'benefits.gs.subtitle', defaultMessage: t('Enjoy golf like never before') },
    gs_button: { id: 'benefits.gs.button', defaultMessage: t("GET IT NOW") },
    gs_message: {
      id: 'benefits.gs.message',
      defaultMessage: t('Gain access to real-time distances to the green and all hazards, scoring, statistics, and Golfscape AR - a 360-degree augmented reality view of the entire course. CoachNow members save $10 off a one-year subscription of Golfshot Pro. You also get all of the best tech integrations with Apple Watch, Android Wear watches, and Bose Frames.')
    }
  });

  return (
    <ProRedirect trialNotAllowed={true}>
      <div className={Styles.benefits}>
        <div className={Styles.header}>
          <div className={Styles.logo} onClick={() => setHome(true)}>
            <img src={coachnow_plus_img} alt="CoachNow+" />
          </div>
          <div className={Styles.subLogo}>
            <Loc value={Locales.sublogo} />
          </div>
        </div>

        <div className={Styles.container}>
          <div className={Styles.boxItem}>
            <div className={cls(Styles.imageBox, Styles.expertVoice)} style={{ backgroundImage: `url(${expert_voice_img})` }} />
            <div className={Styles.contentBox}>
              <div className={Styles.contentBody}>
                <h1><Loc value={Locales.ev_title} /></h1>
                <h3><Loc value={Locales.ev_subtitle} /></h3>
                <p><Loc value={Locales.ev_message} /></p>
              </div>
              <div className={Styles.buttonBar}>
                <a className="btn btn-primary" href={EXPERT_VOICE_LINK} target="_blank" onClick={() => trackEvent('ExpertVoice')} rel="noreferrer noopener">
                  <Loc value={Locales.ev_button} />
                </a>
              </div>
            </div>
          </div>

          <div className={Styles.boxItem}>
            <div className={cls(Styles.imageBox, Styles.golfShot)} style={{ backgroundImage: `url(${golf_shot_img})` }} />
            <div className={Styles.contentBox}>
              <div className={Styles.contentBody}>
                <h1><Loc value={Locales.gs_title} /></h1>
                <h3><Loc value={Locales.gs_subtitle} /></h3>
                <p><Loc value={Locales.gs_message} /></p>
              </div>
              <div className={Styles.buttonBar}>
                <a className="btn btn-primary" href={GOLF_SHOT_LINK} target="_blank" onClick={() => trackEvent('Golfshot')} rel="noreferrer noopener">
                  <Loc value={Locales.gs_button} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProRedirect>
  );
}
