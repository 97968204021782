/**
 * @module Inputs.SelectBox.Helpers
 *
 */
import React, { useRef, useState, useEffect } from 'react';
import { uuid } from 'app/utils/uuid';
import { useError } from 'app/utils/hooks';
import { UserModel } from 'app/models';
import { isValidEmail } from 'app/utils/validate';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface CreateNewFormProps {
  inputValue?: string;
  getLabel: () => string;
  onDone: (user: UserModel) => void;
  onCancel: () => void;
}

interface CreateNewErrorState {
  nameError?: string;
  emailError?: string;
}

const initError: CreateNewErrorState = {
  nameError: null,
  emailError: null,
};

interface State {
  name: string;
  email: string;
  focusElement: React.MutableRefObject<any>;
}

const initState: State = {
  name: '',
  email: '',
  focusElement: null,
};

export default function CreateNewForm(props: CreateNewFormProps) {
  const { t } = useTranslation();
  const [ errors, addErrors, clearErrors ] = useError(initError);
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const [ state ] = useState(() => {
    let name = "";
    let email = "";
    let focusElement = nameRef;
    if (props.inputValue != null) {
      if (props.inputValue.indexOf('@') !== -1) {
        email = props.inputValue;
        if (!/^.+@.+\..+$/.test(props.inputValue)) {
          focusElement = emailRef;
        }
      } else {
        name = props.inputValue;
        if (/^.+\s...+$/.test(props.inputValue)) {
          focusElement = emailRef;
        }
      }
    }
    return { ...initState, name, email, focusElement };
  });

  const addUser = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event != null) { event.preventDefault(); }
    clearErrors();

    const email = emailRef.current.value;
    const name = nameRef.current.value;

    const err: CreateNewErrorState = {};
    if (!(email && email.length)) {
      err.emailError = t("Email is required");
    } else if (!isValidEmail(email)) {
      err.emailError = t("A valid email is required");
    }

    if (!(name && name.length)) {
      err.nameError = t("Name is required");
    }

    if (Object.keys(err).length) {
      addErrors(err);
      return;
    }

    const user: Partial<UserModel> = {
      id: uuid(),
      email, name,
      displayName: name,
    };

    if (props.onDone != null) {
      props.onDone(user as UserModel);
    }
  };

  const keyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      event.preventDefault();
      addUser();
    } else if (event.keyCode === 27) {
      props.onCancel();
    }
    return false;
  };

  // focus nameRef on first render
  useEffect(() => {
    if (state.focusElement != null && state.focusElement.current != null) {
      state.focusElement.current.focus();
    }
  }, [state.focusElement]);

  return (
    <div className={Styles.createNewForm}>
      <h3>{props.getLabel()}</h3>

      <div className={Styles.form}>
        <div className={cls(Styles.formItem, errors.nameError ? Styles.invalid : '')}>
          <input
            ref={nameRef}
            type="text"
            autoComplete="none"
            spellCheck={false}
            onKeyDown={keyHandler}
            placeholder={t("Name")}
            defaultValue={state.name}
          />
          <div className={Styles.error}>
            {errors.nameError}
          </div>
        </div>

        <div className={cls(Styles.formItem, errors.emailError ? Styles.invalid : '')}>
          <input
            ref={emailRef}
            type="text"
            autoComplete="none"
            spellCheck={false}
            onKeyDown={keyHandler}
            placeholder={t("Email")}
            defaultValue={state.email}
          />
          <div className={Styles.error}>
            {errors.emailError}
          </div>
        </div>
      </div>

      <div className={Styles.buttons}>
        <button className="btn btn-primary" onClick={addUser}>
          {t('Add')}
        </button>
        <button className="btn btn-outline-secondary" onClick={props.onCancel}>
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
}
