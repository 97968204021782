import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import SpaceModel from "app/models/SpaceModel";
import { useActions } from "app/utils/hooks";
import {
  fetchSegmentById,
  editSegment,
  clearSegments,
} from "app/actions/segments";
import { fetchSpaces, clearSpace } from "app/actions/space";
import Button from "app/components/Button/ButtonVariant";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { useHistory, Link } from "react-router-dom";

const ManageSpaces = () => {
  const { t } = useTranslation();
  const resourceId = window.location.pathname.split("/")[2];

  const segmentActions = useActions({
    fetchSpaces,
    clearSpace,
    fetchSegmentById,
    clearSegments,
    editSegment,
  });

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const options = {
    include: ["segment_names"],
  };

  useEffect(() => {
    if (resourceId) {
      segmentActions.fetchSegmentById(resourceId);
      segmentActions.fetchSpaces({ options, type: "athlete" });
    }

    return () => {
      segmentActions.clearSegments();
      segmentActions.clearSpace();
    };
  }, [resourceId]);

  const { segmentsData, segmentsPending } = useSelector(
    (state: RootState) => state.segments,
    shallowEqual,
  );
  const spaceData = useSelector(
    (state: RootState) => state.space.data,
    shallowEqual,
  );

  const [selectedSpaceIds, setSelectedSpaceIds] = useState<string[]>(null);

  useEffect(() => {
    if (segmentsData) {
      setSelectedSpaceIds((segmentsData && segmentsData.spaceIds) || []);
    }
  }, [segmentsData]);

  const cancelUrl = resourceId ? `/lists/${resourceId}/spaces` : "/lists/";

  const updateHandler = () => {
    segmentActions
      .editSegment(resourceId, {
        ...segmentsData,
        spaceIds: selectedSpaceIds,
      })
      .then(() => route(cancelUrl));
  };

  const checkHandler = (spaceId: string) => {
    if (selectedSpaceIds && selectedSpaceIds.includes(spaceId)) {
      setSelectedSpaceIds((prevSelectedSpaceIds) =>
        prevSelectedSpaceIds.filter((id) => id !== spaceId),
      );
    } else {
      setSelectedSpaceIds((prevSelectedSpaceIds) => [
        ...prevSelectedSpaceIds,
        spaceId,
      ]);
    }
  };

  const selectAllHandler = () => {
    const allSpaceIds = spaceData.map((space: SpaceModel) => space.id);
    setSelectedSpaceIds(allSpaceIds);
  };

  const onCancel = () => {
    return history.push(cancelUrl);
  };

  return (
    <SegmentsLayout
      isLoading={segmentsPending}
      loaderChild={<h3 className="well_nothing">{t("Loading...")}</h3>}
      displayNoResult={!segmentsData}
      noResultChild={<h3>{t("No Spaces to select")}</h3>}
    >
      <div className={styles.container}>
        <PagePanel
          title={t("Select {{name}}'s Spaces", {
            name: segmentsData && segmentsData.name,
          })}
        >
          <ol className={styles.breadcrumb_wrapper}>
            <li>
              <Link to="/lists">{t("List")}</Link>
            </li>{" "}
            /{" "}
            <li>
              <Link to={cancelUrl}>{segmentsData && segmentsData.name}</Link>
            </li>{" "}
            / <li>{t("Spaces")}</li>
          </ol>
        </PagePanel>

        <div className={styles.table_wrapper}>
          <table className="segment_spaces table">
            <tbody>
              <tr>
                <th className="segment-title">
                  <span>{t("Space")}</span>
                </th>

                <th className="segment-title">{t("Already in other lists")}</th>
              </tr>
              {spaceData &&
                spaceData.map((space: SpaceModel) => {
                  return (
                    <tr
                      key={space.id}
                      ng-repeat="space in spaces | orderBy:'athleteName'"
                    >
                      <td>
                        <label className="checkbox-list">
                          <input
                            name={`space_${space.id}`}
                            onChange={() => checkHandler(space.id)}
                            checked={
                              selectedSpaceIds &&
                              selectedSpaceIds.includes(space.id)
                            }
                            type="checkbox"
                          />
                          {space.athleteName} &nbsp; {space.sport}
                        </label>
                      </td>

                      <td className="other-segments-label">
                        {space.segmentNames &&
                          space.segmentNames
                            .filter(
                              (segmentName: string[]) =>
                                segmentName !== segmentsData &&
                                segmentsData.name,
                            )
                            .join(", ")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={styles.buttons_wrapper}>
            <Button
              title={t("Select All")}
              buttonType="primary"
              onClickFunc={selectAllHandler}
            />
            <Button
              title={t("Update")}
              buttonType="primary"
              onClickFunc={updateHandler}
            />
            <Button
              title={t("Cancel")}
              buttonType="secondary-outline"
              onClickFunc={onCancel}
            />
          </div>
        </div>
      </div>
    </SegmentsLayout>
  );
};

export default ManageSpaces;
