import { destinationsActions } from "app/actions/destinations";
import { RequestError } from "typedefs";

export type destinationState = {
  data: [] | null;
  selectedDestinations: [] | null;
  allowMultipleSelect: boolean;
  error: RequestError | null;
  pending: boolean;
};

const initialState: destinationState = {
  data: null,
  selectedDestinations: null,
  allowMultipleSelect: true,
  error: null,
  pending: true,
};

export default function destinationsReducer(
  state = initialState,
  action: destinationsActions
) {
  switch (action.type) {
    case "@destinations.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@destinations.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@destinations.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@destinations.fetch.selectedDestinations":
      return { ...state, selectedDestinations: action.payload, pending: false };
    case "@destinations.fetch.allowMultipleSelect":
      return { ...state, allowMultipleSelect: action.payload, pending: false };
    case "@destinations.clear":
      return {
        ...state,
        // @ts-ignore
        data: null,
        // @ts-ignore
        selectedDestinations: null,
        allowMultipleSelect: true,
        pending: false,
        loadMore: true,
      };
    default:
      return state;
  }
}
