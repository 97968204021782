const customPrompt = ({
  message,
  onYes,
  onCancel,
}: {
  message: string;
  onYes: () => void;
  onCancel: () => void;
}) => {
  const response = window.confirm(message);
  if (response) {
    onYes();
  } else {
    onCancel();
  }
};

export default customPrompt;
