import { useState } from "react";
import PostModel from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { editPost } from "app/actions/postingForm";

interface Props {
  reply: PostModel;
}

export const useReplyModel = ({ reply }: Props) => {
  const replyActions = useActions({ editPost });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingReply, setIsEditingReply] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  // image/videos preview modal
  const modalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };
  const onEditClick = () => {
    setIsEditingReply(true);
  };

  const onEditClose = () => {
    setIsEditingReply(false);
  };

  const onReplyUpdate = (editedReply: PostModel) => {
    if (isEditingReply) {
      reply.details = editedReply.details;
      replyActions.editPost(editedReply).catch((error) => {
        setUpdateError(error);
      });
      onEditClose();
    }
  };

  return {
    modalHandler,
    isModalOpen,
    onEditClick,
    onEditClose,
    isEditingReply,
    onReplyUpdate,
    updateError,
  };
};
