import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { Settings } from 'luxon';

let tFunction: TFunction;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    supportedLngs: ["en", "ja"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  })
  .then(() => {
    // Set Luxon locale
    Settings.defaultLocale = i18n.language;

    i18n.on('languageChanged', lng => {
      // Set Luxon locale on lang change
      Settings.defaultLocale = lng;
    });

    tFunction = i18n.t.bind(i18n);
  })
  .catch((err) => console.error("i18n initialization failed", err));

export const t = (key: string) => {
  if (!tFunction) {
    throw new Error("Translation function not initialized");
  }
  return tFunction(key);
};

export default i18n;
