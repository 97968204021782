import React from "react";
import { useDispatch } from "react-redux";

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";

import { createEvent, editEvent } from "app/actions/events";
import { useTranslation } from "react-i18next";

type PropsDef = {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  status: "success" | "error" | "conflict";
  onDismiss?: () => void;
  onConfirm?: () => void;
  payload?: any;
  action?: "create" | "edit";
};

export default function InfoModal(props: PropsDef) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isOpen, title, children, status, onDismiss, payload, action } = props;

  const icon =
    status === "success"
      ? `ico ico-check ${styles.success}`
      : `ico ico-x ${styles.error}`;

  const onConfirmConflict = () => {
    const actionFn = action === "create" ? createEvent : editEvent;

    onDismiss();
    dispatch(actionFn({ ...payload, ignore_conflict: true }));
  };

  const closeButton = (
    <div className={styles.close_button} onClick={onDismiss}>
      <i className="ico ico-x" />
    </div>
  );

  return (
    <ModalLayout
      isOpen={isOpen}
      classNames={`${styles.modal} info-modal-container`}
    >
      <div className={`${styles.modal_header} info-modal-header`}>
        <div className={styles.w_100}>
          {status === "conflict" && closeButton}
          <i className={`${icon} ${styles.modal_status_icon}`} />
        </div>
        <h2>{title}</h2>
      </div>
      <div className={`${styles.modal_content} info-modal-content`}>
        {children}
      </div>

      {status !== "conflict" && (
        <button
          onClick={onDismiss}
          className={`${styles.confirm_button} btn btn-primary`}
        >
          {t("Ok")}
        </button>
      )}

      {status === "conflict" && (
        <div className={styles.confirm_conflict_actions}>
          <button
            onClick={onConfirmConflict}
            className={`${styles.confirm_button} btn btn-primary`}
          >
            {t("Confirm")}
          </button>
          <button
            onClick={onDismiss}
            className={`${styles.cancel_button} btn confirm_cancel_conflict`}
          >
            {t("Cancel")}
          </button>
        </div>
      )}
    </ModalLayout>
  );
}
