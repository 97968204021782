/**
 * @module Components
 *
 */
import React, { useState, FormEvent, useCallback, useMemo } from "react";
import { useUrlSearch, usePending, useError } from "app/utils/hooks";
import { MessageDescriptor } from "react-intl";
import { useParams, useLocation } from "react-router-dom";
import { AuthService } from "app/services";
import { UserModel } from "app/models";
import { cls } from "app/utils";
import { PreviewFile } from "app/utils/file";
import FormItem from "app/components/inputs/FormItem";
import PhoneInput from "react-phone-input-2";
import UserAvatarUploader from "app/components/UserAvatarUploader";
import { AgeConsent, TermsOfService } from "app/components/inputs/CheckBox";
import { validateUser } from "./validate";
import styles from "./styles.module.scss";
import { Trans, useTranslation } from "react-i18next";

type PropsDef = {
  user: Partial<UserModel>;
  verificationToken: string;
  phoneCountry: string;
  phoneNormalized: string;
  onUserChange: (user: Partial<UserModel>) => void;
  onConsent: (user: Partial<UserModel>) => void;
  onSignin: (user: Partial<UserModel>) => void;
  onDone: (data: { user: UserModel; provider?: string }) => void;
  consentToken?: string;
  lockEmail?: boolean;
  hideOutsideSources?: boolean;
  coachSignup?: boolean;
};

type FormErrors = {
  firstName?: string | MessageDescriptor;
  lastName?: string | MessageDescriptor;
  email?: string | MessageDescriptor;
  phone?: string | MessageDescriptor;
  password?: string | MessageDescriptor;
  ageConsent?: string | MessageDescriptor | null;
  tosConsent?: string | MessageDescriptor | null;
  avatar?: string | MessageDescriptor | null;
  facebook?: string | MessageDescriptor | null;
  unknown?: string | MessageDescriptor | null;
};

const initErrors: FormErrors = {};
const initMatch: boolean = false;

// type ProviderType = {
//   data: UserModel,
//   provider: string
// };

type UtmObjectType = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
};

const SIGNUP_SOURCE = "self_sign_up";

export default function SignUpForm(props: PropsDef) {
  const { t } = useTranslation();
  const [isMatch, setMatch] = useState(initMatch);
  const [pending, startPending, stopPending] = usePending();
  const [errorsForm, addErrors, clearErrors] = useError(initErrors);
  // @ts-ignore
  const { team_token, invitation_token } = useParams();
  const [searchParams] = useUrlSearch();
  const location = useLocation();
  const isPhoneInvite = props.phoneNormalized !== null;

  let signinPath: string = "/signin";
  if (team_token != null || invitation_token != null) {
    // invitaions that have accounts will click signin
    // after a successfull signin the redirect should come back to signup
    // this will allow signup to redirect to the proper team or space after signin
    signinPath = `${signinPath}?return_url=${encodeURIComponent(
      location.pathname + location.search,
    )}`;
  } else if (location.search != null && location.search.length) {
    signinPath = `/signin${location.search}`;
  }

  const updateUserState = (userState: Partial<UserModel>) => {
    const user = { ...props.user, ...userState };
    props.onUserChange(user);
  };

  const addUTMParams = () => {
    const utmParams: UtmObjectType = {};
    if (searchParams.utm_source) {
      utmParams.utm_source = searchParams.utm_source;
    }
    if (searchParams.utm_medium) {
      utmParams.utm_medium = searchParams.utm_medium;
    }
    if (searchParams.utm_campaign) {
      utmParams.utm_campaign = searchParams.utm_campaign;
    }
    if (searchParams.utm_content) {
      utmParams.utm_content = searchParams.utm_content;
    }
    if (searchParams.utm_term) {
      utmParams.utm_term = searchParams.utm_term;
    }
    return utmParams;
  };

  const response_errors: ErrorObjMap = useMemo(
    () => ({
      signup: {
        password_length: {
          id: "password",
          message: {
            id: "error.invalid.password",
            defaultMessage: t(
              "Password must between 6 and 128 characters long.",
            ),
          },
        },
        email_unavailable: {
          id: "email",
          message: {
            id: "error.unavailable.email",
            defaultMessage: t(
              "This email is already in use, please sign in or reset password.",
            ),
          },
        },
        duplicate_email: {
          id: "email",
          message: {
            id: "error.unavailable.email",
            defaultMessage: t(
              "This email is already in use, please sign in or reset password.",
            ),
          },
        },
        email_already_taken: {
          id: "email",
          message: {
            id: "error.unavailable.email",
            defaultMessage: t(
              "This email is already in use, please sign in or reset password.",
            ),
          },
        },
        email_invalid: {
          id: "email",
          message: {
            id: "error.invalid.email",
            defaultMessage: t("The email address entered is not valid."),
          },
        },
        phone_already_taken: {
          id: "phone",
          message: {
            id: "error.unavailable.phone",
            defaultMessage: t("This phone is already in use, please sign in."),
          },
        },
        unknown: {
          id: "unknown",
          message: {
            id: "error.signup.unknown",
            defaultMessage: t("The account failed to create."),
          },
        },
        no_connection: {
          id: "unknown",
          message: {
            id: "error.signup.no_connection",
            defaultMessage: t(
              "The account failed to create. No internet connection.",
            ),
          },
        },
      },
      facebook: {
        facebook_email_exists: {
          id: "error.signup.facebook.email_exists",
          defaultMessage: t(
            "The email associated with this Facebook account is already in use.",
          ),
        },
        facebook_email_taken: {
          id: "error.signup.facebook.email_taken",
          defaultMessage: t(
            "The email associated with this Facebook account is already in use.",
          ),
        },
        email_not_allowed: {
          id: "error.signup.facebook.email_unavailable",
          defaultMessage: t(
            "The Facebook profile provided either does not have an email or security settings prevents sharing it. Please check your profile and try again.",
          ),
        },
        unknown: {
          id: "error.signup.facebook.unknown",
          defaultMessage: t(
            "Unable to sign up with Facebook. Please try again.",
          ),
        },
      },
    }),
    [],
  );

  const handleApiError = useCallback(
    (errors: string[], addErrors: (e: FormErrors) => void) => {
      const typeErrors = response_errors["signup"];

      let formErrors: FormErrors;
      if (errors == null) {
        errors = ["unknown"];
      }

      formErrors = Object.assign(
        {},
        ...errors.map((key: string) => {
          const err = typeErrors[key];
          if (err && (err as EnhancedMessageDescriptor).message) {
            return { [err.id]: (err as EnhancedMessageDescriptor).message };
          } else if (err) {
            return err as MessageDescriptor;
          }
          return {};
        }),
      );

      addErrors(formErrors as FormErrors);
    },
    [],
  );

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();
    const form = getFormElements(e);
    const user = getUserFromForm(form, props);
    const validation = validateUser(user, isPhoneInvite);

    if (!validation.isValid) {
      addErrors(validation.errors);
      return;
    }

    if (!user.ageConsent && !user.consentToken) {
      props.onConsent(user);

      return;
    }

    startPending();
    const authAny = (AuthService as any).signUp(
      user,
      user.tosConsent,
      addUTMParams(),
      props.verificationToken,
    );

    authAny
      .then((user: UserModel) => {
        props.onDone({ user });
      })
      .catch((e: any) => handleApiError(e.errors, addErrors))
      .finally(stopPending);
  };

  // const handleFacebookSignup = () => {
  //   clearErrors();
  //   const { tosConsent } = props.user;
  //   const validation = validateProperty('tosConsent', tosConsent);
  //   if (!validation.isValid) {
  //     addErrors({ tosConsent: validation.error });
  //     return;
  //   }
  //
  //   const signinIfEmailExists = (error: string) => {
  //     if (error === 'facebook_email_exists' || error === 'facebook_email_taken') {
  //       return (AuthService as any).authenticateWithFacebook()
  //         .then((user: UserModel) => props.onSignin(user));
  //     } else {
  //       return handleResponseError('facebook', error, addErrors);
  //     }
  //   };
  //
  //   startPending();
  //   (AuthService as any).signUpWithFacebook(SIGNUP_SOURCE, tosConsent, addUTMParams())
  //     .then((data: ProviderType) => props.onDone({ user: data.data, provider: data.provider }))
  //     .catch(signinIfEmailExists)
  //     .finally(stopPending);
  // };

  const checkMatch = (event: FormEvent<HTMLFormElement>) => {
    const els = getFormElements(event);
    const password = els.password.value.trim();
    const confirmPassword = els.passwordVerify.value.trim();

    const match = password.length > 0 && password === confirmPassword;
    setMatch(match);
  };

  return (
    <div className={styles.signupForm}>
      {props.coachSignup ? (
        <div className={cls(styles.coachSignupHeaderCopy, "mb-1 mt-1")}>
          <Trans
            i18nKey="create_account_free_trial"
            components={{ strong: <strong />, br: <br /> }}
          />
        </div>
      ) : (
        ""
      )}

      <form
        noValidate
        onChange={checkMatch}
        onSubmit={handleSignup}
        target="_self"
      >
        <FormItem error={getError("firstName", errorsForm)}>
          <input
            name="firstName"
            defaultValue={props.user.firstName}
            placeholder={t("First Name")}
            type="text"
            disabled={pending.status}
          />
        </FormItem>

        <FormItem error={getError("lastName", errorsForm)}>
          <input
            name="lastName"
            defaultValue={props.user.lastName}
            placeholder={t("Last Name")}
            type="text"
            disabled={pending.status}
          />
        </FormItem>

        <FormItem error={getError("email", errorsForm)}>
          <input
            name="email"
            defaultValue={props.user.email}
            placeholder={t("Email")}
            type="text"
            disabled={props.lockEmail || pending.status}
          />
        </FormItem>

        <FormItem
          className={!isPhoneInvite ? styles.hidden : ""}
          error={getError("phone", errorsForm)}
        >
          <PhoneInput
            country={props.phoneCountry}
            value={props.phoneNormalized}
            countryCodeEditable={false}
            inputStyle={{ width: "100%", height: "42px" }}
            disabled={isPhoneInvite}
          />
        </FormItem>

        <FormItem
          className={styles.checkForm}
          error={getError("password", errorsForm)}
        >
          <input
            name="password"
            defaultValue={props.user.password}
            placeholder={t("Password")}
            type="password"
            disabled={pending.status}
          />
          <span className={cls(styles.icon, isMatch ? styles.showing : "")}>
            <i className="fas fa-check-circle fade in"></i>
          </span>
        </FormItem>

        <FormItem className={styles.checkForm}>
          <input
            name="passwordVerify"
            defaultValue={props.user.passwordVerify}
            placeholder={t("Re-type password")}
            type="password"
            disabled={pending.status}
          />
          <span className={cls(styles.icon, isMatch ? styles.showing : "")}>
            <i className="fas fa-check-circle fade in"></i>
          </span>
        </FormItem>

        <FormItem error={getError("avatar", errorsForm)}>
          <UserAvatarUploader
            file={props.user.avatar as PreviewFile}
            onChange={(file: PreviewFile) => updateUserState({ avatar: file })}
            disabled={pending.status}
          />
        </FormItem>

        <FormItem
          className="text-left"
          error={getError("ageConsent", errorsForm)}
        >
          <AgeConsent
            disabled={pending.status}
            checked={props.user.ageConsent}
            onChange={(checked: boolean) =>
              updateUserState({ ageConsent: checked })
            }
          />
        </FormItem>

        <FormItem
          className={cls(styles.coachSignupCopy, "text-left")}
          error={getError("tosConsent", errorsForm)}
        >
          <TermsOfService
            disabled={pending.status}
            checked={props.user.tosConsent}
            onChange={(checked: boolean) =>
              updateUserState({ tosConsent: checked })
            }
          />
        </FormItem>

        {props.coachSignup ? (
          <FormItem className={styles.coachSignupCopy}>
            {t(
              "By creating an account, you consent to receiving emails from us (don’t worry - we hate spam just like you!). You can change your subscription preferences at any time in the footer of our emails.",
            )}
          </FormItem>
        ) : (
          ""
        )}

        <FormItem error={getError("unknown", errorsForm)}>
          <button className="btn btn-primary full" disabled={pending.status}>
            {renderSingupButton(props)}
          </button>
        </FormItem>
      </form>

      <FormItem>
        <a className="btn btn-link full" href={signinPath}>
          {renderSigninButton(props)}
        </a>
      </FormItem>
    </div>
  );
}

const renderSigninButton = (props: PropsDef) => {
  const { t } = useTranslation();
  if (props.coachSignup) {
    return t("Already Have an Account? Sign In Here.");
  } else {
    return t("Sign In");
  }
};

const renderSingupButton = (props: PropsDef) => {
  const { t } = useTranslation();
  if (!props.user.ageConsent && !props.user.consentToken) {
    return t("Provide Billing Information");
  } else if (props.coachSignup) {
    return t("Begin My Free Trial");
  } else {
    return t("Sign Up");
  }
};

type EnhancedMessageDescriptor = { id: string; message: MessageDescriptor };

type ErrorObjMap = {
  [key: string]: {
    [key: string]: MessageDescriptor | EnhancedMessageDescriptor;
  };
};

interface FormElements extends HTMLCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
  password: HTMLInputElement;
  passwordVerify: HTMLInputElement;
}

const getFormElements = (e: FormEvent<HTMLFormElement>) => {
  const form = e.currentTarget.elements as FormElements;
  return form;
};

const getUserFromForm = (form: FormElements, props: PropsDef) => {
  const user = Object.assign(
    {},
    {
      consentToken: props.user.consentToken,
      ageConsent: props.user.ageConsent,
      tosConsent: props.user.tosConsent,
      avatar: props.user.avatar,
    },
    {
      source: SIGNUP_SOURCE,
      firstName: getFormValue(form, "firstName"),
      lastName: getFormValue(form, "lastName"),
      email: getFormValue(form, "email"),
      password: getFormValue(form, "password"),
      passwordVerify: getFormValue(form, "passwordVerify"),
    },
  );
  return user;
};

const getError = (key: keyof FormErrors, errorsForm: FormErrors) => {
  if (errorsForm[key]) {
    return errorsForm[key];
  }
  return null;
};

const getFormValue = (form: any, key: string) => {
  const ref = form[key];
  if (ref) {
    return ref.value.trim();
  }
  return "";
};

// type ErrKeys = keyof typeof response_errors;

// function handleResponseError(key: ErrKeys, error: string, addErrors: (e: FormErrors) => void) {
//   addErrors({ [key]: response_errors[key][error] || response_errors[key]['unknown'] });
// }
