/**
 * @module Routes
 *
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { cls } from 'app/utils';
import upgrade_trial_jpg from 'app/images/upgrade-trial.jpg';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * @class StartTrial
 *
 */
export default function StartTrial() {
  const { t } = useTranslation();
  return (
    <div className={Styles.successMain}>
      <div className={cls(Styles.panel, Styles.panelOne)}>
        <div className={Styles.successImage} style={{ backgroundImage: `url(${upgrade_trial_jpg})` }} />
      </div>

      <div className={cls(Styles.panel, Styles.panelTwo)}>
        <div className={Styles.panelContainer}>
          <div className={Styles.panelHeader}>
            {t('Your 7-day Trial Has Begun!')}
          </div>

          <div className={Styles.panelBody}>
            <p>{t('You now have access to unlimited Spaces and Teams, media posting and video/image analysis in Teams, 150GB of storage, Library, Segements, and Templates Tools.')}</p>
            <p>{t('Transform your coaching and make the most of your time with athletes starting now!')}</p>
            <div className="clear-float"></div>
          </div>

          <div className={Styles.panelFooter}>
            <NavLink className={cls('btn', 'btn-primary', 'btn-success', Styles.doneButton)} to="/">
                {t('Get started')}
            </NavLink>
          </div>

          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
}
