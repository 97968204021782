import React from 'react';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from 'typedefs';
import { useTranslation } from 'react-i18next';

import FormItem from "app/components/inputs/FormItem";
import { SelectStyles, MultiSelectStyles } from 'app/routes/Calendar/EventForm/constants';

import styles from './styles.module.scss';
import {LessonSchedule} from "app/models/EventModel";

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <i className="ico ico-x" style={{ color: '#fff', cursor: 'pointer' }} />
    </components.MultiValueRemove>
  );
};

const LessonPanel = ({
  fieldsLists,
  fieldsEnabled,
  fieldsHandle,
  lessonProperties,
  editMode,
  lessonTypesError,
}) => {
  const { t } = useTranslation();
  const bookLessonDuration = useSelector((state: RootState) => state.bookSession.duration);
  const selectedSession = useSelector((state: RootState): LessonSchedule => state.bookSession.selectedSession);

  const currency_list = [
    { value: 'USD', label: t('US Dollar (USD, $)'), currency: '$' },
    { value: 'AUD', label: t('Australian Dollar (AUD, $)'), currency: '$' },
    { value: 'GBP', label: t('British Pound (GBP, £)'), currency: '£' },
    { value: 'CAD', label: t('Canadian Dollar (CAD, $)'), currency: '$' },
    { value: 'EUR', label: t('Euro (EUR, €)'), currency: '€' },
    { value: 'JPY', label: t('Japanese Yen (JPY, ¥)'), currency: '¥' },
    {
      value: 'AED',
      label: t('United Arab Emirates Dirham (AED)'),
      currency: 'AED',
    },
    {
      value: 'MXN',
      label: t('Mexican Peso (MXN)'),
      currency: 'MXN',
    },
    {
      value: 'NZD',
      label: t('New Zealand Dollar (NZD, $)'),
      currency: '$',
    },
    {
      value: 'TRY',
      label: t('Turkish Lira (TRY, ₺)'),
      currency: '₺',
    },
  ];

  const { facilitiesList, coachesList, pricingValuesList, ratesList } =
    fieldsLists;

  const { facility, coach, duration, lessonTypes, pricingValue } =
    lessonProperties;
  const {
    setFacilityHandle,
    setCoachHandle,
    setLessonTypesHandle,
    setDurationHandle,
    setPricingValuesHandle,
  } = fieldsHandle;
  const currencySymbol =
    currency_list.find((item) => item.value === coach?.currency)?.currency ||
    bookLessonDuration?.currency;

  return (
    <div className={styles.panel_container} data-testid="lesson-pannel">
      <FormItem className={styles.input_field}>
        <label>{t('Facility')}</label>
        <Select
          styles={SelectStyles}
          options={facilitiesList}
          value={facility}
          onChange={setFacilityHandle}
          isDisabled={!fieldsEnabled.facilities}
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => opt.id}
          isSearchable={false}
        />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t('Coach')}</label>
        <Select
          styles={SelectStyles}
          options={coachesList}
          value={coach}
          onChange={setCoachHandle}
          isDisabled={!fieldsEnabled.coaches || coachesList.length < 2}
          getOptionLabel={opt => opt.fullName}
          getOptionValue={opt => opt.id}
          isSearchable={false}
        />
      </FormItem>

      {coach.value !== 'all-coaches' ? (
        <>
          <FormItem className={styles.input_field}>
            <label>{t('Session')}</label>
        <Select
          styles={SelectStyles}
          options={pricingValuesList}
          value={pricingValue}
          onChange={setPricingValuesHandle}
          isDisabled={!fieldsEnabled.pricingValues}
          isSearchable={false}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.id}
        />
          </FormItem>

        <FormItem className={styles.input_field}>
          <label>{t('Duration')}</label>
          <input
            className={styles.input}
            data-testid="lesson-duration"
            value={pricingValue?.duration || ''}
            readOnly
          />
        </FormItem>

        <FormItem className={styles.input_field}>
          <label>{t('Type')}</label>
          <Select
            styles={MultiSelectStyles}
            options={pricingValue?.lessonTypes}
            value={lessonTypes}
            onChange={setLessonTypesHandle}
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt.id}
            isDisabled={!pricingValue?.lessonTypes}
            isClearable={false}
            isSearchable={false}
            isMulti
            components={{ MultiValueRemove }}
          />
          {lessonTypesError && (
            <span className={styles.error_message}>
              {lessonTypesError}
            </span>
          )}
        </FormItem>

        <FormItem className={styles.input_field}>
          <label>{t('Session Rate')}</label>
          <input
            className={styles.input}
            data-testid="lesson-rate"
            value={`${currencySymbol || '$'}${selectedSession?.rate || pricingValue?.rate || ''}`}
            readOnly
          />
        </FormItem>
        </>
      ) : (
        <FormItem className={styles.input_field}>
          <label>{t('Duration')}</label>
          <Select
            styles={SelectStyles}
            options={ratesList}
            value={duration}
            onChange={setDurationHandle}
            isDisabled={editMode}
          />
        </FormItem>
      )}
    </div>
  );
};

export default LessonPanel;
