import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

interface ScheduledAlertProps {
  scheduledIn?: number;
}

const ScheduledAlert = (props: ScheduledAlertProps) => {
  const { t } = useTranslation();
  const { scheduledIn } = props;
  const [generatedTimeValue, setGeneratedTimeValue] = useState(scheduledIn);
  const [generatedTime, setGeneratedTime] = useState<string>(null);

  useMemo(() => {
    if (scheduledIn < 24) {
      setGeneratedTime(t("Hour"));
    } else if (scheduledIn >= 24 && scheduledIn <= 168) {
      setGeneratedTime(t("Day"));
      setGeneratedTimeValue(scheduledIn / 24);
    } else if (scheduledIn > 168) {
      setGeneratedTime(t("Week"));
      setGeneratedTimeValue(scheduledIn / 7 / 24);
    }
  }, [scheduledIn, t]);

  return (
    <div className={styles.scheduled_container}>
      <i className="ico ico-time"></i>
      {generatedTimeValue >= 1 ? (
        <h1 className={styles.scheduledTime}>
          <b>
            {t("Wait for")} {Math.floor(generatedTimeValue)} {generatedTime}
            {Math.floor(generatedTimeValue) > 1 ? t("second").charAt(0) : ""}
          </b>{" "}
          {t("and publish")}
        </h1>
      ) : (
        <h1 className={styles.immediately}>{t("Publish Immediately")}</h1>
      )}
    </div>
  );
};

export default ScheduledAlert;
