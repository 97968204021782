import React from "react";
import styles from "./styles.module.scss";
import TagsList from "./TagsList/TagsList";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import PagePanel from "app/components/PagePanel";
import AccountWrap from "../Account";
import { useTranslation } from 'react-i18next';

const Tags = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.data, shallowEqual);
  const canAddTag = user && user.planPermissions.includes("create_tags");

  return (
    <AccountWrap>
      <div className={styles.container}>
        <PagePanel
          title={t("Tags")}
          titleStyles={styles.title}
          buttonTitle={t("+ Add Tag")}
          buttonType="primary"
          url={canAddTag && "/account/tags/new"}
        />
        <TagsList />
      </div>
    </AccountWrap>
  );
};

export default Tags;
