import { useEffect, useMemo, useRef, useState } from "react";
import { RootState } from "typedefs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  clearSpace,
  clearSpaceForSearch,
  fetchSpaceMemberships,
  hasMoreSpaces,
  hasSpacesData,
  spacePage,
} from "app/actions/space";
import { useActions } from "app/utils/hooks";
import { DemoSpaceModel, fetchDemoSpaces } from "app/actions/DemoSpace";
import useDebounce from "app/utils/hooks/useDebounce";
import SpaceModel from "app/models/SpaceModel";
import { useHistory } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface Props {
  searchTerm?: string | null;
}

const FORCE_PER_PAGE = 100;
const PER_PAGE = FORCE_PER_PAGE;

const useSpacesListModel = ({ searchTerm }: Props) => {
  const spaceActions = useActions({
    fetchDemoSpaces,
    fetchSpaceMemberships,
    clearSpaceForSearch,
    clearSpace,
  });
  const history = useHistory();
  const route = (path: string) => {
    history.push(path);
  };
  const dispatch = useDispatch();

  // const containerRef = useRef<HTMLDivElement | null>(null);

  const selectedSmartList = useSelector(
    (state: RootState) => state.smartList.selectedListValue,
    shallowEqual,
  );
  const { page, query, pending, hasMore } = useSelector(
    (state: RootState) => state.space,
    shallowEqual,
  );
  const [spacesData, setSpacesData] = useState<SpaceModel[] | null>([]);

  const fetchData = async (page?: number) => {
    if (page && page === 1) {
      dispatch(spacePage(1));
      setSpacesData(null);
    }
    const fetchedSpaces = await fetchSpacesWithMemberships(
      abortController.current,
      page,
    );
    setSpacesData((prev) => [...(prev || []), ...fetchedSpaces]);
  };

  const debouncedSearch = useDebounce(searchTerm, 500);

  const abortController = useRef<AbortController | null>(null);

  const fetchDemoSpace = async () => {
    try {
      setSpacesData(null);
      dispatch(hasMoreSpaces(false));
      const res: any = await fetchDemoSpaces(
        null,
        null,
        abortController?.current?.signal,
      );
      if (res && res._hash) {
        const demoSpaces = Object.values(res._hash).reduce(
          (acc: DemoSpaceModel[], item: DemoSpaceModel) => {
            if (item.space) {
              acc.push(item.space);
            }
            return acc;
          },
          [],
        );
        setSpacesData(demoSpaces as SpaceModel[]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSpacesWithMemberships = async (
    abort?: AbortController,
    force_page?: number,
  ): Promise<SpaceModel[]> => {
    try {
      const defaultQuery = {
        page: force_page ? force_page : page,
        ...query,
        force_per_page: FORCE_PER_PAGE,
        per_page: PER_PAGE,
        athlete_name: debouncedSearch,
      };

      let locationQuery = { ...defaultQuery };

      if (selectedSmartList?.object === "smart_list") {
        locationQuery = {
          ...defaultQuery,
          smart_list_id: selectedSmartList.id,
        };
      } else if (selectedSmartList?.object === "segment") {
        locationQuery = {
          ...defaultQuery,
          segment_id: selectedSmartList.id,
        };
      }

      const res: SpaceModel[] = await spaceActions.fetchSpaceMemberships(
        locationQuery,
        abort ? abort.signal : undefined,
      );

      if (res && Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    abortController.current = new AbortController();

    if (selectedSmartList?.object !== "demo_space") {
      if (debouncedSearch?.length >= 3 || debouncedSearch?.length === 0) {
        setSpacesData(null);
        fetchData(1);
      }
    }

    if (selectedSmartList?.object === "demo_space") {
      fetchDemoSpace();
    }

    return () => {
      // spaceActions.clearSpaceForSearch();
      if (abortController.current) {
        abortController.current.abort();
      }
      dispatch(clearSpace());
    };
  }, [selectedSmartList?.id, debouncedSearch]);

  const [containerRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: fetchData,
    rootMargin: "0px 100px 0px 0px",
  });

  useMemo(() => {
    if (spacesData?.length > 0) {
      dispatch(hasSpacesData(true));
    }

    return () => {
      dispatch(hasSpacesData(false));
    };
  }, [spacesData]);

  const spaceClicked = (value: string) => {
    if (value.includes("demo")) {
      route(value);
    } else {
      route(`/spaces/${value}`);
    }
  };

  return {
    spacesData,
    pending,
    selectedSmartList,
    containerRef,
    spaceClicked,
  };
};

export default useSpacesListModel;
