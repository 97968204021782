/**
 * @module Components.Error
 *
 */
import React from "react";
import {
  MessageDescriptor,
  FormattedMessage,
  FormattedHTMLMessage,
} from "react-intl";
import { Props } from "react-intl/dist/components/message";
import { PrimitiveType } from "typedefs";

interface PropsDef {
  value: MessageDescriptor | Props | string | null;
  html?: boolean;
}

/**
 * @class Message
 *
 */
function Loc(props: PropsDef): JSX.Element {
  if (props.value != null) {
    if (typeof props.value === "string") {
      return <span>{props.value as string}</span>;
    }

    if (props.html) {
      return (
        <FormattedHTMLMessage
          {...(props.value as Props<Record<string, PrimitiveType>>)}
        />
      );
    } else {
      return <FormattedMessage {...(props.value as Props)} />;
    }
  }
  return <span></span>;
}

export default Loc;
