import React, { memo } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import ActivitySidebar from "./ActivitySidebar";
import { sidebarItems } from "./ActivitySidebar/sidebarItems";
import TabButton from "app/components/Button/TabButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  children: React.ReactNode;
}

const ActivityLayout = ({ children }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <PagePanel title={t("Activity")} panelClassName={styles.panel}>
        <div className={styles.panel_menu_bar}>
          <div className={styles.navbar_wrapper}>
            {sidebarItems(t).map((item) => (
              <TabButton
                key={item.title}
                label={item.title}
                icon={item.icon}
                isActive={window.location.pathname
                  .toLowerCase()
                  .includes(item.title.toLowerCase())}
                onClick={() => route(item.path)}
                className={styles.tab_btn}
                labelClassName={styles.tab_label}
              />
            ))}
          </div>
        </div>
      </PagePanel>
      <div className={styles.container}>
        <ActivitySidebar />
        <div className={styles.main}>
          <GettingStartedProgress />
          <div className={styles.content_wrapper}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default memo(ActivityLayout);
