/**
 * @module Components.Subscription
 *
 */
import React from "react";
import { RootState } from "app/reducers";
import { useSelector } from "react-redux";

import { Spinner } from "app/components/Loading";
import { Loc } from "app/components/helpers";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const RecurlyBillingInfo = () => {
  return (
    <div className={styles.billingInfoView}>
      <RenderChild />
    </div>
  );
};

function RenderChild() {
  const { t } = useTranslation();
  const state = useSelector((_state: RootState) => _state);

  if (state?.paymentInfo?.error != null) {
    return (
      <div className={styles.error}>
        <Loc value={state.paymentInfo.error} />
      </div>
    );
  }

  if (state.paymentInfo.data !== null) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t("Credit Card on file")}</div>

        <div className={styles.details}>
          <table className={styles.table_show}>
            <tbody>
              <tr>
                <td className={styles.th}>{t("First Name")}</td>
                <td>
                  <p>{state.paymentInfo.data.billingInfo.firstName || ""}</p>
                </td>
              </tr>
              <tr>
                <td className={styles.th}>{t("Last Name")}</td>
                <td>
                  <p>{state.paymentInfo.data.billingInfo.lastName || ""}</p>
                </td>
              </tr>
              <tr>
                <td className={styles.th}>{t("Credit Card")}</td>
                <td>
                  <p>
                    {state.paymentInfo.data.billingInfo.firstSix}
                    XXXXXX{state.paymentInfo.data.billingInfo.lastFour} / &nbsp;
                    {state.paymentInfo.data.billingInfo.cardType}
                  </p>
                </td>
              </tr>
              <tr>
                <td className={styles.th}>{t("Valid Till")}</td>
                <td>
                  <p>
                    {state.paymentInfo.data.billingInfo.month}/
                    {state.paymentInfo.data.billingInfo.year}
                  </p>
                </td>
              </tr>
              <tr>
                <td className={styles.th}>{t("Address")}</td>
                <td>
                  <p>{state.paymentInfo.data.billingInfo.address1}</p>
                  <p>{state.paymentInfo.data.billingInfo.address2}</p>
                  <p>
                    {state.paymentInfo.data.billingInfo.city},{" "}
                    {state.paymentInfo.data.billingInfo.state}{" "}
                    {state.paymentInfo.data.billingInfo.zip}
                  </p>
                  <p>{state.paymentInfo.data.billingInfo.country}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <a
          className="btn btn-outline-secondary"
          href={state.paymentInfo.data.billingInfoUpdateLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Update Credit Card")}
        </a>
      </div>
    );
  }

  return <Spinner />;
}

export default RecurlyBillingInfo;
