import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import styles from "./styles.module.scss";
import PhoneInput from "react-phone-input-2";
import { cls } from "app/utils";
import { IUserPhone } from "app/interfaces/UserTypes";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchPhoneCountryCodes } from "app/actions/user-settings";
import { RootState } from "typedefs";
import { Loading } from "../Wrappers";
import { shallowEqual } from "react-redux";

export type PhoneInputProps = {
  value?: string;
  data?: IUserPhone;
  _event?: React.FormEvent<HTMLInputElement>;
  formattedValue?: string;
};

interface Props {
  value?: string | null;
  country?: string | null;
  countryCodeEditable?: boolean;
  onChange: (e: PhoneInputProps) => void;
  autoFocus?: boolean;
  className?: string;
}

const AddPhoneNumber = ({
  value,
  country,
  countryCodeEditable = false,
  onChange,
  autoFocus = true,
  className,
}: Props) => {
  const { t } = useTranslation();
  const userActions = useActions({ fetchPhoneCountryCodes });
  const [lowercaseCountryCodes, setLowercaseCountryCodes] =
    useState<string[]>(null);
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { countryCodes } = useSelector(
    (state: RootState) => state.userSettings.fetch, shallowEqual
  );

  useEffect(() => {
    userActions.fetchPhoneCountryCodes();
  }, []);

  useEffect(() => {
    if (countryCodes) {
      const lowercaseCodes = countryCodes.map((code: string) => code.toLowerCase());

      if (self && self.phoneVerified) {
        lowercaseCodes.push(self.phoneCountry.toLowerCase());
      }

      setLowercaseCountryCodes(lowercaseCodes);
    }
  }, [countryCodes]);

  if (!lowercaseCountryCodes) {
    return <Loading loadType="spinner" />;
  }

  return (
    <div className={cls(styles.container, className)}>
      <label className={styles.title} htmlFor="name">
        {t("Phone Number")}
      </label>
      <PhoneInput
        inputStyle={{ width: "100%", height: "42px" }}
        value={value}
        country={country ? country.toLocaleLowerCase() : "us"}
        countryCodeEditable={countryCodeEditable}
        onChange={onChange as any}
        inputProps={{ autoFocus }}
        onlyCountries={lowercaseCountryCodes}
      />
    </div>
  );
};

export default AddPhoneNumber;
