import React from "react";
import styles from "./styles.module.scss";
import PostModel from "app/models/PostModel";
import TagModel from "app/models/TagModel";
import { cls } from "app/utils";
import { getType } from "app/utils/file";
import useTruncateTags from "app/utils/hooks/useTruncateTags";
import reorderIcon from "../../../public/images/ico_reorder.svg";

interface Props {
  post: PostModel;
}

function getSpriteClassName(filename: any) {
  switch (getType(filename)) {
    case "doc":
      return "media-sprite-doc";
    case "xls":
      return "media-sprite-xls";
    case "ppt":
      return "media-sprite-ppt";
    case "pdf":
      return "media-sprite-pdf";
    case "video":
      return "media-sprite-vid";
    case "audio":
      return "media-sprite-mic";
    case "image":
      return "media-sprite-img";
  }

  return "";
}

const ReorderPostCard = ({ post }: Props) => {
  const TagComponent = ({
    tags,
    limit,
  }: {
    tags: TagModel[];
    limit: number;
  }) => {
    const truncatedTags = useTruncateTags({ tags, limit });

    return <div>{truncatedTags}</div>;
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.media_sprite_container}>
          {post?.type === "image" && (
            <img
              className={styles.reorder_preview}
              src={post?.image?.smallUrl}
            />
          )}
          {post?.type === "video" && (
            <img
              className={styles.reorder_preview}
              src={post?.video?.smallThumbnailUrl}
            />
          )}
          {post?.type === "audio" && (
            <div
              className={cls(
                styles.media_sprite,
                getSpriteClassName(post?.audio?.url)
              )}
            />
          )}
          {post?.type === "datafile" && (
            <div
              className={cls(
                styles.media_sprite,
                getSpriteClassName(post?.datafile?.url)
              )}
            />
          )}
          {post?.details && <span>{post.details}</span>}
        </div>
        {post?.tags && <TagComponent tags={post.tags} limit={40} />}
      </div>
      {(post?.scheduledIn === 0 || !post?.scheduledIn) && (
        <div className={styles.media_sprite_image_wrapper}>
          <img
            src={reorderIcon}
            alt="reorder-icon"
            className={styles.reorder_icon}
          />
        </div>
      )}
    </div>
  );
};

export default ReorderPostCard;
