import React from "react";
import styles from "./styles.module.scss";
import CalendarIco from "../../../public/images/calendar-ico-gray.svg";
import { useTranslation } from 'react-i18next';

export default function NoSession() {
  const { t } = useTranslation();

  return (
    <div className={styles.no_sesson}>
      <img className={styles.calendar_ico} src={CalendarIco} />
      <div className={styles.sub_container}>
        <h3 className={styles.no_upcoming_sessions}>
          {t("You have no Upcoming Sessions")}
        </h3>
        <p className={styles.text}>
          {t("All your upcoming sessions with coaches will be displayed here.")}
        </p>
      </div>
    </div>
  );
}
