import React from 'react';
import DayRow from './DayRow';
import { useTranslation } from 'react-i18next';

import styles from './DateTime.module.scss';

const DaysPanelForAllCoaches = ({
  currentDay,
  coach,
  timeSlots,
  index,
  isPublicPage,
}) => {
  const { t } = useTranslation();
  const timeHeader = (text, index) => {
    return (
      <div className={styles.time_header_container}>
        {index === 0 && <div className={styles.time_header_text}>{text}</div>}
      </div>
    );
  };

  return (
    <div className={styles.multi_coach_day_panel_container}>
      {timeHeader(t("Morning"), index)}
      <div className={styles.half_of_day}>
        <DayRow
          coach={coach}
          day={currentDay}
          current={true}
          multiCoach
          timeSlots={timeSlots
            .filter((s) => s.startHour.hasSame(currentDay, 'day'))
            .filter(
              (s) =>
                s.endHour.hour < 12 ||
                (s.endHour.hour === 12 && s.endHour.minute === 0)
            )}
          singleDay={true}
          isPublicPage={isPublicPage}
        />
      </div>
      {timeHeader(t('Afternoon'), index)}
      <div className={styles.half_of_day}>
        <DayRow
          coach={coach}
          day={currentDay}
          current={true}
          multiCoach
          timeSlots={timeSlots
            .filter((s) => s.startHour.hasSame(currentDay, 'day'))
            .filter((s) => s.startHour.hour >= 12)}
          singleDay={true}
          isPublicPage={isPublicPage}
        />
      </div>
    </div>
  );
};

export default DaysPanelForAllCoaches;
