import React from "react";
import styles from "./styles.module.scss";
import PostModel from "app/models/PostModel";
import ScheduledAlert from "app/components/ScheduledAlert";
import { toInteger } from "lodash";
import ReorderPostCard from "app/components/ReorderPostCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cls } from "app/utils";

interface Props {
  posts?: Record<string, PostModel[]>; // Assuming posts is an object with groups
  handleOnDragEnd: (result: any) => void;
}

const ReorderPosts = ({ posts, handleOnDragEnd }: Props) => {
  if (!posts) {
    return null;
  }

  return (
    <div className={styles.container}>
      {Object.entries(posts).map(([scheduledIn, postsGroup]) => (
        <div key={scheduledIn}>
          {parseInt(scheduledIn, 10) > 0 && (
            <ScheduledAlert scheduledIn={toInteger(scheduledIn)} />
          )}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.list_wrapper}
                >
                  {postsGroup.map((post: PostModel, index: number) => (
                    <Draggable
                      key={post.id}
                      draggableId={post.id}
                      index={index}
                      isDragDisabled={post.scheduledIn > 0}
                    >
                      {(provided) => {
                        return (
                          <li
                            className={cls(
                              styles.list_item,
                              `reorder-preview-${post.type}`
                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              // borderRadius: "12px",
                              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              left: "auto !important",
                              top: "auto !important",
                            }}
                          >
                            <ReorderPostCard post={post} />
                          </li>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ))}
    </div>
  );
};

export default ReorderPosts;
