import React from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import mailSuccessIcon from "../../../../../../public/images/mail-success-icon.png";
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  selectedMembers: any;
}

const SuccessInviteModal: React.FC<Props> = ({
  selectedMembers,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Invitations Sent")}
    >
      <div className={styles.container}>
        <img
          src={mailSuccessIcon}
          alt="coachnow icon"
          className={styles.group_icon}
        />
        <div className={styles.header_text_wrapper}>
          <p>
            <b>{t("Member Invitations Sent!")}</b>
          </p>
          <p>
            {t("count_member", { count: selectedMembers?.length ?? 0 })}{" "}
            {t("were invited to your group")}.
          </p>
        </div>
        <div className={styles.btn_wrapper}>
          <Button buttonType="primary" title={t("Done")} onClickFunc={onClose} />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default SuccessInviteModal;
