import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import PostModel from "app/models/PostModel";
import PostLocations from "../PostLocations";
import { postDate } from "app/utils/postDate";
import PostMenu from "../PostMenu";

type Props = {
  post?: PostModel;
  displayLocation?: boolean;
  onCopyClick?: () => void;
  onMoveClick?: () => void;
  onEditClick?: () => void;
  onEditTagsClick?: () => void;
  onPinClick?: () => void;
  onPostDelete?: (post: PostModel) => void;
  onPostReport?: (post: PostModel) => void;
  dropdownButtonClassName?: string;
  canPinPost?: boolean;
  isDemo?: boolean;
};

const PostHeader = ({
  post,
  displayLocation,
  onCopyClick,
  dropdownButtonClassName,
  onEditClick,
  onEditTagsClick,
  onMoveClick,
  onPinClick,
  onPostDelete,
  canPinPost,
  onPostReport,
  isDemo,
}: Props) => {
  return (
    <div className={styles.post_header}>
      <div className={styles.post_header_info}>
        <UserAvatar
          sizeWidth={44}
          sizeHeight={44}
          altName="avatar"
          srcName={post.userAvatar}
          userName={post.userName}
          className={styles.avatar}
        />
        <div className={styles.username_container}>
          <p className={styles.username}>
            {post.userName}{" "}
            {post.teamMembership && post.teamMembership.nickname && (
              <span className={styles.nickname_container}>
                {post.teamMembership.nickname}
              </span>
            )}
          </p>
          {displayLocation && <PostLocations post={post} />}
        </div>
      </div>
      <div className={styles.post_header_menu_wrapper}>
        {/* post creation date */}
        <p className={styles.post_date}>{postDate(post.createdAt)}</p>
        {!isDemo && (
          <PostMenu
            props={post}
            onCopy={onCopyClick}
            onMove={onMoveClick}
            onEdit={onEditClick}
            onEditTags={onEditTagsClick}
            onPin={onPinClick}
            onDelete={() => onPostDelete(post)}
            onReport={() => onPostReport(post)}
            buttonClassName={dropdownButtonClassName}
            canPinPost={canPinPost}
          />
        )}
      </div>
    </div>
  );
};

export default PostHeader;
