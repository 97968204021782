/* eslint-disable no-unused-vars */
import { FilterModel } from "app/models/FilterModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

const useFilters = () => {
  const filterState = useSelector(
    (state: RootState) => state.filter.data,
    shallowEqual
  );

  if (!filterState) {
    return null;
  }

  const filters: FilterModel = {
    orderby: filterState.orderby,
    tags: filterState.tags,
    types: filterState.type,
    from: filterState.from,
    to: filterState.to,
    userIds: filterState.userIds,
  };

  const filteredFilter: FilterModel | any = {};

  // iterate through each key-value pair in the filter object
  for (const [key, value] of Object.entries(filters)) {
    if (key === "types") {
      const filteredTypes = Object.entries(value)
        // @ts-ignore
        .filter(([typeKey, typeValue]) => typeValue === true)
        .map(([typeKey]) => typeKey);
      if (filteredTypes.length > 0) {
        filteredFilter["type"] = filteredTypes;
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        filteredFilter[key] = value; // keep non-empty arrays
      }
    } else if (typeof value === "object") {
      if (Object.values(value).some((innerValue) => innerValue !== false)) {
        filteredFilter[key] = value; // keep objects with at least one true value
      }
    } else {
      if (value !== "" && value !== false) {
        filteredFilter[key] = value; // keep non-empty strings and true values
      }
    }
  }

  return { filters: filteredFilter };
};

export default useFilters;
