import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { fetchConnections } from "app/actions/connection";
import { useStoreActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import Fuse from "../../../../vendor/fuse";
import Button from "app/components/Button/ButtonVariant";
import UsersList from "./UsersList";
import Dropdown from "app/components/Dropdown";
import AddNewUser from "app/components/AddNewUser";
import { uuid } from "app/utils/uuid";

const UserSelect = ({
  onChange,
  isCreatable,
  selectedUsersList,
  noOptionsMessage,
  addNewUserModalTitle,
  displayAddUser,
}: {
  onChange: (selectedUsers: UserModel[]) => void;
  isCreatable?: boolean;
  selectedUsersList?: UserModel[];
  noOptionsMessage?: string | null;
  addNewUserModalTitle?: string;
  displayAddUser?: boolean;
}) => {
  const { connection } = useStoreActions({ connection: fetchConnections });
  const [userOptions, setUserOptions] = useState<UserModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const { t } = useTranslation();

  const addNewUserModalHandler = () => {
    setAddNewUserModal((prev) => !prev);
  };

  useEffect(() => {
    if (connection.data) {
      setUserOptions(connection.data);
    }
  }, [connection.data]);

  const fuseOptions = {
    keys: ["displayName", "email"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.1,
  };

  const fuse = useMemo(() => {
    const users = userOptions.map((user: UserModel) => user);
    return new (Fuse as any)(users, fuseOptions);
  }, [userOptions, fuseOptions]);

  const results = useMemo(() => {
    if (searchTerm !== "" || searchTerm) {
      return fuse.search(searchTerm).map((result: any) => result.item);
    } else {
      return userOptions;
    }
  }, [searchTerm, userOptions, fuse]);

  const onSearchTeam = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const toggleUserSelection = useCallback(
    (user: UserModel) => {
      const isUserSelected = selectedUsers.some(
        (selectedUser) => selectedUser.id === user.id,
      );

      const filterSelectedUsers = selectedUsers.filter(
        (selectedUser) => selectedUser.id !== user.id,
      );

      if (isUserSelected) {
        setSelectedUsers(filterSelectedUsers);
        onChange(filterSelectedUsers);
      } else {
        const addSelectedUsers = [user, ...selectedUsers];
        setSelectedUsers(addSelectedUsers);
        onChange(addSelectedUsers);
      }

      setUserOptions((prevUserOptions) =>
        prevUserOptions.filter((option) => option.id !== user.id),
      );
    },
    [selectedUsers, onChange, setUserOptions],
  );

  const removeUser = useCallback(
    (user: UserModel) => {
      const removeSelectedUser = selectedUsers.filter(
        (selectedUser) => selectedUser.id !== user.id,
      );
      setSelectedUsers(removeSelectedUser);
      onChange(removeSelectedUser);
      setUserOptions((prevUserOptions) => [...prevUserOptions, user]);
    },
    [selectedUsers],
  );

  useEffect(() => {
    if (selectedUsersList && selectedUsersList.length >= 1) {
      setSelectedUsers([...selectedUsersList, ...selectedUsers]);
    }
  }, [selectedUsersList]);

  if (connection.pending) {
    return <Loading loadType="spinner" isLoading />;
  }

  const SelectedUsers = ({
    selectedAuthors,
    onRemoveUserClick,
  }: {
    selectedAuthors: UserModel[];
    onRemoveUserClick: (author: UserModel) => void;
  }) => {
    return (
      <div className={styles.users_list}>
        {selectedAuthors.map((author: UserModel) => {
          return (
            <div className={styles.user_container} key={author.id}>
              <div className={styles.user_info_container}>
                <UserAvatar
                  className={styles.avatar}
                  sizeWidth={44}
                  sizeHeight={44}
                  altName="author"
                  userName={author.displayName}
                  srcName={author.avatar as string}
                />
                <div className={styles.user_info}>
                  <h4>{author.displayName}</h4>
                  <p>{author.email}</p>
                </div>
              </div>
              <Button
                title={<i className={cls("ico ico-x text-danger")} />}
                onClickFunc={() => onRemoveUserClick(author)}
                buttonType="secondary-outline"
                buttonStyles={styles.delete_button}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const dropdownContent = () => {
    return (
      <span className={styles.input_container}>
        <input
          type="text"
          className={styles.input}
          onChange={(e) => onSearchTeam(e.target.value)}
          placeholder={t("Search by name or email")}
        />
        <span className={styles.svg_container}>
          <DropdownIndicator />
        </span>
      </span>
    );
  };

  const addNewUser = (newUser: UserModel) => {
    const newUserObj = { id: uuid(), ...newUser, displayName: newUser.name };

    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, newUserObj]);
    onChange([newUserObj, ...selectedUsers]);
    setUserOptions((prevUserOptions) =>
      prevUserOptions.filter((option) => option.id !== newUserObj.id),
    );
  };

  return (
    <>
      <DropdownButton
        closeOnClick
        hideButtonStyles
        dropdownWrapperClass={cls(styles.dropdown)}
        btnClassName={styles.btnn}
        btnContent={dropdownContent()}
      >
        <UsersList
          isCreatable={isCreatable}
          results={results}
          toggleUserSelection={toggleUserSelection}
          noOptionsMessage={noOptionsMessage}
          onNoOptionBtnClick={addNewUserModalHandler}
          displayAddUser={displayAddUser}
        />
      </DropdownButton>
      {isCreatable && addNewUserModal && (
        <Dropdown
          dropdownWrapperClass={styles.add_new_user_dropdown}
          isOpen={addNewUserModal}
          onClose={addNewUserModalHandler}
        >
          <AddNewUser
            title={addNewUserModalTitle}
            onAddUser={addNewUser as any}
            onCancel={addNewUserModalHandler}
            searchInput={searchTerm}
          />
        </Dropdown>
      )}

      {selectedUsers && selectedUsers.length > 0 && (
        <SelectedUsers
          onRemoveUserClick={removeUser}
          selectedAuthors={selectedUsers}
        />
      )}
    </>
  );
};

export default memo(UserSelect);

const DropdownIndicator = () => {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className={cls("css-6q0nyr-Svg", styles.dropdown_indicator)}
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};
