import ToolTip from "app/routes/Scheduling/ToolTip";
import React from "react";
import styles from "./styles.module.scss";

interface PropsDef {
  buttonText: string;
  tooltipText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive: boolean;
  orientation: 'left' | 'right' | 'center';
}

const ButtonWithTooltip = ({ buttonText, tooltipText, onClick, isActive, orientation }: PropsDef) => {
  return (
    <button
      className={`${styles.button} ${isActive ? styles.activeButton : styles.inactiveButton}`}
      onClick={onClick}
    >
      <span className={`${styles.text} ${isActive ? styles.activeText : styles.inactiveText}`}>
        {buttonText}
      </span>
      <span className={styles.tooltipContainer}>
        <ToolTip text={tooltipText} selected={true} iconColor={isActive ? 'white' : null} orientation={orientation}/>
      </span>
    </button>
  );
};

export default ButtonWithTooltip;
