import { PostingModel } from "app/models/PostModel";
import { createPost, fetchPost } from "app/actions/postingForm";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onPost: (post: PostingModel) => void;
}

export const TeamsPostingFormModel = ({ onPost }: Props) => {
  const teamPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user?.planPermissions?.includes("upload_to_library") &&
      user?.planPermissions?.includes("post_media") &&
      selectedTeam?.planPermissions?.includes("post_media")
    );
  };

  // posting form submit handler
  const onSubmit = (data: PostingModel) => {
    // check if selected team exists in redux to use id from it
    if (selectedTeam) {
      teamPostActions
        .createPost({ ...data, team_group_ids: [selectedTeam.id] })
        .then((res) => {
          if (res && onPost) {
            onPost(res);
          }
        });
    }
  };

  return {
    selectedTeam,
    canUploadMedia,
    user,
    onSubmit,
  };
};
