/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { cls } from "app/utils";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchTagById, updateTag, clearTags } from "../../../actions/tag";
import { RootState } from "typedefs";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";

const EditTag = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const tagsPageUrl = "/account/tags";
  const { id: tagId } = useParams<{ id: string }>();

  const tagAction = useActions({ fetchTagById, updateTag, clearTags });
  const { selectedTag, pending } = useSelector((state: RootState) => state.tag, shallowEqual);

  const [tagName, setTagName] = useState<null | string>(null);
  const [tagIsEmpty, setTagIsEmpty] = useState(false);
  const [updateallUsages, setUpdateallUsages] = useState(false);

  const fetchTag = useCallback(async () => {
    await tagAction.fetchTagById(tagId);
  }, []);

  useEffect(() => {
    fetchTag();

    return () => {
      tagAction.clearTags();
    };
  }, [tagId]);

  const tagHandler = (name: string) => {
    if (name && name.length) {
      setTagName(name);
      setTagIsEmpty(false);
    } else {
      setTagIsEmpty(true);
    }
  };

  const submitHandler = useCallback(async () => {
    if (tagName && tagName.length) {
      await tagAction.updateTag(selectedTag, tagName.trim(), true);
      setTagIsEmpty(false);
      history.push(tagsPageUrl);
    } else if (tagName.length === 0) {
      setTagIsEmpty(true);
    }
  }, [tagName, selectedTag]);

  const checkboxHandler = () => {
    setUpdateallUsages((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <GettingStartedProgress />
      <div className={styles.add_tag_container}>
        <div className={styles.panel}>
          <h1>{t("Edit Tag")}</h1>
        </div>
        <form className={styles.add_new_wrapper}>
          <span className={cls(styles.input_wrapper, "w-2-lg")}>
            <label htmlFor="tag">{t("Name")}</label>
            <input
              type="text"
              id="tag"
              name="tag"
              onChange={(e) => tagHandler(e.target.value)}
              className={cls({
                [styles.error]: tagIsEmpty,
              })}
              defaultValue={selectedTag?.name}
            />
          </span>
          <div className={cls(styles.update_all_usages_wrapper)}>
            <div className={cls("checkbox")}>
              <input
                type="checkbox"
                name="usages"
                id="usages"
                defaultChecked={updateallUsages}
                onChange={checkboxHandler}
              />
              <label htmlFor="usages" className="tag_update_all"></label>
            </div>
            <label htmlFor="usages">
              {t("Update all occurrences of this tag in my posts")}
            </label>
          </div>
          <span className={styles.buttons_container}>
            <button
              className="btn btn-primary"
              onClick={submitHandler}
              disabled={pending || tagIsEmpty}
              type="submit"
            >
              <span>{pending ? t("Please Wait...") : t("Update")}</span>
            </button>
            <button
              className={cls("btn btn-outline-secondary")}
              onClick={() => history.push(tagsPageUrl)}
              ng-disabled="processing"
            >
              {t("Cancel")}
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default EditTag;
