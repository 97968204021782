import { useCallback, useState } from "react";
import { useLoc, useSelector } from "app/utils/hooks";
// import { fetchDemoSpaceAction } from "app/actions/DemoSpace";
// import { clearSpace, spaceQuery } from "app/actions/space";
import { RootState } from "typedefs";
// import { SpaceQueryModel } from "app/models";
import { shallowEqual } from "react-redux";
// import { initQuery } from "app/reducers/space";
import { useHistory } from "react-router-dom";

export const SpaceListContainerModel = () => {
  const { formatMessage } = useLoc();
  // const dispatch = useDispatch();
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const [searchTerm, setSearch] = useState("");

  const selectedSmartList = useSelector(
    (state: RootState) => state.smartList.selectedListValue,
    shallowEqual
  );
  const user = useSelector((state: RootState) => state.user.self, shallowEqual);
  const userIsCoach = user.kind === "coach";

  const { pending, hasSpacesData } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );

  // const spaceActions = useActions({
  //   fetchDemoSpaceAction,
  //   clearSpace,
  // });

  // const spaceQueryHandler = (value: SpaceQueryModel) => {
  //   dispatch(spaceQuery({ ...value }));
  //   return () => {
  //     dispatch(spaceQuery(initQuery));
  //   };
  // };
  // useMemo(() => {
  //   spaceActions.clearSpace();

  //   if (selectedSmartList.object && selectedSmartList.object !== "demo_space") {
  //     if (selectedSmartList && selectedSmartList.object === "smart_list") {
  //       spaceQueryHandler({
  //         ...initQuery,
  //         smart_list_id: selectedSmartList.id,
  //       });
  //       return;
  //     } else if (selectedSmartList && selectedSmartList.object === "segment") {
  //       spaceQueryHandler({
  //         ...initQuery,
  //         segment_id: selectedSmartList && selectedSmartList.id,
  //       });
  //       return;
  //     } else {
  //       spaceQueryHandler({
  //         ...initQuery,
  //       });
  //       return;
  //     }
  //   }
  // }, [selectedSmartList.id]);

  const onPostToListClick = useCallback(() => {
    route(
      `/spaces/smartList/${selectedSmartList.name
        .toLocaleLowerCase()
        .replace(/\s+/g, "_")}/post_to_smart_list/${
        selectedSmartList && selectedSmartList.id
      }`
    );
  }, [selectedSmartList]);

  const updateSearch = (value: string) => {
    if (value != null && value.length > 0) {
      setSearch(value);
      // spaceQueryHandler({ ...query, athlete_name: value });
    } else {
      setSearch("");
    }
  };

  return {
    formatMessage,
    selectedSmartList,
    onPostToListClick,
    hasSpacesData,
    user,
    userIsCoach,
    updateSearch,
    searchTerm,
    pending,
  };
};
