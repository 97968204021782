import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles.module.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchSports } from "app/actions/sports";
import { useActions, useStoreActions } from "app/utils/hooks";
import {
  fetchSpacesTemplates,
  clearSpaceTemplate,
} from "app/actions/spaceTemplate";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import TeamAvatar from "app/components/TeamAvatar";
import FileUploadButton from "app/components/Button/FileUploadButton";
import { cls } from "app/utils";
import CustomSelect from "app/components/inputs/CustomSelector";
import { DefaultOption } from "app/components/inputs/SelectBox/Base";
import Button from "app/components/Button/ButtonVariant";
import { clearMediaState } from "app/actions/media-library-data";
import upload from "app/utils/uploader";
import { clearNewGroupData, createGroup, groupData } from "app/actions/groups";
import { RootState } from "typedefs";
import { fetchNotifications } from "app/actions/user-settings";
import { Spinner } from "app/components/Loading";
import { inviteModalIsOpen } from "app/actions/team-group-member-import";
import { useHistory } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateNewGroupModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });
  const actions = useActions({ fetchNotifications });
  const newGroupActions = useActions({
    fetchSpacesTemplates,
    clearSpaceTemplate,
    createGroup,
    clearNewGroupData,
  });
  const { newGroupData } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [templpateOptions, setTemplateOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [nameError, setNameError] = useState(false);

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    actions.fetchNotifications().then((res) => {
      dispatch(
        groupData({
          ...newGroupData,
          user_settings: {
            email_notifications_enabled:
              res.defaultEmailNotificationsEnabled || false,
            push_notifications_enabled:
              res.defaultPushNotificationsEnabled || false,
          },
        }),
      );
    });
    return () => {
      newGroupActions.clearNewGroupData();
    };
  }, []);

  const createTemplateOption = (val: {
    name?: string;
    id?: string;
  }): DefaultOption => {
    return { value: val.name, label: val.name, id: val.id };
  };

  const fetchSpaceTemplates = async () => {
    const res = await newGroupActions.fetchSpacesTemplates();
    try {
      if (res?.length > 0) {
        const namesArray = res?.map((obj: { name: string; id: string }) => obj);
        setTemplateOptions(namesArray?.map(createTemplateOption));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSpaceTemplates();

    return () => {
      newGroupActions.clearSpaceTemplate();
    };
  }, [newGroupActions]);

  const groupNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.value.length > 0) {
      setNameError(false);
    }
    dispatch(
      groupData({
        ...newGroupData,
        name: event.target.value,
      }),
    );
  };

  const sportSelectHandler = (data: { value: string }) => {
    dispatch(
      groupData({
        ...newGroupData,
        sport: data ? data.value : "",
      }),
    );
  };

  const templateSelectHandler = (value: DefaultOption) => {
    dispatch(
      groupData({
        ...newGroupData,
        space_template_id: value ? value.id : "",
      }),
    );
  };

  const uploadRef = useRef(null);

  const handleFileChange = async (acceptedFile: File) => {
    if (acceptedFile) {
      const file = acceptedFile;
      setIsLoading(true);

      try {
        dispatch(clearMediaState());
        const abortableUpload = upload(file, file.name, file.type, undefined);
        uploadRef.current = abortableUpload;
        const response = await abortableUpload.promise;

        dispatch(
          groupData({
            ...newGroupData,
            logo: response.url,
            logo_id: response.id,
          }),
        );
      } catch (error) {
        setUploadError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const groupCreateHandler = () => {
    if (newGroupData) {
      if (!newGroupData.name || newGroupData.length === 0) {
        setNameError(true);
      } else {
        newGroupActions
          .createGroup(newGroupData)
          .then((res: { id: string }) => {
            setNameError(false);
            if (res && res.id) {
              dispatch(inviteModalIsOpen(true));
              route(`/groups/${res.id}`);
            }
          });
      }
    }
  };

  return (
    <ModalLayout
      onClose={onClose}
      isOpen={isOpen}
      classNames={styles.modal_wrapper}
    >
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <h4>{t("New Group")}</h4>
          <i
            className={cls("dismiss ico ico-x cursor_pointer")}
            onClick={onClose}
          />
        </div>
        <div className={styles.icon_upload_wrapper}>
          <TeamAvatar
            altName="team"
            className={styles.team_avatar}
            srcName={
              newGroupData && newGroupData.logo ? newGroupData.logo : null
            }
          />
          <FileUploadButton
            buttonStyles={styles.spinner}
            buttonLabel={
              !isLoading ? t("Add Group Logo") : <Spinner isLoading />
            }
            accept={{ "image/*": [] }}
            onFileChange={handleFileChange}
          />
        </div>
        <div className={styles.inputs_wrapper}>
          <div className={styles.selectbox_container}>
            <label htmlFor="group_name">{t("Group Name")}</label>
            <input
              type="text"
              id="group_name"
              name="group_name"
              placeholder={t("Name of a group, organization, etc.")}
              onChange={groupNameHandler}
              className={cls({
                ["error"]: nameError,
              })}
            />
            {nameError && (
              <div className="helper-text error">
                {t("A group name is required.")}
              </div>
            )}
          </div>
          <div className={styles.selectbox_container}>
            <label className={styles.title} htmlFor="group_sport">
              {t("What is This For?")}
            </label>
            <CustomSelect
              placeholder={t("Select Sport / Subject")}
              isCratable
              options={sports && sports.data}
              isClearable
              isSearchable
              onChange={(data: { value: string; label: string }) =>
                sportSelectHandler(data)
              }
            />
            {/* {error.sport && (
              <div className="helper-text error">A sport is required.</div>
            )} */}
          </div>
          <div className={styles.selectbox_container}>
            <label className={styles.title} htmlFor="group_template">
              {t("Apply Template")}
            </label>
            <CustomSelect
              placeholder={t("Select Template")}
              options={templpateOptions && templpateOptions}
              isClearable
              isSearchable
              onChange={(value: DefaultOption) => templateSelectHandler(value)}
            />
          </div>
        </div>
        {uploadError && (
          <div className="helper-text error">{t("Something went wrong.")}</div>
        )}
        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={onClose}
          />
          <Button
            buttonType="primary"
            title={t("Create Group")}
            disabled={!newGroupData || nameError}
            onClickFunc={groupCreateHandler}
          />
        </div>
      </div>
    </ModalLayout>
  );
};
