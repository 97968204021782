import React, { useRef, useEffect, memo } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface VideoPlayerProps {
  onClick?: () => void;
  src: {
    originalUrl: string;
    thumbnails: {
      default: string;
    };
  };
}

const VideoPlayer = ({ src, onClick }: VideoPlayerProps) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoNode = videoRef.current;

    if (!videoNode) {
      return null;
    }

    // Options for Video.js
    const options = {
      controls: true,
      sources: [
        {
          src: src.originalUrl,
          type: "video/mp4",
        },
      ],
    };

    // Create a new Video.js player
    const player = videojs(videoNode, options);
    player.responsive(true);
    if (src.thumbnails) {
      player.poster(src.thumbnails.default);
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, []);

  return (
    <div data-vjs-player className="data-vjs-player" onClick={onClick}>
      <div className="media-content media-video">
        <video
          ref={videoRef}
          className={
            "video-js vjs-default-skin vjs-big-play-centered videoplayer vjs-layout-large edit-page-vjs"
          }
          playsInline
        ></video>
      </div>
    </div>
  );
};

export default memo(VideoPlayer);
