import PostingForm from "app/components/PostingForm";
import React from "react";
import { SchedulePostingFormModel } from "./indexModel";
import { useTranslation } from "react-i18next";

interface Props {
  onPost?: (post: any) => void;
}

const SchedulePostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { user, canUploadMedia, onSubmit } = SchedulePostingFormModel({
    onPost,
  });

  return (
    <PostingForm
      textAreaPlaceholder={t("Create post...")}
      canUploadMedia={canUploadMedia()}
      canSchedule={
        user && user.planPermissions.includes("access_scheduled_posts")
      }
      scheduleIsRequired
      profilePicture={null}
      postButtonLabel={t("Post")}
      postTextIsRequired
      locationsIsRequired
      onPost={onSubmit}
    />
  );
};

export default SchedulePostingForm;
