import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

export const sidebarItems = (t: (input: string) => string) => [
  {
    title: t("Notifications"),
    icon: <i className={cls("ico ico-notifications", styles.icon)} />,
    path: "/notifications",
  },
  {
    title: t("Invitations"),
    icon: <i className={cls("ico ico-user-add", styles.icon)} />,
    path: "/invitations",
  },
];
