import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import { useScheduleModal } from "./indexModel";
import CustomSelect from "../inputs/SelectBox/CustomSelect";
import { useTranslation } from 'react-i18next';

interface ScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onScheduleChange: (pickedAt: number | string, pickedTime?: string) => void;
}

const ScheduleModal = (Props: ScheduleModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onScheduleChange } = Props;

  const {
    handleSubmit,
    selectTimeOptions,
    selectOptions,
    handleScheduleSelect,
    handleScheduleTimeSelect,
    selectedValue,
    selectedTime,
  } = useScheduleModal({
    onClose,
    onScheduleChange,
  });

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.schedule_container}>
        <h1>{t("Schedule")}</h1>
        <p>
          {t("After applying the template, how much time should pass before this post appears?")}
        </p>
        <form className={styles.day_select_wrapper}>
          <div className={styles.input_wrapper}>
            <label htmlFor="days">{t("Wait for")}</label>
            <div className={styles.timeZoneWrapper}>
              <CustomSelect
                placeholder={t("0 (Post today)")}
                className={styles.customSelectOne}
                value={selectedValue}
                inputClassName={styles.selectInputOne}
                __options={selectOptions}
                onSelectFunc={handleScheduleSelect}
                hideArrow
              />
              <hr />
              <CustomSelect
                placeholder={t("Days")}
                className={styles.time_zone}
                __options={selectTimeOptions}
                onSelectFunc={handleScheduleTimeSelect}
                value={selectedTime}
              />
            </div>
          </div>
        </form>
        <button
          type="button"
          className={cls(styles.done_btn, "btn btn-primary")}
          onClick={handleSubmit}
        >
          {t("Done")}
        </button>
      </div>
    </ModalLayout>
  );
};

export default ScheduleModal;
