import { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useActions } from "app/utils/hooks";
import { clearMediaState, fetchMedia } from "app/actions/media-library-data";
import { clearFilter } from "app/actions/filter";
import { FilterModel } from "app/models/FilterModel";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";

interface QueryParams {
  order?: string;
  page?: number;
  perPage?: number;
}

export const useSelectUploadedResourcesModel = () => {
  const mediaActions = useActions({ fetchMedia, clearMediaState, clearFilter });

  const { pending, hasMore } = useSelector(
    (state: RootState) => state.mediaLibraryData,
    shallowEqual,
  );

  const mediaParams = {
    order: "desc",
    page: 1,
    perPage: 20,
  };

  const [query, setQuery] = useState<QueryParams>(mediaParams);
  const [data, setData] = useState<MediaTypes[]>([]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (queryVal: {}) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const res = await mediaActions.fetchMedia(
        queryVal,
        abortControllerRef.current.signal,
      );

      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          setData((prevData: MediaTypes[]) => [...prevData, ...res]);
          setQuery(queryVal);
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const initialLoadPosts = (queryVal: {}) => {
    setData([]);
    fetchPage(queryVal);
  };

  const loadMore = () => {
    if (!pending && hasMore) {
      const newQuery = { ...query, page: (query.page || 1) + 1 };
      fetchPage(newQuery);
    }
  };

  const filterHandler = (filters: FilterModel) => {
    const filteredQuery = { ...mediaParams, ...filters };
    mediaActions.clearMediaState();
    initialLoadPosts(filteredQuery);
  };

  const onClearFilters = () => {
    setQuery(mediaParams);
    mediaActions.clearMediaState();
    initialLoadPosts(mediaParams);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    initialLoadPosts(mediaParams);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      onClearFilters();
    };
  }, []);

  return { data, pending, filterHandler, sentryRef };
};
