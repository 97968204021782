import { useEffect, useRef, useState } from "react";
import WaveSurfer from "../../../vendor/wavesurfer";
import { AudioPlayerProps } from "./index.types";
import moment from "moment";
import { uuid } from "app/utils/uuid";

const useAudioPlayerModel = ({ data }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [remaining, setRemaining] = useState(data.duration || 0);
  const [isLoaded, setIsLoaded] = useState(false);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const audioDuration = moment.utc(data.duration).format("mm:ss");
  const [currentId] = useState(uuid());

  useEffect(() => {
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: `#waveform-${currentId}`,
        waveColor: "#dddddd",
        progressColor: "#e54500",
        barWidth: 1.6,
        barGap: 3,
        loopSelection: false,
        barHeight: 4,
        cursorWidth: 0,
        height: 32,
        responsive: true,
      });
    }

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [currentId]);

  useEffect(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.on("audioprocess", () => {
        if (isPlaying) {
          const currentTime = wavesurferRef.current.getCurrentTime();
          const duration = wavesurferRef.current.getDuration();
          setRemaining(duration - currentTime);
        }
      });

      wavesurferRef.current.on("ready", () => {
        const duration = wavesurferRef.current.getDuration();
        setRemaining(duration);
        if (!isPlaying && !isLoaded) {
          setIsPlaying(true);
          wavesurferRef.current.play();
          // setCurrentId(data.id)
        }
      });
    }
  }, [isPlaying, isLoaded]);

  const handlePlayPause = () => {
    if (!wavesurferRef.current) {
      return;
    }

    // Load the audio if it hasn't been loaded yet
    if (!isLoaded) {
      setIsLoaded(true);
      wavesurferRef.current.load(data.url);
    }

    setIsPlaying(!isPlaying);
    wavesurferRef.current.playPause();
  };

  const remainingDuration = isLoaded
    ? moment.utc(Math.max(remaining, 0) * 1000).format("mm:ss")
    : audioDuration;

  useEffect(() => {
    if (remainingDuration === "00:00") {
      setIsPlaying(false);
    }
  }, [remainingDuration]);

  return {
    handlePlayPause,
    isPlaying,
    audioDuration,
    remainingDuration,
    currentId,
    isLoaded,
  };
};

export default useAudioPlayerModel;
