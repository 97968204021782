/* eslint-disable no-undef */
/**
 * @module Utils
 *
 */
import URI from 'urijs';
// @ts-ignore
import URITemplate from 'urijs/src/URITemplate';
import { deserialize } from './model';
import isNone from 'app/utils/isNone';
import settings from 'config/environment';

export interface QueryDef {
  [key: string]: any;
}

// @ts-ignore
type templateFuncType = (values: any) => uri.URI;

export const getQuerySymbol = (url: string) => {
  return url.indexOf('?') === -1 ? '?' : '&';
};

/**
 * Builds a url from a path and query object without interpolation.
 *
 * @param {string} path
 * @param {object} query
 * @returns {string}
 */
export function build(path: string, query?: QueryDef): string {
  query = query != null ? query : {};

  let url = new URI(path).toString();
  if (!isNone(query)) {
    const queryString = buildQuery(deserialize(query));
    if (queryString.length) {
      url += getQuerySymbol(url) + queryString;
    }
  }

  return url;
}

/**
 * Creates a url template function that will expand the url template with the provided values.
 *
 * @param {string} path
 * @returns {function}
 */
export function template(url: string): templateFuncType {
  const template = new URITemplate(url);
  return function expand(values) {
    values = values || {};
    return template.expand(values);
  };
}

/**
 * Creates an api url request path
 *
 * @method buildUrl
 * @param {string} api endpoint
 * @param {number} api version number default: 2
 * @param {object} query params object
 * @return {string}
 */
export function buildUrl(endPoint: string, query: QueryDef | null, version?: number | null): string {
  query = query || {};
  version = version != null ? version : 2;

  if (endPoint[0] === '/') {
    endPoint = endPoint.slice(1);
  }
  return build(`${settings.API_URL}/api/v${version}/${endPoint}`, query);
}

export function buildSchedulingUrl(endPoint: string, query: QueryDef | null, version?: number | null): string {
  query = query || {};
  version = version != null ? version : 1;

  if (endPoint[0] === '/') {
    endPoint = endPoint.slice(1);
  }
  return build(`${settings.SCHEDULING_API_URL}/api/v${version}/${endPoint}`, query);
}

export function buildQuery(query: QueryDef): string {
  return Object.keys(query).map(key => {
    const value = query[key];
    if (isNone(value)) {
      return '';
    } else if (Array.isArray(value)) {
      return value.map(v => `${key}[]=${encodeURIComponent(v)}`).join('&');
    } else if (typeof value === 'object') {
      return buildQuery(Object.assign({}, ...Object.keys(value).map(k => ({ [`${key}[${k}]`]: value[k] }))));
    } else {
      return `${key}=${encodeURIComponent(value)}`;
    }
  }).join('&').replace(/^&/, '').replace(/&$/, '');
}
