import React, {useState} from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import NextBtn from '../../../public/images/next-btn.svg';
import DetailsSession from "./DetailsSession";
import CancelConfirmation from './CancelConfirmation';

export default function SessionCard({session, first, onEditSession, onCancelSession}: any) {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const startDate: any = DateTime.fromISO(session.start).setZone(session.timeZone);
  const endDate: any = DateTime.fromISO(session.end).setZone(session.timeZone);

  const formattedDate = startDate.toLocaleString(DateTime.DATE_FULL).toUpperCase();

  const lessonTypes = session.lessonTypes.map((lessonType: any) => lessonType.name);
  const coachName = session.coachProfile.firstName + ' ' + session.coachProfile.lastName;
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const duration = (endDate - startDate) / 60000;

  return (
    <>
      <div className={styles.session_card}>
        <div className={styles.session_info}>
          <div className={styles.date_container}>
            {first && <img src={NextBtn} className={styles.next_btn} alt="next"/>}
            <div className={styles.date}>{formattedDate}</div>
          </div>
          <div>
            <div className={styles.time}>{session.startTimeString.replace(/^0/, '')} {session.timeZoneShortString}</div>
            <div className={styles.duration}>{duration} {t("mins")}</div>
          </div>
        </div>
        <div className={styles.coach_info}>
          <img className={styles.coach_image} src={session.coachProfile.profilePicture}/>
          <div className={styles.coach_name}>{coachName}</div>
          <div className={styles.labels}>
          {
            lessonTypes.map((label: string) => (
              <div key={label} className={styles.label}>{label}</div>
            ))
          }
          </div>
        </div>
        <div className={styles.horizontal_line}/>
        <div className={styles.footer_card}>
          <div className={styles.price}>{session?.coachProfile?.currencySymbol || '$'}{session.rate}</div>
          <div className={styles.details_btn} onClick={openModal}>Details</div>
        </div>
      </div>
      <DetailsSession
        isOpen={isModalOpen}
        onClose={closeModal}
        session={session}
        onEditSession={onEditSession}
        onCancelSession={() => {
          closeModal();
          setCancelConfirmation(true);
        }}
      />
      {cancelConfirmation && (
        <CancelConfirmation
          onConfirm={() => onCancelSession(session, () => setCancelConfirmation(false))}
          onClose={() => {
            setCancelConfirmation(false);
            openModal();
          }}
        />
      )}
    </>
  );
}
