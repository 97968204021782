import { fetchOutgoingInvitationsCount } from "app/actions/invitation";
import { useActions, useSelector } from "app/utils/hooks";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";

export const ActivityInvitationsModel = () => {
  const invitationAction = useActions({
    fetchOutgoingInvitationsCount,
  });
  const [activeTab, setActiveTab] = useState<"incoming" | "outgoing">(
    "incoming"
  );
  const { invitations } = useSelector((state: RootState) => state.notification.totalUnseen, shallowEqual);
  const { outgoingInvitations } = useSelector(
    (state: RootState) => state.invitation, shallowEqual
  );

  const handleTabChange = (tab: "incoming" | "outgoing") => {
    setActiveTab(tab);
  };

  useEffect(() => {
    invitationAction.fetchOutgoingInvitationsCount({ direction: "outgoing" });
  }, []);

  return {
    activeTab,
    handleTabChange,
    outgoingInvitations,
    invitations,
  };
};
