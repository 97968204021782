/**
 * @module Reducers.posts
 *
 */
import { RequestError } from "typedefs";
import { PostsAction } from "app/actions/posts";
import PostModel from "app/models/PostModel";

export type PostsState = {
  data: PostModel[] | null;
  error: RequestError | null;
  pending: boolean;
  hasMore?: boolean;
  replies?: PostModel[] | null;
  replyPending?: boolean;
  replyError?: RequestError | null;
};

const initialState: PostsState = {
  data: null,
  error: null,
  pending: true,
  hasMore: true,
  replies: null,
  replyPending: false,
  replyError: null,
};

export default function PostReducer(
  state = initialState,
  action: PostsAction
): PostsState {
  switch (action.type) {
    case "@post.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@post.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@post.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@post.fetch.hasMore":
      return { ...state, hasMore: action.payload };
    case "@post.fetch.successReply":
      return { ...state, replies: action.payload, replyPending: false };
    case "@post.fetch.pendingReply":
      return { ...state, replyError: null, replyPending: true };
    case "@post.fetch.errorReply":
      return { ...state, replyError: action.payload, replyPending: false };
    case "@post.clear":
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
