/**
 * @module Actions.Space
 *
 */
import { ActionType, action } from "typesafe-actions";
import { SpaceApplicationModel } from "app/models";
import config from "config/environment";
import { request } from "app/utils/request";
import { Dispatch } from "react";

const fetchPending = () => action("@spaceCreation.fetch.pending");

const error = (err: string) => action("@spaceCreation.fetch.error", err);

const success = (spaceCreation: SpaceApplicationModel[]) =>
  action("@spaceCreation.fetch.success", spaceCreation);

// selected space settings to create a new space with
export const newSpaceValues = (spaces_import: SpaceApplicationModel) =>
  action("@spaceCreation.fetch.newSpaceValues", spaces_import);

export const addByPhone = (value: boolean) =>
  action("@spaceCreation.fetch.addByPhone", value);

export const createSpaceStep = (step: number) =>
  action("@spaceCreation.fetch.step", step);

export const isMultiple = (isMultiple: boolean) =>
  action("@spaceCreation.fetch.isMultiple", isMultiple);

export const uploadProgress = (uploadProgress: number) =>
  action("@spaceCreation.fetch.uploadProgress", uploadProgress);

export const clearAppliedTemplates = () => action("@spaceCreation.clear");

const thisActions = {
  error,
  success,
  fetchPending,
  newSpaceValues,
  addByPhone,
  isMultiple,
  uploadProgress,
  createSpaceStep,
  clearAppliedTemplates,
};

export type SpaceCreationActions = ActionType<typeof thisActions>;

export function createSpace({ spaces_import }: SpaceApplicationModel) {
  return (dispatch: Dispatch<SpaceCreationActions>) => {
    const url = config.API_SERVER + `/api/v1/space_imports`;
    const promise = request(
      url,
      "POST",
      {
        spaces_import,
      },
      { version: 1 },
    );

    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function getCreatedSpace(space_id: SpaceApplicationModel) {
  return (dispatch: Dispatch<SpaceCreationActions>) => {
    const url = config.API_SERVER + `/api/v1/space_imports/${space_id}`;
    const promise = request(url, "GET", {}, { version: 1 });

    promise.then((views) => dispatch(success(views)));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const actions = {
  createSpace,
  getCreatedSpace,
};
