import React from "react";
import styles from "./styles.module.scss";
import SmartListPostingTitle from "app/components/SmartListPostingForm/SmartListPostingTitle";
import PostingForm from "app/components/PostingForm";
import { usePostToSmartListModel } from "./indexModel";
import { useTranslation } from "react-i18next";

const PostToSmartList = () => {
  const { t } = useTranslation();
  const { user, canUploadMedia, onSubmit, onCancel } =
    usePostToSmartListModel();

  return (
    <div className={styles.container}>
      <SmartListPostingTitle />
      <PostingForm
        expanded
        textAreaPlaceholder={t("Create post...")}
        canUploadMedia={canUploadMedia()}
        canSchedule={
          user && user.planPermissions.includes("access_scheduled_posts")
        }
        hasLocationSelect={false}
        profilePicture={null}
        postButtonLabel={t("Post")}
        postTextIsRequired
        onPost={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default PostToSmartList;
