import { t } from "app/i18n";
import moment from "moment";

export const notificationSortDate = (val: Date) => {
  if (val) {
    const now = moment();
    const notificationDate = moment(val);

    const diffDays = now.diff(notificationDate, "days");

    if (diffDays < 1) {
      return t("Today");
    } else if (diffDays < 7) {
      return t("This Week");
    } else if (diffDays < 30) {
      return t("This Month");
    } else {
      return t("Everything Else");
    }
  } else {
    return null;
  }
};

export const notificationDate = (val: Date) => {
  if (val) {
    const now = moment();
    const postDate = moment(val);

    const diffMinutes = now.diff(postDate, "minutes");
    const diffHours = now.diff(postDate, "hours");
    const diffDays = now.diff(postDate, "days");
    const diffWeeks = now.diff(postDate, "weeks");

    if (diffMinutes < 1) {
      return t("Just now");
    } else if (diffMinutes < 60) {
      return `${diffMinutes} ${t("m")}`;
    } else if (diffHours < 24) {
      return `${diffHours} ${t("h")}`;
    } else if (diffDays < 7) {
      return `${diffDays} ${t("d")}`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks} ${t("w")}`;
    } else {
      return postDate.format("D MMM");
    }
  } else {
    return null;
  }
};
