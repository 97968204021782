import React, { useState } from "react";
import styles from "./styles.module.scss";
import ImageCropper from "../ImageCropper";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import closeIcon from "../../../public/images/close-icon.svg";
import { useTranslation } from "react-i18next";
import Button from "app/components/Button/ButtonVariant";
import { dataUrlToBlob } from "app/utils/file";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
  imageFile: any;
  onChange: (image: any) => void;
}

const CoachCoverUpload = ({
  isOpen,
  onClose,
  imageSrc,
  imageFile,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const handleCrop = (data: string) => {
    if (data) {
      setCroppedImage(data);
    }
  };

  const COVER_IMAGE_SIZE = {
    width: 1500,
    height: 500,
  };

  const onCancel = () => {
    setCroppedImage(null);
    onClose();
  };

  const onConfirmCrop = () => {
    const file = imageFile;

    if (croppedImage && onChange) {
      file.url = croppedImage;
      file.blob = dataUrlToBlob(croppedImage, file.type);
      onChange(file);
    }

    setCroppedImage(null);
    onClose();
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h1>{t("Adjust Your Picture")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onClose}
          />
        </div>
        <div className={styles.cropper_container}>
          <ImageCropper
            width={COVER_IMAGE_SIZE.width}
            height={COVER_IMAGE_SIZE.height}
            src={imageSrc}
            onCrop={(data: string) => handleCrop(data)}
          />
        </div>
        <div className={styles.footer}>
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            onClickFunc={onCancel}
          />
          <Button
            title={t("Set Cover")}
            buttonType="primary"
            onClickFunc={onConfirmCrop}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default CoachCoverUpload;
