import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import InvitationModel from "app/models/InvitationModel";
import { cls } from "app/utils";
import UserAvatar from "app/components/UserAvatar";
import { notificationDate } from "app/utils/notificationDate";
import { useActions } from "app/utils/hooks";
import {
  cancelSpaceInvitation,
  cancelTeamInvitation,
  fetchOutgoingInvitationsCount,
  resendInvitation,
} from "app/actions/invitation";
import { addAlert } from "app/actions/alert";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

type SingleOutgoingInvitationProps = {
  invitation: InvitationModel;
  onCancelInvite?: () => void;
};

const SingleOutgoingInvitation = ({
  invitation,
  onCancelInvite,
}: SingleOutgoingInvitationProps) => {
  const { t } = useTranslation();
  const invitationAction = useActions({
    resendInvitation,
    fetchOutgoingInvitationsCount,
    cancelSpaceInvitation,
    cancelTeamInvitation,
  });
  const dispatch = useDispatch();

  const formatSummary = (invite: InvitationModel) => {
    if (invite.object === "space_invitation") {
      return (
        <span>
          {t("You invited")} <b>{invite.nameShort}</b> {t("to")}{" "}
          {invite.spaceAthleteNameShort} / {invite.spaceName} {t("Space")}
        </span>
      );
    }

    return (
      <span>
        {t("You invited")} <b>{invite.nameShort}</b> {t("to")} {invite.teamName}{" "}
        {t("Group")}
      </span>
    );
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setIsOpen(false);
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  const dropdownHandler = () => {
    setIsOpen(!isOpen);
  };

  const canResend = invitation?.permissions.includes("resend") ?? false;

  const canCancel = invitation?.permissions.includes("delete") ?? false;

  const onResend = async (invite: InvitationModel) => {
    try {
      const res = await invitationAction.resendInvitation(invite);
      if (res) {
        invitationAction.fetchOutgoingInvitationsCount({
          direction: "outgoing",
        });
        dispatch(
          addAlert({
            message:
              t("Invitation was successfully resent to") + ` ${res.email}`,
          })
        );
      }
    } catch (error) {
      dispatch(
        addAlert({
          message: t(
            "Invitation has been already sent. Please try again later"
          ),
        })
      );
    }
  };

  const onCancel = useCallback(
    async (invite: InvitationModel) => {
      try {
        if (invite.object === "space_invitation") {
          await invitationAction.cancelSpaceInvitation(
            invite.spaceId,
            invite.id
          );
        } else if (invite.object === "team_invitation") {
          await invitationAction.cancelTeamInvitation(invite.teamId, invite.id);
        }

        if (onCancelInvite) {
          onCancelInvite();
          await invitationAction.fetchOutgoingInvitationsCount({
            direction: "outgoing",
          });
        }
      } catch (error) {
        dispatch(
          addAlert({
            message: t("Something went wrong. Please try again later"),
          })
        );
      }
    },
    [onCancelInvite, invitationAction, dispatch]
  );

  return (
    <div className={cls(styles.invitation)}>
      <UserAvatar
        altName="user avatar"
        sizeHeight={44}
        sizeWidth={44}
        className={styles.avatar}
        srcName={invitation.initiator && invitation.initiator.avatarUrl}
        userName={invitation.initiator && invitation.initiator.name}
      />
      <div className={cls(styles.content_container, styles.outgoing_container)}>
        <div className={styles.content_info}>
          <div className={styles.content_wrapper}>
            <span className={styles.content_message}>
              <p className={styles.text_message}>{formatSummary(invitation)}</p>
            </span>
            <span className={styles.content_info}>
              <b>
                {invitation.createdAt && notificationDate(invitation.createdAt)}
              </b>
            </span>
          </div>
        </div>
        <div className={cls(styles.dropdown)}>
          <div className={styles.wrapper} onClick={dropdownHandler}>
            <div
              className={cls("bullets", styles.dropdown_btn)}
              onClick={dropdownHandler}
            />
          </div>
          {(canResend || canCancel) && isOpen && (
            <div ref={dropdownRef} className={styles.dropdown_container}>
              {canResend && (
                <div
                  className={cls(styles.list, styles.bold)}
                  onClick={() => onResend(invitation)}
                >
                  {t("Resend")}
                </div>
              )}
              {canCancel && (
                <div
                  className={cls(styles.list, styles.bold, "text-danger")}
                  onClick={() => onCancel(invitation)}
                >
                  {t("Cancel Invite")}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleOutgoingInvitation;
