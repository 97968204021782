import React, { useEffect, useState } from "react";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import PhoneInput from "react-phone-input-2";
import styles from "./styles.module.scss";
import Button from "../Button/ButtonVariant";
import checkmark from "../../../public/images/checkmark-green.png";
import { useActions, useSelector } from "app/utils/hooks";
import {
  confirmVerificationCode,
  fetchPhoneCountryCodes,
  sendVerificationCode,
} from "app/actions/user-settings";
import { RootState } from "typedefs";
import { IUserPhone } from "app/interfaces/UserTypes";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { editPhone } from "app/actions/user";

interface Props {
  isPhoneModalOpen: boolean;
  handlePhoneModal: () => void;
}

const AccountPhoneVerificationModal = ({
  isPhoneModalOpen,
  handlePhoneModal,
}: Props) => {
  const { t } = useTranslation();
  const userActions = useActions({
    fetchPhoneCountryCodes,
    sendVerificationCode,
    confirmVerificationCode,
    editPhone,
  });

  const { countryCodes, countryCodeErr } = useSelector(
    (state: RootState) => state.userSettings.fetch,
    shallowEqual
  );

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [step, setStep] = useState<number>(1);
  const [lowercaseCountryCodes, setLowercaseCountryCodes] = useState<string[]>(
    []
  );
  const [phoneInfo, setPhoneInfo] = useState<{
    phone: string;
    phoneCountry: string;
    phoneNormalized?: string;
  }>({
    phone: null,
    phoneCountry: null,
    phoneNormalized: null,
  });

  const [error, setError] = useState({
    sendingCode: false,
    codeIsNotCorrect: false,
    somethingWentWrong: false,
  });
  const [code, setCode] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState<{
    id: string;
    object: string;
    status: string;
    token: string | null;
  }>(null);
  const [showResend, setShowResend] = useState(false);

  const closeModal = () => {
    setStep(1);
    handlePhoneModal();
  };

  useEffect(() => {
    userActions.fetchPhoneCountryCodes();
  }, []);

  useEffect(() => {
    if (countryCodes) {
      const lowercaseCodes = countryCodes.map((code: string) =>
        code.toLowerCase()
      );

      if (self && self.phoneVerified && lowercaseCodes) {
        lowercaseCodes.push(self.phoneCountry.toLowerCase());
      }

      setLowercaseCountryCodes(lowercaseCodes);
    }
  }, [countryCodes]);

  const sendVerificationHandler = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();
    setPhoneInfo({
      ...phoneInfo,
      phoneNormalized,
      phone,
      phoneCountry,
    });
  };

  const handleSendVerificationCode = () => {
    if (!phoneInfo.phone) {
      return;
    } else {
      userActions
        .sendVerificationCode({
          phone: phoneInfo.phone,
          phone_country: phoneInfo.phoneCountry,
        })
        .then((res) => {
          setVerificationResponse(res);
          if (step === 1) {
            setStep(step + 1);
          }
          setError({
            codeIsNotCorrect: false,
            sendingCode: false,
            somethingWentWrong: false,
          });
          setShowResend(false);
        })
        .catch(() => {
          setError({ ...error, sendingCode: true });
        });
    }
  };

  const handleTimerEnd = () => {
    setShowResend(true);
  };

  const confirmCodeHandler = () => {
    if (!code || !verificationResponse.id) {
      return;
    } else {
      userActions
        .confirmVerificationCode({
          id: verificationResponse.id,
          code,
        })
        .then(() => {
          userActions.editPhone({
            verification_token: verificationResponse.token,
            phoneInfo,
          });
          if (step === 2) {
            closeModal();
          }
          setError({
            codeIsNotCorrect: false,
            sendingCode: false,
            somethingWentWrong: false,
          });
          setShowResend(false);
        })
        .catch((err) => {
          if (err.error === "sms_gateway_invalid_verification_code") {
            setError({
              ...error,
              somethingWentWrong: false,
              codeIsNotCorrect: true,
            });
          } else {
            setError({
              ...error,
              codeIsNotCorrect: false,
              somethingWentWrong: true,
            });
          }
        });
    }
  };

  return (
    <ModalLayout isOpen={isPhoneModalOpen} onClose={closeModal}>
      <div className={styles.modal_container}>
        <div className={styles.step_wrapper}>
          <span className={styles.header_wrapper}>
            <h4>{t("Verify Phone Number")}</h4>
            <i className="dismiss ico ico-x" onClick={closeModal} />
          </span>
          {step === 1 && (
            <>
              {!countryCodeErr && lowercaseCountryCodes && (
                <>
                  <div className={styles.phone_number_input}>
                    <label htmlFor="phone_number">{t("Phone Number")}</label>
                    <PhoneInput
                      countryCodeEditable={false}
                      onChange={sendVerificationHandler}
                      inputStyle={{ width: "100%", height: "42px" }}
                      inputProps={{ autoFocus: true }}
                      country={
                        self && self.phoneCountry
                          ? self.phoneCountry.toLowerCase()
                          : "us"
                      }
                      onlyCountries={lowercaseCountryCodes}
                    />
                    {error.sendingCode && (
                      <div className="helper-text error">
                        {t("Please provide a valid phone number")}
                      </div>
                    )}
                  </div>
                  <Button
                    title={t("Send Confirmation Code")}
                    buttonType="primary"
                    buttonStyles={styles.confirmation_btn}
                    onClickFunc={handleSendVerificationCode}
                  />
                </>
              )}
            </>
          )}
          {step === 2 && (
            <>
              <div className={styles.title_wrapper}>
                <p>{t("We sent confirmation code to")}</p>
                <strong>{phoneInfo.phoneNormalized || null}</strong>
              </div>
              <div className={styles.phone_number_input}>
                <label htmlFor="confirmation_code">
                  {t("Confirmation Code")}
                </label>
                <input
                  type="text"
                  name="confirmation_code"
                  id="confirmation_code"
                  onChange={(e) => setCode(e.target.value)}
                />
                {error.sendingCode && (
                  <div className="helper-text error">
                    {t("Verification Code is invalid or expired.")}
                  </div>
                )}
                {error.somethingWentWrong && (
                  <div className="helper-text error">
                    {t("Something went wrong.")}
                  </div>
                )}
              </div>
              <p className={styles.resend_text}>
                {t("Didn't get the code? Retry in")}{" "}
                {!showResend && <TimerComponent onTimerEnd={handleTimerEnd} />}
                {showResend && (
                  <span
                    className={styles.resend_btn}
                    onClick={handleSendVerificationCode}
                  >
                    {t("Resend")}
                  </span>
                )}
              </p>
              <Button
                title={t("Confirm Code")}
                buttonType="primary"
                buttonStyles={styles.confirmation_btn}
                onClickFunc={confirmCodeHandler}
              />
            </>
          )}
          {step === 3 && (
            <>
              <img
                src={checkmark}
                alt="success"
                className={styles.success_image}
              />
              <p className={styles.title_wrapper}>
                {t("Phone Number was successfully verified!")}
              </p>
              <Button
                title={t("Done")}
                buttonType="secondary-outline"
                buttonStyles={styles.confirmation_btn}
                onClickFunc={closeModal}
              />
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default AccountPhoneVerificationModal;

const TimerComponent = ({ onTimerEnd }: { onTimerEnd: () => void }) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(intervalId);
          onTimerEnd(); // Call a function when timer ends
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return <span>{t("count_second", { count: seconds })}</span>;
};
