import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface PanelWrapperProps {
  children: React.ReactNode;
  classNames?: string;
}

const PanelWrapper = ({ children, classNames }: PanelWrapperProps) => {
  return <div className={cls(styles.container, classNames)}>{children}</div>;
};

export default PanelWrapper;
