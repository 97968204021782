import React, { useCallback, useMemo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import config from "config/environment";
import { SidebarProps } from "../index.types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { hasFeature } from "app/utils/hasFeature";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

const SidebarMenuList = ({ user }: SidebarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);
  // Directly call hasFeature here instead of using useCallback inside useMemo
  const hasSchedulingFeature = hasFeature(self?.features, "scheduling");

  const listData = useMemo(() => [
    {
      title: t("Feed"),
      icon: "ico-feed-o",
      route: "/",
      targetBlank: false,
      forAthlete: true,
    },
    {
      title: t("Spaces"),
      icon: "ico-tspace",
      route: "/spaces",
      targetBlank: false,
      forAthlete: true,
    },
    {
      title: t("Calendar"),
      icon: "ico-calendar",
      route: "/calendar",
      targetBlank: false,
      forAthlete: false,
      hasAccess: hasSchedulingFeature,
    },
    {
      title: t("Bookings"),
      icon: "ico-calendar",
      route: "/sessions",
      targetBlank: false,
      forAthlete: true,
      onlyAthlete: true,
      hasAccess: hasSchedulingFeature,
    },
    {
      title: t("Groups"),
      icon: "ico-team",
      route: "/groups",
      targetBlank: false,
      forAthlete: true,
    },
    {
      title: t("Lists"),
      icon: "ico-groups",
      route: "/lists",
      targetBlank: false,
      forAthlete: false,
    },
    {
      title: t("Library"),
      icon: "ico-library-o",
      route: "/resources",
      targetBlank: false,
      forAthlete: true,
    },
    {
      title: t("Templates"),
      icon: "ico-templates",
      route: "/space_templates",
      targetBlank: false,
      forAthlete: false,
    },
    {
      title: t("Schedule"),
      icon: "ico-time",
      route: "/schedule",
      targetBlank: false,
      forAthlete: false,
    },
    {
      title: t("Support & FAQ"),
      icon: "ico-heart",
      route: config.SUPPORT_URL,
      targetBlank: true,
      forAthlete: true,
    },
  ],
  [hasSchedulingFeature, t],
);

  const selectHandler = useCallback((val: string, isTargetBlank?: boolean) => {
    if (!isTargetBlank) {
      history.push(val);
    } else {
      window.open(val);
    }
  }, []);

  const checkActive = (route: string) => {
    if (route === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(route);
  };

  return (
    <>
      <div className={styles.sidebar_nav}>
        {listData.map((list, index) => {
          if (
            (Boolean(list?.hasAccess) && list.hasAccess !== undefined) ||
            list.hasAccess === undefined
          ) {
            if (user.kind === "coach" && !list.onlyAthlete) {
              return (
                <div
                  key={index}
                  className={styles.sidebar_nav_item}
                  onClick={() => selectHandler(list.route, list.targetBlank)}
                >
                  <div
                    className={cls(styles.sidebar_nav_item_wrapper, {
                      [styles.active]: checkActive(list.route),
                    })}
                  >
                    {list.icon && (
                      <i className={cls("ico", list.icon, styles.icon)}></i>
                    )}
                    {list.title}
                  </div>
                </div>
              );
            } else if (user.kind !== "coach" && list.forAthlete) {
              return (
                <div
                  key={index}
                  className={styles.sidebar_nav_item}
                  onClick={() => selectHandler(list.route, list.targetBlank)}
                >
                  <div
                    className={cls(styles.sidebar_nav_item_wrapper, {
                      [styles.active]: checkActive(list.route),
                    })}
                  >
                    {list.icon && (
                      <i className={cls("ico", list.icon, styles.icon)}></i>
                    )}
                    {list.title}
                  </div>
                </div>
              );
            }
          }
          return null;
        })}
      </div>
    </>
  );
};

export default SidebarMenuList;
