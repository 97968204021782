import { useState } from "react";

const useConfirmation = (renderFn) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [data, setData] = useState<{ actions?: any }>({});

  const requestConfirmation = (payload) => {
    setData(payload);
    setShowPrompt(true);
  };

  const onCancel = () => {
    setShowPrompt(false);
    setData({});
  };

  const decoratedActions = {};
  if (data.actions) {
    Object.keys(data.actions).forEach(actionName => {
      decoratedActions[actionName] = () => {
        data.actions[actionName](data);
        onCancel();
      };
    });
  }


  const PromptComponent = () => {
    if(!showPrompt) {
      return null;
    }

    return renderFn({ data, actions: { onCancel, ...decoratedActions } });
  };

  return { requestConfirmation, PromptComponent };
};

export default useConfirmation;
