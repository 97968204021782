import React from "react";
import styles from "./styles.module.scss";
import cls from "app/utils/classNames";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const ContentPanel = ({ children, className }: Props) => {
  return <div className={cls(styles.container, className)}>{children}</div>;
};

export default ContentPanel;
