import React, { useMemo } from "react";
import { GroupInvitationModel } from "./indexModel";
import GroupInviteMembersWith from "./GroupInviteMembersWith";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import InviteWithEmailModal from "./InviteWithEmailModal";
import GroupCreatedModal from "./GroupCreatedModal";
import InviteWithUrl from "./InviteWithUrl";
import InviteWithCsv from "./InviteWithCsv";

interface Props {
  defaultModalContent?: GroupInviteModalTypes;
}

const GroupInviteModal: React.FC<Props> = ({ defaultModalContent }) => {
  const {
    modalCloseHandler,
    modalInviteIsOpen,
    groupInviteModalsHandler,
    invitationModalType,
    onCreateGroupHandler,
    selectedGroup,
  } = GroupInvitationModel({ defaultModalContent });

  const modalContent = useMemo(() => {
    switch (invitationModalType) {
      case "group-created":
        return (
          <GroupCreatedModal
            isOpen
            onClose={modalCloseHandler}
            onSubmitHandler={onCreateGroupHandler}
          />
        );
      case "invite-members":
        return (
          <GroupInviteMembersWith
            isOpen
            onClose={modalCloseHandler}
            onSubmitHandler={groupInviteModalsHandler}
          />
        );
      case "invite-with-email":
        return <InviteWithEmailModal isOpen onClose={modalCloseHandler} />;
      case "invite-with-link":
        return selectedGroup ? (
          <InviteWithUrl
            id={selectedGroup.id}
            isOpen
            onClose={modalCloseHandler}
          />
        ) : null;
      case "invite-with-csv":
        return selectedGroup ? (
          <InviteWithCsv isOpen onClose={modalCloseHandler} />
        ) : null;
      default:
        return null;
    }
  }, [
    invitationModalType,
    selectedGroup,
    modalCloseHandler,
    groupInviteModalsHandler,
    onCreateGroupHandler,
  ]);

  return modalInviteIsOpen ? modalContent : null;
};

export default GroupInviteModal;
