import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { useParseCsvData } from "app/utils/hooks/useParseCsvData";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { CsvFileUploadProps } from "./index";

const useCsvFileUpload = ({
  onClose,
  onDataParsed,
  getFileOnUpload,
}: CsvFileUploadProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [choosenMedia, setChoosenMedia] = useState<MediaTypes>(null);
  const isMediaSelected = useSelector(
    (state: RootState) => state.mediaLibraryData.selectedMedia,
    shallowEqual,
  );

  const mediaSelectHandler = () => {
    setChoosenMedia(isMediaSelected);
    modalHandler();
  };

  const uploadRef = useRef(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target && (event.target.result as string);
        try {
          const parsedData = useParseCsvData(csvData);
          if (getFileOnUpload) {
            getFileOnUpload(file);
          }
          if (onDataParsed) {
            onDataParsed(parsedData);
          }
        } catch (err) {
          uploadError(err);
        }
      };

      reader.readAsText(file);
    }
  };

  const modalHandler = () => {
    onClose();
    setIsLoading(false);
    setUploadError(null);
    setProgress(0);
    if (uploadRef.current) {
      uploadRef.current.abort();
      uploadRef.current = null;
      setUploadError(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "document/doc": [".xls", ".xlxx", ".xlsx", ".xlsm", ".csv"],
    },
  });

  return {
    isLoading,
    uploadError,
    progress,
    getRootProps,
    getInputProps,
    isDragActive,
    modalHandler,
    isMediaSelected,
    mediaSelectHandler,
    choosenMedia,
    onDrop,
  };
};

export default useCsvFileUpload;
