/**
 * @module Actions.List
 *
 */
import { reject } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";
import { ListModel } from "app/models";

const fetchPending = () => action("@list.fetch.pending");

const fetchError = (err: string) => action("@list.fetch.error", err);

const fetchSuccess = (lists: ListModel[]) =>
  action("@list.fetch.success", lists);

export const paginatedData = (data: ListModel[]) =>
  action("@list.fetch.paginatedData", data);

export const currentPage = (currentPage: number) =>
  action("@list.fetch.currentPage", currentPage);

export const showPerPage = (showPerPage: number) =>
  action("@list.fetch.showPerPage", showPerPage);

export const hasMore = (hasMore: boolean) =>
  action("@list.fetch.hasMore", hasMore);

export const clearList = () => action("@list.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  paginatedData,
  currentPage,
  showPerPage,
  hasMore,
  clearList,
};

export type ListAction = ActionType<typeof thisActions>;

export function fetchLists(
  params?: { [key: string]: any },
  signal?: AbortSignal,
) {
  return (dispatch: Dispatch<ListAction>) => {
    dispatch(fetchPending());

    return fetchGet<ListModel>("segments", params || {}, {}, signal)
      .then((model: ListModel[]) => {
        dispatch(fetchSuccess(model));
        return model;
      })
      .catch((err) => {
        dispatch(fetchError(err));
        return reject(err);
      });
  };
}

export const actions = {
  fetchLists,
};
