import React from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import SchedulePostingForm from "./SchedulePostingForm";
import { ScheduleTimelineModel } from "./indexModel";
import ScheduleFilter from "app/components/ScheduledPosts/Filter";
import { cls } from "app/utils";
import ScheduleCalendar from "app/components/ScheduledPosts/Calendar";
import ScheduledPagePostsContainer from "app/components/ScheduledPagePostsContainer";
import PagePanel from "app/components/PagePanel";
import { useTranslation } from "react-i18next";

const Schedule = () => {
  const { t } = useTranslation();
  const {
    handleSelectCalendar,
    onResetClick,
    selectedCalendarDate,
    onSearchSubmit,
    onSelectFilterLocation,
    data,
    loading,
    onPostDelete,
    onPostUpdate,
    onPost,
    sentryRef,
  } = ScheduleTimelineModel();

  return (
    <div className={cls("schedule-container")}>
      <GettingStartedProgress />
      <div className={styles.container}>
        <div className={styles.timeline_wrapper}>
          <div className={styles.timeline_container}>
            <PagePanel title={t("Scheduled Posts")} />
            <SchedulePostingForm onPost={onPost} />
            <ScheduleFilter
              onSubmit={onSelectFilterLocation}
              onReset={onResetClick}
              onSearchSubmit={onSearchSubmit}
              onSelectedCalendarDateRemove={handleSelectCalendar}
              selectedCalendarDate={selectedCalendarDate}
            />
            <div className={styles.timeline_posts_content}>
              <ScheduledPagePostsContainer
                displayPostLocations
                onPostDelete={onPostDelete}
                onPostUpdate={onPostUpdate}
                containerRef={sentryRef}
                posts={data}
                pending={loading}
                hideReply
                hideLikes
              />
            </div>
          </div>
          <div
            className={cls(
              styles.scheduled_calendar_container,
              "desktop-only-large schedule-calendar"
            )}
          >
            <ScheduleCalendar
              onChange={handleSelectCalendar}
              scheduledPostsCount={data?.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
