/**
 * @module Model.LessonType
 *
 */
import { Model } from "typedefs";

export interface LessonTypeResponse {
  id: number;
  name: string;
  order: number;
}

export class LessonTypeModel implements Model {
  id: number;
  name: string;
  order: number;

  constructor(lessonType: LessonTypeResponse) {
    this.id = lessonType.id;
    this.name = lessonType.name;
    this.order = lessonType.order;
  }
}
