import React from "react";

import { Alert as AlertModel } from "app/models/AlertModel";
import Alert from "../Alert";
import styles from "./styles.module.scss";
import { v4 } from "uuid";

interface AlertContainerProps {
  alerts: AlertModel[];
  setExpired(id: string): void;
}

const AlertContainer = (props: AlertContainerProps) => {
    return (
        <div className={styles.container}>
            {
                props.alerts
                    .filter((alert) => !alert.expired)
                    .map((alert) => (
                        <Alert
                          key={v4()}
                          alert={alert}
                          setExpired={props.setExpired}
                        />
                    ))
            }
        </div>
    );
};

export default AlertContainer;
