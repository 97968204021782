/**
 * @module Actions.SubscriptionPlan
 *
 */
import { Dispatch } from "react";
import { action, ActionType } from "typesafe-actions";
import { RequestError } from "typedefs";
import { SubscriptionModel } from "app/models";
import { fetchGet, fetchPost } from "app/utils/request";
import { AnalyticsService } from "app/services";

const pending = () => action("@subscription.fetch.pending");
const error = (error: RequestError) =>
  action("@subscription.fetch.error", error);
const success = (data: SubscriptionModel) =>
  action("@subscription.fetch.success", data);

export const clearSubscription = () => action("@subscription.fetch.clear");

const actionHandlers = { pending, error, success, clearSubscription };

export type SubscriptionAction = ActionType<typeof actionHandlers>;

export const startTrial = () => {
  return (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch(pending());

    return fetchPost("subscriptions/trial", {}, { version: 1 })
      .then((data: { subscriptions: SubscriptionModel[] }) => {
        AnalyticsService.track("Trial Started", { Source: "Web" });
        dispatch(success(data.subscriptions[0]));
      })
      .catch((err) => dispatch(error(err)));
  };
};

export const fetchSubscriptions = () => {
  return (dispatch: Dispatch<SubscriptionAction>) => {
    dispatch(pending());

    return fetchGet("account/paid_subscriptions", {}, { version: 1 })
      .then((data) => dispatch(success(data)))
      .catch((err) => dispatch(error(err)));
  };
};

export const actions = {
  startTrial,
};
