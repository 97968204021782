/**
 * @module Model.AvailableSlotModel
 *
 */
import { Model } from "typedefs";
import { DateTime } from 'luxon';

export interface AvailableSlotResponse extends Model {
  startHour: string;
  endHour: string;
  rate: number;
  timeZone: string;
  timeZoneDisplay: string;
  duration: number;
  currency: string;
  coachProfileId: string;
}

export class AvailableSlotModel implements Model {
  startHour: DateTime;
  endHour: DateTime;
  rate: number;
  timeZone: string;
  timeZoneDisplay: string;
  duration: number;
  currency: string;
  coachProfileId: string;
  timeId: number;

  constructor(slot: AvailableSlotResponse) {
    this.rate = slot.rate;
    this.timeZone = slot.timeZone;
    this.timeZoneDisplay = slot.timeZoneDisplay;
    this.duration = slot.duration;
    this.currency = slot.currency;
    this.coachProfileId = slot.coachProfileId;

    this.startHour = DateTime.fromISO(slot.startHour).setZone(
      slot.timeZone
    );

    this.endHour = DateTime.fromISO(slot.endHour).setZone(
      slot.timeZone
    );

    this.timeId = this.startHour.toMillis();
  }
}
