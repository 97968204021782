/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { getMe } from "app/actions/groups";
import {
  fetchScheduledPosts,
  clearSchedulePost,
  scheduledPosts,
} from "app/actions/schedule";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deletePost, editPost } from "app/actions/postingForm";
import { allowMultipleSelect } from "app/actions/destinations";
import useInfiniteScroll from "react-infinite-scroll-hook";
import PostModel from "app/models/PostModel";
import { RootState } from "typedefs";

export const ScheduleTimelineModel = () => {
  const dispatch = useDispatch();
  const postsActions = useActions({
    getMe,
    fetchScheduledPosts,
    clearSchedulePost,
    editPost,
    deletePost,
  });

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const [defaultPostsParams] = useState({
    "options[include]": ["posts"],
    page: 1,
    perPage: 20,
    orderBy: "scheduled_at",
    order: "asc",
    // timezoneOffset: moment().utcOffset().toString(),
  });

  const abortControllerRef = useRef<AbortController | null>(null);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [postsParams, setPostsParams] = useState<any>(defaultPostsParams);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<
    string | null
  >(null);

  const fetchPage = async (query: { page?: number; perPage?: number }) => {
    setLoading(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      const res = await postsActions.fetchScheduledPosts(
        query,
        abortControllerRef.current.signal,
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData: any) => [...prevData, ...res]);
          } else {
            setData(res);
          }

          if (res?.length < query?.perPage) {
            setHasMore(false);
          }
          setPostsParams({
            ...query,
            page: query?.page + 1,
          });
        } else {
          console.error("API response is null or undefined");
        }
      }
      setLoading(false);
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
      setLoading(false);
    }
  };

  const initialLoadPosts = (query?: {}) => {
    setData([]);
    setHasMore(true);
    fetchPage(query || defaultPostsParams);
  };

  const loadMore = () => {
    if (!loading) {
      fetchPage(postsParams);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  // initial fetching
  useEffect(() => {
    initialLoadPosts();
    // posting form locations multiple select ability
    dispatch(allowMultipleSelect(true));
    return () => {
      dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setHasMore(true);
      postsActions.clearSchedulePost();
    };
  }, [dispatch, postsActions]);

  const handleSelectCalendar = (date?: string | null) => {
    if (!date) {
      // const { scheduledAt, ...restParams } = postsParams;
      setSelectedCalendarDate(null);
      initialLoadPosts({ ...postsParams, scheduledAt: null, page: 1 });
      return;
    }

    const formattedDate = new Date(date).toISOString();
    setSelectedCalendarDate(date);

    initialLoadPosts({
      ...postsParams,
      scheduledAt: formattedDate,
      page: 1,
    });
  };

  const onSearchSubmit = (searchValue: string) => {
    if (!searchValue) {
      initialLoadPosts({ ...postsParams, query: null, page: 1 });
      return;
    }
    initialLoadPosts({ ...postsParams, page: 1, query: searchValue });
  };

  const onSelectFilterLocation = (location: {
    scheduleTargetIds: string[];
  }) => {
    if (!location || !location.scheduleTargetIds) {
      const { scheduleTargetIds, ...restParams } = postsParams;
      setPostsParams(restParams);
      initialLoadPosts({
        ...restParams,
      });
      return;
    }

    initialLoadPosts({
      ...postsParams,
      page: 1,
      scheduleTargetIds: location.scheduleTargetIds,
    });
  };

  const onResetClick = () => {
    setPostsParams(defaultPostsParams);
    setSelectedCalendarDate(null);
    initialLoadPosts();
  };

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?",
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          setData(
            data.filter(
              (filteredPost: PostModel) => filteredPost.id !== post.id,
            ),
          );
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post);
    }
  };

  const onPost = (post: PostModel) => {
    setData([{ ...post, userAvatar: self?.avatar }, ...data]);
  };

  return {
    data,
    handleSelectCalendar,
    selectedCalendarDate,
    onSearchSubmit,
    onSelectFilterLocation,
    onResetClick,
    onPostDelete,
    onPostUpdate,
    onPost,
    sentryRef,
    loading,
  };
};
