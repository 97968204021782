import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import AddToRecruitProfile from "app/components/Button/AddToRecruitProfile";
import movePostIcon from "../../../../../public/images/move-post-icon.svg";
import copyPostIcon from "../../../../../public/images/copy-paste-icon.svg";
import editIcon from "../../../../../public/images/edit-icon.svg";
import tagsIcon from "../../../../../public/images/tags-icon.svg";
import pinIcon from "../../../../../public/images/pin-icon-vector.svg";
import unpinIcon from "../../../../../public/images/unpin-icon-vector.svg";
import reportIcon from "../../../../../public/images/Alert.svg";
import deleteIcon from "../../../../../public/images/trash-icon.svg";
import PostModel from "app/models/PostModel";
import {
  canAddToRecruit,
  canCopy,
  canDelete,
  canEdit,
  canMove,
  planCanPin,
} from "../../Post";
import { cls } from "app/utils";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import PinnedPostsPaywall from "app/components/paywalls/PinnedPosts";

interface MenuItem {
  label?: string;
  icon?: string;
  className?: string;
  permission?: boolean;
  onClick?: (post: PostModel) => void;
  children?: React.ReactNode;
  hide?: boolean;
}

interface Props {
  props: PostModel;
  hideButtonStyles?: boolean;
  buttonClassName?: string;
  isReply?: boolean;
  onMove?: () => void;
  onCopy?: () => void;
  onEdit?: () => void;
  onEditTags?: () => void;
  onPin?: () => void;
  onDelete?: (post: PostModel) => void;
  onReport?: (post: PostModel) => void;
  dropdownClassName?: string;
  canPinPost?: boolean;
}

const PostMenu = ({ props, ...rest }: Props) => {
  const { canPinPost = true } = rest;
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [showModal, setPaywallModal] = useState(false);

  const isMainFeed = /^\/$/.test(window.location.pathname);
  console.log(canPinPost);

  const onPaywallOpen = useCallback(() => {
    setPaywallModal(true);
  }, []);

  const onPaywallClose = useCallback(() => {
    setPaywallModal(false);
  }, []);

  if (!self) {
    return null;
  }

  const menuList: MenuItem[] = [
    {
      children: <AddToRecruitProfile />,
      permission: canAddToRecruit(props) && !rest.isReply,
    },
    {
      label: t("Move to") + " ...",
      icon: movePostIcon,
      permission: canMove(props) && !rest.isReply,
      onClick: () => {
        rest.onMove();
      },
    },
    {
      label: t("Copy to") + " ...",
      icon: copyPostIcon,
      permission: canCopy(props) && !rest.isReply,
      onClick: () => {
        rest.onCopy();
      },
    },
    {
      label: t("Edit"),
      icon: editIcon,
      permission: canEdit(props),
      onClick: () => {
        rest.onEdit();
      },
    },
    {
      label: t("Edit Tags"),
      icon: tagsIcon,
      permission: canEdit(props) && !rest.isReply,
      onClick: () => {
        rest.onEditTags();
      },
      hide: rest.isReply,
    },
    {
      label: props && props.pinnedByName ? t("Unpin") : t("Pin to top"),
      icon: props && props.pinnedByName ? unpinIcon : pinIcon,
      permission: !rest?.isReply && !isMainFeed && !props?.scheduledAt,
      onClick: () => {
        if (planCanPin(props)) {
          rest.onPin();
        } else {
          onPaywallOpen();
        }
      },
      hide: !canPinPost,
    },
    {
      label: t("Report"),
      icon: reportIcon,
      permission: self.id !== props.userId,
      onClick: (post: PostModel) => {
        rest.onReport(post);
      },
    },
    {
      label: t("Delete"),
      icon: deleteIcon,
      className: styles.delete_btn,
      permission: canDelete(props),
      onClick: (post) => {
        rest.onDelete(post);
      },
    },
  ];

  return (
    <DropdownButton
      btnContent={<i className="bullets" />}
      btnClassName={rest.buttonClassName}
      hideButtonStyles={rest.hideButtonStyles}
      dropdownWrapperClass={cls(
        styles.dropdown_container,
        rest.dropdownClassName,
      )}
      closeOnClick
    >
      <div className={styles.container}>
        {menuList.map((list, index) => {
          if (list.permission && !list.hide) {
            return list.children ? (
              <React.Fragment key={index}>{list.children}</React.Fragment>
            ) : (
              list.onClick && list.permission && (
                <button key={list.label} onClick={() => list.onClick(props)}>
                  <img src={list.icon} alt={list.label} />
                  <p className={cls(list.className)}>{list.label}</p>
                </button>
              )
            );
          } else {
            return null;
          }
        })}
      </div>
      <PinnedPostsPaywall show={showModal} onClose={onPaywallClose} />
    </DropdownButton>
  );
};

export default PostMenu;
