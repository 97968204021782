/**
 * @module Reducers.DemoSpace
 *
 */
import { RequestError } from "typedefs";
import {
  DemoSpaceAction,
  DemoSpaceMap,
  DemoSpaceModel,
  fetchDemoSpaceAction,
} from "app/actions/DemoSpace";
import { mergeMaps } from "app/utils/HashMap";

export type DemoSpaceState = {
  selectedDemoSpace: DemoSpaceModel | null;
  data: DemoSpaceMap | null;
  error: RequestError | null;
  pending: boolean;
  fetchUpdate: typeof fetchDemoSpaceAction | null;
};

const initialState: DemoSpaceState = {
  selectedDemoSpace: null,
  data: null,
  error: null,
  pending: false,
  fetchUpdate: null,
};

export default function dempSpaceReducer(
  state = initialState,
  action: DemoSpaceAction
): DemoSpaceState {
  switch (action.type) {
    case "@demo-space.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@demo-space.fetch.success":
      return {
        ...state,
        data: mergeMaps(state.data, action.payload.model),
        fetchUpdate: action.payload.updater,
        pending: false,
      };
    case "@demo-space.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@demo-space.selected":
      return { ...state, selectedDemoSpace: action.payload, pending: false };

    default:
      return state;
  }
}
