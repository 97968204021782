/**
 * @module Routes.Unsubscribe
 *
 */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { fetchGet, fetchPut } from 'app/utils/request';
import { Public } from 'app/components/Wrappers';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = RouteComponentProps<{
  spaceId: string;
  token: string;
}>;

type State = {
  error: string | null;
  pending: boolean;
  data: any;
};

const initialState: State = {
  error: null,
  pending: true,
  data: null,
};

/**
 * @class Space
 *
 */
export default function User(props: PropsDef) {
  const { t } = useTranslation();
  const { spaceId, token } = props.match.params;
  const url = `spaces/${spaceId}/user_settings?token=${token}`;
  const [ state, setState ] = useState(initialState);

  const saveSettings = (data: any) => {
    setState({ ...state, pending: true });

    fetchPut(url, { user_settings: state.data }, { version: 1, noAuth: true })
      .then(() => setState({ ...state, data, pending: false }))
      .catch(() => setState({ ...state, pending: false, error: t('Due to server error we were not able to save your changes') }));
  };

  const unsubscribe = () => {
    const data = { ...state.data, emailNotificationsEnabled: false };
    saveSettings(data);
  };

  const subscribe = () => {
    const data = { ...state, emailNotificationsEnabled: true };
    saveSettings(data);
  };

  useEffect(() => {
    const params = { "options[include]": ['space'] };
    fetchGet(url, params, { version: 1, noAuth: true })
      .then((settings) => setState({ ...state, data: settings, pending: false }))
      .catch(() => setState({ ...state, pending: false, error: t('Due to server error we were not able to save your changes')  }));
  }, [ spaceId ]);

  useEffect(() => {
    if (state.data && state.data.space) {
      // unsubscribe();
    }
  }, [ state.data ]);


  let message = (
    <h3 className="text-center">
      {t('Please, wait...')}
    </h3>
  );

  if (!state.pending && state.error) {
    message = (
      <h3 className="text-danger text-center">
        {state.error}
      </h3>
    );
  } else if (!state.pending && !state.error) {
    if (state.data != null) {
      if (state.data.emailNotificationsEnabled) {
        message = (
          <div>
            <h3 className="text-success text-center">
              {t('You are now subscribed to email notifications')}
            </h3>

            <p className="text-center">
              <button className="btn btn-primary" onClick={unsubscribe}>
                {t('Unsubscribe')}
              </button>
            </p>
          </div>
        );
      } else {
        let subMess = (<span></span>);
        const values = { name: state.data.space.ownerName, sport: state.data.space.sport };
        if (state.data.space.type.toLowerCase() === 'athlete') {
          subMess = (
            <span>
              {t("for {{name}} / {{sport}} Training Space", values)}
            </span>
          );
        } else if (state.data.space.type.toLowerCase() === 'team') {
          subMess = (
            <span>
              {t('for {{name}} / {{sport}} Training Team', values)}
            </span>
          );
        }

        message = (
          <div>
            <h3 className="text-success text-center">
              {t('You have unsubscribed from email notifications')}&nbsp;{subMess}
            </h3>

            <p className="text-center">
              <button className="btn btn-large btn-primary" onClick={subscribe}>
                {t('Subscribe me again!')}
              </button>
            </p>
          </div>
        );
      }
    }
  }

  return (
    <Public isLoading={false}>
      <div className={Styles.unsubscribeMain}>
        <div className="panel">
          {message}
        </div>
      </div>
    </Public>
  );
}
