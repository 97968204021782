import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Button from "../Button/ButtonVariant";
import { uuid } from "app/utils/uuid";

interface Props {
  title?: string;
  onAddUser: (newUser: {
    name: string;
    email: string;
    user_id: string;
  }) => void;
  onCancel: () => void;
  searchInput?: string;
}

const AddNewUser = ({ title, onAddUser, onCancel, searchInput }: Props) => {
  const { t } = useTranslation();

  const isEmail = (input: string) => {
    return input.includes("@");
  };

  const isInputEmail = searchInput && isEmail(searchInput);

  const [name, setName] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (searchInput && !isInputEmail) {
      setName(searchInput);
      setEmail(null);
    } else if (searchInput && isInputEmail) {
      setName(null);
      setEmail(searchInput);
    } else {
      setName(null);
      setEmail(null);
    }

    return () => {
      setEmail(null);
      setName(null);
    };
  }, [searchInput, isInputEmail]);

  const handleAddUser = () => {
    onAddUser({ name, email, user_id: uuid() });
    setName("");
    setEmail("");
    onCancel();
  };

  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.input_container}>
        <input
          type="text"
          name="name"
          id="name"
          placeholder={t("Name")}
          spellCheck={false}
          autoComplete="none"
          onChange={handleNameChange}
          value={name}
        />
        <input
          type="email"
          name="Email"
          id="Email"
          placeholder={t("Email")}
          spellCheck={false}
          autoComplete="none"
          onChange={handleEmailChange}
          value={email}
        />
      </div>
      <div className={styles.button_wrapper}>
        <Button
          title={t("Add")}
          buttonType="primary"
          onClickFunc={handleAddUser}
        />
        <Button
          title={t("Cancel")}
          buttonType="secondary-outline"
          onClickFunc={onCancel}
        />
      </div>
    </div>
  );
};

export default AddNewUser;
