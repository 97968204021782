/**
 * @module Components.Routes
 *
 */
import React, { FormEvent, useState } from "react";
import { cls } from "app/utils";
import { Link } from "react-router-dom";
import { fetchPost } from "app/utils/request";
import { Wrapper } from "app/components/Onboarding";
import { Unauthenticated } from "app/components/Wrappers";
import FormItem from "app/components/inputs/FormItem";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  expired?: boolean;
}

interface IState {
  resetError: string | null;
  successPage: boolean;
  inputVal: string;
  pending: boolean;
}

interface FormElements extends HTMLCollection {
  resetPassword: HTMLInputElement;
}

const initState: IState = {
  resetError: null,
  successPage: false,
  inputVal: "",
  pending: false,
};

/**
 * @class Reset
 *
 */
function Reset(props: IProps) {
  const { t } = useTranslation();
  const [state, setState] = useState(initState);

  const title = props.expired
    ? t(
        "This password reset link has expired or is invalid. Enter your email again and we'll send you a new reset link."
      )
    : t("Forgot Password?");
  const subtitle = props.expired
    ? t(
        "This password reset link has expired or is invalid. Enter your email again and we'll send you a new reset link."
      )
    : t(
        "Enter your email address and we'll send you a link to reset your password."
      );
  const resetButton = props.expired
    ? t("Send Password Reset Link")
    : t("Send Reset Password Email");

  const placeholderText = t("Email");

  const form = (
    <div
      className={cls(styles.mainWrapper, props.expired ? styles.expired : "")}
    >
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.subtitle}>{subtitle}</p>

      <form
        noValidate={true}
        onSubmit={(e) => sendReset(e, state, setState, t)}
        target="_self"
      >
        <FormItem error={state.resetError}>
          <input
            name="resetPassword"
            type="email"
            placeholder={placeholderText}
          />
        </FormItem>

        <FormItem>
          <button className="btn btn-primary full" disabled={state.pending}>
            {resetButton}
          </button>
        </FormItem>
      </form>

      <FormItem className={styles.link}>
        <Link to="/signin">{t("I know my password")}</Link>
      </FormItem>
    </div>
  );

  const sent = (
    <div className={styles.mainWrapper}>
      <h1 className={styles.sentTitle}>{t("Reset Link Sent")}</h1>
      <p className={styles.subtitle}>
        {t("An email has been sent to you containing a password reset link.")}
      </p>

      <FormItem className={styles.link}>
        <a onClick={() => returnToReset(state, setState)}>
          {t("Didn't get the email?")}
        </a>
      </FormItem>

      <FormItem className={styles.link}>
        <Link to="/signin">{t("Back to Sign In")}</Link>
      </FormItem>
    </div>
  );

  let page = form;
  if (state.successPage) {
    page = sent;
  }

  return (
    <Unauthenticated>
      <Wrapper>{page}</Wrapper>
    </Unauthenticated>
  );
}

const sendReset = (
  e: FormEvent<HTMLFormElement>,
  state: IState,
  setState: (s: IState) => void,
  t: (key: string) => string
): void => {
  e.preventDefault();

  const els = e.currentTarget.elements as FormElements;
  const email = els.resetPassword.value.trim();

  if (!(email && email.length)) {
    // missing email error
    setState({
      ...state,
      pending: false,
      resetError: t("Please enter your email address."),
    });
    return;
  }

  // reset errors
  state = { ...state, resetError: null, pending: true };
  setState(state);

  fetchPost("account/password", { email }, { version: 1 })
    .then(() =>
      setState({
        ...state,
        pending: false,
        resetError: null,
        successPage: true,
      })
    )
    .catch((err) =>
      setState({ ...state, pending: false, resetError: handleError(err) })
    );
};

const returnToReset = (state: IState, setState: (s: IState) => void): void => {
  setState({ ...state, pending: false, successPage: false });
};

const handleError = (err: any): string => {
  const { t } = useTranslation();
  if (err && err.errors) {
    if (err.errors[0] === "email_not_found") {
      return t("Email doesn't match any accounts on file.");
    }
  }
  return t("An unknown error occurred. Please try again.");
};

export default Reset;
