/**
 * @module Reducers.Space
 *
 */
import { RequestError } from "typedefs";
import { SmartListAction } from "app/actions/smartList";

export type SmartListState = {
  smartListsData: any | null;
  selectedListValue: {
    name: string;
    id: string;
    object: string | null;
  } | null;
  smartListsError: RequestError | null;
  pending: boolean;
};

const initialState: SmartListState = {
  smartListsData: null,
  selectedListValue: {
    name: "All spaces",
    object: "athlete_space",
    id: "1",
  },
  smartListsError: null,
  pending: false,
};

export default function spaceReducer(
  state = initialState,
  action: SmartListAction
): SmartListState {
  switch (action.type) {
    case "@smartList.fetch.pending":
      return { ...state, smartListsError: null, pending: true };
    case "@smartList.fetch.smartListsSuccess":
      return { ...state, smartListsData: action.payload, pending: false };
    case "@smartList.fetch.selectedListValue":
      // @ts-ignore
      return { ...state, selectedListValue: action.payload, pending: false };
    case "@smartList.clear":
      return {
        ...state,
        smartListsData: null,
        selectedListValue: {
          id: null,
          name: "All spaces",
          object: "athlete_space",
        },
        smartListsError: null,
        pending: false,
      };

    default:
      return state;
  }
}
