import React, { useState } from "react";
import { MessageDescriptor } from "react-intl";
import { ReactStripeElements } from "react-stripe-elements";
import FormItem from "app/components/inputs/FormItem";
import styles from "./styles.module.scss";
import { states } from "./states";
import { useTranslation } from 'react-i18next';

type ElResponse = ReactStripeElements.ElementChangeResponse & {
  target?: EventTarget;
};

type PropsDef = {
  value?: string;
  placeholder?: MessageDescriptor;
  onChange?: (e: ElResponse) => void;
  onFocus?: (e: ElResponse) => void;
  onBlur?: (e: ElResponse) => void;
  error?: MessageDescriptor | string | null;
  disabled?: boolean;
  readOnly?: boolean;
};

function UsState(props: PropsDef) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    props.value
  );

  const modifyEvent = (
    e: React.FormEvent<HTMLSelectElement>,
    callback: (e: ElResponse) => void
  ) => {
    const newValue = e.currentTarget.value;
    setSelectedValue(newValue);
    if (callback) {
      callback({
        elementType: "usState",
        complete: true,
        empty: false,
        brand: "Unknown",
        value: newValue,
        target: e.currentTarget,
      });
    }
  };

  return (
    <FormItem className={styles.formControl} error={props.error}>
      <select
        className={styles.select_states}
        onChange={(e) => modifyEvent(e, props.onChange)}
        onFocus={(e) => modifyEvent(e, props.onFocus)}
        onBlur={(e) => modifyEvent(e, props.onBlur)}
        value={selectedValue}
        disabled={props.disabled}
        placeholder={t("Select state")}
      >
        {!selectedValue && <option value="">{t("Select state")}</option>}
        {states.map((state) => (
          <option key={state.abbreviation} value={state.abbreviation}>
            {state.name}
          </option>
        ))}
      </select>
    </FormItem>
  );
}

export default UsState;
