import React from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import DropdownButton from "app/components/DropdownButton";
import TabButton from "app/components/Button/TabButton";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import GroupSettings from "./GroupSettings";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const GroupTabsContainer = () => {
  const { t } = useTranslation();
  const url = window.location.pathname;
  const { selectedGroup } = useSelector((state: RootState) => state.groups, shallowEqual);
  const history = useHistory();

  const groupId = selectedGroup ? selectedGroup.id : null;

  const onMembersClick = () => {
    if (!url.includes("members")) {
      history.push(`${window.location.pathname}/members`);
    }
  };

  const onTimelineClick = () => {
    if (url.includes("members")) {
      history.push(`/groups/${groupId}`);
    }
  };

  const headerDropdown = (
    <DropdownButton
      btnClassName={styles.dropdown_btn_className}
      btnContent={
        <i className="dropdown-toggle btn btn-outline-secondary ico ico-cog dark" />
      }
    >
      <GroupSettings />
    </DropdownButton>
  );

  return (
    <div className={styles.container}>
      <PagePanel
        panelClassName={styles.panel}
        title={t("General")}
        titleStyles={cls("text-muted", styles.page_panel_title)}
        headerChildren={headerDropdown}
      >
        <div className={styles.navbar_wrapper}>
          <TabButton
            label={t("Timeline")}
            icon={<i className={cls("tab-icon ico ico-feed-o")} />}
            isActive={!window.location.pathname.includes("members")}
            onClick={onTimelineClick}
            className={styles.mobile}
          />
          <TabButton
            label={t("Members")}
            icon={<i className={cls("tab-icon ico ico-tspace-o")} />}
            isActive={window.location.pathname.includes("members")}
            onClick={onMembersClick}
            className={styles.mobile}
          />
        </div>
      </PagePanel>
    </div>
  );
};

export default GroupTabsContainer;
