/**
 * @module Components.Filter
 *
 */
import React, { useEffect, useState, useRef } from "react";
import { useStoreActions } from "app/utils/hooks";
import { cls } from "app/utils";
import { fetchTeams } from "app/actions/team";
import { TeamModel } from "app/models";
import CheckBox from "app/components/inputs/CheckBox/Base";
import SearchBar from "app/components/inputs/SearchBar";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  selectedTeams: any;
  onSubmit: (value: any, objectTypes?: any) => void;
};

const initTeams: TeamModel[] = [];
const OBJECT_TYPES = ["team", "team_group"];

/**
 * Renders a select drop down prefilled with spaces to select from
 *
 * @name GroupsSelect
 * @param {Properties} props
 * @return {Element}
 */
export default function GroupsSelect(props: PropsDef) {
  const { t } = useTranslation();
  // fetch spaces list from api
  const { team } = useStoreActions({
    team: () => fetchTeams({ "options[include]": ["team_groups"] }),
  });
  const [teams, setTeams] = useState(initTeams);
  const [search, setSearch] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (team.data != null) {
      setTeams(team.data);
    }

    if (props.selectedTeams) {
      setSelectedTeams(
        props.selectedTeams.filter((target: any) =>
          OBJECT_TYPES.includes(target.object),
        ),
      );
    }
  }, [team.data, props.selectedTeams]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (search && search.length) {
      const filter = team.data.filter((group: TeamModel) => {
        const regex = new RegExp(search, "i");
        const channelNames = Array.from(
          group.teamGroups || [],
          (channel) => channel.name,
        ).toString();

        if (
          regex.test(channelNames) ||
          regex.test(group.name) ||
          regex.test(group.sport)
        ) {
          return true;
        }

        return false;
      });

      setTeams(filter);
    } else {
      setTeams(team.data || []);
    }
  }, [search]);

  const updateSearch = (value: string) => {
    value !== null && value.length > 0 ? setSearch(value) : setSearch("");
  };

  const handleClickOutside = (e: any) => {
    if (
      dropdownOpened &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setDropdownOpened(false);
      setSearch("");
    }
  };

  const toggleDropdown = () => {
    setDropdownOpened(!dropdownOpened);

    if (!dropdownOpened) {
      setSearch("");
    }
  };

  const onSubmit = () => {
    toggleDropdown();
    props.onSubmit(selectedTeams, OBJECT_TYPES);
  };

  const toggleTeamClick = (team: any) => {
    if (selectedTeams.includes(team)) {
      setSelectedTeams((selectedTeams) =>
        selectedTeams.filter((_team) => _team.id !== team.id),
      );
    } else {
      setSelectedTeams([...selectedTeams, team]);
    }
  };

  return (
    <div ref={dropdownRef} className={cls(styles.dropdown)}>
      <button
        onClick={toggleDropdown}
        className={cls(
          styles.filterButton,
          dropdownOpened || selectedTeams.length > 0 ? styles.active : "",
        )}
      >
        <i className={cls("ico", "ico-team", styles.icon)}></i>
        <span className={styles.buttonCaption}>
          {t("count_group", {
            count: selectedTeams.length,
          })}
        </span>
        <i className={cls("ico", "ico-chevron", styles.chevron)}></i>
      </button>

      {dropdownOpened && (
        <div className={styles.dropdownBody}>
          <SearchBar
            className={styles.search}
            placeholder={t("Search groups...")}
            onChange={updateSearch}
          />

          <div className={styles.dropdownOptions}>
            {teams.map((team) => (
              <div key={team.id}>
                <div
                  className={styles.dropdownItem}
                  onClick={() => toggleTeamClick(team)}
                >
                  <img src={team.logo} width="44" height="44" />
                  <div className={styles.name}>
                    {team.name}
                    <div className={styles.sport}>{team.sport}</div>
                  </div>

                  <CheckBox
                    className={styles.checkbox}
                    disabled={true}
                    checked={selectedTeams.indexOf(team) > -1}
                  />
                </div>

                {Number(team.teamGroupsCount) > 0 &&
                  team.teamGroups
                    .filter((channel) =>
                      search
                        ? new RegExp(search, "i").test(channel.name)
                        : true,
                    )
                    .map((channel) => (
                      <div key={channel.id}>
                        <div className={styles.channel}></div>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => toggleTeamClick(channel)}
                        >
                          {/* <img src={team.logo} width="44" height="44"/> */}
                          <i className="ico ico-team" />
                          <div className={styles.name}>
                            {channel.name}
                            <div className={styles.sport}>{t("Channel")}</div>
                          </div>

                          <CheckBox
                            className={styles.checkbox}
                            disabled={true}
                            checked={selectedTeams.indexOf(channel) > -1}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            ))}
          </div>

          <div className={styles.buttonsContainer}>
            <button
              onClick={toggleDropdown}
              className={cls("btn", "btn-outline-secondary")}
            >
              {t("Channel")}
            </button>

            <button onClick={onSubmit} className={cls("btn", "btn-primary")}>
              {t("Filter")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
