/** @module components/Layouts */
import React from 'react';
import { cls } from 'app/utils';
import { isArray } from 'app/utils/array';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PropsDef = React.PropsWithChildren<{
  className?: string;
  text?: string | React.ReactNodeArray;
  subtext?: string | React.ReactNodeArray;
  image?: string;
}>;

const handleNodeArray = (node: string | React.ReactNode, index: number) => {
  return (<span key={index}>{node}</span>);
};

const normalizeText = (text: string | React.ReactNodeArray) => {
  if (text != null) {
    if (isArray(text)) {
      return (text as React.ReactNodeArray).map(handleNodeArray);
    }
    return (text as string);
  }
  return "";
};

export default function NoResults(props: PropsDef) {
  const { t } = useTranslation();
  let image: JSX.Element = (<div className={styles.hidden} />);
  if (props.image && props.image.length) {
    image = (
      <div className={styles.image}>
        <img src={props.image} />
      </div>
    );
  }

  let subtext: JSX.Element = (<div className={styles.hidden} />);
  if (props.subtext) {
    subtext = (<div className={styles.subtext}>{normalizeText(props.subtext)}</div>);
  }

  // @ts-ignore
  let text: JSX.Element = (<div className={styles.text}>{t("No results found")}</div>);
  if (props.text != null) {
    text = (<div className={styles.text}>{normalizeText(props.text)}</div>);
  }

  return (
    <div className={cls(styles.noResults, props.className)}>
      <div className="well well-nothing">{image}{text}{subtext}</div>
    </div>
  );
}
