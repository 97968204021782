import React, { memo } from "react";
import styles from "./styles.module.scss";
import { DemoSpacesPageLayoutModel } from "./indexModel";
import SpaceHeader from "../../SpaceHeader";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { Loading } from "app/components/Wrappers";
import ScheduledModal from "app/routes/DemoSpace/Modals/InspirationalModal";

interface Props {
  children: React.ReactNode;
}

const DemoSpacesPageLayout = ({ children }: Props) => {
  const {
    selectedDemoSpace,
    pending,
    onTimelineClick,
    onScheduleClick,
    onMembersClick,
    isScheduledModalOpen,
  } = DemoSpacesPageLayoutModel();

  if (!selectedDemoSpace) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <SpaceHeader
          displaySettings={false}
          title={
            selectedDemoSpace.space.athleteName &&
            selectedDemoSpace.space.athleteName
          }
          sport={
            selectedDemoSpace.space.sport ? selectedDemoSpace.space.sport : null
          }
          avatar={
            selectedDemoSpace.space.avatar
              ? selectedDemoSpace.space.avatar
              : null
          }
          onTimelineClick={onTimelineClick}
          onScheduleClick={onScheduleClick}
          onMembersClick={onMembersClick}
        />
        <div className={styles.content_container}>
          {selectedDemoSpace && pending ? (
            <Loading isLoading loadType="spinner" />
          ) : (
            children
          )}
        </div>
      </div>
      <ScheduledModal isOpen={isScheduledModalOpen} onClose={onScheduleClick} />
    </>
  );
};

export default memo(DemoSpacesPageLayout);
