import { ActionType, action } from "typesafe-actions";

export const initBookSession = (session: any) => action("@bookSession.init", session);
export const setBookSession = (session: any) => action("@bookSession.set", session);
export const resetBookSession = () => action("@bookSession.reset");
export const setIsComplete = (isComplete: boolean) => action("@bookSession.setIsComplete", isComplete);
export const setSelectedSession = (session: any) => action("@bookSession.setSelectedSession", session);

const thisActions = {
  setBookSession,
  resetBookSession,
  setIsComplete,
  setSelectedSession,
  initBookSession,
};

export type BookSessionAction = ActionType<typeof thisActions>;
