import React from "react";
import styles from "./styles.module.scss";
import Post from "../Post";
import useTemplatePostsContainerModel from "./indexModel";
import NoPosts from "../NoResults/NoPosts";
import { Loading } from "../Wrappers";
import ScheduledAlert from "../ScheduledAlert";
import { toInteger } from "lodash";
import PostModel from "app/models/PostModel";
import null_post from "../../../public/images/null-posts.svg";

export interface TemplatePostsContainerProps {
  schedulesGoToLocation?: string;
  canUploadReplyMedia?: boolean;
  hidePinBadge?: boolean;

  posts?: PostModel[];
  groupedPosts?: Record<string, PostModel[]>;
  onPostDelete?: (post: PostModel) => void;
  onPostUpdate?: (post: PostModel) => void;
  containerRef?: any;
  pending?: boolean;
}

const TemplatePostsContainer: React.FC<TemplatePostsContainerProps> = ({
  hidePinBadge,
  posts,
  groupedPosts,
  onPostDelete,
  onPostUpdate,
  // containerRef,
  pending,
}: TemplatePostsContainerProps) => {
  const { t, self } = useTemplatePostsContainerModel();

  if (!self) {
    return null;
  }

  if (posts?.length === 0 || !groupedPosts) {
    if (!pending) {
      return (
        <NoPosts
          image={null_post}
          description={t("All lists created by you will appear here")}
          className={styles.no_posts_image}
        />
      );
    } else {
      return <Loading isLoading loadType="spinner" />;
    }
  }

  return (
    <>
      {Object?.entries(groupedPosts).map(([scheduledIn, postsGroup]) => (
        <div key={scheduledIn}>
          <ScheduledAlert scheduledIn={toInteger(scheduledIn)} />
          <div className={styles.posts_container}>
            {Array.isArray(postsGroup) &&
              postsGroup.map((post: PostModel) => (
                <Post
                  key={post.id}
                  post={post}
                  user={self}
                  onEdit={onPostUpdate}
                  onEditTags={onPostUpdate}
                  onRemove={onPostDelete}
                  hidePinBadge={hidePinBadge}
                  canPinPost={false}
                />
              ))}
          </div>
        </div>
      ))}
      {/* {pending && posts?.length > 0 && <Loading isLoading loadType="spinner" />} */}
      {/* <div ref={containerRef} /> */}
    </>
  );
};

export default TemplatePostsContainer;
