import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import ModalLayout from "../ModalLayout/ModalLayout";
import checkmark from "../../../../public/images/checkmark-green.png";
import errorIcon from "../../../../public/images/error-x.svg";
import Button from "app/components/Button/ButtonVariant";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  classNames?: string;
  title: string;
  error?: string;
  buttonTitle?: string;
}

const SuccessModal = ({
  isOpen,
  onClose,
  error,
  classNames,
  title,
  children,
  buttonTitle,
}: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={cls(styles.container, classNames)}>
        <div className={styles.header_wrapper}>
          <h4 className={styles.title}>{title}</h4>
          <i
            className={cls("dismiss ico ico-x", styles.close_icon)}
            onClick={onClose}
          />
        </div>
        <img
          src={error ? errorIcon : checkmark}
          alt="success"
          className={styles.checkMark}
        />
        {children}
        <span className={styles.button_container}>
        <Button
          title={buttonTitle}
          buttonType="secondary-outline"
          onClickFunc={onClose}
        />
        </span>
      </div>
    </ModalLayout>
  );
};

export default SuccessModal;
