import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import Button from "../Button/ButtonVariant";
import { cls } from "app/utils";
import useCsvFileUpload from "./indexModel";
import { useTranslation } from "react-i18next";

export interface CsvFileUploadProps {
  isOpen?: boolean;
  onClose: () => void;
  onDataParsed?: (data: any) => void;
  getFileOnUpload?: (file: File) => void;
}

const CsvFileUpload = ({
  isOpen,
  onClose,
  onDataParsed,
  getFileOnUpload,
}: CsvFileUploadProps) => {
  const { t } = useTranslation();
  const {
    isLoading,
    uploadError,
    progress,
    getRootProps,
    getInputProps,
    isDragActive,
    modalHandler,
  } = useCsvFileUpload({ onClose, onDataParsed, getFileOnUpload });

  return (
    <ModalLayout
      onClose={modalHandler}
      isOpen={isOpen}
      classNames={styles.modal}
    >
      <div className={styles.content_wrapper}>
        <h3 className={styles.modal_title}>{t("Import CSV or Excel File")}</h3>
        {isLoading ? (
          <div className={styles.upload_progress}>
            <div className={styles.upload_progress_content}>
              {!uploadError && <p>{t("Loading...")}</p>}
              {uploadError && <p>{t("Upload failed")}</p>}

              <div className={styles.progress_bar_container}>
                <div
                  className={styles.progress_bar}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.dropzone_container}>
            <div {...getRootProps({ className: "dropzone" })}>
              <div
                className={cls(styles.dropzone_inner, {
                  [styles.dropzone_inner_active]: isDragActive,
                })}
              >
                <h1>{t("Drag Media Here")}</h1>
                <p>{t("or you can...")}</p>
                <label
                  htmlFor="media__uploader"
                  className={cls("btn btn-primary", styles.upload_btn)}
                >
                  {t("Select Media to Upload")}
                </label>
              </div>
            </div>
            <input {...getInputProps()} id="media__uploader" />
          </div>
        )}
        <div className={styles.footer_buttons}>
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            buttonStyles={styles.cancel_btn}
            onClickFunc={modalHandler}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default CsvFileUpload;
