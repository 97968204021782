import React from "react";
import styles from "./styles.module.scss";
import Filter from "app/components/Filter";
import ResourcesList from "../ResourcesList";
import { useResourcesContainerModel } from "./indexModel";

interface Props {
  onSelectMedia: (media?: any) => void;
}

const ResourcesContainer = ({ onSelectMedia }: Props) => {
  const { data, pending, filterHandler, onClearFilters, sentryRef } =
    useResourcesContainerModel();

  return (
    <>
      <div className={styles.dropdown_container}>
        <hr />
        <Filter
          filterClickHandler={filterHandler}
          onFilterClear={onClearFilters}
          hideNotes
          hideAuthors
        />
      </div>
      <ResourcesList
        data={data}
        pending={pending}
        containerRef={sentryRef}
        onSelectMedia={onSelectMedia}
      />
    </>
  );
};

export default ResourcesContainer;
