import { useEffect, useRef, useState } from "react";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { deletePost, editPost } from "app/actions/postingForm";
import moment from "moment";
import {
  clearSchedulePost,
  fetchScheduledPosts,
  scheduledPosts,
} from "app/actions/schedule";
import PostModel from "app/models/PostModel";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

export const SpacesScheduledTimelineModel = () => {
  const dispatch = useDispatch();
  const postsActions = useActions({
    fetchScheduledPosts,
    clearSchedulePost,
    editPost,
    deletePost,
  });

  const urlParams = useParams<{ id: string }>();

  const { selectedSpace } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );

  const [data, setData] = useState<PostModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [postsParams] = useState({
    space_id: urlParams?.id,
    page: 1,
    perPage: 20,
    order: "asc",
    orderBy: "scheduled_at",
    timezoneOffset: moment().utcOffset().toString(),
  });

  const [query, setQuery] = useState(postsParams);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (page?: number) => {
    setLoading(true);
    let postQuery = { ...query };

    if (page === 1) {
      postQuery = { ...query, page: 1 };
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      const res = await postsActions.fetchScheduledPosts(
        postQuery,
        abortControllerRef.current.signal
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData: any) => [...prevData, ...res]);
          } else {
            setData(res);
          }

          if (res?.length < postsParams.perPage) {
            setHasMore(false);
          }

          setQuery({
            ...postQuery,
            page: postQuery.page + 1,
          });
        } else {
          console.error("API response is null or undefined");
        }
      }
      setLoading(false);
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
      setLoading(false);
    }
  };

  const initialLoadPosts = () => {
    setData([]);
    setHasMore(true);
    fetchPage(1);
  };

  const loadMore = () => {
    if (!loading) {
      fetchPage();
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  // initial fetching
  useEffect(() => {
    initialLoadPosts();

    return () => {
      dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearSchedulePost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setHasMore(true);
      setQuery(postsParams);
    };
  }, [dispatch, postsActions]);

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          setData(
            data.filter(
              (filteredPost: PostModel) => filteredPost.id !== post.id
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post);
    }
  };

  return {
    postsParams,
    selectedSpace,
    data,
    loading,
    onPostDelete,
    onPostUpdate,
    sentryRef,
  };
};
