import { fetchReplies, reportPost } from "app/actions/posts";
import PostModel, { PostingModel } from "app/models/PostModel";
import { useActions, useSelector } from "app/utils/hooks";
import { useState } from "react";
import { RootState } from "typedefs";
import { PostRepliesProps } from ".";
import { createPost, deletePost } from "app/actions/postingForm";
import { shallowEqual } from "react-redux";

export const RepliesModel = ({ post }: PostRepliesProps) => {
  const replyActions = useActions({
    fetchReplies,
    createPost,
    deletePost,
    reportPost,
  });
  const { replyPending } = useSelector(
    (state: RootState) => state.posts,
    shallowEqual
  );
  const { attachment } = useSelector(
    (state: RootState) => state.mediaLibraryData,
    shallowEqual
  );
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [cursor, setCursor] = useState<string | null>(
    post.replies && post.replies.length > 0 ? post.replies[0].cursor : null
  );
  const [hasMoreReplies, setHasMoreReplies] = useState(true);
  const [replies, setReplies] = useState(post?.replies ?? []);
  const [reportModal, setReportModal] = useState({ isOpen: false, id: null });
  const [isEditingReply, setIsEditingReply] = useState(false);

  const repliesParams = {
    cursor,
    limit: 8,
    throttle: 10000,
    order: "desc",
    reverse: true,
  };

  const fetchMoreReplies = () => {
    replyActions
      .fetchReplies(post.id, repliesParams)
      .then((res: PostModel[]) => {
        if (res) {
          setCursor(res[0].cursor);
          setReplies([...res, ...replies]);
          if (res.length < repliesParams.limit) {
            setHasMoreReplies(false);
          }
        }
      });
  };

  const onSubmitReply = (data: PostingModel) => {
    if (post && post.id) {
      const replyParams: {
        rootId: string;
        resources?: any[];
        scheduled_in?: string | null;
        spaceId?: string | null;
      } = {
        ...data,
        rootId: post.id,
      };

      if (attachment) {
        replyParams.resources = [attachment];
      }

      if (post.spaceId) {
        replyParams.spaceId = post.spaceId;
      }

      replyActions.createPost(replyParams).then((res) => {
        if (replies && replies.length > 0) {
          setReplies([
            ...replies,
            { ...res, userAvatar: self.avatar, userName: self.name },
          ]);
        } else {
          setReplies([{ ...res, userAvatar: self.avatar }]);
        }
      });
    }
  };

  const onDeleteReply = (reply: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this reply?"
    );
    if (confirmation) {
      replyActions
        .deletePost(reply.id)
        .then(() => {
          setReplies((prevPosts) => prevPosts.filter((r) => r.id !== reply.id));
        })
        .catch((error) => {
          console.error("Error deleting reply:", error);
        });
    }
  };

  const onReplyReportClick = (reply: PostModel) => {
    setReportModal({ isOpen: true, id: reply.id });
  };

  const reportModalClose = () => {
    setReportModal({ isOpen: false, id: null });
  };

  const onReport = (message?: string) => {
    replyActions
      .reportPost(reportModal.id, message)
      .then(() => {
        setReplies((prevPosts) =>
          prevPosts.filter((post) => post.id !== reportModal.id)
        );
        reportModalClose();
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  // image/videos preview modal
  const modalHandler = (data?: any) => {
    setIsModalOpen(!isModalOpen);
    setSelectedMedia(data);
  };

  const onEditClick = () => {
    setIsEditingReply(true);
  };

  const onEditClose = () => {
    setIsEditingReply(false);
  };

  return {
    replies,
    isModalOpen,
    modalHandler,
    selectedMedia,
    fetchMoreReplies,
    hasMoreReplies,
    replyPending,
    onSubmitReply,
    onDeleteReply,
    onReplyReportClick,
    onReport,
    reportModal,
    reportModalClose,
    onEditClick,
    onEditClose,
    isEditingReply,
  };
};
