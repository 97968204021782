import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import useDatafileIcons from "app/components/inputs/SelectBox/helpers/DatafileIcons";
import { fetchMediaById } from "app/actions/media-library-data";
import { shallowEqual, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import { RootState } from "typedefs";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { Loading } from "app/components/Wrappers";
import VideoAttachment from "./VideoAttachment";

interface Props {
  uploadedMedia: MediaTypes | null;
  onClearMedia?: () => void;
}

const AttachmentPreview = ({ uploadedMedia, onClearMedia }: Props) => {
  const { dataFileIconHandler } = useDatafileIcons();
  const mediaActions = useActions({ fetchMediaById });
  const [selectedMedia, setSelectedMedia] = useState(uploadedMedia || null);

  const uploadError = useSelector(
    (state: RootState) => state.mediaLibraryData.error,
    shallowEqual,
  );
  useEffect(() => {
    if (uploadedMedia) {
      setSelectedMedia(uploadedMedia);
    }
  }, [uploadedMedia]);

  useEffect(() => {
    if (!selectedMedia) {
      return null;
    }

    const fetchMedia = () => {
      if (selectedMedia && selectedMedia?.id && !selectedMedia?.processed) {
        mediaActions.fetchMediaById(selectedMedia?.id).then((res) => {
          if (res?.processed) {
            setSelectedMedia(res);
          }
        });
      }
    };

    let pollingInterval: any;

    pollingInterval = setInterval(() => {
      fetchMedia();

      if (selectedMedia?.processed) {
        clearInterval(pollingInterval);
      }
    }, 5000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [selectedMedia, uploadedMedia]);

  const onClearClick = () => {
    onClearMedia();
    setSelectedMedia(null);
  };

  const renderImage = () => {
    if (!selectedMedia?.processed) {
      return <Loading isLoading loadType="spinner" />;
    } else {
      if (selectedMedia?.object === "video") {
        return <VideoAttachment attachment={selectedMedia} />;
      } else if (
        selectedMedia?.object === "image" &&
        selectedMedia?.originalUrl
      ) {
        return (
          <img
            src={selectedMedia?.originalUrl}
            alt="image"
            className={styles.preview}
          />
        );
      } else {
        return (
          <img
            src={dataFileIconHandler(
              selectedMedia?.extension || selectedMedia?.object,
            )}
            alt="media"
            className={styles.preview}
          />
        );
      }
    }
  };

  return (
    <div className={styles.preview_container}>
      {selectedMedia && !uploadError && renderImage()}
      {onClearMedia && (
        <button className={styles.delete_preview_btn} onClick={onClearClick}>
          ✕
        </button>
      )}
    </div>
  );
};

export default AttachmentPreview;
