import React from "react";
import styles from "./styles.module.scss";

import ResourcesList from "app/routes/Resources/ResourcesList";
import { useSelectUploadedResourcesModel } from "./indexModel";
import FileUploadFilter from "../FileUploadFilter";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";

interface Props {
  onSelectMedia: (media: MediaTypes) => void;
}

const SelectUploadedResources = ({ onSelectMedia }: Props) => {
  const { data, pending, filterHandler, sentryRef } =
    useSelectUploadedResourcesModel();

  return (
    <div className={styles.resources_container}>
      <FileUploadFilter onFilterChange={filterHandler} disabled={pending} />
      <div className={styles.resources_list_container}>
        <ResourcesList
          data={data}
          pending={pending}
          containerRef={sentryRef}
          onSelectMedia={onSelectMedia}
        />
      </div>
    </div>
  );
};

export default SelectUploadedResources;
