import React, { useEffect } from "react";
import PagePanel from "app/components/PagePanel";
import styles from "./styles.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import CopyField from "../CopyField";
import Navbar from "../Navbar";
import ReturnBar from "../Navbar/ReturnBar";
import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { fetchCoachProfile } from "app/actions/scheduling/coachProfile";
import { Loading } from "app/components/Wrappers";
import { useTranslation } from 'react-i18next';

interface FormValues {
  calendarUrl: string;
  publicityCalendarEnabled: boolean;
  publicityCalendarUrl: string;
  calendarSyncEnabled: boolean;
}

export default function CalendarSettings() {
  const { t } = useTranslation();

  const {  handleSubmit } = useForm({
    defaultValues: {
      calendarUrl: 'https://example.com/calendar.ics',
      publicityCalendarEnabled: false,
      publicityCalendarUrl: "https://example.com/public-calendar.ics",
      calendarSyncEnabled: false,
    },
  });

  const coachProfile = useSelector((state: RootState) => state.coachProfile);

  const userId: any = useSelector((state: RootState) => state.user.self.id);
  const coachProfileActions = useActions({
    fetchCoachProfile,
  });

  useEffect(() => {
    if (coachProfile.data === null) {
      coachProfileActions.fetchCoachProfile(userId);
    }
  }, []);

  if (coachProfile.pending) {
    return <Loading loadType="spinner" isLoading className={styles.loadingSpinner} />;
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };

  return (
    <>
    <ReturnBar />
    <div className={styles.container}>
      <Navbar location={'calendar'}/>
      <div className={styles.insideContainer}>
      <PagePanel title={t('Calendar Integrations')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <label htmlFor="calendarUrl" className={styles.labelStyle}>
              {t('Subscribe to your Coach Now Calendar')}
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                {t('Copy the URL so you can paste it in your preferred calendar app.')}
              </div>
              <div className={styles.customText}>
                <strong className={styles.boldText}>{t('Sessions')}</strong> {t('and ')}
                <strong className={styles.boldText}>{t('unavailable times')}</strong>{" "}
                {t('will be synced with your preferred calendar.')}
              </div>
            </div>
            <CopyField value={coachProfile.data.calendarSubscriptionUrl} />
            {/* <div className={styles.horizontalLine} />
            <label htmlFor="publicityCalendar" className={styles.labelStyle}>
              Publicly Available Calendar
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                Copy the URL, so you can link it from your website.
              </div>
              <div className={styles.customText}>
                Anyone will be able to view your availability and book lessons.
              </div>
            </div>
            <div className={styles.toggleContainer}>
              <SwitchToggle
                defaultChecked={false}
                title="Enable public calendar"
                register={register}
                name="publicityCalendarEnabled"
                setValue={setValue}
                defaultValue={false}
              />
              {isCalendarPublic && (
                <CopyField
                  value={watch("publicityCalendarUrl")}
                  className={styles.copyField}
                />
              )}
            </div>
            <div className={styles.horizontalLine} />
            <label htmlFor="externalCalendar" className={styles.labelStyle}>
              Connect External Calendar
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px", maxWidth: '550px' }}>
              <div className={styles.customText}>
                Copy the URL, so you can link it from your website. You can change this selection at any time.
                <br />
                To learn more about calendar sync, visit our{" "}
                <a href="https://support.google.com/calendar" className={styles.link}>
                  Google
                </a>{" "}
                help articles.
              </div>
            </div>
            <div className={styles.horizontalLine} />
            <label htmlFor="calendarSync" className={styles.labelStyle}>
              Enable Calendar Sync
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                Enable this option to sync your calendar with external services.
              </div>
            </div>
            <div className={styles.toggleContainer}>
              <SwitchToggle
                defaultChecked={false}
                title="Enable calendar sync"
                register={register}
                name="calendarSyncEnabled"
                setValue={setValue}
                defaultValue={false}
              />
            </div> */}
            {/* <button type="submit" className={styles.submitButton}>
              Save changes
            </button> */}
          </div>
        </form>
      </PagePanel>
      </div>
    </div>
    </>
  );
}
