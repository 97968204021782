import Select, { components } from 'react-select';
import React from "react";
import styles from './styles.module.scss';

interface OptionProps {
  value: string;
  label: string;
}

interface SelectBoxProps {
  options: OptionProps[];
  value: OptionProps;
  setValue: any;
  noOptionsText?: string;
  ariaLabel?: string | null;
  containerStyle?: string | null;
  customControl?: any;
}

const SelectBox = ({
                     options,
                     value,
                     setValue,
                     noOptionsText,
                     ariaLabel = null,
                     containerStyle = null,
                   }: SelectBoxProps) => {

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 'none',
      height: '38px',
      width: '152px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      position: 'static',
    }),
    container: (provided) => ({
      ...provided,
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
      height: "40px",
      color: '#031e5e',
    }),
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noOptionsText || 'No options'}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div
      className={`${styles.select_box_wrap} ${containerStyle}`}>
      <Select
        placeholder={'Select'}
        styles={customStyles}
        value={value}
        onChange={setValue}
        aria-label={ariaLabel}
        components={{
          IndicatorSeparator: () => null,
          NoOptionsMessage,
        }}
        options={options}
        menuPlacement="auto"
      />
    </div>
  );
};

export default SelectBox;
