import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import closeIcon from "../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import UserModel from "app/models/UserModel";
import { cls } from "app/utils";
import {
  fetchSpaceMembers,
  setModalOpen,
  transferSpaceOwnership,
} from "app/actions/space";
import UserAvatar from "app/components/UserAvatar";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  isModalOpen: boolean;
  modalHandler: () => void;
}

const TransferSpaceModal = ({ isModalOpen, modalHandler }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transferActions = useActions({
    fetchSpaceMembers,
    transferSpaceOwnership,
  });
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { selectedSpace } = useSelector((state: RootState) => state.space, shallowEqual);
  const [spaceMembers, setSpaceMembers] = useState(null);
  const [error, setError] = useState({
    youOwnGroup: false,
    noEmail: false,
    invalidMember: false,
    sportTitle: false,
  });
  const [enteredEmail, setEnteredEmail] = useState(null);
  const [enteredSportName, setEnteredSportName] = useState(null);

  useEffect(() => {
    transferActions
      .fetchSpaceMembers(selectedSpace.id)
      .then((res: UserModel[]) => {
        setSpaceMembers(res);
      });

    return () => {
      setSpaceMembers(null);
    };
  }, []);

  const emailHandler = (e: any) => {
    setEnteredEmail(e.target.value);
  };

  const groupNameHandler = (e: any) => {
    setEnteredSportName(e.target.value);
  };

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const saveHandler = () => {
    const updatedError = {
      youOwnGroup:
        selectedSpace.ownerId === self.id && self.email === enteredEmail,
      noEmail: !enteredEmail || !enteredEmail.trim().length,
      invalidMember:
        spaceMembers &&
        !spaceMembers.some((coach: UserModel) => coach.email === enteredEmail),
      sportTitle:
        selectedSpace.name.toLowerCase() !==
        enteredSportName.toLowerCase().trim(),
    };

    setError(updatedError);

    if (
      !updatedError.youOwnGroup &&
      !updatedError.noEmail &&
      !updatedError.invalidMember &&
      !updatedError.sportTitle
    ) {
      transferActions
        .transferSpaceOwnership({
          id: selectedSpace.id,
          email: enteredEmail,
        })
        .then(() => {
          route("/spaces");
          dispatch(setModalOpen(true));
        });
    }
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Transfer Space Ownership")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>

        <div className={styles.content_container}>
          <UserAvatar
            sizeHeight={44}
            sizeWidth={44}
            altName="group"
            className={styles.header_logo}
            userName={selectedSpace.athleteName}
            srcName={selectedSpace && selectedSpace.avatar}
          />
          <b>{t("New Owner")}</b>
          <p>
            {t(
              "Enter the email of a Member within the Space to transfer ownership."
            )}
          </p>
        </div>

        <div className={styles.text_content_container}>
          <label htmlFor="owner">{t("New Owner's Email")}</label>
          <input
            className={cls({
              [styles.error]:
                error.youOwnGroup || error.noEmail || error.invalidMember,
            })}
            type="email"
            name="owner"
            id="owner"
            onChange={emailHandler}
          />
          {error.youOwnGroup && (
            <div className="helper-text error">
              {t("But you already own this Space.")}
            </div>
          )}
          {(error.noEmail || error.invalidMember) && (
            <div className="helper-text error">
              {t("Please enter an email of a Member within the Space.")}
            </div>
          )}
        </div>

        <div className={styles.text_content_container}>
          <label htmlFor="name">{t("Space Sport to Confirm")}</label>
          <input
            className={cls({ [styles.error]: error.sportTitle })}
            type="text"
            name="name"
            id="name"
            onChange={groupNameHandler}
          />
          {error.sportTitle && (
            <div className="helper-text error">
              {t("Entered sport does not match Space sport.")}
            </div>
          )}
        </div>

        <section className={styles.rights}>
          {t(
            'By clicking "Confirm and Transfer", you are transferring Space ownership and permissions to this Member. You will still be part of the Space'
          )}
        </section>

        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="primary"
            title={t("Confirm and Transfer")}
            onClickFunc={saveHandler}
            disabled={!selectedSpace}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default TransferSpaceModal;
