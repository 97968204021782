import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { useActions, useSelector } from "app/utils/hooks";
import { clearAppliedTemplates } from "app/actions/spaceCreation";
// import { fetchSelf } from "app/actions/user";
import { RootState } from "typedefs";
import InvitationToMultipleSpaces from "./StepsComponents/InvitationToMultipleSpaces";
import SelectSpaceList from "../CreateNewSpace/StepsComponents/SelectSpaceList";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { clearList } from "app/actions/list";

const CreateNewMultipleSpaces = () => {
  const { t } = useTranslation();
  // const useractions = useActions({ user: fetchSelf });
  const newSpaceActions = useActions({ clearAppliedTemplates, clearList });
  const { step } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      newSpaceActions.clearAppliedTemplates();
      newSpaceActions.clearList();
    };
  }, []);

  const renderContent = () => {
    if (step === 2) {
      return <SelectSpaceList />;
    }

    return <InvitationToMultipleSpaces />;
  };

  return (
    <div className={styles.container}>
      <PagePanel
        title={t("Create Multiple Spaces")}
        titleStyles={styles.header_title}
      />
      <div className={styles.panel}>{renderContent()}</div>
    </div>
  );
};

export default CreateNewMultipleSpaces;
