import {DateTime} from "luxon";

export const days_of_the_week = [
  { value: 0, label: 'Sunday', short: 'Sun' },
  { value: 1, label: 'Monday', short: 'Mon' },
  { value: 2, label: 'Tuesday', short: 'Tue' },
  { value: 3, label: 'Wednesday', short: 'Wed' },
  { value: 4, label: 'Thursday', short: 'Thu' },
  { value: 5, label: 'Friday', short: 'Fri' },
  { value: 6, label: 'Saturday', short: 'Sat' },
];

export const parseTime = (time: string) => {
  const [hour, minute] = time.split(':');
  return parseInt(hour, 10) * 60 + parseInt(minute, 10);
};

export const convertTime = (time: number) => {
  const hour = Math.floor(time / 60);
  const minute = time % 60;
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
};

export const dateToString = (time_table: any) => {
  let start_date;
  let end_date;

  if(time_table.customPeriod === false) {
    time_table.startDate = null;
    time_table.endDate = null;
  } else {
    if(time_table.startDate.target && time_table.endDate.target) {
      start_date = time_table.startDate.target.value;
      end_date = time_table.endDate.target.value;
    } else {
      start_date = time_table.startDate;
      end_date = time_table.endDate;
    }

    return {
      ...time_table,
      startDate: DateTime.fromJSDate(start_date).toFormat('yyyy-MM-dd'),
      endDate: DateTime.fromJSDate(end_date).toFormat('yyyy-MM-dd'),
    };
  }

  return time_table;
};

export const stringToDate = (time_table: any) => {
  let start_date;
  let end_date;
  if(time_table.customPeriod === false) {
    time_table.startDate = null;
    time_table.endDate = null;
  } else {
    start_date = DateTime.fromFormat(time_table.startDate, 'yyyy-MM-dd').toJSDate();
    end_date = DateTime.fromFormat(time_table.endDate, 'yyyy-MM-dd').toJSDate();
    time_table.startDate = start_date;
    time_table.endDate = end_date;
  }

  return time_table;
};

export const parseSchedules = (schedule: any) => {
  let from;
  let to;

  if (schedule.startDate) {
    from = DateTime.fromFormat(schedule.startDate, 'yyyy-MM-dd').toJSDate();
  } else {
    from = DateTime.now().startOf('year').toJSDate();
  }

  if (schedule.endDate) {
    to = DateTime.fromFormat(schedule.endDate, 'yyyy-MM-dd').toJSDate();
  } else {
    to = DateTime.now().endOf('year').toJSDate();
  }

  return {
    ...schedule,
    startDate: from,
    endDate: to,
    dailyTimeTables: schedule.dailyTimeTables
      .map((day: any) => ({
        ...day,
        active: day.active,
        value: day.day,
        label: days_of_the_week[day.day].label,
        short: days_of_the_week[day.day].short,
        startTime: convertTime(day.beginAt),
        endTime: convertTime(day.endAt),
      }))
      .sort((a: any, b: any) => a.day - b.day),
    customPeriod: schedule.startDate !== null && schedule.endDate !== null,
  };
};

export const currency_list = [
  { value: 'USD', label: 'US Dollar (USD, $)', currency: '$' },
  { value: 'AUD', label: 'Australian Dollar (AUD, $)', currency: '$' },
  { value: 'GBP', label: 'British Pound (GBP, £)', currency: '£' },
  { value: 'CAD', label: 'Canadian Dollar (CAD, $)', currency: '$' },
  { value: 'EUR', label: 'Euro (EUR, €)', currency: '€' },
  { value: 'JPY', label: 'Japanese Yen (JPY, ¥)', currency: '¥' },
  { value: 'AED', label: 'United Arab Emirates Dirham (AED)', currency: 'AED' },
  { value: 'MXN', label: 'Mexican Peso (MXN)', currency: 'MXN' },
  { value: 'NZD', label: 'New Zealand Dollar (NZD, $)', currency: '$' },
];

export const time_zone_list = [
  { value: "Etc/GMT+12", code: "International Date Line West", label: "(GMT-12:00) International Date Line West" },
  { value: "Pacific/Pago_Pago", code: "American Samoa", label: "(GMT-11:00) American Samoa" },
  { value: "Pacific/Midway", code: "Midway Island", label: "(GMT-11:00) Midway Island" },
  { value: "Pacific/Honolulu", code: "Hawaii", label: "(GMT-10:00) Hawaii" },
  { value: "America/Juneau", code: "Alaska", label: "(GMT-09:00) Alaska" },
  { value: "America/Los_Angeles", code: "Pacific Time (US & Canada)", label: "(GMT-08:00) Pacific Time (US & Canada)" },
  { value: "America/Tijuana", code: "Tijuana", label: "(GMT-08:00) Tijuana" }
];

export const pricings = [
  { title: 'Test title 1', duration: '30 min', price: 50, sessionType1: ['basics', 'full swing'], sessionType2: 'full swing', location: 'Golf Club', setDefault: true },
  { title: 'Test title 2', duration: '15 min', price: 100, sessionType1: ['full swing'], sessionType2: 'putting', location: 'Golf Academy', setDefault: false },
  { title: 'Test title 3', duration: '60 min', price: 150, sessionType1: ['wedges'], sessionType2: 'bunkers', location: 'Driving Range', setDefault: false },
  { title: 'Test title 4', duration: '75 min', price: 75, sessionType1: ['putting'], sessionType2: 'recover', location: 'Mini Golf Course', setDefault: false },
  { title: 'Test title 5', duration: '90 min', price: 200, sessionType1: ['playing lesson'], sessionType2: 'club fitting', location: 'Golf Course', setDefault: false },
];

export const list_of_months = ["1 Month", "2 Months", "3 Months", "4 Months", "5 Months", "6 Months"];

export const list_of_times = [
  "1 Week",
  "2 Weeks",
  "3 Weeks",
  "4 Weeks",
  "5 Weeks",
  "6 Weeks",
  "7 Weeks",
  "8 Weeks",
  "3 Months",
  "4 Months",
  "5 Months",
  "6 Months",
  "Unlimited",
];
