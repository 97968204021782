import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import SuccessModal from "app/components/Layouts/SuccessModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { newOwnerData, setModalOpen } from "app/actions/space";
import { useTranslation } from 'react-i18next';

const SpaceTransferSuccessModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isModalOpen: isSuccessModalOpen, newOwner } = useSelector(
    (state: RootState) => state.space, shallowEqual
  );

  const successModalHandler = useCallback(() => {
    dispatch(setModalOpen(!isSuccessModalOpen));
    dispatch(newOwnerData(null));
  }, [isSuccessModalOpen]);

  if (!isSuccessModalOpen || !newOwner) {
    return null;
  }

  const onCloseHandler = () => {
    successModalHandler();
  };

  return (
    <SuccessModal
      buttonTitle={t("Done")}
      title={t("Ownership Transferred")}
      isOpen={isSuccessModalOpen}
      onClose={onCloseHandler}
    >
      <div className={styles.success_container}>
        <h3>{t("New Owner Confirmed")}</h3>
        <p>{newOwner && newOwner.userName} {t("is now the Owner of this Space.")}</p>
      </div>
    </SuccessModal>
  );
};

export default SpaceTransferSuccessModal;
