import { useTranslation } from "react-i18next";

const useSmartListsData = () => {
  const { t } = useTranslation();

  return [
    {
      id: "b58c0eb022b1013bf060784f4383ab9b",
      name: t("Unanswered by me"),
      object: "smart_list",
    },
    {
      id: "b58caf3022b1013bf061784f4383ab9b",
      name: t("15 days inactive"),
      object: "smart_list",
    },
    {
      id: "db854d8026c3013bfa4d784f4383ab9b",
      name: t("15 days active"),
      object: "smart_list",
    },
  ];
};

export default useSmartListsData;
