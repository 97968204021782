import React, { memo } from "react";
import styles from "./styles.module.scss";
import { InvitationsListModel } from "./indexModel";
import { Loading } from "app/components/Wrappers";
import SingleInvitation from "./SingleInvitation";
import { cls } from "app/utils";
import InvitationModel from "app/models/InvitationModel";
import { getInvitationGroupTitle } from "../helpers/getInvitationGroupTitle";
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  nullResultClassname?: string;
}

const InvitationsList = ({ className, nullResultClassname }: Props) => {
  const { t } = useTranslation();
  const {
    loading,
    incomingInvitations,
    invitedInvitationsCount,
    notificationsContainer,
    onDeclineInvite,
  } = InvitationsListModel();

  if (invitedInvitationsCount === 0 && !loading) {
    return (
      <div className={cls(styles.empty_container, nullResultClassname)}>
        <p className={styles.empty_text}>{t("No invitations")}</p>
      </div>
    );
  }

  return (
    <div className={cls(className, styles.container)}>
      {Object.entries(incomingInvitations).map(
        ([key, invitations]: any) =>
          invitations.length > 0 && (
            <div className={styles.invitations_list} key={key}>
              <label className={styles.group_label}>
                {getInvitationGroupTitle(key, t)}
              </label>
              {invitations.map((notification: InvitationModel) => {
                return (
                  <div key={notification.id}>
                    <SingleInvitation
                      invitation={notification}
                      onDecline={onDeclineInvite}
                    />
                  </div>
                );
              })}
            </div>
          )
      )}
      {loading && <Loading loadType="spinner" isLoading={loading} />}

      <div ref={notificationsContainer} />
    </div>
  );
};

export default memo(InvitationsList);
