import { scheduleIn } from "app/actions/schedule";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// import { RootState } from "typedefs";
// import { RootState } from "typedefs";

interface Props {
  onClose: () => void;
  onScheduleChange: (pickedAt: number, pickedTime: string) => void;
}

export const useScheduleModal = ({ onClose, onScheduleChange }: Props) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>("Days");
  // const scheduledData = useSelector(
  //   (state: RootState) => state.schedulePost.scheduleIn
  // );
  // const [generatedTimeValue, setGeneratedTimeValue] = useState<number | null>(
  //   null
  // );
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const selectTimeOptions = useMemo(() => ["Hours", "Days", "Weeks"], []);
  // const selectedPickedAtValue = useSelector(
  //   (state: RootState) => state.schedulePost.scheduleIn
  // );

  useMemo(() => {
    // if (selectedTime === "Weeks") {
    //   setGeneratedTimeValue(selectedValue * 7 * 24);
    // } else if (selectedTime === "Days") {
    //   setGeneratedTimeValue(selectedValue * 24);
    // } else {
    //   setGeneratedTimeValue(selectedValue);
    // }

    dispatch(
      scheduleIn({
        pickedAt: selectedValue === null ? null : selectedValue,
        pickedIn: selectedTime,
      })
    );
  }, [dispatch, selectedTime, selectedValue]);

  const optionValues = useCallback((maxNum: number) => {
    const options = [] as any;
    for (let i = 1; i <= maxNum; i++) {
      options.push(i);
      // options.push(i + ` ${title}${i > 1 ? "s" : ""}`);
    }
    setSelectOptions(options);
  }, []);

  useMemo(() => {
    optionValues(31);
  }, [optionValues]);

  const handleScheduleSelect = useCallback((pickedAt: number | null) => {
    setSelectedValue(pickedAt);
  }, []);

  const handleScheduleTimeSelect = useCallback(
    (pickedTime: string) => {
      // @ts-ignore
      let maxOptionValue;
      setSelectedTime(pickedTime);

      if (pickedTime === "Hours") {
        maxOptionValue = 24;
      } else if (pickedTime === "Weeks") {
        maxOptionValue = 52;
      } else {
        maxOptionValue = 31;
      }

      setSelectedValue((prevValue) => {
        // @ts-ignore
        const selectedValueInt = parseInt(prevValue, 10);
        // @ts-ignore
        if (prevValue !== null && selectedValueInt > maxOptionValue) {
          return null;
        } else {
          return prevValue;
        }
      });

      optionValues(maxOptionValue);
    },
    [optionValues]
  );

  const handleSubmit = useCallback(() => {
    const pickedAt = selectedValue === null ? null : selectedValue;
    onScheduleChange(pickedAt, selectedTime);
    onClose();
  }, [onClose, onScheduleChange, selectedValue, selectedTime]);

  return {
    selectOptions,
    handleScheduleSelect,
    handleScheduleTimeSelect,
    handleSubmit,
    selectedValue,
    selectTimeOptions,
    selectedTime,
  };
};
