/**
 * @module Model.FacilityModel
 *
 */
import { Model } from "typedefs";

export interface FacilityResponse extends Model {
  id: string;
  name: string;
}

export class FacilityModel implements Model {
  id: string;
  name: string;

  constructor(facility: FacilityResponse) {
    this.id = facility.id;
    this.name = facility.name;
  }
}
