import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PostingForm from "app/components/PostingForm";
import { shallowEqual, useSelector } from "react-redux";
import { fetchMediaById } from "app/actions/media-library-data";
import { RootState } from "typedefs";
import { useActions } from "app/utils/hooks";
import { createPost } from "../../../actions/postingForm";
import { PostingModel } from "app/models/PostModel";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

const CreatePostWithMedia = () => {
  const { t } = useTranslation();
  const params = useParams<{ resourceId: string }>();
  const [mediaData, setMediaData] = useState<any>({});

  const resourceActions = useActions({
    fetchMediaById,
    createPost,
  });
  const user = useSelector((state: RootState) => state.user.self, shallowEqual);
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const selectedLocations = useSelector(
    (state: RootState) => state.destinations.selectedDestinations,
    shallowEqual,
  );

  useEffect(() => {
    if (params?.resourceId) {
      resourceActions.fetchMediaById(params?.resourceId).then((res) => {
        setMediaData(res);
      });
    }
  }, [params]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return (
      user.planPermissions.includes("upload_to_library") &&
      user.planPermissions.includes("post_media")
    );
  };

  const onPostHandler = (data: PostingModel) => {
    if (!selectedLocations || selectedLocations.length < 1) {
      setErrorMessage(t("Please select location"));
      return;
    }

    setErrorMessage(null);
    if (!errorMessage || canUploadMedia) {
      resourceActions
        .createPost({ ...data })
        .finally(() => route("/resources"));
    }
  };

  const onCancel = () => history.goBack();

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title={t("Create post with media")} />
        {mediaData && (
          <PostingForm
            attachmentProps={mediaData}
            postTextIsRequired
            locationsIsRequired
            canUploadMedia={canUploadMedia()}
            canSchedule={
              user && user.planPermissions.includes("access_scheduled_posts")
            }
            expanded
            onPost={onPostHandler}
            disablePostButton={
              !canUploadMedia ||
              (errorMessage && errorMessage.length > 0) ||
              !mediaData
            }
            hasSchedule={false}
            canSelectMedia={false}
            onCancel={onCancel}
          />
        )}
      </div>
    </>
  );
};

export default CreatePostWithMedia;
