/** @module components/selectbox */
import React, { useState, useEffect } from "react";
import { components } from "react-select";
import { ControlProps } from "react-select/src/components/Control";
import { useStoreActions } from "app/utils/hooks";
import { fetchTags } from "app/actions/tag";
import { TagModel } from "app/models";
import SelectBox, {
  OptionsType,
  ValueType,
  DefaultOption,
  SelectProps,
} from "app/components/inputs/SelectBox/Base";
import { uuid } from "app/utils/uuid";
import { cls } from "app/utils";
import Styles from "./styles.module.scss";
import tagsIcon from "../../../../public/images/tags-icon.svg";
import { useTranslation } from "react-i18next";

interface TagOption extends DefaultOption {
  model: TagModel;
}

type PropsDef = SelectProps<TagOption> & {
  onChange?: (value: TagModel[]) => void;
  ignore?: TagModel[];
  defaultValue?: TagModel[];
  value?: TagModel[];
};

/**
 * @class TagSelect
 *
 */
export default function TagSelect(props: PropsDef) {
  const { t } = useTranslation();
  const {
    tagValContainer,
    className,
    onChange,
    value,
    isCreatable,
    defaultValue,
    ignore,
    ...rest
  } = props;

  // load tags from api
  const { tag } = useStoreActions({ tag: fetchTags });
  const [options, setOptions] = useState<OptionsType<TagOption>>(null);

  function createOption(
    val?: TagModel | string | null,
    isNew?: boolean
  ): TagOption | undefined {
    if (val == null) {
      return undefined;
    }

    if (typeof val === "string") {
      if (!val.length) {
        return undefined;
      }

      let tagModel: TagModel;
      if (tag.data && tag.data.length) {
        tagModel = tag.data.find(
          (item: TagModel) => item.name === (val as string)
        );
      }

      if (tagModel != null) {
        val = tagModel;
      } else {
        val = { id: uuid(), name: val };
      }
    }
    return { value: val.id, label: val.name, model: val, __isNew__: isNew };
  }

  const createValueOpt = (val?: TagModel[] | TagModel | null) => {
    if (typeof val === "object" && Array.isArray(val)) {
      return (val as TagModel[]).map((v: any) => createOption(v));
    } else {
      return createOption(val);
    }
  };

  // set options and value after options are fetched
  useEffect(() => {
    if (tag.data != null) {
      setOptions(tag.data.map(createOption));
    }
  }, [tag.data]);

  const handleChange = (inputVal: ValueType<TagOption>) => {
    if (onChange != null) {
      if (inputVal == null) {
        inputVal = [];
      }
      if (!Array.isArray(inputVal)) {
        inputVal = [inputVal as TagOption];
      }
      onChange((inputVal as TagOption[]).map((item) => item.model));
    }
  };

  function Control({ children, ...props }: ControlProps<TagOption>) {
    return (
      <div
        className={cls(
          "tag-pills",
          "needsclick",
          Styles.tagValueContainer,
          tagValContainer,
          props.isFocused ? Styles.focused : ""
        )}
      >
        <div className={cls(Styles.iconContainer, "needsclick")}>
          {/* <span className="pill-search-icon needsclick"></span> */}
          <img src={tagsIcon} alt="tags" />
        </div>
        <components.Control {...props}>{children}</components.Control>
      </div>
    );
  }

  return (
    <SelectBox
      className={cls("tag-select", className)}
      placeholder={t("Add some tags") + "."}
      isCreatable={isCreatable === false ? false : true}
      isMulti={true}
      onChange={handleChange}
      createOption={createOption}
      value={createValueOpt(value)}
      defaultValue={createValueOpt(defaultValue)}
      options={options}
      components={{ Control }}
      {...rest}
    />
  );
}
