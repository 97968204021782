/* eslint-disable */
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import PagePanel from "app/components/PagePanel";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import Button from "app/components/Button/ButtonVariant";
import { TagSelect } from "app/components/inputs/SelectBox";
import { useActions } from "app/utils/hooks";
import {
  clearMediaState,
  editMedia,
  fetchMediaById,
} from "../../../actions/media-library-data";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AttachmentPreview from "app/components/AttachmentPreview";
import CreateMedia from "app/components/CreateMedia";

const CreateNewResource = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const params = useParams<{ resourceId: string }>();

  const mediaActions = useActions({
    fetchMediaById,
    editMedia,
    clearMediaState,
  });

  const user = useSelector((state: RootState) => state.user.data, shallowEqual);

  const uploadedMedia = useSelector(
    (state: RootState) => state.mediaLibraryData.attachment,
    shallowEqual,
  );

  const canAddMedia =
    user && user.planPermissions.includes("upload_to_library");

  const [tags, setTags] = useState<string[]>([]);

  const fetchMedia = useCallback(() => {
    mediaActions.fetchMediaById(params?.resourceId);
  }, [params]);

  useEffect(() => {
    if (params?.resourceId) {
      fetchMedia();
    }

    return () => {
      mediaActions.clearMediaState();
      setTags([]);
    };
  }, [params]);

  const onTagsChange = useCallback(
    (tags: []) => {
      if (tags) {
        const tagNames = tags.map((tag: { name: string }) => tag.name);
        setTags(tagNames);
      }
    },
    [tags],
  );

  const addUploadedMedia = () => {
    mediaActions
      .fetchMediaById(uploadedMedia?.id)
      .then((res) => {
        mediaActions.editMedia(res.id, {
          resource: {
            ...res,
            tags,
            tmp: false,
          },
        });
      })
      .finally(() => history.push("/resources"));
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title={t("Upload Media")} titleStyles={styles.title}>
          <div className={styles.content_wrapper}>
            {uploadedMedia && (
              <AttachmentPreview uploadedMedia={uploadedMedia} />
            )}
            <CreateMedia
              hasPermission={canAddMedia}
              hideUploadBtn={uploadedMedia}
            />
            <span className={styles.tags_container}>
              <label>{t("Tags")}</label>
              <TagSelect
                className={styles.select_box}
                placeholder={t("Add some tags.")}
                defaultValue={uploadedMedia?.tags}
                isClearable
                isCreatable={true}
                isMulti={true}
                onChange={onTagsChange}
              />
            </span>
            <span className={styles.button_container}>
              <Button
                title={t("Add")}
                buttonType="primary"
                buttonStyles={styles.upload_btn}
                onClickFunc={addUploadedMedia}
                disabled={!uploadedMedia}
              />
              <Link to="/resources">
                <Button
                  title={t("Cancel")}
                  buttonType="secondary-outline"
                  buttonStyles={styles.upload_btn}
                />
              </Link>
            </span>
          </div>
        </PagePanel>
      </div>
    </>
  );
};

export default CreateNewResource;
