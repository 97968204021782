import React, { useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import SpaceMemberModel from "app/models/SpaceMemberModel";

interface Props {
  member?: SpaceMemberModel;
}

const MemberRoleBadge: React.FC<Props> = ({ member }: Props) => {
  const [role, setRole] = useState(member.spaceRole || member.role);

  if (role === "contributor") {
    setRole("Member");
  }

  return (
    <div
      className={cls(styles.role_badge, {
        [styles.coach]: role === "coach",
        [styles.athlete]: role === "athlete",
        [styles.admin]: role === "admin",
        [styles.recruiter]: role === "recruiter",
        [styles.contributor]: role === "Member",
      })}
    >
      {role}
    </div>
  );
};

export default MemberRoleBadge;
